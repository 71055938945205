import { useContext, useEffect } from "react";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";
import { useLocation } from "react-router-dom";

export default function Login() {
  const { login }: IAuthContext = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    const path = location.state?.from?.pathname ?? "/";
    const search = location.state?.from?.search ?? "";
    login(path + search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
