import { ViewIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import { InputWithCopyToClipboard } from "../../components";

export function ObfuscatedInput({
  isLoading = false,
  value,
  onDeObfuscate,
  ...props
}: {
  value?: string;
  onDeObfuscate: () => void;
  isLoading?: boolean;
} & InputProps) {
  if (value) {
    return <InputWithCopyToClipboard value={value} {...props} />;
  }

  return (
    <InputGroup size={props.size}>
      <Input
        variant="outline"
        isReadOnly
        bg="chakra-subtle-bg"
        type="password"
        value="********************************************"
        {...props}
      />
      <InputRightElement boxSize={10}>
        <IconButton
          isLoading={isLoading}
          size={props.size}
          variant="ghost"
          onClick={onDeObfuscate}
          icon={<ViewIcon />}
          aria-label="Reveal value"
        />
      </InputRightElement>
    </InputGroup>
  );
}
