import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import {
  getReservedInstances,
  ReservedInstance,
} from "../../session-management";

export function getReservedInstancesQueryKey() {
  return ["reserved-instances"];
}

export function useReservedInstancesQuery(
  options: Partial<UseQueryOptions<ReservedInstance[]>> = {},
) {
  return useQuery({
    queryKey: getReservedInstancesQueryKey(),
    queryFn: () => getReservedInstances(),
    retry: false,
    throwOnError: true,
    ...options,
  });
}
