import { useDashboardParameters } from "../context";
import { useSessionsStatsQuery } from "../hooks/useSessionsStatsQuery";
import { PeriodStatCompare } from "./PeriodStatCompare";

export function TotalApplications() {
  const filters = useDashboardParameters();
  const distinctApplicationCountQuery = useSessionsStatsQuery({
    ...filters,
    groupBy: "application",
    queryOptions: {
      select: (data) => {
        return [
          {
            count: data.length,
            key: "total",
          },
        ];
      },
    },
  });

  return (
    <PeriodStatCompare
      currentPeriodStatQuery={distinctApplicationCountQuery}
      title="Distinct applications"
      label="Number of different applications used"
    />
  );
}
