import { AxiosProgressEvent } from "axios";
import SparkMD5 from "spark-md5";
import { backendClient } from "../backend";
import { readFileChunk } from "./readFileChunk";

type FirstChunkResponse = {
  upload_id: string;
  offset: number;
  expires: string;
};

const chunkSize = 1024 * 1024 * 10;

// Simple event system inspired by https://stackoverflow.com/a/15308814
class Event<T> {
  name: string;
  callbacks: ((args: T) => void)[];

  constructor(name: string) {
    this.name = name;
    this.callbacks = [];
  }

  registerCallback(callback: (args: T) => void) {
    this.callbacks.push(callback);
  }

  dispatch(args: T) {
    this.callbacks.forEach((callback) => callback(args));
  }
}

export class ChunkedUploader {
  file: File;
  endpoint: string;
  events = {
    progress: new Event<number>("progress"),
    error: new Event<Error>("error"),
    success: new Event<string>("success"),
    abort: new Event<void>("abort"),
  };
  totalChunkCount: number;
  progress = 0;
  uploadId?: string;
  offset: number = 0;
  aborted: boolean = false;

  constructor(
    file: File,
    endpoint: string = "/api/v2/application-builds/chunked_uploads/",
  ) {
    this.file = file;
    this.endpoint = endpoint;
    // to calculate progress, take a look at how many chunks we'll need to upload
    // we will have one request per chunk and one final request to finalize the upload
    this.totalChunkCount = Math.ceil(file.size / chunkSize) + 1;
  }

  async start() {
    this.progress = 0;
    const spark = new SparkMD5.ArrayBuffer();
    // iterate over the file in chunks, and upload each chunk individually
    // at the same time calculate the file's md5 hash iteratively
    let chunkId = 0;
    try {
      // Upload the first chunk
      const chunk = await readFileChunk(this.file, 0, chunkSize);
      // update md5
      spark.append(chunk);
      const { uploadId, offset } = await this._uploadFirstChunk(chunk);
      chunkId++;
      this.uploadId = uploadId;
      this.offset = offset;
      // continue uploading all other chunks
      let bytesUploaded = chunk.byteLength;
      while (bytesUploaded < this.file.size) {
        const chunk = await readFileChunk(this.file, bytesUploaded, chunkSize);
        // update md5
        spark.append(chunk);
        // send the next chunk to the backend
        this.offset = await this._uploadChunk(chunk, chunkId);
        bytesUploaded += chunk.byteLength;
        chunkId++;
      }

      // now that all chunks have been written, let's finalize the process
      const fileUrl = await this._commitChunkedUpload(spark.end(), chunkId);
      this.events.success.dispatch(fileUrl);
      return fileUrl;
    } catch (err) {
      if (
        "message" in (err as object) &&
        (err as Error).message === "Aborted"
      ) {
        this.events.abort.dispatch();
      } else {
        console.error("Error during chunked upload", err);
        this.events.error.dispatch(new Error("Error during chunked upload"));
      }
    }
  }

  abort() {
    this.aborted = true;
  }

  /**
   * Checks whether the upload should be aborted and if so, does so
   */
  _checkAbort() {
    if (this.aborted) {
      throw new Error("Aborted");
    }
  }

  async _uploadFirstChunk(chunk: ArrayBuffer) {
    this._checkAbort();
    // create the chunked upload on the backend
    const form = new window.FormData();
    form.append("chunk", new Blob([chunk]), this.file.name);

    const firstChunkResponse = await backendClient.post<FirstChunkResponse>(
      this.endpoint,
      form,
      {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${form.get(
            "_boundary",
          )}`,
        },
        onUploadProgress: (progress) =>
          this._updateProgress(progress, 0, "upload"),
        onDownloadProgress: (progress) =>
          this._updateProgress(progress, 0, "download"),
      },
    );
    // keep track of the upload id which needs to be provided for all subsequent chunks
    const { upload_id: uploadId, offset } = firstChunkResponse.data;
    return { uploadId, offset };
  }

  async _uploadChunk(chunk: ArrayBuffer, chunkdIdx: number = 1) {
    this._checkAbort();
    const form = new window.FormData();
    form.append("chunk", new Blob([chunk]), this.file.name);
    const nextChunkResponse = await backendClient.put(
      `${this.endpoint}${this.uploadId}/`,
      form,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Content-Range": `bytes ${this.offset}-${
            this.offset + chunk.byteLength - 1
          }/${this.file.size}`,
          "Content-Disposition": `filename="${this.file.name}"`,
        },
        onUploadProgress: (progress) =>
          this._updateProgress(progress, chunkdIdx, "upload"),
        onDownloadProgress: (progress) =>
          this._updateProgress(progress, chunkdIdx, "download"),
      },
    );

    // update the offset variable
    return nextChunkResponse.data.offset;
  }

  async _commitChunkedUpload(md5: string, chunkdIdx: number) {
    this._checkAbort();
    const form = new window.FormData();
    form.append("md5", md5);
    const {
      data: { file_url: fileUrl },
    } = await backendClient.post<{ file_url: string }>(
      `${this.endpoint}${this.uploadId}/commit/`,
      form,
      {
        onUploadProgress: (progress) =>
          this._updateProgress(progress, chunkdIdx, "upload"),
        onDownloadProgress: (progress) =>
          this._updateProgress(progress, chunkdIdx, "download"),
      },
    );
    return fileUrl;
  }

  _updateProgress(
    progressEvent: Pick<AxiosProgressEvent, "loaded" | "total">,
    chunkdIdx: number,
    operation: "upload" | "download",
  ) {
    this.progress =
      progressEvent.total === undefined
        ? 0
        : (chunkdIdx +
            (progressEvent.loaded / progressEvent.total) *
              // upload is just the first out of two operations per chunk (but the more important one)
              (operation === "upload" ? 0.9 : 1)) /
          this.totalChunkCount;
    this.events.progress.dispatch(Math.round(this.progress * 100) / 100);
  }
}
