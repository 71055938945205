import { EditIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  HStack,
  Icon,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  Spacer,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { AxiosError } from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import { FaPlus as AddIcon } from "react-icons/fa";
import { generatePath, useNavigate } from "react-router-dom";
import { updateApplicationCategory } from "../backend/api";
import {
  ApplicationCategory,
  PaginatedApplicationCategoriesList,
} from "../backend/types";
import { DeleteButton, LinkButton, OptionsButton } from "../components";
import { AddButton } from "../components/AddButton";
import { NoData } from "../components/NoData";
import { ReorderableRowsTable } from "../components/RearrangeableRowsTable";
import {
  getQueryKeyForApplicationCategories,
  useApplicationCategoriesListQuery,
} from "../hooks/useApplicationCategoriesQuery";
import { namedRoutes } from "../routes";
import { columns as applicationCategoryColumns } from "./applicationCategoryColums";
import { ApplicationCategoryCreateForm } from "./components/ApplicationCategoryCreateForm";
import { useApplicationCategoryDeleteConfirm } from "./hooks/useApplicationCategoryDeleteConfirm";

const columnHelper = createColumnHelper<ApplicationCategory>();

const paginationOptions = {
  page: 1,
  page_size: 100,
};

export function ApplicationCategoryList() {
  const addApplicationCategoryDialogState = useDisclosure();
  const firstFieldRef = useRef<HTMLInputElement>(null);
  const confirmApplicationCategoryDeletion =
    useApplicationCategoryDeleteConfirm();
  const navigate = useNavigate();

  const [applicationCategories, setApplicationCategories] = useState<
    ApplicationCategory[]
  >([]);
  const {
    data: applicationCategoriesResponse,
    isError,
    error,
    isLoading,
  } = useApplicationCategoriesListQuery(paginationOptions, {
    retry: false,
  });

  useEffect(() => {
    applicationCategoriesResponse?.results &&
      setApplicationCategories(applicationCategoriesResponse?.results);
  }, [applicationCategoriesResponse?.results]);

  const columns = useMemo(
    () => [
      ...applicationCategoryColumns,
      columnHelper.display({
        id: "options",
        cell: (props) => {
          const applicationCategory = props.row.original;

          return (
            <Flex justifyContent={"end"}>
              <OptionsButton label="Click on this button to display user actions">
                <LinkButton
                  variant="ghost"
                  fontWeight="normal"
                  fontSize="sm"
                  w="full"
                  to={generatePath(namedRoutes.applicationCategory.overview, {
                    applicationCategoryId: applicationCategory.id.toString(),
                  })}
                  leftIcon={<EditIcon />}
                >
                  Edit
                </LinkButton>
                <DeleteButton
                  variant="ghost"
                  fontWeight="normal"
                  fontSize="sm"
                  w="full"
                  colorScheme="red"
                  onClick={confirmApplicationCategoryDeletion(
                    applicationCategory,
                  )}
                >
                  Delete
                </DeleteButton>
              </OptionsButton>
            </Flex>
          );
        },
        header: () => <Flex justifyContent="end">Options</Flex>,
      }),
    ],
    [confirmApplicationCategoryDeletion],
  );
  const dynamicColumns = useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            cell: () =>
              column.id !== "order" ? (
                <Skeleton>
                  <Text>Loading</Text>
                </Skeleton>
              ) : null,
          }))
        : columns,
    [columns, isLoading],
  );
  const data = useMemo<ApplicationCategory[]>(
    () =>
      isLoading
        ? Array(5).fill({} as ApplicationCategory)
        : applicationCategories ?? [],
    [applicationCategories, isLoading],
  );

  const queryClient = useQueryClient();
  const reorderApplicationCategoriesMutation = useMutation<
    void,
    AxiosError,
    ApplicationCategory[],
    {
      previousApplicationCategories?: PaginatedApplicationCategoriesList;
      queryKey: QueryKey;
    }
  >({
    mutationFn: async (data) => {
      await Promise.all(
        data
          .sort((a, b) => a.order - b.order)
          .map((category) =>
            updateApplicationCategory(category.id, { order: category.order }),
          ),
      );
    },
    onMutate: async (data) => {
      const queryKey = getQueryKeyForApplicationCategories(paginationOptions);
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey,
      });
      // Snapshot the previous value
      const previousApplicationCategories =
        queryClient.getQueryData<PaginatedApplicationCategoriesList>(queryKey);
      // Optimistically update to the new value
      queryClient.setQueryData<PaginatedApplicationCategoriesList>(
        queryKey,
        (old) => (old ? { ...old, results: data } : old),
      );
      // Return a context object with the snapshotted value
      return { previousApplicationCategories, queryKey };
    },
    onError: (_, __, context) => {
      // Rollback to the previous value
      if (context?.previousApplicationCategories) {
        queryClient.setQueryData(
          context.queryKey,
          context.previousApplicationCategories,
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationCategories(),
      });
    },
  });

  if (isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>{JSON.stringify(error)}</AlertDescription>
      </Alert>
    );
  }

  return (
    <>
      <VStack alignItems="normal" spacing={6}>
        <Text>
          Application categories can be used to group together similar
          applications. Categories simplify the discovery of applications for
          users.
        </Text>
        <Flex paddingLeft={6} justifyContent="space-between">
          <Spacer />
          <HStack>
            <Spinner
              display={
                !reorderApplicationCategoriesMutation.isPending
                  ? "none"
                  : undefined
              }
            />
            <Popover
              isOpen={addApplicationCategoryDialogState.isOpen}
              initialFocusRef={firstFieldRef}
              onOpen={addApplicationCategoryDialogState.onOpen}
              onClose={addApplicationCategoryDialogState.onClose}
              placement="right"
              closeOnBlur={false}
            >
              <PopoverTrigger>
                <Button
                  leftIcon={<Icon boxSize={3} as={AddIcon} />}
                  onClick={addApplicationCategoryDialogState.onOpen}
                  colorScheme="brand"
                >
                  Create Category
                </Button>
              </PopoverTrigger>
              <PopoverContent p={5}>
                <PopoverArrow />
                <PopoverCloseButton />
                <ApplicationCategoryCreateForm
                  firstFieldRef={firstFieldRef}
                  onSuccess={(category) => {
                    addApplicationCategoryDialogState.onClose();
                    navigate(
                      generatePath(namedRoutes.applicationCategory.overview, {
                        applicationCategoryId: category.id.toString(),
                      }),
                    );
                  }}
                />
              </PopoverContent>
            </Popover>
          </HStack>
        </Flex>
        {applicationCategoriesResponse &&
        !applicationCategoriesResponse.count ? (
          <NoData
            height="md"
            text="Looks like there are no categories (yet). Try adding some."
            title="No Categories"
            callToAction={
              <AddButton
                onClick={addApplicationCategoryDialogState.onOpen}
                flexShrink={0}
              >
                Create Category
              </AddButton>
            }
          />
        ) : (
          <>
            {reorderApplicationCategoriesMutation.isError && (
              <Alert status="error">
                <AlertIcon />
                <AlertDescription>
                  {reorderApplicationCategoriesMutation.error?.message ??
                    "Could not update order. Please try again later."}
                </AlertDescription>
              </Alert>
            )}
            <ReorderableRowsTable
              columns={dynamicColumns}
              data={data}
              onReordered={(categories) => {
                const reorderedCategories = categories.map((category, idx) => ({
                  ...category,
                  order: idx,
                }));
                // find categories where the order has changed
                const changedCategories = reorderedCategories.filter((cat) => {
                  const originalCategory = applicationCategories.find(
                    (_cat) => _cat.id === cat.id,
                  );
                  return originalCategory?.order !== cat.order;
                });
                // eagerly update the internal state
                setApplicationCategories(reorderedCategories);
                // adjust the order of the data based on the new ordering
                reorderApplicationCategoriesMutation.mutate(changedCategories);
              }}
            />
          </>
        )}
      </VStack>
    </>
  );
}
