import { HStack, Icon, IconProps, StackProps, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import {
  ApplicationBuild,
  TargetPlatform,
  XRPlatformType,
} from "../backend/types";
import {
  AndroidIcon,
  MetaIcon,
  PicoIcon,
  WaveIcon,
  WindowsIcon,
} from "./icons";

export function TargetPlatformIcon({
  platform,
  xrPlatform,
  ...props
}: {
  platform: TargetPlatform;
  xrPlatform?: XRPlatformType;
} & IconProps) {
  return (
    <Icon
      as={
        xrPlatform?.startsWith("win-")
          ? WindowsIcon
          : xrPlatform === "pico"
          ? PicoIcon
          : xrPlatform === "wave"
          ? WaveIcon
          : xrPlatform === "quest"
          ? MetaIcon
          : platform === "android"
          ? AndroidIcon
          : platform === "windows"
          ? WindowsIcon
          : undefined
      }
      {...props}
    />
  );
}

export function TargetPlatformDisplay({
  platform,
  ...props
}: {
  platform: ApplicationBuild["target_platform"];
} & StackProps) {
  const { isAndroid, isWindows } = useMemo(
    () => ({
      isAndroid: platform === "android",
      isWindows: platform === "windows",
    }),
    [platform],
  );

  return (
    <HStack color="GrayText" {...props}>
      <TargetPlatformIcon platform={platform} />
      <Text>
        {isAndroid
          ? "Standalone VR / Android"
          : isWindows
          ? "Windows"
          : "Unknown"}
      </Text>
    </HStack>
  );
}
