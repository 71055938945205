import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  CreateVirtualMachine,
  createVirtualMachineSchema,
} from "../VirtualMachines/virtualMachineSchema";

export const useCreateVirtualMachineForm = (
  virtualMachine?: CreateVirtualMachine,
) => {
  const form = useForm<CreateVirtualMachine>({
    resolver: yupResolver(createVirtualMachineSchema),
    defaultValues: {
      ...virtualMachine,
    },
  });

  return form;
};
