import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Divider,
  Stack,
  Text,
} from "@chakra-ui/react";
import { loadDataFromElement } from "../utils/hydrate-data";

const oauthApplicationName = loadDataFromElement<string | undefined>(
  "oauth-application-name",
  undefined,
);
const error = loadDataFromElement<string>("error", "");
const errorDescription = loadDataFromElement<string>(
  "error-description",
  error,
);

export function AuthorizationErrorView() {
  return (
    <Alert
      status="error"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        Authorization failed
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        <Stack spacing={2} paddingTop={4}>
          {oauthApplicationName && (
            <Text>
              <b>{oauthApplicationName}</b> could not be authorized.
            </Text>
          )}
          {errorDescription && (
            <Text>
              Reason was: <i>{errorDescription}</i>
            </Text>
          )}
          <Divider />
          <Text>You may now close this window.</Text>
        </Stack>
      </AlertDescription>
    </Alert>
  );
}
