import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { createFixedIps } from "../../session-management";
import { getFixedIpsQueryKey } from "./useFixedIpsQuery";

export interface CreateFixedIpsProps {
  region: string;
  count: number;
}

export function useCreateFixedIpsMutation({
  onSuccess,
  ...options
}: Omit<
  UseMutationOptions<void, AxiosError, CreateFixedIpsProps>,
  "mutationFn"
> = {}) {
  const queryClient = useQueryClient();

  const mutation = useMutation<void, AxiosError, CreateFixedIpsProps>({
    mutationFn: async (props: CreateFixedIpsProps) => {
      await createFixedIps(props.count, props.region);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: getFixedIpsQueryKey() });

      if (onSuccess) onSuccess(data, variables, context);
    },
    ...options,
  });
  return mutation;
}
