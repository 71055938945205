import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Button,
  HStack,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { CalendarDate, getLocalTimeZone } from "@internationalized/date";
import dayjs from "dayjs";
import { useMemo, useRef } from "react";
import { DateValue, useDatePicker } from "react-aria";
import { BsStopwatch } from "react-icons/bs";
import { DatePickerStateOptions, useDatePickerState } from "react-stately";
import { Calendar } from "../../components/date-range-picker/Calendar";

import relativeTime from "dayjs/plugin/relativeTime";
import { omit } from "remeda";
import { ApplicationPermission, PermissionSubject } from "../../backend";
import { ApplicationId } from "../../backend/types";
import { toCalendarDate } from "../../components/date-range-picker";
import { useApplicationPermissionMutation } from "../../hooks";
dayjs.extend(relativeTime);

export function PermissionExpiry({
  organizationId,
  applicationId,
  permissions,
  subject,
  validUntil: expiryDate,
  ...datePickerOptions
}: Omit<DatePickerStateOptions<CalendarDate>, "onChange" | "value"> & {
  validUntil?: Date;
  applicationId: ApplicationId;
  subject: PermissionSubject;
  organizationId: number;
  permissions: ApplicationPermission[];
}) {
  const permissionMutation = useApplicationPermissionMutation(applicationId);
  const onChange = (date: DateValue) => {
    permissionMutation.mutate({
      permissions,
      organizationId,
      validUntil: dayjs(date.toDate(getLocalTimeZone())).endOf("day").toDate(),
      subject,
    });
  };
  const minDateValue = useMemo(
    () => toCalendarDate(dayjs().startOf("day").add(1, "day").toDate()),
    [],
  );
  const ref = useRef(null);
  const state = useDatePickerState({
    shouldCloseOnSelect: true,
    ...datePickerOptions,
    value: expiryDate ? toCalendarDate(expiryDate) : null,
    onChange,
  });
  const { buttonProps, calendarProps } = useDatePicker(
    {
      label: "Pick an expiry date for this permission",
      minValue: minDateValue,
    },
    state,
    ref,
  );
  const expirationDate = dayjs(expiryDate);

  const onClearExpiry = () => {
    permissionMutation.mutate({
      permissions,
      organizationId,
      validUntil: undefined,
      subject,
    });
  };

  const errorMessage = permissionMutation.error?.response?.data
    ? Object.values(permissionMutation.error?.response?.data).flat().join(", ")
    : permissionMutation.error?.message;

  return (
    <HStack spacing={2}>
      {expiryDate && (
        <Tooltip label={`Access expires on ${expirationDate.format("L")}`}>
          <HStack paddingLeft={2}>
            <BsStopwatch size={14} />
            <Text
              size="xs"
              color={permissionMutation.isError ? "red" : "chakra-subtle-text"}
            >
              {permissionMutation.isError ? (
                <>{errorMessage}</>
              ) : (
                <>Access expires {expirationDate.fromNow()}</>
              )}
            </Text>
          </HStack>
        </Tooltip>
      )}
      <HStack spacing={0}>
        <Popover
          isOpen={state.isOpen}
          closeOnBlur={true}
          closeOnEsc={true}
          onClose={state.close}
          isLazy={true}
        >
          <PopoverTrigger>
            <IconButton
              {...omit(buttonProps, ["onPress", "onFocusChange"])}
              title="Add expiry"
              size="sm"
              aria-label="Add expiry"
              icon={expiryDate ? <EditIcon /> : <BsStopwatch />}
              variant={expiryDate ? "ghost" : "solid"}
              onClick={() => state.toggle()}
              colorScheme={permissionMutation.isError ? "red" : "gray"}
              isLoading={permissionMutation.isPending}
            />
          </PopoverTrigger>
          <PopoverContent padding={2}>
            <Stack spacing={2}>
              <Calendar {...calendarProps} />
              <HStack justifyContent={"end"}>
                <Button
                  aria-label="Remove expiry"
                  variant="ghost"
                  onClick={onClearExpiry}
                >
                  Remove expiry
                </Button>
                <Button
                  aria-label="Close date selection"
                  variant="ghost"
                  onClick={() => state.close()}
                >
                  Done
                </Button>
              </HStack>
            </Stack>
          </PopoverContent>
        </Popover>
        {expiryDate && (
          <IconButton
            title="Remove expiry"
            size="sm"
            aria-label="Remove expiry"
            variant="ghost"
            icon={<DeleteIcon />}
            isRound={true}
            onClick={onClearExpiry}
          />
        )}
      </HStack>
      {permissionMutation.isError && !expiryDate && (
        <Text color="red" size="xs">
          {errorMessage}
        </Text>
      )}
    </HStack>
  );
}
