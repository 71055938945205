import { ApplicationBuildId } from "../backend/types";
import {
  useActiveOrganizationQuery,
  usePaginationQueryParams,
  useSessionsQuery,
} from "../hooks";
import { SessionFilters } from "../session-management";
import { SessionsListContext } from "./SessionsListContext";

export function SessionListContextProvider({
  children,
  applicationBuildId,
  ...filters
}: {
  children: React.ReactNode;
  applicationBuildId?: ApplicationBuildId;
} & Omit<SessionFilters, "page" | "pageSize" | "appId">) {
  const activeOrganizationQuery = useActiveOrganizationQuery();
  const [pagination, setPagination] = usePaginationQueryParams();
  const query = useSessionsQuery({
    organizationId: activeOrganizationQuery?.data?.id ?? 0,
    includeFailed: true,
    appId: applicationBuildId,
    ...filters,
    page: pagination.pageIndex + 1,
    pageSize: pagination.pageSize,
  });

  return (
    <SessionsListContext.Provider
      value={{ query, setPagination, pagination, filters }}
    >
      {children}
    </SessionsListContext.Provider>
  );
}
