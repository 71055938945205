import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateApplicationPermissions } from "../backend/api";
import {
  ApplicationPermission,
  GrantedApplicationPermission,
  PermissionSubject,
} from "../backend/types";
import {
  invalidateApplicationPermissionQueries,
  optimisticallyUpdateApplicationPermissions,
  restorePreviousApplicationPermissions,
} from "./useApplicationPermissionMutation";

export function useGrantAccessToSubjectOnApplicationMutation(
  subject: PermissionSubject,
  permissions: ApplicationPermission[] = ["view"],
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      application: applicationId,
      organization: organizationId,
      validUntil,
    }: Pick<GrantedApplicationPermission, "application" | "organization"> & {
      validUntil?: Date;
    }) => {
      await updateApplicationPermissions({
        subject,
        applicationId,
        organizationId,
        permissions,
        validUntil,
      });
    },
    onMutate: async ({
      application: applicationId,
      organization: organizationId,
    }) =>
      optimisticallyUpdateApplicationPermissions(
        {
          subject,
          organizationId,
          applicationId,
          permissions,
        },
        queryClient,
      ),
    onError: (_, { application: applicationId }, context) => {
      restorePreviousApplicationPermissions(
        subject,
        queryClient,
        applicationId,
        context,
      );
    },
    onSuccess: (_, { application: applicationId }) => {
      // refetch the permissions
      invalidateApplicationPermissionQueries(
        subject,
        queryClient,
        applicationId,
      );
    },
  });
}
