import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { deleteFixedIps } from "../../session-management";
import { getFixedIpsQueryKey } from "./useFixedIpsQuery";

export interface DeleteFixedIpsProps {
  id: string;
  region: string;
}

export function useDeleteFixedIpsMutation({
  onSuccess,
  ...options
}: Omit<
  UseMutationOptions<void, AxiosError, DeleteFixedIpsProps>,
  "mutationFn"
> = {}) {
  const queryClient = useQueryClient();
  const mutation = useMutation<void, AxiosError, DeleteFixedIpsProps>({
    mutationFn: async (props: DeleteFixedIpsProps) => {
      await deleteFixedIps(props.id);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: getFixedIpsQueryKey(variables.region),
      });

      if (onSuccess) onSuccess(data, variables, context);
    },
    ...options,
  });
  return mutation;
}
