import { Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { createApplicationNetworkSecurityRule } from "../../backend/api";
import {
  ApplicationId,
  ApplicationNetworkSecurityRule,
  NetworkSecurityRuleDirection,
  supportedNetworkProtocols,
} from "../../backend/types";
import { FormRootErrorMessage } from "../../components";
import {
  getQueryKeyForApplicationNetworkSecurityRules,
  useApplicationNetworkSecurityRules,
} from "../../hooks/useApplicationNetworkSecurityRules";
import { validatePortRequirements } from "../../session-management";
import { mapNetworkSecurityRuleToSmType } from "../../session-management/utils";
import { extractFormSubmissionErrors } from "../../utils/extractFormSubmissionErrors";
import { ApplicationNetworkSecurityRuleForm } from "./ApplicationNetworkSecurityRuleForm";
import {
  ApplicationNetworkSecurityRuleSchema,
  defaultCidrBlock,
  netwokSecurityRuleSchema,
} from "./ApplicationNetworkdSecurityRuleSchema";

export function CreateApplicationNetworkSecurityRuleForm({
  applicationId,
  onSuccess,
}: {
  applicationId: ApplicationId;
  onSuccess?: () => void;
}) {
  const queryClient = useQueryClient();
  const securityRulesQuery = useApplicationNetworkSecurityRules(applicationId);
  const form = useForm<ApplicationNetworkSecurityRuleSchema>({
    resolver: yupResolver(netwokSecurityRuleSchema),
    mode: "onBlur",
    defaultValues: {
      cidr_blocks: [defaultCidrBlock],
      protocol: supportedNetworkProtocols[0],
      direction: NetworkSecurityRuleDirection.Inbound,
      from_port: 0,
      to_port: 0,
    },
  });
  const { mutateAsync: createNetworkSecurityRuleAsync } = useMutation<
    ApplicationNetworkSecurityRule,
    AxiosError,
    ApplicationNetworkSecurityRuleSchema
  >({
    mutationFn: async (data) => {
      const allRules =
        securityRulesQuery.data?.results?.map((rule) =>
          mapNetworkSecurityRuleToSmType(rule),
        ) ?? [];

      // add updated rule to validation
      allRules.push(mapNetworkSecurityRuleToSmType(data));

      const validationResponse: AxiosResponse =
        await validatePortRequirements(allRules);

      if (validationResponse.status !== 200) {
        const errors = Object.entries(validationResponse.data).map((c) => c[1]);
        throw new Error("Validation errors occured: " + errors.join(", "));
      }

      return await createApplicationNetworkSecurityRule(applicationId, {
        ...data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationNetworkSecurityRules(applicationId),
      });
      form.reset(undefined, { keepValues: true });
      onSuccess?.();
    },
    onError: () => {
      form.reset(undefined, { keepValues: true, keepDirty: true });
    },
  });

  return (
    <Stack spacing={2}>
      <ApplicationNetworkSecurityRuleForm
        form={form}
        onSubmit={useCallback(
          async (data) => {
            try {
              await createNetworkSecurityRuleAsync(data);
            } catch (err) {
              extractFormSubmissionErrors(
                err,
                netwokSecurityRuleSchema,
              ).forEach(([field, message]) =>
                form.setError(field, { type: "server", message }),
              );
            }
          },
          [createNetworkSecurityRuleAsync, form],
        )}
      />
      <FormRootErrorMessage formState={form.formState} />
    </Stack>
  );
}
