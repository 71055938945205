import { Box, Flex, Link, Tooltip } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { ApplicationCategory } from "../backend/types";
import { namedRoutes } from "../routes";
import { LazyTinyApplicationPreview } from "./components/TinyApplicationPreview";

const columnHelper = createColumnHelper<ApplicationCategory>();

export const nameColumn = columnHelper.accessor("name", {
  id: "name",
  header: "Category",
  cell: (props) => (
    <Link
      textDecoration={"none"}
      _hover={{ textDecoration: "underline", cursor: "pointer" }}
      as={RouterLink}
      to={generatePath(namedRoutes.applicationCategory.overview, {
        applicationCategoryId: props.row.original.id.toString(),
      })}
      role="group"
    >
      {props.getValue()}
    </Link>
  ),
});

export const applicationsColumn = columnHelper.display({
  id: "applications",
  header: "Applications",
  cell: (props) => {
    if (!props.row.original.applications.length) {
      return <>No applications (yet)</>;
    }

    const maxItems = 5;
    const styleProps = {
      borderWidth: 1,
      borderRadius: "md",
      width: 16,
      height: 9,
      marginRight: 1,
      borderColor: "chakra-border-color",
    };

    return (
      <Flex>
        {props.row.original.applications.slice(0, maxItems).map((app) => (
          <LazyTinyApplicationPreview
            key={app}
            applicationId={app}
            {...styleProps}
          />
        ))}
        {props.row.original.applications.length > maxItems && (
          <Tooltip
            hasArrow
            label={`There${
              props.row.original.applications.length - maxItems > 1
                ? " are"
                : "'s"
            } ${
              props.row.original.applications.length - maxItems
            } more application${
              props.row.original.applications.length - maxItems > 1 ? "s" : ""
            } in this category`}
          >
            <Box
              fontWeight={"bold"}
              fontSize={"sm"}
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
              {...styleProps}
              width={styleProps.height}
              color="text-muted"
            >
              +{props.row.original.applications.length - maxItems}
            </Box>
          </Tooltip>
        )}
      </Flex>
    );
  },
});

export const columns = [
  columnHelper.display({
    id: "order",
    header: "Order",
    cell: () => null,
  }),
  nameColumn,
  applicationsColumn,
];
