import { useQuery } from "@tanstack/react-query";
import { VmFilter, getVirtualMachines } from "../../session-management";

export function getVirtualMachinesQueryKey(params: VmFilter = {}) {
  return ["virtualMachines", params];
}

export function useVirtualMachinesQuery(params: VmFilter = {}) {
  return useQuery({
    queryKey: getVirtualMachinesQueryKey(params),
    queryFn: () => getVirtualMachines(params),
    refetchOnMount: false,
    staleTime: 1800,
    retry: false,
  });
}
