import { InferType, object, string,boolean } from "yup";

export const createVirtualMachineSchema = object().shape({
  region: string().defined().nonNullable().required(),
  organizationId: string().defined().nonNullable().required(),
  vmSize: string().defined().nonNullable().required(),
  image: string().notRequired().nullable().default(null),
  expirationTimeSpan: string().defined().nonNullable().required(),
  debugModeEnabled: boolean().notRequired().nullable().default(false),
});

export type CreateVirtualMachine = InferType<typeof createVirtualMachineSchema>;
