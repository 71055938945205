import { useEffect, useMemo } from "react";
import { useFixedIpsQuery } from "./useFixedIpsQuery";

export const useFixedIps = (region?: string) => {
  const fixedIpQuery = useFixedIpsQuery(region);
  const allIpsAvailable = useMemo(
    () => !fixedIpQuery.data?.find((c) => !c.ip),
    [fixedIpQuery.data],
  );

  useEffect(() => {
    if (allIpsAvailable) return;

    // refetch till we have all the ips
    const interval = setInterval(() => {
      fixedIpQuery.refetch();
    }, 5000);

    return () => clearInterval(interval);
  }, [allIpsAvailable, fixedIpQuery]);

  return {
    ...fixedIpQuery,
    data: fixedIpQuery.data ?? [],
    allIpsAvailable,
  };
};
