import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { backendClient, PortalUserGroupsList } from "../backend";
import { QueryParameters } from "../utils/types";
import { getQueryForGroup } from "./useGroupQuery";

async function fetchUserGroups(queryParams: QueryParameters) {
  const response = await backendClient.get<PortalUserGroupsList>(
    `/api/groups/`,
    {
      params: queryParams,
    },
  );
  return response.data;
}

export function useGroupsQuery(
  { page_size = 10, ...queryParams }: QueryParameters = {},
  queryOptions: Partial<UseQueryOptions<PortalUserGroupsList>> = {},
) {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["groups", { ...queryParams, page_size }],
    queryFn: async () => {
      const userGroupsResponse = await fetchUserGroups({
        page_size,
        ...queryParams,
      });
      // put the groups into the cache
      userGroupsResponse.results.forEach((userGroup) =>
        queryClient.setQueryData<typeof userGroup>(
          getQueryForGroup(userGroup.id).queryKey,
          userGroup,
        ),
      );
      return userGroupsResponse;
    },
    ...queryOptions,
  });
}
