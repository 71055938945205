import { ApplicationAccess } from "./ApplicationAccess";
import { ApplicationActivity } from "./ApplicationActivity";
import { ApplicationCloudRenderingSettings } from "./ApplicationCloudRenderingSettings";
import { ApplicationConfiguration } from "./ApplicationConfiguration";
import { ApplicationDetails } from "./ApplicationDetails";
import { ApplicationInfo } from "./ApplicationInfo";
import { ApplicationList } from "./ApplicationList";

import { Heading, Stack } from "@chakra-ui/react";
import { useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";
import { NavigationTab, NavigationTabs } from "../components";
import { namedRoutes } from "../routes";
import { ApplicationBuilds } from "./ApplicationBuilds";
import { ShareViaSearch } from "./ShareViaSearch";

export function ApplicationManagementPage() {
  const { applicationId, applicationCategoryId } = useParams();
  const isDetailPage = useMemo(
    () => !!(applicationId ?? applicationCategoryId),
    [applicationCategoryId, applicationId],
  );

  return (
    <Stack spacing={6}>
      {!isDetailPage && (
        <>
          <Heading>Application Management</Heading>
          <NavigationTabs>
            <NavigationTab
              to={namedRoutes.application.list}
              label="Applications"
            />
            <NavigationTab
              to={namedRoutes.applicationCategory.list}
              label="Categories"
            />
          </NavigationTabs>
        </>
      )}

      <Outlet />
    </Stack>
  );
}

export {
  ApplicationAccess,
  ApplicationActivity,
  ApplicationBuilds,
  ApplicationCloudRenderingSettings,
  ApplicationConfiguration,
  ApplicationDetails,
  ApplicationInfo,
  ApplicationList,
  ShareViaSearch,
};
