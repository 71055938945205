import { Box, Heading, Stack, Text, Tooltip } from "@chakra-ui/react";
import { ShareViaSearch } from "../applications";
import { useActiveOrganizationQuery } from "../hooks";
import { useApplicationDetailsContext } from "./ApplicationDetailsContext";
import { ExternalRequestAccessManagement } from "./ExternalRequestAccessManagement";
import { ApplicationOrganizationMemberships } from "./components/ApplicationOrganizationMemberships";

export function ApplicationAccess() {
  const { application } = useApplicationDetailsContext();
  const { data: organization } = useActiveOrganizationQuery();

  return (
    <Stack spacing={8}>
      <Heading size="md">Internal Access</Heading>
      <Stack spacing={2}>
        <Text>
          Control who can do what with this application within the organization
          &laquo;{organization?.name}&raquo;.
        </Text>
        <Text>
          Find the users or user groups you grant to grant access to the
          application and then select the permissions you want to grant.
        </Text>
        <Text>
          Access can be granted on a user or user group level granularly.
        </Text>
      </Stack>
      <Box>
        <ShareViaSearch applicationId={application.id} />
      </Box>
      <Stack spacing={4}>
        <Heading size="sm">Handling Access Requests</Heading>
        <Text fontSize="sm">
          Please decide how you want to handle access requests for this
          application. The default behaviour is to notify the{" "}
          <Tooltip label='Anyone with permission to "change" the application.'>
            <Text
              as="span"
              borderBottom={"1px"}
              borderBottomColor={"chakra-subtle-text"}
              borderBottomStyle={"dotted"}
            >
              application managers
            </Text>
          </Tooltip>{" "}
          via e-mail. If you want to handle access requests via an external
          system, please enter the URL for submitting access requests, such as a
          form or support page.
        </Text>
        <ExternalRequestAccessManagement applicationId={application.id} />
      </Stack>
      <Heading size="md">External Access</Heading>
      <Stack spacing={2}>
        <Text>
          You can also share this application with other organizations you are
          part of.
        </Text>
        <Text>
          Simply find the organization to share with and click on the &quot;Add
          to organization&quot; button.
        </Text>
        <Text>
          Please note that granular access (who can do what) is managed within
          the respective organization.
        </Text>
      </Stack>
      <ApplicationOrganizationMemberships application={application} />
    </Stack>
  );
}
