import {
  Alert,
  AlertIcon,
  CircularProgress,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Spacer,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import React, { useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { FaInfoCircle as InfoIcon } from "react-icons/fa";
import { InferType, object, string } from "yup";
import { updateBranding } from "../backend/api";
import { BrandingResponseData } from "../backend/types";
import {
  BrandingImageUploadWithPreview,
  ColorInput,
  FormSubmitButton,
} from "../components";
import { DevTool } from "../hookform-devtools";
import { usePromptDirtyForm } from "../hooks";
import { useActiveOrganizationQuery } from "../hooks/useActiveOrganization";
import {
  BrandingData,
  getQueryKeyForBranding,
  sanitizeBraningResponseData,
  useBrandingQuery,
} from "../hooks/useBranding";

const hexColorValidator = (value: string | undefined) => {
  if (!value) return true; // color is optional
  const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  return hexColorRegex.test(value);
};

const brandingSchema = object({
  product_name: string()
    .min(3, "Name must be at least 3 characters long")
    .max(100, "Name cannot be longer than 100 characters.")
    .required()
    .defined()
    .default("XR Portal"),
  app_image_placeholder: string()
    .transform((value) => (!value ? null : value))
    .url()
    .default(null)
    .nullable(),
  product_icon: string()
    .transform((value) => (!value ? null : value))
    .url()
    .default(null)
    .nullable(),
  logo: string()
    .transform((value) => (!value ? null : value))
    .url()
    .default(null)
    .nullable(),
  primary: string()
    .test(
      "color",
      "Please enter a valid color in HEX (3 or 6 digit) format.",
      hexColorValidator,
    )
    .required()
    .defined()
    .default(""),
  background: string()
    .test(
      "color",
      "Please enter a valid color in HEX (3 or 6 digit) format.",
      hexColorValidator,
    )
    .required()
    .defined()
    .default(""),
}).noUnknown();

type BrandingSchema = InferType<typeof brandingSchema>;

export function FormHelperTextTooltip({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Tooltip hasArrow label={children}>
      <Icon as={InfoIcon} boxSize="4" />
    </Tooltip>
  );
}

export function OrganizationBrandingForm() {
  const queryClient = useQueryClient();
  const { data: organization } = useActiveOrganizationQuery();
  const brandingQuery = useBrandingQuery();
  const { setValue, handleSubmit, control, reset, formState, register } =
    useForm<BrandingSchema>({
      resolver: yupResolver(brandingSchema),
      mode: "onChange",
    });
  const primary = useWatch({
    control,
    name: "primary",
  });
  const background = useWatch({
    control,
    name: "background",
  });
  const product_name = useWatch({
    control,
    name: "product_name",
  });

  const errors = formState.errors;

  const { mutateAsync, error } = useMutation<
    BrandingResponseData,
    AxiosError,
    BrandingSchema
  >({
    mutationFn: async (values) => {
      if (!organization?.id) throw new Error("Missing organization context");
      return await updateBranding({
        organization: organization?.id,
        ...values,
      });
    },
    // make sure data is reloaded after modification
    onSuccess: (branding) => {
      branding.organization &&
        queryClient.setQueryData<BrandingData>(
          getQueryKeyForBranding(branding.organization),
          sanitizeBraningResponseData(branding),
        );
    },
    onError: () => {
      reset(brandingQuery.data);
    },
  });

  usePromptDirtyForm({ formState });

  useEffect(() => {
    // once the data is there, pass it to the form, calling reset
    if (brandingQuery.data) {
      reset(brandingQuery.data);
    }
  }, [brandingQuery.data, reset]);

  if (brandingQuery.isError) {
    return (
      <Alert status="error" marginY={4}>
        <AlertIcon />
        Existing branding data could not be loaded!
      </Alert>
    );
  }

  if (brandingQuery.isLoading) {
    return <CircularProgress isIndeterminate />;
  }

  return (
    <form onSubmit={handleSubmit((values) => mutateAsync(values))}>
      <Stack spacing={4}>
        <FormControl isInvalid={!!errors.product_name}>
          <FormLabel>Portal name</FormLabel>
          <Input type="text" {...register("product_name")} />
          <FormHelperText>
            Name shown within the Portal UI. Choose a name your users should be
            familiar with.
          </FormHelperText>
          <FormErrorMessage>
            {errors.product_name && errors.product_name.message}
          </FormErrorMessage>
        </FormControl>
        <Heading as="h3" size="sm" paddingTop={5}>
          Color Scheme
        </Heading>
        <Text>
          Choose a color scheme for {product_name}. This color scheme will also
          apply to this control panel.
        </Text>
        <FormControl isInvalid={!!errors.primary}>
          <HStack alignItems="center">
            <Stack spacing={0}>
              <FormLabel mb={0}>Primary Color</FormLabel>
              <FormHelperText>
                Color used for primary actions like downloading or running an
                app.
              </FormHelperText>
            </Stack>
            <Spacer />
            <ColorInput
              onColorChange={(color: string) =>
                setValue("primary", color, {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              value={primary ?? undefined}
              {...register("primary")}
            />
          </HStack>
          <FormErrorMessage>
            {errors.primary && errors.primary.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.background}>
          <HStack alignItems="center">
            <Stack spacing={0}>
              <FormLabel mb={0}>Background Color</FormLabel>
              <FormHelperText>
                Background color used for Portal interface.
              </FormHelperText>
            </Stack>
            <Spacer />
            <ColorInput
              onColorChange={(color: string) =>
                setValue("background", color, {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              value={background ?? undefined}
              {...register("background")}
            />
          </HStack>
          <FormErrorMessage>
            {errors.background && errors.background.message}
          </FormErrorMessage>
        </FormControl>
        <Heading as="h3" size="sm" paddingTop={5}>
          Visuals
        </Heading>
        <FormControl isInvalid={!!errors.logo}>
          <HStack alignItems="center">
            <Stack spacing={0}>
              <FormLabel>Logo</FormLabel>
              <FormHelperText>
                Your organization&apos;s logo. Will be shown in the top left
                corner of {product_name} and the corresponding client
                applications, e.g. for Oculus Quest or HTC Vive Focus 3. We
                recommend to upload a transparent PNG image of your company logo
                in order to offer a consistent user experience both in web as
                also in the VR headset
              </FormHelperText>
              <FormErrorMessage>
                {errors?.logo && errors.logo.message}
              </FormErrorMessage>
            </Stack>
            <Spacer />
            <Controller
              control={control}
              name="logo"
              render={({ field: { value, onChange } }) => (
                <BrandingImageUploadWithPreview
                  accept={{
                    "image/jpg": [".jpg"],
                    "image/jpeg": [".jpeg"],
                    "image/png": [".png"],
                  }}
                  imageUrl={value ?? undefined}
                  width={36}
                  onChange={onChange}
                />
              )}
            />
          </HStack>
        </FormControl>
        <FormControl isInvalid={!!errors.product_icon}>
          <HStack alignItems="center">
            <Stack spacing={0}>
              <FormLabel>Icon</FormLabel>
              <FormHelperText>
                Smaller (square) version of your organization&apos;s logo.
                displayed on browser tabs (favicon). Please upload a square
                version (e.g. 128x128px). Should be in .ico format,
                alternatively .png or .jpg are also supported.
              </FormHelperText>
              <FormErrorMessage>
                {errors.product_icon && errors.product_icon.message}
              </FormErrorMessage>
            </Stack>
            <Spacer />
            <Controller
              control={control}
              name="product_icon"
              render={({ field: { value, onChange } }) => (
                <BrandingImageUploadWithPreview
                  accept={{
                    "image/x-icon": [".ico"],
                    "image/jpg": [".jpg"],
                    "image/jpeg": [".jpeg"],
                    "image/png": [".png"],
                  }}
                  imageUrl={value ?? undefined}
                  onChange={onChange}
                />
              )}
            />
          </HStack>
        </FormControl>
        <FormControl isInvalid={!!errors.app_image_placeholder}>
          <HStack alignItems="center">
            <Stack spacing={0}>
              <FormLabel>Placeholder Image</FormLabel>
              <FormHelperText>
                Placeholder picture shown when no preview picture is available
                for an application. Should be in 16:9 format.
              </FormHelperText>
              <FormErrorMessage>
                {errors.app_image_placeholder &&
                  errors.app_image_placeholder.message}
              </FormErrorMessage>
            </Stack>
            <Spacer />

            <Controller
              control={control}
              name="app_image_placeholder"
              render={({ field: { value, onChange } }) => (
                <BrandingImageUploadWithPreview
                  imageUrl={value ?? undefined}
                  width={36}
                  onChange={onChange}
                />
              )}
            />
          </HStack>
        </FormControl>
        {error && (
          <Alert status="error" variant="subtle">
            <AlertIcon />
            {error.message}
          </Alert>
        )}
        <FormSubmitButton formState={formState} alignSelf="end" />
      </Stack>
      <DevTool control={control} />
    </form>
  );
}
