import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useActiveOrganizationQuery } from "../../hooks";
import { getQueryKeyForOrganization } from "../../hooks/useActiveOrganization";
import {
  SessionsQueryFilterProps,
  UseSessionsQueryProps,
} from "../../hooks/useSessionsQuery";
import {
  getSessionsConcurrencyStats,
  getSessionsStats,
  SessionsConcurrencyStat,
  SessionsStatDto,
  SessionsStatsFilters,
} from "../../session-management";
import { useUserIdsFilter } from "./useUserIdsFilter";

export function getSessionsStatsQueryProps<TTransformed = SessionsStatDto[]>({
  organization,
  userIds,
  queryOptions,
  ...filters
}: SessionsQueryFilterProps & {
  queryOptions?: Partial<
    UseQueryOptions<SessionsStatDto[], AxiosError, TTransformed>
  >;
}): UseQueryOptions<SessionsStatDto[], AxiosError, TTransformed> {
  return {
    queryKey: [
      ...getQueryKeyForOrganization(organization?.id ?? null),
      "session-stats",
      { userIds, ...filters },
    ],
    queryFn: () =>
      getSessionsStats({
        organizationId: organization?.id.toString(),
        userIdentifier: userIds,
        ...filters,
      }),
    enabled: !!organization?.id,
    retry: false,
    throwOnError: true,
    refetchOnWindowFocus: false,
    ...queryOptions,
  };
}

export type UseSessionsStatsQueryProps<TTransformed = SessionsStatDto[]> =
  UseSessionsQueryProps<SessionsStatDto[], TTransformed> &
    Pick<SessionsStatsFilters, "groupBy">;

export function useSessionsStatsQuery<TTransformed = SessionsStatDto[]>({
  userGroupId,
  ...filters
}: UseSessionsStatsQueryProps<TTransformed>) {
  const { data: organization } = useActiveOrganizationQuery();
  const userIdsFilter = useUserIdsFilter(userGroupId);

  return useQuery(
    getSessionsStatsQueryProps({
      organization,
      ...userIdsFilter,
      ...filters,
    }),
  );
}

export function useSessionsConcurrencyStatsQuery<T = SessionsConcurrencyStat>({
  userGroupId,
  queryOptions,
  ...filters
}: UseSessionsQueryProps<SessionsConcurrencyStat, T>) {
  const { data: organization } = useActiveOrganizationQuery();
  const userIdsFilter = useUserIdsFilter(userGroupId);

  return useQuery({
    queryKey: [
      ...getQueryKeyForOrganization(organization?.id ?? null),
      "session-concurrency-stats",
      { ...filters, ...userIdsFilter },
    ],
    queryFn: () =>
      getSessionsConcurrencyStats({
        organizationId: organization?.id.toString(),
        ...userIdsFilter,
        ...filters,
      }),
    enabled: !!organization?.id,
    retry: false,
    throwOnError: true,
    refetchOnWindowFocus: false,
    ...queryOptions,
  });
}
