import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  useMergeRefs,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Ref, useCallback } from "react";
import { useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import { UserGroup, ValidationErrorResponse } from "../../backend";
import { createUserGroup } from "../../backend/api";
import { FormRootErrorMessage, FormSubmitButton } from "../../components";
import { useActiveOrganizationQuery } from "../../hooks";
import { namedRoutes } from "../../routes";
import { extractFormSubmissionErrors } from "../../utils/extractFormSubmissionErrors";
import { userGroupInfoSchema, type UserGroupInfoSchema } from "../schema";

export function UserGroupCreateForm({
  firstFieldRef,
}: {
  firstFieldRef?: Ref<HTMLInputElement>;
}) {
  const { formState, handleSubmit, register, setError } =
    useForm<UserGroupInfoSchema>({
      mode: "onChange",
      resolver: yupResolver(userGroupInfoSchema),
    });
  const inputRef = useMergeRefs(register("name").ref, firstFieldRef);
  const navigate = useNavigate();
  const organizationQuery = useActiveOrganizationQuery();
  const { mutateAsync: createUserGroupAsync } = useMutation<
    UserGroup,
    AxiosError<ValidationErrorResponse>,
    UserGroupInfoSchema
  >({
    mutationFn: async (groupData) =>
      await createUserGroup({
        ...groupData,
        organization: organizationQuery.data ? organizationQuery.data.id : 0,
      }),
    onSuccess: (group) =>
      navigate(
        generatePath(namedRoutes.userGroup.overview, {
          groupId: group.id.toString(),
        }),
      ),
  });

  const onSubmit = useCallback(
    async (values: UserGroupInfoSchema) => {
      try {
        return await createUserGroupAsync(values);
      } catch (err) {
        extractFormSubmissionErrors(err, userGroupInfoSchema).forEach(
          ([field, message]) => {
            if (
              field === "root" &&
              message ===
                "The fields name, organization must make a unique set."
            ) {
              message =
                "Group could not be created. Likely, the name is already taken.";
            }
            setError(field, { type: "server", message });
          },
        );
      }
    },
    [createUserGroupAsync, setError],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <FormControl isInvalid={!!formState.errors.name}>
          <FormLabel htmlFor="name">Group name</FormLabel>
          <Input
            placeholder="Enter the group's name..."
            id="name"
            {...register("name")}
            ref={inputRef}
          />
          <FormHelperText>
            Once saved, you&apos;ll be able to add users to this group
          </FormHelperText>
          <FormErrorMessage>
            {formState.errors.name?.message?.toString()}
          </FormErrorMessage>
        </FormControl>
        <FormRootErrorMessage formState={formState} />
        <FormSubmitButton formState={formState} alignSelf="end" />
      </Stack>
    </form>
  );
}
