import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from "react";
import { ConfirmDialogContext, ConfirmDialogContextType } from "./context";

export function ConfirmDialogProvider({ children }: PropsWithChildren) {
  const alertDialogState = useDisclosure();
  const { onOpen, onClose } = alertDialogState;
  const cancelRef = useRef(null);
  const [title, setTitle] = useState<ReactNode>("Confirm Deletion");
  const [body, setBody] = useState<ReactNode>(
    "Are you sure you want to delete everything?",
  );
  const [confirmButtonText, setConfirmButtonText] =
    useState<ReactNode>("Confirm");
  const [cancelButtonText, setCancelButtonText] = useState<ReactNode>("Cancel");
  const confirmPromise = useRef<Promise<void>>();
  const confirmPromiseResolve =
    useRef<(value: void | PromiseLike<void>) => void>();
  const confirmPromiseReject = useRef<(reason?: unknown) => void>();
  const [confirmOperationState, setConfirmOperationState] = useState<
    "idle" | "confirming" | "confirmed" | "errored"
  >("idle");
  const [confirmationError, setConfirmationError] = useState<string | null>(
    null,
  );

  const close = useCallback(onClose, [onClose]);
  const confirm: ConfirmDialogContextType["confirm"] = useCallback(
    ({
      title: _title,
      body: _body,
      confirmButtonText: _confirmButtonText = "Confirm",
      cancelButtonText: _cancelButtonText = "Cancel",
      onConfirm: _onConfirm,
      onCancel: _onCancel,
    }) => {
      setConfirmOperationState("idle");
      setConfirmationError(null);
      setTitle(_title);
      setBody(_body);
      setConfirmButtonText(_confirmButtonText);
      setCancelButtonText(_cancelButtonText);
      let resolve: (value: void | PromiseLike<void>) => void,
        reject: (reason?: unknown) => void;
      const userCallbacksPromise = new Promise<void>((_resolve, _reject) => {
        resolve = _resolve;
        reject = _reject;
      });
      const _confirmPromise = new Promise<void>((resolve, reject) => {
        confirmPromiseResolve.current = resolve;
        confirmPromiseReject.current = reject;
      })
        // attach the callbacks
        .then(() => {
          _onConfirm?.();
        })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          _onCancel?.();
          throw err;
        })
        .catch((err) => {
          reject(err);
        })
        .then(() => userCallbacksPromise)
        .then(() => {
          setConfirmOperationState("confirmed");
        })
        .catch((err) => {
          setConfirmationError((err as Error).message);
          setConfirmOperationState("errored");
        })
        .finally(() => {
          confirmPromiseResolve.current = undefined;
          confirmPromiseReject.current = undefined;
          confirmPromise.current = undefined;
          close();
        });
      confirmPromise.current = _confirmPromise;
      onOpen();

      return userCallbacksPromise;
    },
    [close, onOpen],
  );

  return (
    <ConfirmDialogContext.Provider value={{ confirm, cancelConfirm: close }}>
      {children}
      <AlertDialog
        leastDestructiveRef={cancelRef}
        {...alertDialogState}
        onClose={close}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>
              {body}
              {confirmationError && (
                <Alert status="error" marginTop={4}>
                  <AlertIcon />
                  {confirmationError}
                </Alert>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  confirmPromiseReject.current?.(new Error("User cancelled"));
                }}
              >
                {cancelButtonText}
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  setConfirmOperationState("confirming");
                  confirmPromiseResolve.current?.();
                }}
                isLoading={confirmOperationState === "confirming"}
                ml={3}
              >
                {confirmButtonText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </ConfirmDialogContext.Provider>
  );
}
