import { Link, LinkProps } from "@chakra-ui/react";
import { ReactNode } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  generatePath,
} from "react-router-dom";
import { ApplicationBuildId } from "../backend/types";
import { useApplicationBuildQuery } from "../hooks";
import { namedRoutes } from "../routes";

export function LazyApplicationBuildBasedLink({
  applicationBuildId,
  children,
  ...props
}: {
  applicationBuildId: ApplicationBuildId;
  children: ReactNode;
} & LinkProps &
  Omit<RouterLinkProps, "to">) {
  const applicationBuildQuery = useApplicationBuildQuery(applicationBuildId);

  return (
    <Link
      as={RouterLink}
      {...props}
      to={
        applicationBuildQuery.isSuccess
          ? generatePath(namedRoutes.application.overview, {
              applicationId: applicationBuildQuery.data?.application,
            })
          : {}
      }
    >
      {children}
    </Link>
  );
}
