import { ObjectSchema, boolean, number, object, string } from "yup";
import { ApplicationUrlRewriteRule } from "./types";

export const applicationUrlRewriteRuleMatchSchema = string()
  .required("Matcher expression is required")
  .test(
    "is-regex",
    "Match must be a valid regular expression (regex)",
    (value) => {
      try {
        new RegExp(value);
        return true;
      } catch (e) {
        return false;
      }
    },
  );
export const applicationUrlRewriteRuleReplacerSchema = string().required(
  "Replacer expression is required",
);
export const applicationUrlRewriteRuleNameSchema =
  string().required("Name is required");

export const applicationUrlRewriteRuleSchema: ObjectSchema<ApplicationUrlRewriteRule> =
  object({
    name: applicationUrlRewriteRuleNameSchema,
    description: string().defined().nullable(),
    match: applicationUrlRewriteRuleMatchSchema,
    replace: applicationUrlRewriteRuleReplacerSchema,
    enabled: boolean().default(true),
    isPreset: boolean().default(false),
  });

export const applicationBuildMetadataSchema = object({
  uses_hub_sdk: boolean().default(false),
  archive_size: number(),
  optimized_archive_size: number(),
  application_size: number(),
  uses_access_control: boolean().default(false),
  application_type: string()
    .nullable()
    .oneOf(["unity", "unreal", "other"] as const),
});
