import {
  Link,
  ListItem,
  OrderedList,
  Text,
  Tooltip,
  UnorderedList,
} from "@chakra-ui/react";
import { RenderElementProps, useSelected } from "slate-react";
import {
  Address,
  Blockquote,
  Formatted,
  HeadingFive,
  HeadingFour,
  HeadingOne,
  HeadingSix,
  HeadingThree,
  HeadingTwo,
} from "./components";
import { LinkElement } from "./types";

export const Element = ({
  attributes,
  children,
  element,
}: RenderElementProps) => {
  const selected = useSelected();

  switch (element.type) {
    case "blockquote":
      return <Blockquote {...attributes}>{children}</Blockquote>;
    case "list-item":
      return <ListItem {...attributes}>{children}</ListItem>;
    case "numbered-list":
      return (
        <OrderedList marginLeft={6} {...attributes}>
          {children}
        </OrderedList>
      );
    case "bulleted-list":
      return (
        <UnorderedList marginLeft={6} {...attributes}>
          {children}
        </UnorderedList>
      );
    case "heading-one":
      return <HeadingOne {...attributes}>{children}</HeadingOne>;
    case "heading-two":
      return <HeadingTwo {...attributes}>{children}</HeadingTwo>;
    case "heading-three":
      return <HeadingThree {...attributes}>{children}</HeadingThree>;
    case "heading-four":
      return <HeadingFour {...attributes}>{children}</HeadingFour>;
    case "heading-five":
      return <HeadingFive {...attributes}>{children}</HeadingFive>;
    case "heading-six":
      return <HeadingSix {...attributes}>{children}</HeadingSix>;
    case "address":
      return <Address {...attributes}>{children}</Address>;
    case "formatted":
      return <Formatted {...attributes}>{children}</Formatted>;
    case "link":
      return (
        <Tooltip isOpen={selected} label={(element as LinkElement).url}>
          <Link
            style={selected ? { boxShadow: "0 0 0 3px #ddd" } : {}}
            href={(element as LinkElement).url}
            {...attributes}
          >
            {children}
          </Link>
        </Tooltip>
      );
    default:
      return (
        <Text position={"relative"} marginY={1} {...attributes}>
          {children}
        </Text>
      );
  }
};
