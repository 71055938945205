import { useMemo } from "react";
import { useUserGroupQuery } from "./useUserGroups";
import { useAllUsersQuery } from "./useUsers";

export function useUserIdsFilter(userGroupId?: number) {
  const userGroupQuery = useUserGroupQuery(userGroupId);
  const usersQuery = useAllUsersQuery(
    { groups: userGroupId ? [userGroupId] : undefined },
    { enabled: userGroupQuery.isSuccess },
  );
  return useMemo(
    () => ({
      userIds:
        // everyone is the same as not having any filter, otherwise return the user ids within that group
        userGroupQuery.data && userGroupQuery.data?.name !== "Everyone"
          ? usersQuery.data?.map((user) => user.id.toString())
          : undefined,
    }),
    [userGroupQuery.data, usersQuery.data],
  );
}
