import React from "react";

export function ControllableStrictMode({
  children,
  disabled,
}: {
  children: React.ReactNode;
  disabled?: boolean;
}) {
  if (disabled) return children;

  return <React.StrictMode>{children}</React.StrictMode>;
}
