import { DragHandleIcon } from "@chakra-ui/icons";
import { Box, SystemProps, Tooltip, forwardRef } from "@chakra-ui/react";
import { useSortable } from "@dnd-kit/sortable";

export const DragHandle = forwardRef(
  (
    {
      listeners,
      attributes,
      isDragging,
      ...props
    }: SystemProps &
      Partial<
        Pick<
          ReturnType<typeof useSortable>,
          "attributes" | "listeners" | "isDragging"
        >
      >,
    ref,
  ) => {
    return (
      <Tooltip isDisabled={isDragging} label="Drag to rearrange" hasArrow>
        <Box ref={ref} {...listeners} {...attributes} {...props}>
          <DragHandleIcon color="inherit" />
        </Box>
      </Tooltip>
    );
  },
);
