import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  FaLock as LockedIcon,
  FaLockOpen as UnlockedIcon,
} from "react-icons/fa";
import { InferType, object, string } from "yup";
import { backendClient } from "../backend";
import { updateBranding } from "../backend/api";
import { FormRootErrorMessage, FormSubmitButton } from "../components";
import { useActiveOrganizationQuery, usePromptDirtyForm } from "../hooks";
import { queryKeyForActiveOrganization } from "../hooks/useActiveOrganization";
import { getQueryKeyForBranding } from "../hooks/useBranding";
import { extractFormSubmissionErrors } from "../utils/extractFormSubmissionErrors";

const organizationDetailsSchema = object({
  name: string()
    .required()
    .min(3, "Name must be at least 3 characters long")
    .max(100, "Name cannot be longer than 100 characters."),
  subdomain: string()
    .required()
    // only lowercase chars, numbers, dashes allowed in (sub)domains
    .matches(
      /^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?$/,
      "Subdomains may only contain lowercase alphanumeric characters (a-z and 0-9) and hyphens (-). They may not begin or end with a hyphen.",
    ),
});

type OrganizationSchema = InferType<typeof organizationDetailsSchema>;

export function OrganizationDetailsForm() {
  const { handleSubmit, watch, reset, formState, register, setError } =
    useForm<OrganizationSchema>({
      resolver: yupResolver(organizationDetailsSchema),
      mode: "onChange",
    });

  const [subdomainLocked, setSubdomainLocked] = useState(true);
  const { data: organization } = useActiveOrganizationQuery({
    retry: false,
    // otherwise the form would be constantly reset
    refetchOnWindowFocus: false,
  });
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation<void, AxiosError, OrganizationSchema>({
    mutationFn: async (values) => {
      if (!organization?.id) return;

      // we need to also update the organization name on the branding endpoint (legacy). This needs to happen before the potential change of the subdomain!
      await updateBranding({
        organization: organization?.id,
        company_name: values.name,
      });
      await backendClient.patch(`/api/organizations/${organization?.id}/`, {
        subdomain: values.subdomain,
        name: values.name,
      });
    },
    // make sure data is reloaded after modification
    onSuccess: (_, { subdomain }) => {
      // if subdomain changed, redirect
      if (organization?.subdomain && subdomain !== organization?.subdomain) {
        window.location.replace(
          window.location.href.replace(organization?.subdomain, subdomain),
        );
        return;
      }

      // otherwise, reload data
      queryClient.invalidateQueries({
        queryKey: queryKeyForActiveOrganization,
      });
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForBranding(organization?.id ?? ""),
      });
    },
  });

  const errors = useMemo(() => formState.errors, [formState.errors]);

  usePromptDirtyForm({ formState });

  // once the data is there, pass it to the form, calling reset
  useEffect(() => {
    if (organization) {
      reset(organization, { keepIsSubmitted: true });
    }
  }, [organization, reset]);

  const portalDomain = organization?.domain.replace(
    organization?.subdomain,
    watch("subdomain"),
  );

  return (
    <>
      <form
        onSubmit={handleSubmit(async (values) => {
          try {
            await mutateAsync(values);
            reset(values);
          } catch (err: unknown) {
            extractFormSubmissionErrors(err, organizationDetailsSchema).forEach(
              ([field, message]) =>
                setError(field, { type: "server", message }),
            );
          }
        })}
      >
        <Stack spacing={4}>
          <FormControl isInvalid={!!errors.name}>
            <FormLabel>Organization name</FormLabel>
            <Input {...register("name")} />
            <FormHelperText>The name of your organization.</FormHelperText>
            <FormErrorMessage>
              {errors.name && errors.name.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={!!errors.subdomain}
            isDisabled={subdomainLocked}
          >
            <FormLabel>(Sub)domain</FormLabel>

            <HStack spacing={2}>
              <InputGroup>
                <InputLeftAddon>
                  {window.location.protocol + "//"}
                </InputLeftAddon>
                <Input {...register("subdomain")} />
                <InputRightAddon>
                  {organization?.domain.replace(organization?.subdomain, "")}
                </InputRightAddon>
              </InputGroup>
              <Popover trigger="hover">
                <PopoverTrigger>
                  <IconButton
                    aria-label="Unlock subdomain field"
                    variant="outline"
                    icon={
                      <Icon as={subdomainLocked ? LockedIcon : UnlockedIcon} />
                    }
                    onClick={() => setSubdomainLocked(!subdomainLocked)}
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverBody>
                    <Stack spacing={2}>
                      <Text>
                        Changing the subdomain means all users need to access
                        your organization via the changed domain.
                      </Text>
                      <Text fontWeight={"bold"}>
                        The old / current domain will cease to be accessible
                        immediately!
                      </Text>
                      <Text>
                        Click the button to unlock the field and change the
                        subdomain anyways.
                      </Text>
                    </Stack>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </HStack>
            <FormHelperText>
              <Stack spacing={2}>
                <Text>
                  The subdomain of your organization. Your organization&apos;s
                  Portal will be accessible at:{" "}
                  <Link href={`${window.location.protocol}//${portalDomain}`}>
                    {portalDomain}
                  </Link>
                  .
                </Text>
              </Stack>
            </FormHelperText>
            <FormErrorMessage>
              {errors.subdomain && errors.subdomain.message}
            </FormErrorMessage>
          </FormControl>
          <FormRootErrorMessage formState={formState} />
          <FormSubmitButton formState={formState} alignSelf="end" />
        </Stack>
      </form>
    </>
  );
}
