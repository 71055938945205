import { InferType, number, object, string } from "yup";
import { ReservedInstanceType } from "../../session-management";

export const reservedInstanceSchema = object()
  .shape(
    {
      organizationId: string().defined().notRequired().nullable().default(null),
      timeZoneId: string().defined().required(),
      region: string().defined().required(),
      instances: number().defined().required().min(0).max(30).integer(),
      vmSize: string().defined().required(),
      weekDaysBitmask: number()
        .defined()
        .min(1)
        .max(127)
        .integer()
        .nullable()
        .default(null),
      type: string<ReservedInstanceType>().defined().required(),
      description: string().defined().required(),
      fromTime: string()
        .defined()
        .nullable()
        .when("toTime", {
          is: (toTime: string | undefined) => !!toTime,
          then: (schema) =>
            schema
              .nonNullable()
              .required("From time is required when To time is set"),
          otherwise: (schema) => schema,
        }),
      toTime: string()
        .defined()
        .nullable()
        .when("fromTime", {
          is: (fromTime: string | undefined) => !!fromTime,
          then: (schema) =>
            schema
              .nonNullable()
              .required("To time is required when From time is set")
              .test(
                "is-greater",
                "From time must be smaller than To time",
                (value, { parent }) => {
                  const { fromTime } = parent;
                  const fromTimeInt = parseInt(fromTime.replace(":", ""));
                  const toTimeInt = parseInt(value?.replace(":", "") ?? "");
                  return fromTimeInt < toTimeInt;
                },
              ),
          otherwise: (schema) => schema,
        }),
      fromDate: string()
        .defined()
        .nullable()
        .when("toDate", {
          is: (toDate: string | undefined) => !!toDate,
          then: (schema) =>
            schema
              .nonNullable()
              .required("From date is required when to date is set"),
          otherwise: (schema) => schema,
        }),
      toDate: string()
        .defined()
        .nullable()
        .when("fromDate", {
          is: (fromDate: string | undefined) => !!fromDate,
          then: (schema) =>
            schema
              .nonNullable()
              .required("To date is required when from date is set"),
          otherwise: (schema) => schema,
        }),
    },
    [
      ["fromTime", "toTime"],
      ["fromDate", "toDate"],
    ],
  )
  .noUnknown();

export type UpdateReservedInstanceSchema = InferType<
  typeof reservedInstanceSchema
>;
