import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Checkbox,
  CheckboxGroup,
  CheckboxProps,
  FormControl,
  FormHelperText,
  FormLabel,
  GridItem,
  SimpleGrid,
  Stack,
  Text,
  forwardRef,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  fetchGroupPlatformPermissions,
  grantPlatformPermissionsToGroup,
  revokePlatformPermissionsFromGroup,
} from "../backend/api";
import { PermissionString } from "../backend/types";

const getQueryKeyForGroupPermissions = (groupId: string) => [
  "group",
  groupId,
  "platform-permissions",
];

const PermissionCheckbox = forwardRef(
  (props: Omit<CheckboxProps, "value"> & { value: PermissionString }, ref) => (
    <Checkbox {...props} ref={ref} />
  ),
);

export function UserGroupPlatformPermissions() {
  const { groupId = "" } = useParams();
  const queryClient = useQueryClient();
  const groupPermissionsQuery = useQuery({
    queryKey: getQueryKeyForGroupPermissions(groupId),
    queryFn: () => fetchGroupPlatformPermissions(parseInt(groupId)),
    retry: false,
    throwOnError: true,
  });
  const toast = useToast({ position: "top", isClosable: true });
  const { mutate: updatePermissions } = useMutation<
    void,
    AxiosError,
    PermissionString[],
    { previousPerms: PermissionString[] }
  >({
    mutationFn: async (perms) => {
      // figure out which perms are to be granted and which are to be revoked
      const currentPerms = groupPermissionsQuery.data ?? [];
      const permsToBeGranted = perms.filter(
        (perm) => !currentPerms.includes(perm),
      );
      const permsToBeRevoked = currentPerms.filter(
        (perm) => !perms.includes(perm),
      );
      await Promise.all([
        grantPlatformPermissionsToGroup(permsToBeGranted, parseInt(groupId)),
        revokePlatformPermissionsFromGroup(permsToBeRevoked, parseInt(groupId)),
      ]);
    },
    onMutate: async (perms) => {
      const queryKey = getQueryKeyForGroupPermissions(groupId);

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey,
      });

      // Snapshot the previous value
      const previousPerms =
        queryClient.getQueryData<PermissionString[]>(queryKey) ?? [];

      // Optimistically update to the new value
      queryClient.setQueryData<PermissionString[]>(
        queryKey,
        (existingPerms) => [
          ...new Set<PermissionString>([...(existingPerms ?? []), ...perms]),
        ],
      );

      // Return a context object with the snapshotted value
      return { previousPerms };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, perms, context) => {
      queryClient.setQueryData(
        getQueryKeyForGroupPermissions(groupId),
        context?.previousPerms,
      );
      toast({
        title: "Could not update platform-wide permissions",
        description: err.message,
        status: "error",
      });
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForGroupPermissions(groupId),
      });
    },
  });

  const onPermissionChange = useCallback(
    (values: PermissionString[]) => {
      // attempt to mutate the group permissions and optimistically update the UI already
      updatePermissions(values);
    },
    [updatePermissions],
  );

  return (
    <>
      <Alert status="info" alignItems={"normal"}>
        <AlertIcon />
        <Box>
          <AlertTitle>Platform-wide access control</AlertTitle>
          <AlertDescription>
            <Stack mt={3}>
              <Text>
                This section allows controlling which operations users within
                this group are allowed to perform.
              </Text>
              <Text>
                All members of the group will be able to perform the tasks
                selected below.
              </Text>
              <Text>
                Permissions granted here have no effect on individual
                applications. E.g. having the permission to stream applications
                does not mean that the user will be able to stream all
                applications. The user will only be able to stream applications
                to which they have been granted explicit access.
              </Text>
            </Stack>
          </AlertDescription>
        </Box>
      </Alert>
      <CheckboxGroup
        isDisabled={groupPermissionsQuery.isLoading}
        value={groupPermissionsQuery.data}
        onChange={onPermissionChange}
      >
        <SimpleGrid columns={[1, 1, 1, 2]} spacing={10}>
          <GridItem>
            <FormControl as="fieldset">
              <FormLabel as="legend" htmlFor={undefined}>
                Application Management
              </FormLabel>
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <PermissionCheckbox value="assets.add_application">
                    Upload applications
                  </PermissionCheckbox>
                  <FormHelperText paddingLeft={6}>
                    Allows uploading new applications or application versions.
                  </FormHelperText>
                </Stack>
                <Stack spacing={1}>
                  <PermissionCheckbox value="assets.add_applicationcategory">
                    Categorize applications
                  </PermissionCheckbox>
                  <FormHelperText paddingLeft={6}>
                    Allows grouping similar applications into categories.
                  </FormHelperText>
                </Stack>
              </Stack>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl as="fieldset">
              <FormLabel as="legend" htmlFor={undefined}>
                Application Rendering
              </FormLabel>
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <PermissionCheckbox value="assets.stream_application">
                    Stream applications
                  </PermissionCheckbox>
                  <FormHelperText paddingLeft={6}>
                    Allows rendering applications in the cloud and streaming
                    them to a local device.
                  </FormHelperText>
                </Stack>
                <Stack spacing={1}>
                  <PermissionCheckbox value="assets.download_application">
                    Download applications
                  </PermissionCheckbox>
                  <FormHelperText paddingLeft={6}>
                    Allows downloading applications to a local device.
                  </FormHelperText>
                </Stack>
              </Stack>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl as="fieldset">
              <FormLabel as="legend" htmlFor={undefined}>
                Organization Management
              </FormLabel>
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <PermissionCheckbox value="core.change_organization">
                    Manage organization
                  </PermissionCheckbox>
                  <FormHelperText paddingLeft={6}>
                    Manage organization&apos;s details like name, branding and
                    track organization-wide usage.
                  </FormHelperText>
                </Stack>
              </Stack>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl as="fieldset">
              <FormLabel as="legend" htmlFor={undefined}>
                Client Features
              </FormLabel>
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <PermissionCheckbox value="core.browse_applications">
                    View applications on VR client
                  </PermissionCheckbox>
                  <FormHelperText paddingLeft={6}>
                    Allows to view and start applications from VR client
                  </FormHelperText>
                </Stack>
              </Stack>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl as="fieldset">
              <FormLabel as="legend" htmlFor={undefined}>
                User Management
              </FormLabel>
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <PermissionCheckbox value="core.view_user">
                    View all user accounts
                  </PermissionCheckbox>
                  <FormHelperText paddingLeft={6}>
                    Allows to view all users within the organization.
                  </FormHelperText>
                </Stack>
                <Stack spacing={1}>
                  <PermissionCheckbox value="core.change_user">
                    Manage user accounts
                  </PermissionCheckbox>
                  <FormHelperText paddingLeft={6}>
                    Allows to create, delete and manage user accounts.
                  </FormHelperText>
                </Stack>
                <Stack spacing={1}>
                  <PermissionCheckbox value="core.view_usergroup">
                    View user groups
                  </PermissionCheckbox>
                  <FormHelperText paddingLeft={6}>
                    Allows to view all user groups within the organization.
                  </FormHelperText>
                </Stack>
                <Stack spacing={1}>
                  <PermissionCheckbox value="core.change_usergroup">
                    Manage user groups
                  </PermissionCheckbox>
                  <FormHelperText paddingLeft={6}>
                    Allows to create, delete and manage user groups and their
                    members and platform-level permissions.
                  </FormHelperText>
                </Stack>
              </Stack>
            </FormControl>
          </GridItem>
        </SimpleGrid>
      </CheckboxGroup>
    </>
  );
}
