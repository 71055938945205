import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { PortalError } from "../../backend";
import { getApplicationVersionSignedDownloadUrl } from "../../backend/api";
import { ApplicationBuildId } from "../../backend/types";

export function useSignedApplicationBuildUrl(
  applicationBuildId: ApplicationBuildId,
  queryOptions: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApplicationVersionSignedDownloadUrl>>,
      AxiosError<PortalError>
    >
  > = {},
) {
  return useQuery({
    queryKey: ["application-signed-url", applicationBuildId],
    queryFn: () => getApplicationVersionSignedDownloadUrl(applicationBuildId),
    enabled: false,
    ...queryOptions,
  });
}
