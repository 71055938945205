import { Text } from "@chakra-ui/react";
import { RenderPlaceholderProps } from "slate-react";

export const Placeholder = ({
  attributes: {
    style: { position, maxWidth, display, top, width, ...style },
    ...attributes
  },
  children,
}: RenderPlaceholderProps) => {
  return (
    <Text as="span" {...attributes} style={style}>
      {children}
    </Text>
  );
};
