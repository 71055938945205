import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  useMergeRefs,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Ref, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { InferType, object, string } from "yup";
import { ValidationErrorResponse } from "../../backend";
import { createApplicationCategory } from "../../backend/api";
import { ApplicationCategory } from "../../backend/types";
import { FormRootErrorMessage, FormSubmitButton } from "../../components";
import { getQueryKeyForApplicationCategories } from "../../hooks/useApplicationCategoriesQuery";
import { extractFormSubmissionErrors } from "../../utils/extractFormSubmissionErrors";

const applicationCategoryCreateSchema = object({
  name: string()
    .min(3, "Name must be at least 3 characters long")
    .required("Name cannot be blank."),
}).noUnknown();

type ApplicationCategoryCreateSchema = InferType<
  typeof applicationCategoryCreateSchema
>;

export function ApplicationCategoryCreateForm({
  firstFieldRef,
  onSuccess,
}: {
  firstFieldRef?: Ref<HTMLInputElement>;
  onSuccess?: (category: ApplicationCategory) => void;
}) {
  const toast = useToast({ position: "top" });
  const { formState, handleSubmit, register, watch, setError, reset } =
    useForm<ApplicationCategoryCreateSchema>({
      mode: "onSubmit",
      resolver: yupResolver(applicationCategoryCreateSchema),
    });
  const inputRef = useMergeRefs(register("name").ref, firstFieldRef);
  const queryClient = useQueryClient();
  const {
    mutateAsync: createApplicationCategoryAsync,
    ...createApplicationCategoryMutation
  } = useMutation<
    ApplicationCategory,
    AxiosError<ValidationErrorResponse>,
    ApplicationCategoryCreateSchema
  >({
    mutationFn: async ({ name }) => {
      return await createApplicationCategory(name);
    },
    onSuccess: (category, { name }) => {
      toast({
        title: "Category created",
        status: "success",
        description: `Application Category ${name} has been created.`,
        duration: 5000,
        isClosable: true,
      });
      reset();
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationCategories().slice(0, 1),
      });
      onSuccess?.(category);
    },
    onError: (error) => {
      if (error.response?.status === 400) {
        if (error.response.data.name) {
          setError("name", {
            message: error.response.data.name.join(", "),
          });
        }
      }
    },
  });
  const onSubmit = useCallback(
    (values: ApplicationCategoryCreateSchema) => {
      try {
        createApplicationCategoryAsync(values);
      } catch (err) {
        extractFormSubmissionErrors(
          err,
          applicationCategoryCreateSchema,
        ).forEach(([field, message]) =>
          setError(field, { type: "server", message }),
        );
      }
    },
    [createApplicationCategoryAsync, setError],
  );

  useEffect(() => {
    // reset the mutation errors whenever data has changed
    const subscription = watch(() => {
      if (createApplicationCategoryMutation.isError) {
        createApplicationCategoryMutation.reset();
      }
      return () => subscription.unsubscribe();
    });
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <FormControl isInvalid={!!formState.errors.name}>
          <FormLabel htmlFor="name">Category name</FormLabel>
          <Input
            placeholder="Enter the category's name..."
            id="name"
            {...register("name")}
            ref={inputRef}
          />
          <FormHelperText>
            In the next step, you&apos;ll be able to add applications to the
            newly created category.
          </FormHelperText>
          <FormErrorMessage>{formState.errors.name?.message}</FormErrorMessage>
        </FormControl>
        <FormRootErrorMessage formState={formState} />
        <FormSubmitButton formState={formState} alignSelf="end" />
      </Stack>
    </form>
  );
}
