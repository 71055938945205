import { Props, Select } from "chakra-react-select";
import { useMemo } from "react";
import { useVmSizesQuery } from "../cloud-rendering/hooks";

interface DropDownOptions {
  value: string;
  label: string;
}

export const VmSizesDropDown = ({
  value,
  defaultValue,
  onChange,
  allowedVmSizes,
  ...props
}: {
  value?: string;
  defaultValue?: string;
  allowedVmSizes?: string[];
  onChange: (value?: string) => void;
} & Omit<
  Props<DropDownOptions, false>,
  "value" | "onChange" | "defaultValue"
>) => {
  const { data: vmSizes, isSuccess } = useVmSizesQuery();
  const filteredVmSizes = useMemo(() => {
    return vmSizes?.filter((r) => allowedVmSizes?.includes(r.name));
  }, [allowedVmSizes, vmSizes]);

  return (
    <Select
      {...props}
      isLoading={!isSuccess}
      useBasicStyles
      value={filteredVmSizes
        ?.map((r) => ({ value: r.name, label: r.displayName }))
        .find((r) => r.value === value)}
      defaultValue={filteredVmSizes
        ?.map((r) => ({ value: r.name, label: r.displayName }))
        .find((r) => r.value === defaultValue)}
      onChange={(newValue) => onChange(newValue?.value)}
      options={filteredVmSizes
        ?.filter((r) => r.isEnabled)
        .map((r) => {
          return {
            value: r.name,
            label: r.displayName,
          };
        })}
    />
  );
};
