import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { SubmitHandler, UseFormReturn, useWatch } from "react-hook-form";
import { ApplicationUrlRewriteRule } from "../../backend/types";
import { FormSubmitButton } from "../../components";
import { DevTool } from "../../hookform-devtools";

export function RewriteRuleForm({
  form: { formState, handleSubmit, register, control },
  onSubmit,
}: {
  form: UseFormReturn<ApplicationUrlRewriteRule>;
  onSubmit: SubmitHandler<ApplicationUrlRewriteRule>;
}) {
  const isPreset = useWatch({ control, name: "isPreset" });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Stack spacing={4} display={isPreset ? "none" : "flex"}>
          <FormControl
            isInvalid={!!formState.errors.name}
            isDisabled={isPreset}
          >
            <FormLabel>Name</FormLabel>
            <Input
              placeholder="My rewrite rule"
              type="text"
              {...register("name")}
            />
            <FormErrorMessage>
              {formState.errors.name?.message}
            </FormErrorMessage>
            <FormHelperText>
              Give your rule a name to identify it later.
            </FormHelperText>
          </FormControl>
          <FormControl
            isInvalid={!!formState.errors.match}
            isDisabled={isPreset}
          >
            <FormLabel>Matcher (RegEx)</FormLabel>
            <Input
              placeholder="https://(.*).org"
              type="text"
              {...register("match")}
            />
            <FormErrorMessage>
              {formState.errors.match?.message}
            </FormErrorMessage>
            <FormHelperText>
              The matcher is a regular expression that will be used to match the
              URL. Any captures can be used in the replace field. The regular
              expression uses the ECMAScript / Javascript flavor. To test your
              regular expression, you can use a tool like{" "}
              <Link href="https://regex101.com/">regex101.com</Link>.
            </FormHelperText>
          </FormControl>
          <FormControl
            isInvalid={!!formState.errors.replace}
            isDisabled={isPreset}
          >
            <FormLabel>Replace with</FormLabel>
            <Input
              placeholder="https://new.$1.org"
              type="text"
              {...register("replace")}
            />
            <FormErrorMessage>
              {formState.errors.replace?.message}
            </FormErrorMessage>
            <FormHelperText>
              The replace field is a string that will replace the matched URL.
              You can use captures from the matcher. Additionally, you can use
              the $serverIp variable to inject the remote rendering node&apos;s
              ip address and the $organizationDomain variable to inject the
              (full) domain of the currently selected organization.
            </FormHelperText>
          </FormControl>
          <FormControl
            isInvalid={!!formState.errors.description}
            isDisabled={isPreset}
          >
            <FormLabel>Description (optional)</FormLabel>
            <Textarea {...register("description")} />
            <FormErrorMessage>
              {formState.errors.description?.message}
            </FormErrorMessage>
          </FormControl>
          <Input type="hidden" {...register("isPreset")} />
        </Stack>
        <FormSubmitButton alignSelf={"end"} formState={formState} />
      </Stack>
      <DevTool control={control} />
    </form>
  );
}
