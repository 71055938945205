import { forwardRef } from "@chakra-ui/react";
import { useMemo } from "react";
import { htmlToSlate, slateToHtml } from "./html-serializer";
import { RichTextEditor, RichTextEditorProps } from "./RichTextEditor";

export const HTMLRichTextEditor = forwardRef(
  (
    {
      value = "",
      onChange,
      ...props
    }: { value?: string; onChange?: (value: string) => void } & Omit<
      RichTextEditorProps,
      "value" | "onChange"
    >,
    ref,
  ) => {
    return (
      <RichTextEditor
        value={useMemo(() => htmlToSlate(value), [value])}
        onChange={(value) => onChange && onChange(slateToHtml(value))}
        ref={ref}
        {...props}
      />
    );
  },
);
