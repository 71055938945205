import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { LinkButton } from "../components";
import { useBrandingQuery } from "../hooks";
import { namedRoutes } from "../routes";
import { authProviders } from "./authProviders";
import { CSRFTokenInput } from "./CSRFTokenInput";
import { getFormErrors } from "./getFormErrors";
import { SSOButtonGroup } from "./SSOButtonGroup";

// load the form errors (json) from a script tag with id "form-errors" if available
const formErrors = getFormErrors();

export function LoginForm() {
  const { data: branding } = useBrandingQuery();

  return (
    <form method="post">
      <Stack spacing="6">
        <Heading size={{ base: "xs", md: "sm" }}>
          Sign in to your {branding?.company_name} {branding?.product_name}{" "}
          account
        </Heading>
        <Stack spacing="5">
          {formErrors.__all__ && (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>
                {formErrors.__all__.join(", ")}
              </AlertDescription>
            </Alert>
          )}
          <FormControl isInvalid={"username" in formErrors}>
            <FormLabel htmlFor="username">Email</FormLabel>
            <Input id="username" name="username" type="email" />
            <FormErrorMessage>
              {formErrors.username?.join(", ")}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={"password" in formErrors}>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
            />
            <FormErrorMessage>
              {formErrors.password?.join(", ")}
            </FormErrorMessage>
          </FormControl>
          <Stack spacing={2}>
            <Button colorScheme="brand" type="submit">
              Sign in
            </Button>
            <HStack justify="space-between">
              <Spacer />
              <LinkButton
                variant="link"
                colorScheme="brand"
                size="sm"
                fontWeight="normal"
                to={namedRoutes.resetPassword.request}
              >
                Forgot password?
              </LinkButton>
            </HStack>
          </Stack>
        </Stack>
        {authProviders.length && (
          <Stack spacing="6">
            <HStack>
              <Divider />
              <Text fontSize="sm" whiteSpace="nowrap" color="muted">
                or sign in using
              </Text>
              <Divider />
            </HStack>
            <SSOButtonGroup />
          </Stack>
        )}
      </Stack>
      <CSRFTokenInput />
    </form>
  );
}
