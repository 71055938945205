import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
} from "@chakra-ui/react";
import { DeleteIcon } from "./icons";

export function DeleteButton(props: ButtonProps) {
  return (
    <Button
      variant="ghost"
      fontWeight="normal"
      fontSize="sm"
      w="full"
      colorScheme="red"
      leftIcon={<DeleteIcon />}
      {...props}
    />
  );
}

export function DeleteIconButton(props: IconButtonProps) {
  return (
    <IconButton icon={<DeleteIcon />} size="sm" colorScheme="red" {...props} />
  );
}
