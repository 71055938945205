import { flatten } from "flat";
import { isPlainObject as _isPlainObject } from "remeda";

type PrimitiveValueType = string | number | boolean | null | undefined;
type PlainObject = {
  [key: string]: PlainObject | PrimitiveValueType;
};
type FlattenedPlainObject = {
  [key: string]: PrimitiveValueType;
};

export function isPlainObject(value: unknown): value is PlainObject {
  return _isPlainObject(value);
}

export function flattenObjectKeys(obj: PlainObject): FlattenedPlainObject {
  return flatten(obj);
}
