import {
  Alert,
  AlertIcon,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  CreatableProps,
  CreatableSelect,
  GroupBase,
} from "chakra-react-select";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { array, InferType, object, string } from "yup";
import { Application } from "../backend";
import { fetchTags, updateApplication } from "../backend/api";
import {
  FormSubmitButton,
  HTMLRichTextEditor,
  InputWithCopyToClipboard,
} from "../components";
import { DevTool } from "../hookform-devtools";
import { usePromptDirtyForm } from "../hooks";
import { getQueryKeyForApplication } from "../hooks/useApplicationQuery";
import { useApplicationDetailsContext } from "./ApplicationDetailsContext";
import { ApplicationImageList } from "./components/ApplicationImageList";

const applicationInfoSchema = object({
  name: string()
    .defined()
    .required()
    .min(3, "Name must be at least 3 characters long")
    .max(100, "Name cannot be longer than 100 characters."),
  description_html: string().default(""),
  tags: array(string().defined()).defined().default([]),
}).noUnknown();

type ApplicationInfoSchema = InferType<typeof applicationInfoSchema>;

export function ApplicationInfo() {
  const { application } = useApplicationDetailsContext();
  const queryClient = useQueryClient();
  const { handleSubmit, formState, register, control, reset } =
    useForm<ApplicationInfoSchema>({
      resolver: yupResolver(applicationInfoSchema),
      mode: "onChange",
      defaultValues: application,
    });

  const { mutateAsync, error } = useMutation<
    ApplicationInfoSchema,
    AxiosError,
    ApplicationInfoSchema & Pick<Application, "id">
  >({
    mutationFn: async ({ id: applicationId, ...values }) =>
      await updateApplication(applicationId, values),
    // make sure data is reloaded after modification
    onSuccess: (_, application) => {
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplication(application.id),
      });
      reset(application);
    },
  });

  usePromptDirtyForm({ formState });

  return (
    <>
      <form
        onSubmit={handleSubmit((values) =>
          mutateAsync({ ...values, id: application.id }),
        )}
      >
        <Stack spacing={4}>
          <Heading size="sm">Basic Information</Heading>
          <HStack spacing={8}>
            <FormControl isInvalid={!!formState.errors.name} flexGrow={1}>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input {...register("name")} />
              <FormErrorMessage>
                {formState.errors.name?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl width="auto">
              <FormLabel htmlFor="id" whiteSpace={"nowrap"} marginRight={0}>
                Application ID
              </FormLabel>
              <InputWithCopyToClipboard
                id="id"
                isReadOnly
                value={application?.id}
                width={"full"}
              />
            </FormControl>
          </HStack>
          <FormControl isInvalid={!!formState.errors.description_html}>
            <FormLabel>Description</FormLabel>
            <Box
              borderWidth={1}
              borderRadius={"md"}
              paddingX={2}
              paddingY={1}
              _hover={{ borderColor: "gray.300" }}
            >
              <Controller
                control={control}
                name="description_html"
                render={({ field }) => <HTMLRichTextEditor {...field} />}
              />
            </Box>
            <FormErrorMessage>
              {formState.errors.description_html?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!formState.errors.tags}>
            <FormLabel htmlFor="tags">Tags</FormLabel>
            <Controller
              control={control}
              name="tags"
              render={({ field: { ref: _unusedRef, ...field } }) => (
                <TagSelect
                  id="tags"
                  {...field}
                  value={(field.value ?? []).map((tag) => ({
                    value: tag,
                    label: tag,
                  }))}
                  onChange={(newValue) =>
                    field.onChange(newValue.map((val) => val.value))
                  }
                />
              )}
            />
            <FormErrorMessage>
              {formState.errors.tags?.message}
            </FormErrorMessage>
          </FormControl>
          {error && (
            <Alert status="error" variant="subtle">
              <AlertIcon />
              {error.message}
            </Alert>
          )}
          <FormSubmitButton formState={formState} alignSelf="start" />
        </Stack>
      </form>
      <Stack spacing={4}>
        <Heading size="sm">Media</Heading>
        <ApplicationImageList applicationId={application.id} />
      </Stack>
      <DevTool control={control} placement="bottom-left" />
    </>
  );
}

type TagOption = { value: string; label: string };

function TagSelect(
  props: CreatableProps<TagOption, true, GroupBase<TagOption>>,
) {
  const [search, setSearch] = useState("");
  const tagsQuery = useQuery({
    queryKey: ["tags", search],
    queryFn: () => fetchTags(search),
  });

  return (
    <CreatableSelect<TagOption, true>
      isMulti
      allowCreateWhileLoading
      placeholder="Use tags to help categorize your application. Examples include its use case, target audience, key features (e.g., 'collaboration', 'task management', 'for students')"
      closeMenuOnSelect={false}
      onInputChange={(value) => setSearch(value)}
      isLoading={tagsQuery.isLoading}
      options={tagsQuery.data?.results.map((tag) => ({
        value: tag,
        label: tag,
      }))}
      {...props}
    />
  );
}
