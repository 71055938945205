import { useDashboardParameters } from "../context";
import { SessionCountInTimeSpan } from "./SessionCountInTimeSpan";

export function CloudRenderedSessionsInTimeSpan() {
  const { from, to, userGroupId } = useDashboardParameters();

  return (
    <SessionCountInTimeSpan
      from={from}
      to={to}
      filters={{
        renderType: "CloudRendered",
        deviceType: "VrStandalone",
        userGroupId,
      }}
      title="Cloud rendered VR sessions"
    />
  );
}
