import {
  chakraComponents,
  OptionProps,
  Props,
  Select,
} from "chakra-react-select";
import { useMemo } from "react";
import { UserGroup } from "../backend";
import { useUserGroupsQuery } from "../dashboard/hooks/useUserGroups";
import { LazyGroupProfile } from "./GroupProfile";

export function UserGroupSelect({
  filter = () => true,
  ...props
}: Props<UserGroupOption, true> & {
  filter?: (value: UserGroup, index: number, array: UserGroup[]) => boolean;
}) {
  const userGroupsQuery = useUserGroupsQuery();
  const filteredUserGroups = useMemo(
    () => userGroupsQuery.data?.results.filter(filter),
    [userGroupsQuery, filter],
  );

  return (
    <Select
      placeholder="Add user to group(s) ..."
      size="sm"
      isMulti
      isLoading={!filteredUserGroups}
      isDisabled={!filteredUserGroups || !filteredUserGroups.length}
      options={(filteredUserGroups ?? [])?.map((userGroup) => ({
        value: userGroup.id,
        label: userGroup.name,
      }))}
      components={customSelectComponents}
      {...props}
    />
  );
}
export type UserGroupOption = {
  value: number;
  label: string;
};

const customSelectComponents = {
  Option: ({ ...props }: OptionProps<UserGroupOption, true>) => (
    <chakraComponents.Option {...props}>
      <LazyGroupProfile groupId={props.data.value} />
    </chakraComponents.Option>
  ),
};
