import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useClipboard,
} from "@chakra-ui/react";
import { FaCheck as CheckmarkIcon, FaCopy as CopyIcon } from "react-icons/fa";

export function InputWithCopyToClipboard({
  value,
  ...props
}: { value: string } & InputProps) {
  const { hasCopied, onCopy } = useClipboard(value);

  return (
    <InputGroup size={props.size} width={props.width}>
      <Input
        variant="outline"
        value={value}
        isReadOnly
        bg="chakra-subtle-bg"
        {...props}
      />
      <InputRightElement>
        <IconButton
          size={props.size}
          variant="ghost"
          onClick={onCopy}
          icon={<Icon as={hasCopied ? CheckmarkIcon : CopyIcon} />}
          aria-label="Copy to Clipboard"
        />
      </InputRightElement>
    </InputGroup>
  );
}
