import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { useFormContext, useWatch } from "react-hook-form";
import { AuthProviderSchema } from "../OrganizationAuthProvidersForm";
import { ProviderSlug } from "./utils";

type ProviderDisplayName = `${ProviderSlug}.display_name`;

export function AuthProviderDisplayNameInput({
  providerSlug,
}: {
  providerSlug: ProviderSlug;
}) {
  const fieldName: ProviderDisplayName = `${providerSlug}.display_name`;
  const {
    control,
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<AuthProviderSchema>();
  const providerDisplayName = useWatch({
    control,
    name: fieldName,
  });

  return (
    <FormControl
      isInvalid={!!errors[providerSlug]?.display_name}
      isDisabled={isSubmitting}
    >
      <FormLabel htmlFor={fieldName}>Provider Name</FormLabel>
      <Input {...register(fieldName)} />
      <FormHelperText>
        You can change the default provider&apos;s name. This name will be shown
        to users on the login screen like:{" "}
        <i>Login with {providerDisplayName}</i>
      </FormHelperText>
      <FormErrorMessage>
        {errors[providerSlug]?.display_name?.message}
      </FormErrorMessage>
    </FormControl>
  );
}
