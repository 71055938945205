import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { deleteApplicationCategory } from "../../backend/api";
import { ApplicationCategory } from "../../backend/types";
import { useConfirm } from "../../confirm-dialog";
import { getQueryKeyForApplicationCategories } from "../../hooks/useApplicationCategoriesQuery";
import { namedRoutes } from "../../routes";
import { ApplicationCategoryDeleteConfirmBody } from "../components/ApplicationCategoryDeleteConfirmBody";

export function useApplicationCategoryDeleteConfirm() {
  const { confirm } = useConfirm();
  const { mutateAsync: deleteApplicationCategoryAsync } = useMutation<
    void,
    AxiosError,
    ApplicationCategory
  >({
    mutationFn: async (category) => {
      await deleteApplicationCategory(category.id);
    },
  });
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();

  return useCallback(
    (category?: ApplicationCategory) => () => {
      if (!category) return;

      confirm({
        title: `Delete Category "${category.name}"`,
        body: <ApplicationCategoryDeleteConfirmBody category={category} />,
        confirmButtonText: "Delete",
        onConfirm: () =>
          deleteApplicationCategoryAsync(category, {
            onSuccess: (_, category) => {
              navigate(namedRoutes.applicationCategory.list);
              toast({
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
                description: `Category ${category.name} has been deleted.`,
              });
              queryClient.invalidateQueries({
                queryKey: getQueryKeyForApplicationCategories().slice(0, 1),
              });
            },
          }),
      });
    },
    [deleteApplicationCategoryAsync, confirm, navigate, queryClient, toast],
  );
}
