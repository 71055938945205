import {
  Flex,
  HStack,
  Heading,
  Link,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useMemo } from "react";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { PaginatedTable } from "../../components";
import { LazyApplicationDisplay } from "../../components/ApplicationDisplay";
import { namedRoutes } from "../../routes";
import { SessionsStatDto } from "../../session-management";
import { useDashboardParameters } from "../context";
import { useSessionsStatsQuery } from "../hooks/useSessionsStatsQuery";
import { FailedLoadingData } from "./FailedLoadingData";
dayjs.extend(duration);
dayjs.extend(relativeTime);

const columnHelper = createColumnHelper<SessionsStatDto>();

const columns = [
  columnHelper.display({
    id: "app",
    header: "Name",
    cell: (props) => (
      <Link
        as={RouterLink}
        textDecoration={"none"}
        _hover={{ textDecoration: "none", cursor: "pointer" }}
        to={generatePath(namedRoutes.application.overview, {
          applicationId: props.row.original.key,
        })}
        role="group"
      >
        <LazyApplicationDisplay
          applicationId={props.row.original.key}
          size="small"
        />
      </Link>
    ),
  }),
  columnHelper.accessor("count", {
    header: "# Sessions",
  }),
  columnHelper.accessor("averageDurationSeconds", {
    header: "Average Session Duration",
    cell: (props) => renderDuration(props.getValue()),
  }),
  columnHelper.accessor("totalDurationSeconds", {
    header: "Total Time",
    cell: (props) => renderDuration(props.getValue()),
  }),
];

export function PopularApplications() {
  const filters = useDashboardParameters();
  const applicationVersionPopularityQuery = useSessionsStatsQuery({
    ...filters,
    groupBy: "application",
  });

  const cols = useMemo(
    () =>
      applicationVersionPopularityQuery.isLoading
        ? columns.map((column) => ({
            ...column,
            cell: () => (
              <Skeleton>
                <Text>Loading</Text>
              </Skeleton>
            ),
          }))
        : columns,
    [applicationVersionPopularityQuery.isLoading],
  );

  // Create the table and pass your options
  const table = useReactTable({
    data: useMemo(
      () => applicationVersionPopularityQuery.data ?? [],
      [applicationVersionPopularityQuery.data],
    ),
    columns: cols,
    initialState: {
      sorting: [{ id: "count", desc: true }],
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Flex direction={"column"} height="full">
      <HStack>
        <Heading size="sm">Application usage</Heading>
      </HStack>
      {applicationVersionPopularityQuery.isError && (
        <FailedLoadingData h="100%" justifyContent={"center"} py={20} />
      )}
      {!applicationVersionPopularityQuery.isError && (
        <Stack mt={4} flexGrow={1}>
          <PaginatedTable table={table} />
        </Stack>
      )}
    </Flex>
  );
}
function renderDuration(durationInSeconds: number) {
  return <>{dayjs.duration(durationInSeconds, "seconds").humanize()}</>;
}
