import { QueryClient } from "@tanstack/react-query";
import axios from "axios";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry(failureCount, error) {
        // do not retry if the error is a 4xx
        if (axios.isAxiosError(error)) {
          const status = error?.response?.status;
          if (status && status >= 400 && status < 500) {
            return false;
          }
        }

        return failureCount < 2;
      },
    },
  },
});
