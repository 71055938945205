import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { InferType, object, string } from "yup";
import { Application, PortalError } from "../../backend";
import { createApplication, updateApplication } from "../../backend/api";
import { HTMLRichTextEditor } from "../../components";
import { FormRootErrorMessage } from "../../components/FormRootErrorMessage";
import { Step, StepProps } from "../../components/Stepper";
import { DevTool } from "../../hookform-devtools";
import { useActiveOrganizationQuery } from "../../hooks";
import { baseQueryKeyForApplications } from "../../hooks/useApplicationQuery";
import { extractFormSubmissionErrors } from "../../utils/extractFormSubmissionErrors";
import { useAddApplicationContext } from "../AddApplicationContext";

const applicationDataSchema = object({
  name: string()
    .defined()
    .required("Name is required.")
    .min(3, "Name must be at least 3 characters long.")
    .max(100, "Name cannot be longer than 100 characters."),
  description_html: string().ensure(),
});

type ApplicationMetaDataSchema = InferType<typeof applicationDataSchema>;

export function AddApplicationStep({ ...props }: StepProps) {
  const activeOrganizationQuery = useActiveOrganizationQuery();
  const {
    dispatch,
    state: {
      applicationBuild: { application: applicationId },
    },
  } = useAddApplicationContext();
  const { handleSubmit, setError, register, formState, control } =
    useForm<ApplicationMetaDataSchema>({
      resolver: yupResolver(applicationDataSchema),
      mode: "onChange",
    });
  const { errors, isSubmitting, isValid } = formState;
  const queryClient = useQueryClient();

  const { goToNext } = props;

  const { mutateAsync: upserApplicationAsync, ...upsertApplicationMutation } =
    useMutation<
      Application,
      AxiosError<PortalError>,
      ApplicationMetaDataSchema & Pick<Application, "organizations">
    >({
      mutationFn: (applicationData) =>
        !applicationId
          ? createApplication({
              ...applicationData,
              organizations: [activeOrganizationQuery.data?.id ?? 0],
            })
          : updateApplication(applicationId, applicationData),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: baseQueryKeyForApplications,
        });
      },
    });

  const onSubmit = useCallback(
    async (values: ApplicationMetaDataSchema) => {
      try {
        // create or update application version
        const isNew = !applicationId;
        const application = await upserApplicationAsync({
          ...values,
          organizations: [activeOrganizationQuery.data?.id ?? 0],
        });
        if (isNew) {
          dispatch({
            type: "APPLICATION_CREATE:SUCCESS",
            data: application,
          });
        }
        goToNext && goToNext();
      } catch (err: unknown) {
        extractFormSubmissionErrors(err, applicationDataSchema).forEach(
          ([field, message]) => setError(field, { type: "server", message }),
        );
      }
    },
    [
      applicationId,
      upserApplicationAsync,
      activeOrganizationQuery.data?.id,
      goToNext,
      dispatch,
      setError,
    ],
  );

  return (
    <Step
      icon={
        isSubmitting ? (
          <CircularProgress
            size={8}
            isIndeterminate
            color="brand.500"
            bg="modal-bg"
          />
        ) : undefined
      }
      actionButtons={
        <HStack>
          <Button
            size="sm"
            colorScheme={"brand"}
            isLoading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
            isDisabled={!isValid}
          >
            Save
          </Button>
        </HStack>
      }
      {...props}
      title={"Name application"}
    >
      <Text>
        While your upload is being processed, please share some details about
        your application.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <FormControl
            isInvalid={!!errors.name}
            isDisabled={upsertApplicationMutation.isPending}
          >
            <FormLabel htmlFor="name">Application name</FormLabel>
            <Input id="name" placeholder="Name" {...register("name")} />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!errors.description_html}
            isDisabled={upsertApplicationMutation.isPending}
          >
            <FormLabel>Description</FormLabel>
            <Box
              borderWidth={1}
              borderRadius={"md"}
              paddingX={2}
              paddingY={1}
              _hover={{ borderColor: "gray.300" }}
            >
              <Controller
                control={control}
                name="description_html"
                render={({ field }) => (
                  <HTMLRichTextEditor
                    placeholder="Tell us about your application! What makes it unique? Describe its features, advantages, and why someone should use it."
                    {...field}
                  />
                )}
              />
            </Box>
            <FormErrorMessage>
              {errors.description_html?.message}
            </FormErrorMessage>
          </FormControl>

          <FormRootErrorMessage formState={formState} />

          {/* Hidden submit button to ensure form can be submitted with return key */}
          <Button type="submit" hidden></Button>
        </Stack>
      </form>
      <DevTool control={control} placement="bottom-left" />
    </Step>
  );
}
