import { WarningTwoIcon } from "@chakra-ui/icons";
import {
  Heading,
  HStack,
  Skeleton,
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
} from "@chakra-ui/react";
import { UseQueryResult } from "@tanstack/react-query";
import { ReactElement, useMemo } from "react";
import { SessionsStatDto } from "../../session-management";

export function PeriodStatCompare({
  currentPeriodStatQuery,
  previousPeriodStatQuery,
  title,
  label,
  render = (stat) => <>{stat}</>,
}: {
  currentPeriodStatQuery: UseQueryResult<
    Pick<SessionsStatDto, "count" | "key">[]
  >;
  previousPeriodStatQuery?: UseQueryResult<
    Pick<SessionsStatDto, "count" | "key">[]
  >;
  label: string;
  title: string;
  render?: (stat: number) => ReactElement;
}) {
  const currentPeriodStat = useMemo(
    () => currentPeriodStatQuery.data?.at(0)?.count ?? 0,
    [currentPeriodStatQuery],
  );
  const previousPeriodStat = useMemo(
    () => previousPeriodStatQuery?.data?.at(0)?.count ?? 0,
    [previousPeriodStatQuery],
  );

  return (
    <StatGroup>
      <Stat>
        <Heading size="sm">{title}</Heading>
        <StatLabel>{label}</StatLabel>
        <StatNumber fontSize={"xxx-large"}>
          <Skeleton isLoaded={!currentPeriodStatQuery.isLoading}>
            {currentPeriodStatQuery.isError && (
              <HStack>
                <WarningTwoIcon color="orange" />
                <Text>Data unavailable</Text>
              </HStack>
            )}
            {currentPeriodStatQuery.isLoading && "Loading"}
            {currentPeriodStatQuery.isSuccess && render(currentPeriodStat)}
          </Skeleton>
        </StatNumber>

        <StatHelpText>
          <Skeleton isLoaded={!currentPeriodStatQuery.isLoading}>
            {!currentPeriodStatQuery.isError &&
            currentPeriodStat > 0 &&
            previousPeriodStat > 0 &&
            currentPeriodStat !== previousPeriodStat ? (
              <>
                <StatArrow
                  type={
                    currentPeriodStat > previousPeriodStat
                      ? "increase"
                      : "decrease"
                  }
                />
                {(
                  (currentPeriodStat * 100) / (previousPeriodStat || 1) -
                  100
                ).toFixed(0)}
                %
              </>
            ) : null}
          </Skeleton>
        </StatHelpText>
      </Stat>
    </StatGroup>
  );
}
