import QueryString from "query-string";
import { AuthProviderData } from "../organization/auth-providers/utils";
import { QueryParameters } from "../utils/types";
import {
  axiosInstance as backendClient,
  axiosInstanceGeneric as genericBackendClient,
} from "./client";
import { applicationBuildMetadataSchema } from "./schema";
import {
  Application,
  ApplicationBuild,
  ApplicationBuildId,
  ApplicationBuildListFilters,
  ApplicationCategory,
  ApplicationCategoryFilters,
  ApplicationCloudRenderingConfiguration,
  ApplicationExternalReference,
  ApplicationId,
  ApplicationImageDetails,
  ApplicationLaunchConfiguration,
  ApplicationListFilters,
  ApplicationNetworkSecurityRule,
  ApplicationPermission,
  BrandingResponseData,
  GrantedApplicationPermission,
  InvitationInfo,
  LegacyApplicationVersion,
  OrderingFilters,
  Organization,
  PaginatedApplicationBuildsList,
  PaginatedApplicationCategoriesList,
  PaginatedApplicationNetworkSecurityRulesList,
  PaginatedApplicationsList,
  PaginatedUserIdsList,
  PaginationFilters,
  PermissionSubject,
  PermissionString as PlatformPermission,
  PlatformPermissionInfo,
  PortalBackendPaginatedResult,
  User,
  UserApplicationPermission,
  UserGroup,
  UserGroupApplicationPermission,
  type LegacyApplicationVersionListFilters,
  type PaginatedLegacyApplicationVersionsList,
} from "./types";

export async function getApplicationBuildsPaginated(
  filters: ApplicationBuildListFilters = { page_size: 10 },
) {
  return (
    await backendClient.get<PaginatedApplicationBuildsList>(
      `/api/v2/application-builds/`,
      {
        params: filters,
      },
    )
  ).data;
}

export async function getApplicationBuild(id: ApplicationBuildId) {
  const buildData = (
    await backendClient.get<ApplicationBuild>(
      `/api/v2/application-builds/${id}/`,
    )
  ).data;

  buildData.metadata = await applicationBuildMetadataSchema.validate(
    buildData.metadata,
  );

  return buildData;
}

export async function deleteApplicationBuild(id: ApplicationBuildId) {
  await backendClient.delete(`/api/v2/application-builds/${id}/`);
}

export async function getLaunchConfigurationsForBuild(
  applicationBuildId: ApplicationBuildId,
) {
  return (
    await backendClient.get<
      PortalBackendPaginatedResult<
        Pick<ApplicationLaunchConfiguration, "application" | "xr_platform">
      >
    >(`/api/v2/application-builds/${applicationBuildId}/launch-configurations/`)
  ).data;
}

/**
 * @deprecated /api/applications/ is deprecated and should not be used
 */
export async function getLegacyApplicationVersionsPaginated(
  filters: LegacyApplicationVersionListFilters = { page_size: 200 },
) {
  return (
    await backendClient.get<PaginatedLegacyApplicationVersionsList>(
      "/api/applications/",
      {
        params: filters,
        paramsSerializer: (params) =>
          // we need comma-separated stringification for `id_in`
          QueryString.stringify(params, { arrayFormat: "comma" }),
        headers: {
          // version 2 returns invalid and valid apps, version 1 only valid ones
          Accept: "application/json; v=2",
        },
      },
    )
  ).data;
}

/**
 * @deprecated /api/applications/<application-version-id>/ is deprecated and should not be used
 */
export async function getAllLegacyApplicationVersions(
  filters: Omit<LegacyApplicationVersionListFilters, "page">,
) {
  let page = 1;
  let applicationsList: PaginatedLegacyApplicationVersionsList | undefined =
    undefined;
  const allResults: PaginatedLegacyApplicationVersionsList[] = [];
  do {
    applicationsList = await getLegacyApplicationVersionsPaginated({
      ...filters,
      page,
    });
    allResults.push(applicationsList);
    page++;
  } while (applicationsList?.next);

  return allResults.map((result) => result.results).flat();
}

/**
 * @deprecated /api/applications/<application-version-id>/ is deprecated and should not be used
 */
export async function getLegacyApplicationVersion(
  applicationVersionId: number | string,
) {
  return (
    await backendClient.get<LegacyApplicationVersion>(
      `/api/applications/${applicationVersionId}/`,
    )
  ).data;
}

export type UsersQueryFilters = {
  organization: number;
  groups?: number[];
  userIds?: number[];
} & PaginationFilters;

export async function getAllUsers({ userIds, ...filters }: UsersQueryFilters) {
  let allUsers: User[] = [];
  let url: string | null = "/api/users/";
  let usersPage: PortalBackendPaginatedResult<User>;
  do {
    usersPage = (
      await backendClient.get<PortalBackendPaginatedResult<User>>(url!, {
        params: {
          page_size: 200,
          id__in: userIds?.join(","),
          ...filters,
        },
        paramsSerializer: (params) => {
          return QueryString.stringify(params, {
            arrayFormat: "none",
          });
        },
      })
    ).data;
    url = usersPage.next;
    allUsers = allUsers.concat(usersPage.results);
  } while (usersPage.next);

  return allUsers;
}

/**
 * @deprecated /api/applications/<application-version-id>/ is deprecated and should not be used
 */
export async function uploadLegacyApplicationImage(
  file: File,
  applicationVersionId: string | number,
) {
  const formData = new FormData();
  formData.append("image", file);
  const response = await backendClient.post<ApplicationImageDetails>(
    `/api/applications/${applicationVersionId}/images/`,
    formData,
  );
  return response.data;
}

export async function uploadApplicationImage(
  file: File,
  applicationId: ApplicationId,
) {
  const formData = new FormData();
  formData.append("image", file);
  const response = await backendClient.post<ApplicationImageDetails>(
    `/api/v2/applications/${applicationId}/images/`,
    formData,
  );
  return response.data;
}

/**
 * @deprecated /api/applications/<application-version-id>/images/ is deprecated and should not be used
 */
export async function deleteLegacyApplicationImage(
  imageId: string | number,
  applicationVersionId: string | number,
): Promise<void> {
  await backendClient.delete(
    `/api/applications/${applicationVersionId}/images/${imageId}/`,
  );
}

export async function deleteApplicationImage(
  applicationId: ApplicationId,
  imageId: ApplicationImageDetails["id"],
): Promise<void> {
  await backendClient.delete(
    `/api/v2/applications/${applicationId}/images/${imageId}/`,
  );
}

export async function fetchTags(search: string) {
  const response = await backendClient.get<
    PortalBackendPaginatedResult<string>
  >(`/api/tags/`, {
    params: { search, page_size: 200 },
  });
  return response.data;
}

/**
 * @deprecated /api/applications/<application-version-id>/ is deprecated and should not be used
 */
export async function updateLegacyApplicationVersion(
  applicationVersionId: number | string,
  data: Partial<LegacyApplicationVersion>,
) {
  return (
    await backendClient.patch<LegacyApplicationVersion>(
      `/api/applications/${applicationVersionId}/`,
      data,
    )
  ).data;
}

/**
 * @deprecated /api/applications/<application-version-id>/ is deprecated and should not be used
 */
export async function deleteLegacyApplicationVersion(
  applicationVersionId: number | string,
) {
  await backendClient.delete(`/api/applications/${applicationVersionId}/`);
}

/**
 * @deprecated /api/applications/<application-version-id>/versions/ is deprecated and should not be used
 */
export async function fetchApplicationVersions(
  applicationVersionId: string | number,
) {
  return (
    await backendClient.get<PaginatedLegacyApplicationVersionsList>(
      `/api/applications/${applicationVersionId}/versions/`,
      {
        params: { page_size: 200, ordering: "-version" },
      },
    )
  ).data;
}

export async function updateUserInformation(
  id: number | string,
  { ...userInfo }: Partial<Omit<User, "id">>,
) {
  return (await backendClient.patch<User>(`/api/users/${id}/`, userInfo)).data;
}

export async function getUserIdsWithinGroup(
  groupId: number | string,
  queryParams: QueryParameters = {},
) {
  return (
    await backendClient.get<PaginatedUserIdsList>(
      `/api/groups/${groupId}/users/`,
      {
        params: queryParams,
      },
    )
  ).data;
}

export async function addUsersToGroup(
  groupId: number | string,
  userIds: number[],
) {
  return await backendClient.post<void>(
    `/api/groups/${groupId}/users/`,
    userIds,
  );
}

export async function removeUserFromGroup(
  groupId: number | string,
  userId: number | string,
) {
  return await backendClient.delete<void>(
    `/api/groups/${groupId}/users/${userId}/`,
  );
}

export async function addUsersToOrganization(
  orgId: number | string,
  userIds: number[],
) {
  return await backendClient.post<void>(
    `/api/organizations/${orgId}/users/`,
    userIds,
  );
}

export async function removeUserFromOrganization(
  orgId: number | string,
  userId: number | string,
) {
  return await backendClient.delete<void>(
    `/api/organizations/${orgId}/users/${userId}/`,
  );
}

export async function updateUserGroupInformation(
  userGroupId: string | number,
  userGroupData: Partial<UserGroup>,
) {
  return (
    await backendClient.patch<UserGroup>(
      `/api/groups/${userGroupId}/`,
      userGroupData,
    )
  ).data;
}

export async function createUserGroup(
  userGroupData: Pick<UserGroup, "name" | "organization">,
) {
  return (await backendClient.post<UserGroup>(`/api/groups/`, userGroupData))
    .data;
}

export async function deleteUserGroup(userGroupId: string | number) {
  await backendClient.delete<void>(`/api/groups/${userGroupId}/`);
}

export async function createUser(email: string, organizationId: number) {
  return (
    await backendClient.post<User>(`/api/users/`, {
      email,
      organizations: [organizationId],
    })
  ).data;
}

export async function createApplicationBuild(
  applicationBuildData: Partial<ApplicationBuild> &
    Pick<ApplicationBuild, "application">,
) {
  return (
    await backendClient.post<ApplicationBuild>(
      "/api/v2/application-builds/",
      applicationBuildData,
    )
  ).data;
}

export async function updateApplicationBuild(
  applicationVersionId: number,
  data: Partial<ApplicationBuild>,
) {
  return (
    await backendClient.patch<ApplicationBuild>(
      `/api/v2/application-builds/${applicationVersionId}/`,
      data,
    )
  ).data;
}

/**
 * @deprecated /api/applications/ is deprecated and should not be used
 */
export async function createLegacyApplicationVersion(
  applicationData: Partial<LegacyApplicationVersion>,
) {
  return (
    await backendClient.post<LegacyApplicationVersion>(
      "/api/applications/",
      applicationData,
    )
  ).data;
}

export async function createApplication(applicationData: Partial<Application>) {
  return (
    await backendClient.post<Application>(
      "/api/v2/applications/",
      applicationData,
    )
  ).data;
}

export async function updateApplication(
  applicationId: ApplicationId,
  data: Partial<Application>,
) {
  return (
    await backendClient.patch<Application>(
      `/api/v2/applications/${applicationId}/`,
      data,
    )
  ).data;
}

export async function deleteApplication(applicationId: ApplicationId) {
  await backendClient.delete<void>(`/api/v2/applications/${applicationId}/`);
}

export async function fetchApplicationBuildExecutables(
  applicationBuildId: ApplicationBuildId,
) {
  return (
    await backendClient.get<string[]>(
      `/api/v2/application-builds/${applicationBuildId}/executables/`,
    )
  ).data;
}

export async function triggerApplicationBuildMetadataDetection(
  applicationBuildId: ApplicationBuildId,
) {
  return (
    await backendClient.post(
      `/api/v2/application-builds/${applicationBuildId}/run-metadata-detection/`,
    )
  ).data;
}

export async function triggerApplicationBuildValidationAndOptimization(
  applicationBuildId: ApplicationBuildId,
) {
  return (
    await backendClient.post(
      `/api/v2/application-builds/${applicationBuildId}/run-validation/`,
    )
  ).data;
}

export async function getApplicationNetworkSecurityRules(
  applicationId: ApplicationId,
) {
  return (
    await backendClient.get<PaginatedApplicationNetworkSecurityRulesList>(
      `/api/v2/applications/${applicationId}/network-security-rules/`,
    )
  ).data;
}

export async function createApplicationNetworkSecurityRule(
  applicationId: ApplicationId,
  data: Omit<
    ApplicationNetworkSecurityRule,
    "id" | "created_date" | "modified_date" | "application"
  >,
) {
  return (
    await backendClient.post<ApplicationNetworkSecurityRule>(
      `/api/v2/applications/${applicationId}/network-security-rules/`,
      data,
    )
  ).data;
}

export async function deleteApplicationNetworkSecurityRule(
  applicationId: ApplicationId,
  id: ApplicationNetworkSecurityRule["id"],
) {
  await backendClient.delete<void>(
    `/api/v2/applications/${applicationId}/network-security-rules/${id}/`,
  );
}

export async function updateApplicationNetworkSecurityRule(
  applicationId: ApplicationId,
  {
    id,
    ...data
  }: Omit<
    ApplicationNetworkSecurityRule,
    "created_date" | "modified_date" | "application"
  >,
) {
  return (
    await backendClient.patch<ApplicationNetworkSecurityRule>(
      `/api/v2/applications/${applicationId}/network-security-rules/${id}/`,
      data,
    )
  ).data;
}

export async function fetchApplicationCloudRenderingConfiugration(
  applicationId: ApplicationId,
) {
  return (
    await backendClient.get<ApplicationCloudRenderingConfiguration>(
      `/api/v2/applications/${applicationId}/cloud-rendering-configuration/`,
    )
  ).data;
}

export async function updateApplicationCloudRenderingConfiguration(
  applicationId: ApplicationId,
  data: Partial<ApplicationCloudRenderingConfiguration>,
) {
  return (
    await backendClient.patch<ApplicationCloudRenderingConfiguration>(
      `/api/v2/applications/${applicationId}/cloud-rendering-configuration/`,
      data,
    )
  ).data;
}

export async function fetchPlatformPermissionInfo() {
  return (
    await backendClient.get<PlatformPermissionInfo[]>(
      `/api/platform-permissions/`,
    )
  ).data;
}

export async function fetchGroupPlatformPermissions(groupId: number) {
  return (
    await backendClient.get<PlatformPermission[]>(
      `/api/groups/${groupId}/platform-permissions/`,
    )
  ).data;
}

export async function grantPlatformPermissionsToGroup(
  permissions: PlatformPermission[],
  groupId: number,
) {
  if (!permissions.length) {
    return;
  }

  return (
    await backendClient.post<void>(
      `/api/groups/${groupId}/platform-permissions/`,
      permissions,
    )
  ).data;
}

export async function revokePlatformPermissionsFromGroup(
  permissions: PlatformPermission[],
  groupId: number,
) {
  if (!permissions.length) {
    return;
  }

  return (
    await backendClient.delete<void>(
      `/api/groups/${groupId}/platform-permissions/`,
      { data: permissions },
    )
  ).data;
}

export async function fetchServiceInfo() {
  return (await backendClient.get<{ version: string }>(`/api/info/`)).data;
}

export async function fetchCurrentUserInformation() {
  return (await backendClient.get<User>(`/api/users/current/`)).data;
}

export type PlatformPermissions = {
  uploadApplications: boolean;
  manageUsers: boolean;
  manageUserGroups: boolean;
  manageOrganization: boolean;
};

export async function fetchUserPlatformPermissions(
  userId: number,
  organizationId: number,
) {
  return (
    await backendClient.get<PlatformPermission[]>(
      `/api/users/${userId}/platform-permissions/`,
      { params: { organization: organizationId } },
    )
  ).data;
}

export async function inviteUserToOrganization(
  email: string,
  organizationId: number,
  groups: number[] = [],
) {
  await backendClient.post(`/api/organization-invitations/`, {
    email,
    organization: organizationId,
    groups,
  });
}

export async function fetchInvitations({
  pending,
  ...params
}: { pending?: boolean } & PaginationFilters & OrderingFilters = {}) {
  return (
    await backendClient.get<PortalBackendPaginatedResult<InvitationInfo>>(
      `/api/organization-invitations/`,
      {
        params: { accepted_at__isnull: pending === true, ...params },
      },
    )
  ).data;
}

export async function deleteInvitation(invitationId: string) {
  await backendClient.delete(`/api/organization-invitations/${invitationId}/`);
}

export async function uploadImage(file: File): Promise<string> {
  const formData = new FormData();
  formData.append("file", file);
  const response = await backendClient.put("/api/image-upload/", formData);
  return response.data.filename;
}

export async function fetchBranding(organizationId: string | number) {
  return (
    await genericBackendClient.get<BrandingResponseData>(`/api/branding/`, {
      params: { organization: organizationId },
    })
  ).data;
}

export async function updateBranding({
  organization,
  ...updatedValues
}: {
  organization: string | number;
} & Partial<Omit<BrandingResponseData, "organization">>) {
  return (
    await genericBackendClient.put<BrandingResponseData>(
      "/api/branding/",
      updatedValues,
      {
        params: { organization: organization },
      },
    )
  ).data;
}

export async function getApplicationCategoriesPaginated(
  filters: ApplicationCategoryFilters = { page_size: 200 },
) {
  return (
    await backendClient.get<PaginatedApplicationCategoriesList>(
      "/api/v2/application-categories/",
      {
        params: filters,
      },
    )
  ).data;
}

export async function getApplicationCategory(id: ApplicationCategory["id"]) {
  return (
    await backendClient.get<ApplicationCategory>(
      `/api/v2/application-categories/${id}/`,
    )
  ).data;
}

export async function createApplicationCategory(name: string) {
  return (
    await backendClient.post<ApplicationCategory>(
      `/api/v2/application-categories/`,
      { name },
    )
  ).data;
}

export async function deleteApplicationCategory(id: ApplicationCategory["id"]) {
  await backendClient.delete(`/api/v2/application-categories/${id}/`);
}

export async function updateApplicationCategory(
  id: ApplicationCategory["id"],
  data: Partial<Omit<ApplicationCategory, "id">>,
) {
  return (
    await backendClient.patch<ApplicationCategory>(
      `/api/v2/application-categories/${id}/`,
      data,
    )
  ).data;
}

export async function addApplicationToCategory(
  applicationId: ApplicationId,
  categoryId: ApplicationCategory["id"],
  order?: number,
) {
  return (
    await backendClient.post<ApplicationCategory>(
      `/api/v2/application-categories/${categoryId}/add-application/`,
      { application: applicationId, order },
    )
  ).data;
}

export async function removeApplicationFromCategory(
  applicationId: ApplicationId,
  categoryId: ApplicationCategory["id"],
) {
  return (
    await backendClient.post<ApplicationCategory>(
      `/api/v2/application-categories/${categoryId}/remove-application/`,
      { application: applicationId },
    )
  ).data;
}

export async function requestPasswordReset(email: string) {
  return (
    await backendClient.post<{ detail: string }>(
      `/api/auth/request-password-reset/`,
      { email },
    )
  ).data;
}

export async function resetPassword(reset: {
  password: string;
  user_id: string;
  timestamp: string;
  signature: string;
}) {
  return (
    await backendClient.post<{ detail: string }>(
      `/api/auth/reset-password/`,
      reset,
    )
  ).data;
}

export async function changePassword(change: {
  old_password: string;
  password: string;
  password_confirm: string;
}) {
  return (
    await backendClient.post<{ detail: string }>(
      `/api/auth/change-password/`,
      change,
    )
  ).data;
}

export async function getOrganization(id: number | string) {
  const response = await backendClient.get<Organization>(
    `/api/organizations/${id}/`,
  );
  return response.data;
}

export async function getOrganizationByDomain(domain: string) {
  const response = await backendClient.get<Organization>(
    `/api/organizations/by-domain/${domain}/`,
  );
  return response.data;
}

export async function getOrganizationBySubdomain(subdomain: string) {
  const response = await backendClient.get<Organization>(
    `/api/organizations/by-subdomain/${subdomain}/`,
  );
  return response.data;
}

export async function createOrganizationAuthProvider(
  organizationId: number | string,
  providerData: Omit<AuthProviderData, "id">,
) {
  return (
    await backendClient.post<AuthProviderData>(
      `/api/organizations/${organizationId}/auth-providers/`,
      providerData,
    )
  ).data;
}

export async function updateOrganizationAuthProvider(
  organizationId: number | string,
  authProviderId: number | string,
  providerData: Partial<Omit<AuthProviderData, "id">>,
) {
  return (
    await backendClient.patch<AuthProviderData>(
      `/api/organizations/${organizationId}/auth-providers/${authProviderId}/`,
      providerData,
    )
  ).data;
}

export async function updateOrganization(
  id: number | string,
  data: Partial<Omit<Organization, "id">>,
) {
  return (
    await backendClient.patch<Organization>(`/api/organizations/${id}/`, data)
  ).data;
}

export async function updateApplicationImageOrder(
  applicationVersionId: number | string,
  imageIds: number[],
) {
  await backendClient.put(
    `/api/v2/applications/${applicationVersionId}/images/set_order/`,
    { id_list: imageIds },
  );
}

export async function getApplicationImage(
  applicationVersionId: number | string,
  imageId: number | string,
) {
  return (
    await backendClient.get<ApplicationImageDetails>(
      `/api/v2/applications/${applicationVersionId}/images/${imageId}/`,
    )
  ).data;
}

export async function getApplicationPermissionsForUser(
  userId: number | string,
) {
  return (
    await backendClient.get<GrantedApplicationPermission[]>(
      `/api/users/${userId}/application-permissions/`,
    )
  ).data;
}

export async function getApplicationPermissionsForUserGroup(
  userGroupId: number | string,
) {
  return (
    await backendClient.get<GrantedApplicationPermission[]>(
      `/api/groups/${userGroupId}/application-permissions/`,
    )
  ).data;
}

export async function getUserPermissionsForApplication(
  applicationId: ApplicationId,
) {
  return (
    await backendClient.get<UserApplicationPermission[]>(
      `/api/v2/applications/${applicationId}/user_permissions/`,
    )
  ).data;
}

export async function getUserGroupPermissionsForApplication(
  applicationId: ApplicationId,
) {
  return (
    await backendClient.get<UserGroupApplicationPermission[]>(
      `/api/v2/applications/${applicationId}/group_permissions/`,
    )
  ).data;
}

export async function updateApplicationPermissions({
  applicationId,
  subject,
  organizationId,
  validUntil,
  permissions,
}: {
  applicationId: ApplicationId;
  organizationId: number;
  subject: PermissionSubject;
  permissions: ApplicationPermission[];
  validUntil?: Date;
}) {
  // validUntil = new Date();
  await backendClient.patch(
    `/api/v2/applications/${applicationId}/${subject.type}_permissions/`,
    {
      [subject.type]: subject.id,
      permissions: permissions,
      organization: organizationId,
      valid_until: validUntil,
    },
  );
}

export async function getApplication(applicationId: ApplicationId) {
  return (
    await backendClient.get<Application>(
      `/api/v2/applications/${applicationId}/`,
    )
  ).data;
}

export async function getApplicationsPaginated(
  filters: ApplicationListFilters = { page_size: 200 },
) {
  return (
    await backendClient.get<PaginatedApplicationsList>(
      "/api/v2/applications/",
      {
        params: filters,
        paramsSerializer: (params) => {
          // we need comma-separated stringification for `id_in`
          const { id_in, ...rest } = params;
          return [
            QueryString.stringify({ id_in }, { arrayFormat: "comma" }),
            QueryString.stringify(rest),
          ].join("&");
        },
      },
    )
  ).data;
}

export async function getApplicationVersionSignedDownloadUrl(
  applicationVersionId: ApplicationBuildId,
) {
  return (
    await backendClient.get<{ url: string }>(
      `/api/applications/${applicationVersionId}/signed-download-url/`,
    )
  ).data;
}

export async function getTermsAndPrivacy() {
  const data = (
    await backendClient.get<
      PortalBackendPaginatedResult<{
        privacy_content: string;
        terms_content: string;
        is_enabled: boolean;
      }>
    >(`/api/privacy/`)
  ).data;

  if (!data.count) {
    throw new Error("No privacy and terms content found");
  }

  return data.results[0];
}

export async function consentToTermsAndPrivacy(organizationId: number) {
  return (
    await backendClient.post(`/api/privacy/accept/`, {
      organization: organizationId,
    })
  ).data;
}

export async function getApplicationExternalReferences(
  applicationId: ApplicationId,
  filters: PaginationFilters & { type?: string } = {},
) {
  return (
    await backendClient.get<
      PortalBackendPaginatedResult<ApplicationExternalReference>
    >(`/api/v2/applications/${applicationId}/external-references/`, {
      params: filters,
    })
  ).data;
}

export async function createApplicationExternalReference(
  applicationId: ApplicationId,
  data: Omit<ApplicationExternalReference, "is_active"> &
    Partial<Pick<ApplicationExternalReference, "is_active">>,
) {
  return (
    await backendClient.post<ApplicationExternalReference>(
      `/api/v2/applications/${applicationId}/external-references/`,
      data,
    )
  ).data;
}

export async function updateApplicationExternalReference(
  applicationId: ApplicationId,
  type: ApplicationExternalReference["type"],
  data: Partial<Pick<ApplicationExternalReference, "url" | "is_active">>,
) {
  return (
    await backendClient.patch<ApplicationExternalReference>(
      `/api/v2/applications/${applicationId}/external-references/${type}/`,
      data,
    )
  ).data;
}

export async function deleteApplicationExternalReference(
  applicationId: ApplicationId,
  type: ApplicationExternalReference["type"],
) {
  await backendClient.delete(
    `/api/v2/applications/${applicationId}/external-references/${type}/`,
  );
}

export async function getApplicationLaunchConfigurations(
  applicationId: ApplicationId,
  filters: PaginationFilters & { type?: string } = {},
) {
  return (
    await backendClient.get<
      PortalBackendPaginatedResult<ApplicationLaunchConfiguration>
    >(`/api/v2/applications/${applicationId}/launch-configurations/`, {
      params: filters,
    })
  ).data;
}

export async function updateApplicationLaunchConfiguration(
  applicationId: ApplicationId,
  xr_platform: ApplicationLaunchConfiguration["xr_platform"],
  data: Partial<
    Omit<ApplicationLaunchConfiguration, "application" | "xr_platform">
  >,
) {
  return (
    await backendClient.patch<ApplicationLaunchConfiguration>(
      `/api/v2/applications/${applicationId}/launch-configurations/${xr_platform}/`,
      data,
    )
  ).data;
}
