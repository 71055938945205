import { Container, ContainerProps, Stack } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { OrganizationLogo, PageTitle } from "../components";

export function BaseLayout({
  children,
  ...props
}: React.PropsWithChildren<ContainerProps>) {
  return (
    <>
      <PageTitle />
      <Container
        maxW="lg"
        justifyContent={"center"}
        height="100vh"
        display="flex"
        flexDirection={"column"}
        {...props}
      >
        <Stack py={{ base: 0, sm: 8 }} px={{ base: 4, sm: 10 }} spacing={8}>
          {children}
        </Stack>
      </Container>
    </>
  );
}

export function LoginLayout() {
  return (
    <BaseLayout>
      <OrganizationLogo maxWidth={"3xs"} />
      <Outlet />
    </BaseLayout>
  );
}
