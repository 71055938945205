import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { InferType, object, string } from "yup";
import { useAuthentication } from "../auth";
import { changePassword } from "../backend/api";
import { DevTool } from "../hookform-devtools";
import { namedRoutes } from "../routes";
import { extractFormSubmissionErrors } from "../utils/extractFormSubmissionErrors";

const passwordChangeSchema = object({
  old_password: string().required("Current password is required"),
  password: string().required("Password is required"),
  password_confirm: string()
    .test("passwords-match", "Passwords must match", function (value, context) {
      return context.parent.password === value;
    })
    .required("Password confirmation is required"),
}).noUnknown();

type PasswordChangeSchema = InferType<typeof passwordChangeSchema>;
type ErrorResponse =
  | {
      non_field_errors?: string[];
      old_password?: string[];
      password?: string[];
      password_confirm?: string[];
    }
  | { detail: string };

export function PasswordChangeForm() {
  const { user } = useAuthentication();
  const [next] = useQueryParam("next", StringParam);
  const navigate = useNavigate();
  const changePasswordMutation = useMutation<
    void,
    AxiosError<ErrorResponse>,
    Parameters<typeof changePassword>[0]
  >({
    mutationFn: async (data) => {
      await changePassword(data);
    },
  });
  const { register, formState, handleSubmit, control, setError } =
    useForm<PasswordChangeSchema>({
      mode: "onSubmit",
      resolver: yupResolver(passwordChangeSchema),
    });

  return (
    <form
      onSubmit={handleSubmit(async (values) => {
        try {
          await changePasswordMutation.mutateAsync(values);
          // redirect to success view
          navigate(namedRoutes.changePassword.done, { state: { next } });
        } catch (err) {
          extractFormSubmissionErrors(err, passwordChangeSchema).forEach(
            ([field, message]) => setError(field, { type: "server", message }),
          );
        }
      })}
    >
      <Stack spacing="6">
        <Heading size={{ base: "xs", md: "sm" }}>
          Please enter your current password, for security&apos;s sake, then
          enter your new password twice so we can verify you typed it in
          correctly.
        </Heading>
        <Text color="chakra-subtle-text">
          You are currently authenticated as {user?.email}. Not you?{" "}
          <Link as={RouterLink} to={namedRoutes.logout}>
            Switch account
          </Link>
        </Text>
        <Stack spacing="5">
          <FormControl isInvalid={!!formState.errors.old_password}>
            <FormLabel htmlFor="old_password">Current password</FormLabel>
            <Input
              id="old_password"
              type="password"
              {...register("old_password")}
            />
            <FormHelperText>
              If you don&apos;t remember your password or don&apos;t have one
              set yet (e.g. because you usually log in through another
              provider), you can{" "}
              <Link as={RouterLink} to={namedRoutes.resetPassword.request}>
                request a password reset
              </Link>{" "}
              instead.
            </FormHelperText>
            <FormErrorMessage>
              {formState.errors.old_password?.message}
            </FormErrorMessage>
          </FormControl>
          <Divider />
          <FormControl isInvalid={!!formState.errors.password}>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Input id="password" type="password" {...register("password")} />
            <FormHelperText>
              <UnorderedList>
                <ListItem>
                  Your password can&apos;t be too similar to your other personal
                  information.
                </ListItem>
                <ListItem>
                  Your password must contain at least 8 characters.
                </ListItem>
                <ListItem>
                  Your password can&apos;t be a commonly used password.
                </ListItem>
                <ListItem>
                  Your password can&apos;t be entirely numeric.
                </ListItem>
              </UnorderedList>
            </FormHelperText>
            <FormErrorMessage>
              {formState.errors.password?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!formState.errors.password_confirm}>
            <FormLabel htmlFor="password_confirm">
              Password Confirmation
            </FormLabel>
            <Input
              id="password_confirm"
              type="password"
              {...register("password_confirm")}
            />
            <FormErrorMessage>
              {formState.errors.password_confirm?.message}
            </FormErrorMessage>
          </FormControl>
          <Button
            colorScheme="brand"
            type="submit"
            isLoading={formState.isSubmitting}
          >
            Change password
          </Button>
        </Stack>
      </Stack>
      <DevTool control={control} />
    </form>
  );
}
