import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonProps,
  Link as ExternalLink,
  LinkProps as ExternalLinkProps,
} from "@chakra-ui/react";
import { Link, LinkProps } from "react-router-dom";

export function LinkButton(props: ButtonProps & LinkProps) {
  return <Button as={Link} {...props} />;
}

export function ExternalLinkButton({
  children,
  ...props
}: ButtonProps & ExternalLinkProps) {
  return (
    <Button
      as={ExternalLink}
      textDecoration="none"
      sx={{ "&:hover": { textDecoration: "none" } }}
      rightIcon={<ExternalLinkIcon />}
      {...props}
    >
      {children}
    </Button>
  );
}
