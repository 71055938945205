import {
  chakraComponents,
  OptionProps,
  Props,
  Select,
} from "chakra-react-select";
import { useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import { User } from "../backend";
import { useSearchUserQuery } from "../hooks";
import { LazyUserProfile } from "./UserProfile";

export function UserSelect({
  filter = () => true,
  ...props
}: Props<UserOption, true> & {
  filter?: (value: User, index: number, array: User[]) => boolean;
}) {
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const { data: { results: users } = {}, isLoading } =
    useSearchUserQuery(debouncedSearchText);
  const filteredUsers = useMemo(
    () => (users ?? []).filter(filter),
    [users, filter],
  );

  return (
    <Select
      placeholder="Find user(s) ..."
      size="sm"
      isMulti
      isLoading={isLoading}
      inputValue={searchText}
      onInputChange={setSearchText}
      blurInputOnSelect
      components={customSelectComponents}
      options={filteredUsers?.map((user) => ({
        value: user.id,
        label: user.full_name,
      }))}
      // Need to disable the built-in filtering as we're loading data via the API, see https://github.com/TanStack/query/discussions/3277#discussioncomment-4861535
      filterOption={null}
      {...props}
    />
  );
}
export type UserOption = {
  value: number;
  label: string;
};

const customSelectComponents = {
  Option: ({ ...props }: OptionProps<UserOption, true>) => (
    <chakraComponents.Option {...props}>
      <LazyUserProfile userId={props.data.value} />
    </chakraComponents.Option>
  ),
};
