import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  CircularProgress,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Select } from "chakra-react-select";
import { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { InferType, object, string } from "yup";
import { ApplicationBuild, ApplicationStatus } from "../../backend";
import {
  deleteApplicationBuild,
  getApplicationBuild,
  updateApplicationBuild,
  updateApplicationLaunchConfiguration,
} from "../../backend/api";
import { ApplicationBuildId, PortalError } from "../../backend/types";
import { DeleteButton } from "../../components";
import { Step, StepProps } from "../../components/Stepper";
import {
  useSkipStep,
  useStepperContext,
} from "../../components/StepperContext";
import { DevTool } from "../../hookform-devtools";
import {
  baseQueryKeyForApplicationBuilds,
  getQueryKeyForApplicationBuild,
  getQueryKeyForApplicationBuildsPaginated,
} from "../../hooks/useApplicationBuildsQuery";
import { useAddApplicationContext } from "../AddApplicationContext";
import { useApplicationBuildExecutablesQuery } from "../hooks/useApplicationBuildForm";
import { getQueryKeyForApplicationLaunchConfigurationsQuery } from "../hooks/useApplicationLaunchConfigurationsQuery";

const applicationBuildWithExecutableSchema = object({
  executable_path: string()
    .defined()
    .nonNullable()
    .required("Windows applications must specify the path to the executable."),
}).noUnknown();

type ApplicationBuildWithExecutableSchema = InferType<
  typeof applicationBuildWithExecutableSchema
>;

function getQueryKeyForApplicationBuildStatusPolling(
  applicationBuildId: ApplicationBuildId,
) {
  return [
    ...getQueryKeyForApplicationBuild(applicationBuildId),
    "status-polling",
  ];
}

export function ValidateApplicationBuildStep({ ...props }: StepProps) {
  const queryClient = useQueryClient();
  const {
    state: {
      applicationBuild: { id: applicationBuildId },
    },
  } = useAddApplicationContext();

  const { onCompleted } = useStepperContext();
  const { goToNext, goToPrev } = props;

  // Skip validation if no build uploaded
  useSkipStep(props, !applicationBuildId);

  const updateApplicationLaunchConfigurationsMutation = useMutation<
    void,
    AxiosError<PortalError>,
    ApplicationBuild
  >({
    mutationFn: async (applicationBuild) => {
      await Promise.all(
        applicationBuild.supported_xr_platforms.map((xrPlatform) =>
          updateApplicationLaunchConfiguration(
            applicationBuild.application,
            xrPlatform,
            { application_build: applicationBuild.id },
          ),
        ),
      );
    },
    onSuccess: async (_, build) => {
      await queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationLaunchConfigurationsQuery(
          build.application,
        ),
      });
      goToNext && goToNext();
    },
  });

  // this query will poll the application build's information after save until it is validated
  const applicationBuildPollingQuery = useQuery({
    queryKey: getQueryKeyForApplicationBuildStatusPolling(
      applicationBuildId ?? 0,
    ),
    queryFn: async () => {
      const build = await getApplicationBuild(applicationBuildId ?? 0);
      // only return the application build information if the app build has been validated
      if (build.status !== ApplicationStatus.Pending) {
        // if the app build is valid, update the launch configurations next
        if (build.status === ApplicationStatus.Valid) {
          updateApplicationLaunchConfigurationsMutation.mutate(build);
        }
        // otherwise, nothing to do, user will need to make their app build valid first
        reset({ executable_path: build.executable_path ?? undefined });
        return build;
      }
      throw new Error("Pending validation");
    },
    retry(failureCount, error) {
      // retry at most 5 times, as long as validation is pending
      if ((error as Error).message !== "Pending validation") return false;
      if (failureCount > 5) return false;
      return true;
    },
    enabled: !!applicationBuildId,
    refetchOnWindowFocus: false,
  });
  const applicationStatus = useMemo(
    () => applicationBuildPollingQuery.data?.status,
    [applicationBuildPollingQuery.data?.status],
  );
  const applicationExecutablesQuery = useApplicationBuildExecutablesQuery(
    applicationBuildId,
    {
      enabled:
        applicationStatus === ApplicationStatus.MultipleExecutables ||
        applicationStatus === ApplicationStatus.InvalidPath,
    },
  );
  const applicationExecutables = useMemo(
    () => applicationExecutablesQuery.data ?? [],
    [applicationExecutablesQuery.data],
  );
  const { handleSubmit, formState, control, reset } =
    useForm<ApplicationBuildWithExecutableSchema>({
      resolver: yupResolver(
        applicationExecutables.length
          ? applicationBuildWithExecutableSchema.concat(
              object({
                executable_path: string()
                  .defined()
                  .required(
                    "Windows applications must specify the path to the executable.",
                  )
                  .nonNullable()
                  .oneOf(
                    applicationExecutables,
                    // eslint-disable-next-line no-template-curly-in-string
                    "The only detected executables are: ${values}",
                  ),
              }),
            )
          : applicationBuildWithExecutableSchema,
      ),
      mode: "onChange",
    });

  const deleteApplicationBuildMutation = useMutation<void, AxiosError, number>({
    mutationFn: (applicationBuildId) =>
      deleteApplicationBuild(applicationBuildId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: baseQueryKeyForApplicationBuilds,
      });
    },
  });

  const isLoading =
    applicationBuildPollingQuery.isFetching ||
    updateApplicationLaunchConfigurationsMutation.isPending;

  const {
    mutateAsync: updateApplicationBuildAsync,
    ...updateApplicationBuildMutation
  } = useMutation<
    ApplicationBuild,
    AxiosError,
    ApplicationBuildWithExecutableSchema & Pick<ApplicationBuild, "id">
  >({
    mutationFn: ({ id, executable_path }) =>
      updateApplicationBuild(id, {
        executable_path,
      }),
    onSuccess: (applicationBuild) =>
      queryClient.setQueryData<ApplicationBuild>(
        getQueryKeyForApplicationBuild(applicationBuild.id),
        (oldData) =>
          oldData
            ? {
                ...oldData,
                executable_path: applicationBuild.executable_path,
              }
            : oldData,
      ),
  });

  const onSubmit = useCallback(
    async (values: ApplicationBuildWithExecutableSchema) => {
      if (!applicationBuildId) throw new Error("Missing application id");
      // update existing application
      const result = await updateApplicationBuildAsync({
        id: applicationBuildId,
        ...values,
      });
      // now we need to wait again for the validation to happen (and hopefully succed)
      // to do so, invalidate the polling query to let it poll again
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationBuildStatusPolling(
          applicationBuildId ?? 0,
        ),
      });
      // refetch relevant queries
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationBuild(applicationBuildId ?? 0),
      });
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationBuildsPaginated({
          application: result.application,
        }).slice(0, 2),
      });
    },
    [applicationBuildId, queryClient, updateApplicationBuildAsync],
  );

  return (
    <Step
      icon={
        isLoading ? (
          <CircularProgress
            size={8}
            isIndeterminate
            color="brand.500"
            bg="modal-bg"
          />
        ) : undefined
      }
      actionButtons={
        <HStack>
          {applicationStatus === ApplicationStatus.NoExecutables ||
          (applicationStatus === ApplicationStatus.InvalidPath &&
            !applicationExecutables.length) ||
          applicationStatus === ApplicationStatus.InvalidApplicationArchive ? (
            <DeleteButton
              variant="solid"
              width="auto"
              size="sm"
              onClick={() =>
                applicationBuildId &&
                deleteApplicationBuildMutation.mutate(applicationBuildId, {
                  onSuccess: () => onCompleted && onCompleted(),
                })
              }
              isLoading={deleteApplicationBuildMutation.isPending}
            >
              Delete application
            </DeleteButton>
          ) : applicationStatus === ApplicationStatus.MultipleExecutables ||
            applicationStatus === ApplicationStatus.InvalidPath ? (
            <Button
              size="sm"
              colorScheme={"brand"}
              isLoading={
                updateApplicationBuildMutation.isPending ||
                applicationBuildPollingQuery.isFetching
              }
              onClick={handleSubmit(onSubmit)}
            >
              Save Changes
            </Button>
          ) : applicationStatus === ApplicationStatus.Valid ? (
            <>
              <Button size="sm" onClick={goToPrev}>
                Back
              </Button>
              <Button
                colorScheme="brand"
                size="sm"
                onClick={goToNext}
                isDisabled={
                  updateApplicationLaunchConfigurationsMutation.isError
                }
              >
                Next
              </Button>
            </>
          ) : undefined}
        </HStack>
      }
      {...props}
      title={"Validate"}
    >
      <Alert
        status={
          applicationStatus === ApplicationStatus.NoExecutables ||
          applicationStatus === ApplicationStatus.InvalidPath ||
          updateApplicationLaunchConfigurationsMutation.isError
            ? "error"
            : applicationBuildPollingQuery.isLoading
              ? "loading"
              : applicationStatus === ApplicationStatus.Valid
                ? "success"
                : "warning"
        }
      >
        <AlertIcon />
        <AlertDescription>
          {applicationBuildPollingQuery.isLoading
            ? "Please wait while we're validating your application'information."
            : updateApplicationLaunchConfigurationsMutation.isError
              ? "An error occurred while trying to update the application launch configurations. Please try again later."
              : applicationStatus === ApplicationStatus.NoExecutables ||
                  (applicationStatus === ApplicationStatus.InvalidPath &&
                    !applicationExecutables.length)
                ? "This application build does not contain any executables, please delete the build and upload one with executables."
                : applicationStatus === ApplicationStatus.InvalidPath
                  ? "Please select a valid executable."
                  : applicationStatus === ApplicationStatus.MultipleExecutables
                    ? "Please select the correct executable for this application to proceed."
                    : applicationStatus === ApplicationStatus.Valid
                      ? "Application configuration seems to be valid."
                      : applicationStatus === ApplicationStatus.Pending
                        ? "Application validation is still pending. Please contact an administrator!"
                        : applicationStatus ===
                            ApplicationStatus.InvalidApplicationArchive
                          ? "Application archive is invalid. Please delete the build and upload a valid application archive instead."
                          : undefined}
        </AlertDescription>
      </Alert>

      {(applicationStatus === ApplicationStatus.MultipleExecutables ||
        applicationStatus === ApplicationStatus.InvalidPath) &&
        applicationExecutables.length && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl
              isInvalid={!!formState.errors.executable_path}
              isDisabled={isLoading}
            >
              <FormLabel htmlFor="executable_path">Executable Path</FormLabel>
              <Controller
                control={control}
                name="executable_path"
                render={({ field }) => (
                  <Select
                    {...field}
                    isMulti={false}
                    value={{ value: field.value, label: field.value }}
                    options={applicationExecutables.map((executable) => ({
                      value: executable,
                      label: executable,
                    }))}
                    onChange={(val) =>
                      field.onChange(val ? val.value : undefined)
                    }
                    isLoading={applicationExecutablesQuery.isLoading}
                  />
                )}
              />
              {!formState.errors.executable_path && (
                <FormHelperText>
                  Path to the executable used to run the application. This path
                  is relative to the root directory of the application archive.
                </FormHelperText>
              )}
              <FormErrorMessage>
                {formState.errors.executable_path?.message}
              </FormErrorMessage>
            </FormControl>
            {/* Hidden submit button to ensure form can be submitted with return key */}
            <Button type="submit" hidden></Button>
          </form>
        )}
      <DevTool control={control} />
    </Step>
  );
}
