import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useMemo } from "react";

dayjs.extend(duration);

export function useTimeSettings(from: Date, to: Date) {
  return {
    startDate: from,
    endDate: to,
    unitOfTime: useMemo<"day" | "month" | "year">(() => {
      const dur = dayjs.duration(to.getTime() - from.getTime(), "milliseconds");
      return dur.asYears() > 1.1
        ? "year"
        : dur.asMonths() > 1.1
        ? "month"
        : "day";
    }, [from, to]),
  };
}
