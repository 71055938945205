import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { getApplicationNetworkSecurityRules } from "../backend/api";
import {
  ApplicationId,
  NetworkSecurityRule,
  NetworkSecurityRuleDirection,
  PaginatedApplicationNetworkSecurityRulesList,
} from "../backend/types";
import { getDefaultPorts, PortRule } from "../session-management";
import { getQueryKeyForApplication } from "./useApplicationQuery";

export function getQueryKeyForApplicationNetworkSecurityRules(
  applicationId: ApplicationId,
) {
  return [
    ...getQueryKeyForApplication(applicationId),
    "network-security-rules",
  ];
}

export function getQueryForApplicationNetworkSecurityRules(
  applicationId: ApplicationId,
  queryOptions: Partial<
    UseQueryOptions<PaginatedApplicationNetworkSecurityRulesList, AxiosError>
  > = {},
) {
  return {
    queryKey: getQueryKeyForApplicationNetworkSecurityRules(applicationId),
    queryFn: () => getApplicationNetworkSecurityRules(applicationId),
    ...queryOptions,
    enabled: !!applicationId && (queryOptions.enabled ?? true),
  };
}

export function useApplicationNetworkSecurityRules(
  applicationId: ApplicationId,
) {
  return useQuery(getQueryForApplicationNetworkSecurityRules(applicationId));
}

export function useDefaultApplicationNetworkSecurityRules(
  queryOptions: Partial<
    UseQueryOptions<PortRule[], AxiosError, NetworkSecurityRule[]>
  > = {},
) {
  return useQuery<PortRule[], AxiosError, NetworkSecurityRule[]>({
    queryKey: ["network-security-rules", "default"],
    queryFn: () => getDefaultPorts(),
    // translate the data format from SM API to our internal structure
    select: (data) =>
      data?.map((portRule) => ({
        description: portRule.description,
        cidr_blocks: portRule.cidrBlocks,
        protocol:
          portRule.ipProtocol.toLowerCase() as NetworkSecurityRule["protocol"],
        from_port: portRule.fromPort,
        to_port: portRule.toPort,
        direction:
          portRule.direction === "Outbound"
            ? NetworkSecurityRuleDirection.Outbound
            : NetworkSecurityRuleDirection.Inbound,
      })) ?? [],
    retry: false,
    // this data changes very very rarely, so good enough to cache forever
    gcTime: Infinity,
    staleTime: Infinity,
    ...queryOptions,
    enabled: queryOptions.enabled === undefined || queryOptions.enabled,
  });
}
