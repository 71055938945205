import { Heading, Stack, Text } from "@chakra-ui/react";

export function ValidateEmail() {
  return (
    <Stack spacing="4">
      <Heading size={{ base: "xs", md: "sm" }}>
        Please confirm your e-mail address
      </Heading>
      <Text size={{ base: "xs", md: "sm" }} fontWeight="normal">
        Please check your e-mail for a verification message to complete your
        registration and start using our service.
      </Text>
    </Stack>
  );
}
