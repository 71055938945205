import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { loadDataFromElement } from "../utils/hydrate-data";

const messages = loadDataFromElement<
  { level: string; message: string; tags: string }[]
>("messages", []);
const errorMessages = messages.filter((m) => m.level === "error");

export function SSOErrorView() {
  return (
    <Alert
      status="error"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        Sign in via Auth Provider failed
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        Reason was:{" "}
        <i>
          {errorMessages.map((errorMessage) => errorMessage.message).join(", ")}
        </i>
      </AlertDescription>
    </Alert>
  );
}
