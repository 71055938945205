import { IconProps } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  HStack,
  Icon,
  Skeleton,
  StackProps,
  Text,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import {
  FaBuilding as BaseOrganizationIcon,
  FaUsers as GroupIcon,
} from "react-icons/fa";
import { Organization, UserGroup } from "../backend";
import { useGroupQuery } from "../hooks";

type GroupProfileProps = {
  group?: Pick<UserGroup, "name">;
  icon?: ReactElement;
} & StackProps;

export function GroupProfile({
  group,
  icon = <Icon as={GroupIcon} />,
  ...props
}: GroupProfileProps) {
  return (
    <HStack {...props}>
      <Skeleton isLoaded={!!group}>
        <Avatar icon={icon} size="sm" />
      </Skeleton>
      <Box>
        <Skeleton isLoaded={!!group}>
          <Text fontSize="sm" fontWeight="medium">
            {group?.name || "Loading"}
          </Text>
        </Skeleton>
      </Box>
    </HStack>
  );
}

export function LazyGroupProfile({
  groupId,
  ...props
}: { groupId: string | number } & Omit<GroupProfileProps, "group">) {
  const userGroupQuery = useGroupQuery({
    groupId,
    queryOptions: {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  });

  return (
    <GroupProfile
      group={
        userGroupQuery.isError
          ? ({
              name: "Unknown",
            } as UserGroup)
          : userGroupQuery.data
      }
      {...props}
    />
  );
}

export function OrganizationIcon(props: IconProps) {
  return <Icon as={BaseOrganizationIcon} {...props} />;
}

export function OrganizationProfile({
  organization,
  ...props
}: { organization: Organization } & Omit<GroupProfileProps, "group">) {
  return (
    <GroupProfile icon={<OrganizationIcon />} group={organization} {...props} />
  );
}
