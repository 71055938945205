import { Alert, AlertIcon, Skeleton, Stack, Text } from "@chakra-ui/react";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { ApplicationCategory } from "../backend/types";
import { LinkButton, NoData, PaginatedTable } from "../components";
import { usePaginationQueryParams, useSortingQueryParams } from "../hooks";
import { useApplicationCategoriesListQuery } from "../hooks/useApplicationCategoriesQuery";
import { namedRoutes } from "../routes";
import { useApplicationDetailsContext } from "./ApplicationDetailsContext";
import { applicationsColumn, nameColumn } from "./applicationCategoryColums";

const columns = [nameColumn, applicationsColumn];

export function ApplicationCategorization() {
  const [pagination, setPagination] = usePaginationQueryParams();
  const [sorting, setSorting] = useSortingQueryParams([
    { desc: false, id: "name" },
  ]);
  const { application } = useApplicationDetailsContext();
  const {
    isLoading,
    data: applicationCategories,
    isSuccess,
  } = useApplicationCategoriesListQuery({
    with_application: application.id,
    page: pagination.pageIndex + 1,
    page_size: pagination.pageSize,
  });
  const dynamicColumns = useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            cell: () =>
              column.id !== "order" ? (
                <Skeleton>
                  <Text>Loading</Text>
                </Skeleton>
              ) : null,
          }))
        : columns,
    [isLoading],
  );
  const data = useMemo<ApplicationCategory[]>(
    () =>
      isLoading
        ? Array(5).fill({} as ApplicationCategory)
        : applicationCategories?.results ?? [],
    [applicationCategories, isLoading],
  );
  const table = useReactTable({
    columns: dynamicColumns,
    data,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      pagination,
    },
    manualSorting: true,
    manualPagination: true,
    pageCount: applicationCategories?.count
      ? Math.ceil(applicationCategories?.count / pagination.pageSize)
      : undefined,
  });

  return (
    <Stack spacing={4}>
      <Alert status="info" alignItems={"normal"}>
        <AlertIcon />
        Applications can be added to categories to help users to discover them.
      </Alert>
      {applicationCategories?.count && isSuccess ? (
        <>
          <Text>
            This application is currently part of the following categories:
          </Text>
          <PaginatedTable table={table} />
        </>
      ) : (
        <NoData
          title="Uncategorized"
          text="This application is currently not part of any category"
          height="sm"
          callToAction={
            <LinkButton
              to={namedRoutes.applicationCategory.list}
              flexShrink={0}
            >
              Add it to a category
            </LinkButton>
          }
        />
      )}
    </Stack>
  );
}
