import {
  Stack,
  StackProps,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Row,
  TableOptions,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { DragHandle } from "./DragHandle";

interface Item {
  id: number | string;
}

function DraggableTableRow<T extends Item>({ row }: { row: Row<T> }) {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
    setActivatorNodeRef,
  } = useSortable({
    id: row.original.id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  return (
    <Tr
      _hover={{
        borderColor: "brand-bg",
      }}
      borderLeft="1px"
      borderColor={"transparent"}
      transition="all 250ms"
      ref={setNodeRef}
      style={style}
    >
      {row.getVisibleCells().map((cell, i) => {
        return (
          <Td key={cell.id} {...(cell.column.columnDef.meta?.props ?? {})}>
            {i === 0 && (
              <DragHandle
                attributes={attributes}
                listeners={listeners}
                cursor={isDragging ? "grabbing" : "grab"}
                padding={2}
                _hover={{ color: "brand-bg" }}
                ref={setActivatorNodeRef}
                isDragging={isDragging}
              />
            )}
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
    </Tr>
  );
}

export function ReorderableRowsTable<T extends Item>({
  data,
  columns,
  onReordered,
  ...props
}: Pick<TableOptions<T>, "data" | "columns"> & {
  onReordered: (data: T[]) => void;
} & StackProps) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row, idx) => row.id?.toString() ?? idx.toString(),
  });
  const [activeId, setActiveId] = useState<T["id"] | null>();
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragStart(event: DragStartEvent) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active.id && over?.id && active.id !== over.id) {
      const oldIndex = data.findIndex((data) => data.id === active.id);
      const newIndex = data.findIndex((data) => data.id === over.id);
      onReordered(arrayMove(data, oldIndex, newIndex));
    }

    setActiveId(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    return table.getRow(activeId.toString());
  }, [activeId, table]);

  if (table.getRowModel().rows.length === 0) {
    return null;
  }

  return (
    <Stack {...props}>
      <TableContainer>
        <DndContext
          sensors={sensors}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
        >
          <Table size="sm">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      {...(header.column.columnDef.meta?.props ?? {})}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              <SortableContext
                items={data}
                strategy={verticalListSortingStrategy}
              >
                {table.getRowModel().rows.map((row) => (
                  <DraggableTableRow key={row.id} row={row} />
                ))}
              </SortableContext>
            </Tbody>
          </Table>
          <DragOverlay>
            {selectedRow && (
              <Table w="full">
                <Tbody>
                  <Tr>
                    {selectedRow.getVisibleCells().map((cell, i) => (
                      <Td key={cell.id} bg="chakra-body-bg">
                        {i === 0 && <DragHandle isDragging={true} />}
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </Td>
                    ))}
                  </Tr>
                </Tbody>
              </Table>
            )}
          </DragOverlay>
        </DndContext>
      </TableContainer>
    </Stack>
  );
}
