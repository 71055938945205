import {
  Box,
  Button,
  Collapse,
  Flex,
  Heading,
  HStack,
  Icon,
  Stack,
  usePrevious,
} from "@chakra-ui/react";
import React, { ComponentType, ReactNode, useMemo, useState } from "react";
import { FaCheck as CompletedIcon } from "react-icons/fa";
import { isValidElementType } from "react-is";
import { StepperContext } from "./StepperContext";

export type StepProps = {
  number: number;
  title?: string;
  children?: ReactNode;
  isActive: boolean;
  isCompleted: boolean;
  goToNext?: () => void;
  goToPrev?: () => void;
  icon?: ReactNode;
  actionButtons?: ReactNode;
};
const brandColor = "brand.500";

export function Step({
  number,
  title,
  children,
  isActive = false,
  isCompleted = false,
  goToNext,
  goToPrev,
  actionButtons = undefined,
  icon = undefined,
}: StepProps) {
  const borderColor = useMemo(() => {
    if (isCompleted) return brandColor;
    if (!isActive) return "gray.200";
    return "transparent";
  }, [isActive, isCompleted]);

  const bgColor = useMemo(() => {
    if (isActive) return brandColor;
    return "transparent";
  }, [isActive]);

  const color = useMemo(() => {
    if (isCompleted) return brandColor;
    if (isActive) return "text-on-brand-bg";
    return "gray.200";
  }, [isActive, isCompleted]);

  return (
    <Stack spacing={0}>
      <HStack spacing={4} marginBottom={2}>
        <Flex
          borderRadius={"full"}
          bgColor={bgColor}
          borderColor={borderColor}
          borderStyle={"solid"}
          borderWidth={1}
          width={8}
          height={8}
          justifyContent="center"
          alignItems="center"
          fontWeight="bold"
          color={color}
          flexGrow={0}
          flexShrink={0}
        >
          {icon ? icon : isCompleted ? <Icon as={CompletedIcon} /> : number}
        </Flex>
        <Heading as="h2" size="md">
          {title}
        </Heading>
      </HStack>
      <Box>
        <Box marginLeft={4} paddingLeft={8} borderLeftWidth={goToNext && 1}>
          <Collapse in={isActive} animateOpacity style={{ overflow: "show" }}>
            <Stack spacing={4} fontSize="sm">
              {children}
              {actionButtons ? (
                actionButtons
              ) : (
                <HStack>
                  <Button
                    size="sm"
                    variant="ghost"
                    isDisabled={!goToPrev}
                    onClick={goToPrev}
                  >
                    Back
                  </Button>
                  <Button size="sm" isDisabled={!goToNext} onClick={goToNext}>
                    {(goToNext && "Next") || "Finish"}
                  </Button>
                </HStack>
              )}
            </Stack>
          </Collapse>
        </Box>
      </Box>
      {goToNext && (
        <Box>
          <Box
            marginStart={4}
            marginLeft={4}
            paddingLeft={8}
            height={4}
            borderLeftWidth={1}
            borderColor={borderColor}
          />
        </Box>
      )}
    </Stack>
  );
}

function isStepComponent(
  step: StepProps | ComponentType<StepProps>,
): step is ComponentType<StepProps> {
  return isValidElementType(step);
}

export function Stepper({
  steps = [],
  onCompleted,
}: {
  steps: (StepProps | ComponentType<StepProps>)[];
  onCompleted?: () => void;
}) {
  const [currentStepIdx, setCurrentStepIdx] = useState(0);
  const previousStepIdx = usePrevious(currentStepIdx);

  return (
    <StepperContext.Provider
      value={{
        previousStepIdx,
        currentStepIdx,
        setCurrentStepIdx,
        onCompleted,
      }}
    >
      <Stack>
        {steps.map((step, idx) => {
          const props = {
            key: idx,
            number: idx + 1,
            isActive: currentStepIdx === idx,
            isCompleted: currentStepIdx > idx,
            goToNext:
              idx + 1 < steps.length
                ? () => setCurrentStepIdx(currentStepIdx + 1)
                : undefined,
            goToPrev:
              idx > 0 ? () => setCurrentStepIdx(currentStepIdx - 1) : undefined,
          };

          if (isStepComponent(step)) {
            return React.createElement(step, props);
          }
          return <Step {...{ ...props, ...step }} />;
        })}
      </Stack>
    </StepperContext.Provider>
  );
}
