import { ReactNode } from "react";

export enum CapabilityResultType {
  Yes,
  No,
  Maybe,
}

export type Capability = {
  type: string;
  result: CapabilityResultType;
  text: string | ReactNode;
};
