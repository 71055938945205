import { useEffect, useState } from "react";

export function useBlobUrl(file: File) {
  const [src, setSrc] = useState<string>();

  useEffect(() => {
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      function () {
        // convert image file to base64 string
        setSrc(reader.result as string);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }

    return () => URL.revokeObjectURL(reader.result as string);
  }, [file]);

  return src;
}
