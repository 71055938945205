import {
  ChakraProvider as BaseChakraProvider,
  createLocalStorageManager,
} from "@chakra-ui/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PropsWithChildren, useMemo } from "react";
import { AuthProvider } from "./auth/AuthProvider";
import { RequireOrganization } from "./auth/RequireOrganization";
import { useBrandingQuery } from "./hooks";
import { queryClient } from "./queryClient";
import { ColorModeConfiguration } from "./theme/ColorModeConfiguration";
import { brandTheme } from "./theme/theme";

export function ChakraProvider({ children }: PropsWithChildren) {
  const brandingQuery = useBrandingQuery();

  const theme = useMemo(() => {
    if (brandingQuery.data) {
      return brandTheme(
        brandingQuery.data.primary,
        brandingQuery.data.background,
      );
    } else {
      return brandTheme();
    }
  }, [brandingQuery.data]);

  return (
    <BaseChakraProvider
      theme={theme} // Ensure the color modes of control panel and frontend are not mixed
      colorModeManager={createLocalStorageManager(
        "chakra-ui-color-mode-control-panel",
      )}
    >
      <ColorModeConfiguration />
      {children}
    </BaseChakraProvider>
  );
}

export function Providers({ children }: PropsWithChildren) {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        <RequireOrganization>
          <AuthProvider>{children}</AuthProvider>
        </RequireOrganization>
      </ChakraProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom" />
    </QueryClientProvider>
  );
}
