import { Text } from "@chakra-ui/react";
import { Session } from "../session-management";

export function SessionStatus({
  session,
}: {
  session: Pick<
    Session,
    "sessionTerminatedDateTime" | "experienceStartedDateTime"
  >;
}) {
  let text = "Success";
  if (session.sessionTerminatedDateTime && !session.experienceStartedDateTime) {
    text = "Cancelled";
  }
  return (
    <Text color="menu-text" fontSize="sm">
      {text}
    </Text>
  );
}
