import { Skeleton } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback, useState } from "react";
import { DropzoneOptions } from "react-dropzone";
import { ApplicationImageDetails } from "../../backend";
import {
  deleteApplicationImage,
  getApplicationImage,
  uploadApplicationImage,
} from "../../backend/api";
import { ApplicationId } from "../../backend/types";
import { FileUpload } from "../../components";
import {
  FileUploadImagePreview,
  FileUploadImagePreviewProps,
} from "../../components/FileUpload";
import {
  baseQueryKeyForApplications,
  getQueryForApplication,
} from "../../hooks/useApplicationQuery";

export type ApplicationImageUploadProps = {
  accept?: DropzoneOptions["accept"];
  image?: ApplicationImageDetails;
  applicationId: ApplicationId;
} & FileUploadImagePreviewProps;

export function ApplicationImageUpload({
  accept = {
    "image/jpg": [".jpg"],
    "image/jpeg": [".jpeg"],
    "image/png": [".png"],
    "image/gif": [".gif"],
    "image/tiff": [".tiff"],
    "image/bmp": [".bmp"],
    "image/webp": [".webp"],
  },
  applicationId,
  image,
  ...previewProps
}: ApplicationImageUploadProps) {
  const [details, setDetails] = useState<ApplicationImageDetails | undefined>(
    image,
  );
  const queryClient = useQueryClient();
  const imageQuery = useQuery({
    queryKey: getQueryKeyForApplicationImage(applicationId, details?.id),
    queryFn: async () => getApplicationImage(applicationId, details?.id ?? 0),
    enabled: !!details?.id,
    retry: true,
  });
  const uploadMutation = useMutation<ApplicationImageDetails, AxiosError, File>(
    {
      mutationFn: (file) => uploadApplicationImage(file, applicationId),
      onSuccess: (data) => {
        queryClient.setQueryData<typeof data>(
          getQueryKeyForApplicationImage(applicationId, data.id),
          data,
        );
        onChange(null);
      },
      onSettled: () =>
        queryClient.invalidateQueries(getQueryForApplication(applicationId)),
    },
  );
  const deleteMutation = useMutation<void, AxiosError, ApplicationImageDetails>(
    {
      mutationFn: async (image) => {
        if (!details) throw new Error("No image to delete");
        await deleteApplicationImage(applicationId, image.id);
      },
      onSuccess: () => {
        onChange(null);
      },
      onSettled: () =>
        queryClient.invalidateQueries(getQueryForApplication(applicationId)),
    },
  );

  const onChange = useCallback((imageData: ApplicationImageDetails | null) => {
    if (imageData === null) {
      setDetails(undefined);
      return;
    }

    setDetails(imageData);
  }, []);

  const imageUrl = image && imageQuery.data?.image;
  let previewImageUrl = imageUrl;
  if (previewImageUrl) {
    previewImageUrl += "?width=400";
  }

  return (
    <FileUpload
      accept={accept}
      multiple={false}
      maxSize={10 * 1024 * 1024}
      onDropAccepted={async (files) => {
        if (!files.length) return;

        const imageData = await uploadMutation.mutateAsync(files[0]);
        onChange(imageData);
      }}
      onDropRejected={(rejectedFiles) => {
        // TODO: handle rejected files
        console.error(rejectedFiles);
      }}
    >
      {details && imageQuery.isLoading ? (
        <Skeleton {...previewProps} />
      ) : (
        <FileUploadImagePreview
          imageUrl={previewImageUrl}
          downloadImageUrl={imageUrl}
          isLoading={uploadMutation.isPending || deleteMutation.isPending}
          onDelete={() => {
            details && deleteMutation.mutate(details);
          }}
          {...previewProps}
        />
      )}
    </FileUpload>
  );
}

function getQueryKeyForApplicationImage(
  applicationId: ApplicationId,
  applicationImageId?: ApplicationImageDetails["id"],
) {
  return [
    ...baseQueryKeyForApplications,
    applicationId,
    "images",
    applicationImageId ?? null,
  ];
}
