import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import Color from "color";
import { fetchBranding } from "../backend/api";
import { BrandingResponseData } from "../backend/types";
import { loadDataFromElement } from "../utils/hydrate-data";
import { useActiveOrganizationQuery } from "./useActiveOrganization";

export interface BrandingData {
  primary?: string;
  primaryContrast?: string;
  background?: string;
  organization?: string;
  logo?: string;
  app_image_placeholder?: string;
  product_icon?: string;
  company_name?: string;
  product_name?: string;
}

export function getQueryKeyForBranding(organizationId: string | number) {
  return ["branding", organizationId.toString()];
}

const fallbackBranding: Required<
  Pick<BrandingData, "primary" | "background" | "product_name">
> = {
  primary: "#04385F",
  background: "#FFF",
  product_name: "Portal",
};

export const sanitizeBraningResponseData = (
  data: BrandingResponseData,
): BrandingData => {
  const lightTextColor = Color("white");
  const darkTextColor = Color("black");
  const primary = data.primary ? data.primary : fallbackBranding.primary;
  const primaryContrast =
    lightTextColor.contrast(Color(primary ?? fallbackBranding.primary)) >=
    darkTextColor.contrast(Color(primary ?? fallbackBranding.primary))
      ? lightTextColor.hex()
      : darkTextColor.hex();

  return {
    // cleanup the messy api response from branding service
    ...data,
    primary,
    primaryContrast,
    background: data.background ? data.background : fallbackBranding.background,
    product_name:
      data.product_name !== ""
        ? data.product_name
        : fallbackBranding.product_name,
    app_image_placeholder:
      data.app_image_placeholder !== null
        ? data.app_image_placeholder
        : undefined,
    logo: data.logo !== null ? data.logo : undefined,
    product_icon: data.product_icon !== null ? data.product_icon : undefined,
    organization: data.organization !== null ? data.organization : undefined,
  };
};

export function useBrandingForOrganizationQuery(
  organizationId: string | number,
  options: Partial<UseQueryOptions<BrandingData, AxiosError>> = {},
) {
  return useQuery<BrandingData, AxiosError>({
    queryKey: getQueryKeyForBranding(organizationId),
    queryFn: async () => {
      const branding = await fetchBranding(organizationId);
      return sanitizeBraningResponseData(branding);
    },
    retry: false,
    ...options,
  });
}

export function useBrandingQuery() {
  const organizationQuery = useActiveOrganizationQuery();

  return useBrandingForOrganizationQuery(organizationQuery.data?.id ?? 0, {
    placeholderData: { ...fallbackBranding, organization: "" },
    // load the branding data (json) from a script tag with id "branding" if available
    initialData: loadDataFromElement("branding", fallbackBranding),
  });
}
