import { Button, ButtonProps, Icon } from "@chakra-ui/react";
import { FaPencilAlt as EditIcon } from "react-icons/fa";

export function EditButton(props: ButtonProps) {
  return (
    <Button
      leftIcon={<Icon boxSize={3} as={EditIcon} />}
      colorScheme="gray"
      {...props}
    >
      {props.children}
    </Button>
  );
}
