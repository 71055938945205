import { Box, BoxProps, HStack } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";

export function NavigationTab({
  to,
  label,
  ...props
}: { to: string; label: string } & BoxProps) {
  return (
    <Box as="li" {...props}>
      <NavLink end to={to}>
        {({ isActive }) => (
          <Box
            mb="-1px"
            as="span"
            display="block"
            fontSize="sm"
            px="5"
            py="3"
            fontWeight="medium"
            borderBottom="2px solid transparent"
            data-selected={isActive ? "" : undefined}
            _selected={{
              color: "brand-text",
              borderColor: "brand-text",
            }}
            _hover={{ borderColor: "brand-bg" }}
          >
            {label}
          </Box>
        )}
      </NavLink>
    </Box>
  );
}

export function NavigationTabs({ children }: { children: React.ReactNode }) {
  return (
    <Box as="nav" aria-label="Component navigation" mt="8">
      <HStack as="ul" listStyleType="none" borderBottomWidth="1px">
        {children}
      </HStack>
    </Box>
  );
}
