import { Box, BoxProps, Skeleton, Tooltip } from "@chakra-ui/react";
import { Application, ApplicationId } from "../../backend/types";
import { useApplicationQuery } from "../../hooks";
import { ApplicationPreviewImage } from "./ApplicationPreviewImage";

export function LazyTinyApplicationPreview({
  applicationId,
  ...props
}: { applicationId: ApplicationId } & BoxProps) {
  const { data: application } = useApplicationQuery(applicationId);

  if (!application) {
    return <Skeleton {...props} />;
  }

  return <TinyApplicationPreview application={application} {...props} />;
}

export function TinyApplicationPreview({
  application,
  ...props
}: {
  application: Application;
} & BoxProps) {
  return (
    <Tooltip hasArrow label={application?.name}>
      <Box {...props}>
        {application && (
          <ApplicationPreviewImage
            application={application}
            height={"full"}
            width={"full"}
          />
        )}
      </Box>
    </Tooltip>
  );
}
