import fuzzy from "fuzzy/lib/fuzzy";
import { useMemo } from "react";
import { useCurrentUserQuery } from "./useUserQuery";

export function useFilteredUserOrganizations(search: string) {
  const { data: user } = useCurrentUserQuery();

  return useMemo(
    () =>
      fuzzy
        .filter(search.toLowerCase(), user?.organizations ?? [], {
          extract: (organization) => organization.name.toLowerCase(),
        })
        .map((result) => result.original),
    [search, user?.organizations],
  );
}
