import { createContext, useContext, useEffect } from "react";
import { StepProps } from "./Stepper";

type StepperState = {
  currentStepIdx: number;
  previousStepIdx: number;
  setCurrentStepIdx: (idx: number) => void;
  onCompleted?: () => void;
};

export const StepperContext = createContext({} as StepperState);

export function useStepperContext() {
  return useContext(StepperContext);
}

export function useStepperDirection() {
  const { currentStepIdx, previousStepIdx } = useStepperContext();

  return currentStepIdx >= previousStepIdx ? "forward" : "backward";
}

export function useSkipStep(
  { isActive, goToNext, goToPrev }: StepProps,
  condition: boolean,
) {
  const stepperDirection = useStepperDirection();
  useEffect(() => {
    // Skip step if condition is met
    if (isActive && condition) {
      if (goToNext && stepperDirection === "forward") {
        goToNext();
      }
      if (goToPrev && stepperDirection === "backward") {
        goToPrev();
      }
    }
  }, [condition, goToNext, goToPrev, isActive, stepperDirection]);
}
