import { ButtonOptions, Flex, Stack } from "@chakra-ui/react";
import {
  CellContext,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { Organization } from "../../backend";
import {
  ExternalLinkButton,
  OptionsButton,
  PaginatedTable,
} from "../../components";
import { baseColumns } from "../../components/OrganizationMembershipTable";
import { useActiveOrganizationQuery, useUserQuery } from "../../hooks";
import { useOrganizationQuery } from "../../hooks/useOrganizationDetailsQuery";

function ManageUserInOrganizationButton({
  orgId,
  ...props
}: { orgId: number | string } & ButtonOptions) {
  const organizationDetailsQuery = useOrganizationQuery({
    organizationId: orgId,
  });
  const activeOrganizationQuery = useActiveOrganizationQuery();

  return (
    <ExternalLinkButton
      variant="ghost"
      fontWeight="normal"
      fontSize="sm"
      w="full"
      isExternal
      href={window.location.href.replace(
        activeOrganizationQuery.data?.subdomain ?? "",
        organizationDetailsQuery.data?.subdomain ?? "",
      )}
      {...props}
    >
      Manage user within {organizationDetailsQuery.data?.name ?? "organization"}
    </ExternalLinkButton>
  );
}

export function UserOrganizationMemberships({
  userId,
}: {
  userId: number | string;
}) {
  const activeOrganizationQuery = useActiveOrganizationQuery();
  const userQuery = useUserQuery({
    userId,
    options: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
    },
  });
  const columns = useMemo(
    () =>
      baseColumns.map((column) => {
        if (column.id === "options") {
          column.cell = (props: CellContext<Organization, unknown>) => (
            <Flex justifyContent={"end"}>
              <OptionsButton
                label="Click on this button to display user actions"
                isDisabled={
                  props.row.original.id === activeOrganizationQuery.data?.id
                }
              >
                <ManageUserInOrganizationButton orgId={props.row.original.id} />
              </OptionsButton>
            </Flex>
          );
        }

        return column;
      }),
    [activeOrganizationQuery.data?.id],
  );
  const table = useReactTable({
    columns,
    data: useMemo(() => userQuery.data?.organizations ?? [], [userQuery]),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
    autoResetPageIndex: true,
  });

  return (
    <Stack maxW={"lg"} spacing={3}>
      <PaginatedTable table={table} />
    </Stack>
  );
}
