import {
  Box,
  BoxProps,
  CircularProgress,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Wrap,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback, useState } from "react";
import { FileRejection } from "react-dropzone";
import { ApplicationImageDetails } from "../../backend";
import { uploadApplicationImage } from "../../backend/api";
import { ApplicationId } from "../../backend/types";
import { Dropzone } from "../../components/FileDropzone";
import { Step, StepProps } from "../../components/Stepper";
import {
  getQueryKeyForApplication,
  useApplicationQuery,
} from "../../hooks/useApplicationQuery";
import { useBlobUrl } from "../../hooks/useBlobUrl";
import { useAddApplicationContext } from "../AddApplicationContext";
import {
  ApplicationImageUpload,
  ApplicationImageUploadProps,
} from "../components/ApplicationImageUpload";

type FileState = FileRejection & {
  progress?: number;
  result?: boolean;
};

export function UploadMediaStep({ ...props }: StepProps) {
  const queryClient = useQueryClient();
  const {
    state: {
      baseData: { application: applicationId },
    },
  } = useAddApplicationContext();
  const applicationQuery = useApplicationQuery(applicationId ?? "", {
    enabled: !!applicationId,
  });
  const [uploads, setUploads] = useState<FileState[]>([]);
  const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null);
  const { mutate: upload } = useMutation<
    ApplicationImageDetails,
    AxiosError,
    { file: File; applicationId: ApplicationId }
  >({
    mutationFn: ({ file, applicationId }) =>
      uploadApplicationImage(file, applicationId),
    onSuccess: async (_, { applicationId, file }) => {
      // remove the upload again on success
      setUploads((fileStates) => {
        return [
          ...fileStates.filter(
            (fileState) => fileState.file.name !== file.name,
          ),
        ];
      });

      await queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplication(applicationId),
      });
    },
  });

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setUploads((files) => [
        ...files,
        ...acceptedFiles.map((file) => ({ file, errors: [] })),
      ]);

      applicationId &&
        acceptedFiles.forEach((acceptedFile) => {
          upload({
            file: acceptedFile,
            applicationId,
          });
        });
    },
    [applicationId, upload],
  );

  if (!applicationId) return null;

  return (
    <Step {...props} title="Upload media files">
      <Text>Next, you can upload some media files (e.g. preview images).</Text>
      <Dropzone
        onDrop={onDrop}
        multiple={true}
        accept={{
          "image/jpg": [".jpg"],
          "image/jpeg": [".jpeg"],
          "image/png": [".png"],
          "image/gif": [".gif"],
          "image/tiff": [".tiff"],
          "image/bmp": [".bmp"],
          "image/webp": [".webp"],
        }}
        instructionText="Click or drag files (.jpg, .png) to this area to upload"
      />
      {applicationId && (
        <Wrap spacing={3}>
          {applicationQuery.data?.images.map((imageDetails) => (
            <StyledApplicationImageUpload
              key={imageDetails.id}
              applicationId={applicationId}
              image={imageDetails}
              onClick={(evt) => {
                setPreviewImageUrl(imageDetails?.image);
                evt.stopPropagation();
              }}
            />
          ))}
          {uploads.map((imageToUpload) => (
            <ApplicationUploadPlaceholder
              key={imageToUpload.file.name}
              imageFile={imageToUpload.file}
            />
          ))}
        </Wrap>
      )}
      <Modal
        size="4xl"
        isOpen={!!previewImageUrl}
        onClose={() => setPreviewImageUrl(null)}
        isCentered
      >
        <ModalOverlay backgroundColor="backgroundAlpha.700" />
        <ModalContent background="transparent" width="auto">
          <ModalCloseButton top="-2rem" right="-2rem" />
          <ModalBody display="flex" justifyContent="center" padding={0}>
            {previewImageUrl && (
              <Image
                maxHeight={"90vh"}
                src={previewImageUrl}
                borderRadius="md"
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Step>
  );
}

function StyledApplicationImageUpload(props: ApplicationImageUploadProps) {
  return (
    <ApplicationImageUpload
      padding={0.5}
      width={28}
      height={28}
      borderRadius="md"
      objectFit={"cover"}
      _hover={{
        transform: "scaleX(1.05) scaleY(1.05)",
        boxShadow: "md",
      }}
      transition="all 300ms"
      cursor="pointer"
      {...props}
    />
  );
}

function ApplicationUploadPlaceholder({
  imageFile,
  width = 28,
  height = 28,
  borderRadius = "md",
  ...props
}: { imageFile: File } & BoxProps) {
  const imageUrl = useBlobUrl(imageFile);

  return (
    <Box
      position={"relative"}
      width={width}
      height={height}
      borderWidth={1}
      borderRadius={borderRadius}
      flexShrink={0}
      cursor="pointer"
      overflow="hidden"
      padding={2}
      {...props}
    >
      <Box
        bgImage={imageUrl}
        backgroundSize="cover"
        backgroundPosition={"center"}
        backgroundRepeat="no-repeat"
        height="100%"
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems={"center"}
        transition="all 300ms"
      />
      <Box
        position="absolute"
        height="100%"
        width="100%"
        top={0}
        left={0}
        backgroundColor={"blackAlpha.600"}
        transition="all 300ms"
        display="flex"
        justifyContent="center"
        alignItems={"center"}
        opacity={imageUrl ? 0 : 1}
        _hover={{ opacity: 1 }}
      >
        <HStack spacing={2}>
          <CircularProgress isIndeterminate />
        </HStack>
      </Box>
    </Box>
  );
}
