import { UseQueryOptions } from "@tanstack/react-query";
import { PortalUserGroupsList } from "../../backend";
import { useActiveOrganizationQuery, useGroupQuery } from "../../hooks";
import { useGroupsQuery } from "../../hooks/useGroupsQuery";

export function useUserGroupsQuery(
  queryOptions: Partial<UseQueryOptions<PortalUserGroupsList>> = {},
) {
  const { data: organization } = useActiveOrganizationQuery();

  return useGroupsQuery(
    { page_size: 200, organization: organization?.id },
    { enabled: !!organization, ...queryOptions },
  );
}

export function useUserGroupQuery(userGroupId?: number | string) {
  const { data: organization } = useActiveOrganizationQuery();

  return useGroupQuery({
    groupId: userGroupId ?? -1,
    queryOptions: { enabled: !!organization && !!userGroupId },
  });
}
