import { AxiosError } from "axios";
import { PortalError } from "../backend";

export function MessageFromAxiosError({
  error,
  message = "An error occurred",
}: {
  error: AxiosError<PortalError>;
  message?: string;
}) {
  if (!error.response) {
    return (
      <>
        {message}: {error.message}
      </>
    );
  }

  const { data } = error.response;

  if (data.detail) {
    return (
      <>
        {message}: {data.detail} ({error.message})
      </>
    );
  }

  if (data.message) {
    return (
      <>
        {message} {data.message} ({error.message})
      </>
    );
  }

  if (Object.keys(data).length === 0) {
    return (
      <>
        {message}: {error.message}
      </>
    );
  }

  return (
    <>
      {message}: {Object.values(data).flat().join(", ")}
    </>
  );
}
