import { useCallback, useMemo } from "react";
import { useAuthentication } from "../auth";
import { PermissionString } from "../backend/types";
import { useCurrentUserPlatformPermissionsQuery } from "./useUserPlatformPermissionsQuery";

export function useIsSuperuser() {
  const { user } = useAuthentication();

  return user?.is_superuser ?? false;
}

export function usePermissions() {
  const { data: permissions, isLoading } =
    useCurrentUserPlatformPermissionsQuery({
      refetchOnWindowFocus: true,
    });

  const hasPermission = useCallback(
    (perm: PermissionString) => permissions?.includes(perm),
    [permissions],
  );
  const isSuperuser = useIsSuperuser();

  return useMemo(
    () => ({
      is_superuser: isSuperuser,
      change_organization: hasPermission("core.change_organization"),
      change_user: hasPermission("core.change_user"),
      change_usergroup: hasPermission("core.change_usergroup"),
      view_user: hasPermission("core.view_user"),
      view_usergroup: hasPermission("core.view_usergroup"),
      add_application: hasPermission("assets.add_application"),
      hasPermission,
      isLoading,
    }),
    [hasPermission, isLoading, isSuperuser],
  );
}
