import { PropsWithChildren } from "react";
import { PermissionString } from "../backend/types";
import { AuthorizingAlert, FullPageCenteredContainer } from "../components";
import { HttpErrorFallback } from "../components/ErrorFallback";
import { usePermissions } from "../hooks/usePermissions";

export function RequireAuthz({
  children,
  requiredPermissions,
}: PropsWithChildren & {
  requiredPermissions: PermissionString[];
}): JSX.Element {
  const { hasPermission, isLoading } = usePermissions();

  // if we are currently checking the user's permissions, wait for it to finish before doing anything
  if (isLoading) {
    return (
      <FullPageCenteredContainer>
        <AuthorizingAlert />
      </FullPageCenteredContainer>
    );
  }

  if (!requiredPermissions.every((perm) => hasPermission(perm))) {
    return <HttpErrorFallback statusCode={403} message="Access Denied" />;
  }

  // all good, render children
  return <>{children}</>;
}
