import { Helmet } from "react-helmet";
import {
  usePageTitleFromRoutes,
  usePageTitleSuffix,
} from "../hooks/useMatchesWithHandle";

export function PageTitle({ title: titleOverride }: { title?: string }) {
  const pageTitleSuffix = usePageTitleSuffix();
  const fallbackTitle = usePageTitleFromRoutes();
  const title = titleOverride ?? fallbackTitle;
  const fullTitle = title ? `${title} - ${pageTitleSuffix}` : pageTitleSuffix;

  return <Helmet title={fullTitle} />;
}
