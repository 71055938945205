import { ButtonProps } from "@chakra-ui/react";
import { ReactNode, createContext } from "react";

export type ConfirmFunctionProps = {
  title: ReactNode;
  body: ReactNode;
  confirmButtonText?: ReactNode;
  cancelButtonText?: ReactNode;
  onConfirm?: () => Promise<void>;
  onCancel?: () => Promise<void>;
  confirmButtonProps?: ButtonProps;
};

export type ConfirmDialogContextType = {
  confirm: (props: ConfirmFunctionProps) => Promise<void>;
  cancelConfirm: () => void;
};

export const ConfirmDialogContext = createContext<ConfirmDialogContextType>(
  {} as ConfirmDialogContextType,
);
