import { CalendarIcon } from "@chakra-ui/icons";
import {
  Box,
  FormControl,
  IconButton,
  InputGroup,
  InputRightAddon,
  Popover,
  PopoverAnchor,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useRef } from "react";
import { I18nProvider, useDateRangePicker } from "react-aria";
import { useTranslation } from "react-i18next";
import {
  DateRangePickerStateOptions,
  useDateRangePickerState,
} from "react-stately";
import { omit } from "remeda";
import { DateField } from "./DateField";
import { FakeInput } from "./FakeInput";
import { RangeCalendar } from "./RangeCalendar";

export function DateRangePicker(
  props: DateRangePickerStateOptions,
): JSX.Element {
  const state = useDateRangePickerState(props);
  const ref = useRef(null);
  const {
    startFieldProps,
    endFieldProps,
    buttonProps,
    dialogProps: dialogAriaProps,
    groupProps,
    calendarProps,
  } = useDateRangePicker(props, state, ref);
  const { i18n } = useTranslation();

  return (
    <I18nProvider locale={i18n.language}>
      <Popover
        {...dialogAriaProps}
        isOpen={state.isOpen}
        onClose={() => state.setOpen(false)}
      >
        <FormControl
          isInvalid={state.validationState === "invalid"}
          width="auto"
          isDisabled={props.isDisabled}
        >
          <PopoverAnchor>
            <InputGroup
              {...groupProps}
              ref={ref}
              width="auto"
              display="inline-flex"
              size="sm"
            >
              <FakeInput>
                <>
                  <DateField
                    {...startFieldProps}
                    shouldForceLeadingZeros={true}
                  />
                  <Box as="span" aria-hidden="true" paddingX="2">
                    –
                  </Box>
                  <DateField
                    {...endFieldProps}
                    shouldForceLeadingZeros={true}
                  />
                </>
              </FakeInput>
              <InputRightAddon padding={0}>
                <PopoverTrigger>
                  <IconButton
                    icon={<CalendarIcon />}
                    {...omit(buttonProps, ["onPress", "onFocusChange"])}
                    aria-label={buttonProps["aria-label"] ?? "Open Calendar"}
                    onClick={() => state.setOpen(true)}
                    isDisabled={props.isDisabled}
                    size="sm"
                  />
                </PopoverTrigger>
              </InputRightAddon>
            </InputGroup>
          </PopoverAnchor>
          <PopoverContent padding={2}>
            {state.isOpen && <RangeCalendar {...calendarProps} />}
          </PopoverContent>
        </FormControl>
      </Popover>
    </I18nProvider>
  );
}
