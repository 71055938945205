import { HStack, Icon, Text } from "@chakra-ui/react";
import { InternetIcon, TelefonicaIcon, VerizonIcon, VodafoneIcon } from "./icons";

export function NetworkOperatorDisplay({
  networkOperator,
}: {
  networkOperator?: string;
}) {
  return (
    <HStack spacing={2}>
      <Icon
        as={
          networkOperator === "VodafoneDe"
            ? VodafoneIcon
            : networkOperator === "VerizonUs"
            ? VerizonIcon
            : networkOperator === "TelefonicaEs"
            ? TelefonicaIcon
            : InternetIcon
        }
      />
      <Text>
        {networkOperator === "VodafoneDe"
          ? "Vodafone DE 5G"
          : networkOperator === "VerizonUs"
          ? "Verizon US 5G"
          : networkOperator === "TelefonicaEs"
          ? "Telefónica Es"
          : networkOperator === "Internet"
          ? "Internet"
          : "Unknown"}
      </Text>
    </HStack>
  );
}
