import { getLocalTimeZone } from "@internationalized/date";
import { DateRange } from "@react-types/datepicker";
import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import { toCalendarDate } from "../components/date-range-picker";
import { useDateTimeRangeQueryParams } from "./useDateTimeRangeQueryParams";

const maxDate = toCalendarDate(new Date());
const minDate = toCalendarDate(dayjs().subtract(12, "months").toDate());

export function useDateRange({
  fromDate,
  toDate,
}: {
  fromDate: Date;
  toDate: Date;
}) {
  const [{ fromDate: startDate, toDate: endDate }, setDateTimeRangeRange] =
    useDateTimeRangeQueryParams({
      fromDate,
      toDate,
    });
  const updateDateRange = useCallback(
    (range: DateRange) => {
      if (
        range.start >= minDate &&
        range.end <= maxDate &&
        range.start <= range.end
      ) {
        const end = range.end.toDate(getLocalTimeZone());
        setDateTimeRangeRange({
          fromDate: range.start.toDate(getLocalTimeZone()),
          toDate: dayjs(end).endOf("day").toDate(),
        });
      } else {
        console.error("Invalid date range selected");
      }
    },
    [setDateTimeRangeRange],
  );

  const defaultDateRange = useMemo(
    () => ({
      start: toCalendarDate(startDate),
      end: toCalendarDate(endDate),
    }),
    [startDate, endDate],
  );

  return {
    startDate,
    endDate,
    updateDateRange,
    defaultDateRange,
    maxDate,
    minDate,
  };
}
