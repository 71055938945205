import { Text } from "@chakra-ui/react";
import { RenderLeafProps } from "slate-react";

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <Text as="b">{children}</Text>;
  }

  if (leaf.code) {
    children = (
      <Text
        as="code"
        paddingX={1}
        paddingY={0.5}
        fontSize={"sm"}
        backgroundColor="gray.100"
      >
        {children}
      </Text>
    );
  }

  if (leaf.italic) {
    children = <Text as="em">{children}</Text>;
  }

  if (leaf.underlined) {
    children = <Text as="u">{children}</Text>;
  }

  if (leaf.strikethrough) {
    children = <Text as="s">{children}</Text>;
  }

  return (
    <Text as="span" {...attributes}>
      {children}
    </Text>
  );
};
