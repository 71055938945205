import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { getApplicationPermissionsForUser } from "../../backend/api";
import { GrantedApplicationPermission, PortalError } from "../../backend/types";

export function getQueryKeyForApplicationPermissionsForUser(
  userId: string | number,
) {
  return ["users", userId.toString(), "application-permissions"];
}

export function useApplicationPermissionsForUserQuery(
  userId: string | number,
  queryOptions: Omit<
    UseQueryOptions<GrantedApplicationPermission[], AxiosError<PortalError>>,
    "queryFn" | "queryKey"
  > = {},
) {
  return useQuery({
    queryKey: getQueryKeyForApplicationPermissionsForUser(userId),
    queryFn: () => getApplicationPermissionsForUser(userId),
    ...queryOptions,
  });
}
