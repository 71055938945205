import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { updateApplicationCloudRenderingConfiguration } from "../../backend/api";
import { applicationUrlRewriteRuleSchema } from "../../backend/schema";
import {
  ApplicationCloudRenderingConfiguration,
  ApplicationId,
  ApplicationUrlRewriteRule,
} from "../../backend/types";
import { getQueryKeyForApplicationCloudRenderingConfiguration } from "../../hooks/useApplicationCloudRenderingConfigurationQuery";
import { extractFormSubmissionErrors } from "../../utils/extractFormSubmissionErrors";
import { RewriteRuleForm } from "./RewriteRuleForm";
import { useRewriteRuleSchema } from "./useRewriteRuleSchema";

export function RewriteRuleEditForm({
  existingRules,
  applicationId,
  ruleIndex,
}: {
  existingRules: Array<ApplicationUrlRewriteRule>;
  applicationId: ApplicationId;
  ruleIndex: number;
}) {
  const queryClient = useQueryClient();
  const rule = existingRules[ruleIndex];
  const { mutateAsync: updateRewriteRuleAsync } = useMutation({
    mutationFn: (data: ApplicationUrlRewriteRule) =>
      updateApplicationCloudRenderingConfiguration(applicationId, {
        url_rewrites: {
          rules: existingRules.map((innerRule, idx) =>
            idx === ruleIndex ? data : innerRule,
          ),
        },
      }),
    onMutate: (data) => {
      // backup the current state
      const previousData =
        queryClient.getQueryData<ApplicationCloudRenderingConfiguration>(
          getQueryKeyForApplicationCloudRenderingConfiguration(applicationId),
        );

      // Optimistically update the UI
      queryClient.setQueryData<ApplicationCloudRenderingConfiguration>(
        getQueryKeyForApplicationCloudRenderingConfiguration(applicationId),
        (existingConfiguration) => ({
          ...existingConfiguration,
          vm_size: existingConfiguration?.vm_size ?? null,
          url_rewrites: {
            ...existingConfiguration?.url_rewrites,
            rules: existingRules.map((rule, idx) =>
              idx === ruleIndex ? data : rule,
            ),
          },
        }),
      );

      return { previousData };
    },
    onError: (_error, _variables, context) => {
      form.reset(undefined, { keepValues: true, keepDirty: true });
      // rollback the optimistic update
      queryClient.setQueryData<ApplicationCloudRenderingConfiguration>(
        getQueryKeyForApplicationCloudRenderingConfiguration(applicationId),
        context?.previousData,
      );
    },
    onSuccess: () => {
      form.reset(undefined, { keepValues: true, keepIsSubmitted: true });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey:
          getQueryKeyForApplicationCloudRenderingConfiguration(applicationId),
      });
    },
  });
  const { handleSubmit, setError, formState, register, ...form } =
    useForm<ApplicationUrlRewriteRule>({
      defaultValues: rule,
      mode: "onChange",
      resolver: yupResolver(useRewriteRuleSchema(existingRules)),
    });

  const onSubmit = useCallback(
    async (values: ApplicationUrlRewriteRule) => {
      try {
        await updateRewriteRuleAsync(values);
      } catch (err) {
        extractFormSubmissionErrors(
          err,
          applicationUrlRewriteRuleSchema,
        ).forEach(([field, message]) =>
          setError(field, { type: "server", message }),
        );
      }
    },
    [updateRewriteRuleAsync, setError],
  );

  return (
    <RewriteRuleForm
      form={{ formState, handleSubmit, register, setError, ...form }}
      onSubmit={onSubmit}
    />
  );
}
