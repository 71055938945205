import { Heading, Stack, Text } from "@chakra-ui/react";

export function RequestPasswordResetDone() {
  return (
    <Stack spacing="6">
      <Heading size={{ base: "xs", md: "sm" }}>
        Password successfully reset!
      </Heading>
      <Text>
        We&apos;ve emailed you instructions for setting your password, if an
        account exists with the email you entered. You should receive them
        shortly.
      </Text>
      <Text>
        If you don&apos;t receive an email, please make sure you&apos;ve entered
        the address you registered with, and check your spam folder.
      </Text>
    </Stack>
  );
}
