import { Badge } from "@chakra-ui/react";
import { ApplicationStatus } from "../backend";

export function StatusBadge({ status }: { status: ApplicationStatus }) {
  let colorScheme;
  let caption;

  switch (status) {
    case ApplicationStatus.Valid:
      caption = "Valid";
      colorScheme = "green";
      break;
    case ApplicationStatus.NoExecutables:
      caption = "Missing executables";
      colorScheme = "red";
      break;
    case ApplicationStatus.MultipleExecutables:
      caption = "Multiple executables";
      colorScheme = "red";
      break;
    case ApplicationStatus.Pending:
      caption = "Pending validation";
      colorScheme = "gray";
      break;
    case ApplicationStatus.InvalidPath:
      caption = "Invalid executable";
      colorScheme = "red";
      break;
    default:
      caption = "Unknown";
      colorScheme = "gray";
      break;
  }

  return <Badge colorScheme={colorScheme}>{caption}</Badge>;
}
