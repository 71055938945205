import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { backendClient, PortalError, type User } from "../backend";
import { QueryParameters } from "../utils/types";

export function getQueryKeyForUser(
  userId: string | number,
  queryParams: QueryParameters = {},
) {
  return ["user", userId.toString(), queryParams];
}

export type UserQueryOptions<T = User> = Partial<
  UseQueryOptions<User, AxiosError<PortalError>, T>
>;

export function getQueryForUser<T = User>(
  userId: number | string,
  queryParams: QueryParameters = {},
  options: UserQueryOptions<T> = {},
) {
  return {
    queryKey: getQueryKeyForUser(userId, queryParams),
    queryFn: async () =>
      (
        await backendClient.get<User>(`/api/users/${userId}/`, {
          params: queryParams,
        })
      ).data,
    ...options,
    enabled: !!userId && (options.enabled ?? true),
  };
}

export function useUserQuery<T = User>({
  userId,
  queryParams = {},
  options = {},
}: {
  userId: number | string;
  queryParams?: QueryParameters;
  options?: UserQueryOptions<T>;
}) {
  return useQuery(getQueryForUser(userId, queryParams, options));
}

export function useCurrentUserQuery<T = User>(
  options: UserQueryOptions<T> = {},
) {
  return useUserQuery({ userId: "current", options });
}
