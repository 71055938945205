import { array, mixed, object, string } from "yup";
import { XRPlatformType, xrPlatformTypes } from "../../backend/types";

export const applicationBuildVersionSchema = string()
  .defined()
  .required()
  .matches(
    // regex matching either a 4-number version (x.y.z.a) or a semantic version, see https://github.com/semver/semver/issues/232#issue-48635632 for semantic version regex
    /(^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)$)|(^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(-(0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(\.(0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*)?(\+[0-9a-zA-Z-]+(\.[0-9a-zA-Z-]+)*)?$)/gm,
    "Version must be either a valid semantic version or 4-number (x.y.z.a) version.",
  );

export const xrPlatformSchema = mixed<XRPlatformType>()
  .oneOf([...xrPlatformTypes])
  .defined();

export const applicationBuildMetaDataSchema = object({
  version: applicationBuildVersionSchema,
  application_archive: string()
    .required("Missing application archive. Please go back and upload one.")
    .url("Missing application archive. Please go back and upload one."),
  changelog: string().ensure(),
  supported_xr_platforms: array(xrPlatformSchema)
    .default([])
    .min(1, "At least one supported platform is required"),
});
