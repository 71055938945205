import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  IconButton,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { ApplicationBuildId } from "../backend/types";
import { useApplicationBuildQuery } from "../hooks";
import { ObfuscatedInput } from "./components/ObfuscatedInput";
import { useSignedApplicationBuildUrl } from "./hooks/useSignedApplicationUrl";

export function ApplicationBuildDeploymentInstructions({
  applicationBuildId,
}: {
  applicationBuildId: ApplicationBuildId;
}) {
  const { hash } = useLocation();
  const mdmDisclosure = useDisclosure();
  const applicationBuildQuery = useApplicationBuildQuery(applicationBuildId);
  const applicationSignedUrlQuery =
    useSignedApplicationBuildUrl(applicationBuildId);

  if (
    !applicationBuildQuery.isSuccess ||
    applicationBuildQuery.data.target_platform !== "android"
  ) {
    return null;
  }

  return (
    <Card
      variant="outline"
      id="mdm-deployment"
      sx={
        hash === "#mdm-deployment"
          ? {
              padding: 4,
              borderRadius: "md",
              bg: "chakra-subtle-bg",
              boxShadow: "outline",
            }
          : undefined
      }
    >
      <CardHeader onClick={mdmDisclosure.onToggle} cursor={"pointer"}>
        <Heading size="sm">
          Deployment{" "}
          <IconButton
            size="sm"
            variant="ghost"
            icon={
              mdmDisclosure.isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />
            }
            aria-label={mdmDisclosure.isOpen ? "Close" : "Open"}
          />
        </Heading>
      </CardHeader>
      <Collapse in={mdmDisclosure.isOpen}>
        <CardBody>
          <Stack spacing={4}>
            <FormControl width="auto">
              <FormLabel
                htmlFor="identity"
                whiteSpace={"nowrap"}
                marginRight={0}
              >
                Unrestricted Download Url
              </FormLabel>
              <Stack spacing={2}>
                <FormHelperText>
                  You can use the application&apos;s unrestricted download URL
                  to distribute this application binary via any 3rd party system
                  (like an MDM) that requires a direct download URL.
                </FormHelperText>
                <ObfuscatedInput
                  onDeObfuscate={() => applicationSignedUrlQuery.refetch()}
                  isLoading={applicationSignedUrlQuery.isInitialLoading}
                  value={applicationSignedUrlQuery.data?.url}
                />
                {applicationSignedUrlQuery.isSuccess && (
                  <Alert status="warning" size="xs">
                    <AlertIcon />
                    Never share this URL with end-users. It allows anyone to
                    download the application binary without access control.
                  </Alert>
                )}
              </Stack>
            </FormControl>
          </Stack>
        </CardBody>
      </Collapse>
    </Card>
  );
}
