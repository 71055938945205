import { Box, Flex, Heading, Skeleton } from "@chakra-ui/react";
import ReactApexChart from "react-apexcharts";
import { useCurrentApplicationVersionsListQuery } from "../../hooks";
import { useChartOptions } from "./chart-options";

export function Immersiveness() {
  const chartOptions = useChartOptions();
  const vrCompatibleAppsQuery = useCurrentApplicationVersionsListQuery({
    supports_vr: true,
    supports_non_vr: false,
    page_size: 1,
  });
  const nonVrCompatibleAppsQuery = useCurrentApplicationVersionsListQuery({
    supports_non_vr: true,
    supports_vr: false,
    page_size: 1,
  });
  const bothCompatibleAppsQuery = useCurrentApplicationVersionsListQuery({
    supports_non_vr: true,
    supports_vr: true,
    page_size: 1,
  });
  const isLoaded =
    vrCompatibleAppsQuery.isSuccess &&
    nonVrCompatibleAppsQuery.isSuccess &&
    bothCompatibleAppsQuery.isSuccess;

  return (
    <Flex direction={"column"}>
      <Heading size="sm">Immersiveness</Heading>
      <Skeleton w="100%" isLoaded={isLoaded}>
        <Box paddingTop={2} w="100%">
          {isLoaded && (
            <ReactApexChart
              type="pie"
              series={[
                vrCompatibleAppsQuery.data?.count,
                nonVrCompatibleAppsQuery.data?.count,
                bothCompatibleAppsQuery.data?.count,
              ]}
              options={{
                ...chartOptions,
                chart: {
                  ...chartOptions.chart,
                  stacked: true,
                  dropShadow: { enabled: false },
                },
                labels: ["VR", "Non-VR", "Both"],
                xaxis: {
                  ...chartOptions.xaxis,
                  title: {
                    ...chartOptions.xaxis?.title,
                    text: "Week of year",
                    style: {
                      fontWeight: "light",
                      color: "gray",
                    },
                    offsetY: -10,
                  },
                },
                plotOptions: {
                  bar: {
                    borderRadius: 5,
                    columnWidth: "50px",
                  },
                },
                legend: {
                  ...chartOptions.legend,
                  show: true,
                  position: "bottom",
                  labels: {
                    ...chartOptions.legend?.labels,
                    colors: ["gray"],
                  },
                },
              }}
            />
          )}
        </Box>
      </Skeleton>
    </Flex>
  );
}
