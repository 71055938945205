import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Code,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Fragment, useState } from "react";
import { useAuthentication } from "../../auth";
import { Application, ApplicationBuild, User } from "../../backend";
import { useActiveOrganizationQuery } from "../../hooks";

export function LaunchCommand({
  exeuctablePath: executablePath,
  launchArgs,
  supportsCliArgs,
  user,
  interfaceMode = "vr",
  resolvedLanguage = "en",
  renderingMode = "remote",
  targetDeviceUuid = "7816ff3f-e184-40cb-81d4-56b2ac19f59d",
  sessionId = "7e50e1ee-a382-4c8a-a6b8-4054561be1b7",
  appId,
  appIdentity,
  organizationName,
  organizationSubdomain,
  organizationDomain,
  lineBreakStyle,
}: {
  exeuctablePath: string | null;
  launchArgs: string | null;
  supportsCliArgs: boolean;
  user?: Omit<Partial<User>, "id"> & { id: string | number };
  interfaceMode?: string;
  resolvedLanguage?: string;
  renderingMode?: string;
  targetDeviceUuid?: string;
  sessionId?: string;
  appId: number;
  appIdentity: string;
  organizationName: string;
  organizationSubdomain: string;
  organizationDomain: string;
  lineBreakStyle?: "unix" | "cmd" | "powershell";
}) {
  const lineBreak =
    lineBreakStyle === "cmd"
      ? "^"
      : lineBreakStyle === "powershell"
      ? "`"
      : "\\";

  return (
    <Code padding={2} fontSize="xs" whiteSpace="nowrap">
      {`${executablePath} ${lineBreak}`}
      {launchArgs ? `${launchArgs} ${lineBreak}` : ""}
      {supportsCliArgs
        ? `
        --portal-session-id ${sessionId} ${lineBreak}
        --portal-user-name "${user?.full_name}" ${lineBreak}
        --portal-email ${user?.email} ${lineBreak}
        --portal-user-id ${user?.id} ${lineBreak}
        --portal-interface-mode ${interfaceMode} ${lineBreak}
        --portal-resolved-language ${resolvedLanguage} ${lineBreak}
        --portal-rendering-mode ${renderingMode} ${lineBreak}
        --portal-target-device ${targetDeviceUuid} ${lineBreak}
        --portal-app-id ${appId} ${lineBreak}
        --portal-app-identity ${appIdentity} ${lineBreak}
        --portal-organization-name ${organizationName} ${lineBreak}
        --portal-organization-subdomain ${organizationSubdomain} ${lineBreak}
        --portal-organization-domain ${organizationDomain}`
            .split("\n")
            .map((line) => (
              <Fragment key={line}>
                {line}
                <br />
              </Fragment>
            ))
        : null}
    </Code>
  );
}

export function LaunchConfigurationPreview({
  application,
}: {
  application: Pick<
    ApplicationBuild,
    "id" | "executable_path" | "launch_args" | "supports_arbitrary_cli_args"
  > & { identity: Application["legacy_identity"] };
}) {
  const { user } = useAuthentication();
  const { data: organization } = useActiveOrganizationQuery();
  const [lineBreakStyle, setLineBreakStyle] = useState<
    "unix" | "cmd" | "powershell"
  >("cmd");

  return (
    <Stack spacing={2}>
      <Text>
        Based on the configuration of executable path, arguments and support of
        default launch arguments, the application will be run e.g. like:
      </Text>

      <LaunchCommand
        exeuctablePath={application.executable_path}
        launchArgs={application.launch_args}
        supportsCliArgs={application.supports_arbitrary_cli_args}
        user={user}
        appId={application.id}
        appIdentity={application.identity}
        organizationName={organization?.name ?? ""}
        organizationSubdomain={organization?.subdomain ?? ""}
        organizationDomain={organization?.domain ?? ""}
        lineBreakStyle={lineBreakStyle}
      />

      <HStack>
        <Text fontSize={"xs"}>Target Shell</Text>
        <ButtonGroup size="xs" isAttached variant="outline">
          <Button
            isActive={lineBreakStyle === "cmd"}
            onClick={() => setLineBreakStyle("cmd")}
          >
            CMD
          </Button>
          <Button
            isActive={lineBreakStyle === "powershell"}
            onClick={() => setLineBreakStyle("powershell")}
          >
            Powershell
          </Button>
          <Button
            isActive={lineBreakStyle === "unix"}
            onClick={() => setLineBreakStyle("unix")}
          >
            Bash
          </Button>
        </ButtonGroup>
      </HStack>

      <Alert variant="subtle" status="info">
        <AlertIcon />
        Please note that the values of the arguments above will change depending
        on the user and their preferences.
      </Alert>
      <Text>
        You can copy the above command to try whether the application launches
        as expected, given the configuration.
      </Text>
    </Stack>
  );
}
