import { CheckIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  CircularProgress,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { UseMutationResult } from "@tanstack/react-query";
import { DataExportProps } from "../index";

export function ExportDataDialog({
  exportDataMutation,
}: {
  exportDataMutation: Pick<
    UseMutationResult<DataExportProps>,
    "isIdle" | "isPending" | "data" | "isError" | "isSuccess" | "reset"
  >;
}) {
  return (
    <Modal
      isCentered
      onClose={() => exportDataMutation.reset()}
      isOpen={!exportDataMutation.isIdle}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Data Export</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            {exportDataMutation.isError && (
              <Alert status="error">
                <AlertIcon />
                <AlertDescription>
                  Could not gather the required data for exporting.
                </AlertDescription>
              </Alert>
            )}
            {exportDataMutation.isPending && (
              <>
                <Text>
                  Please wait while we&apos;re preparing your data export.
                </Text>
                <CircularProgress isIndeterminate />
              </>
            )}
            {exportDataMutation.isSuccess && (
              <>
                <Flex
                  bgColor="green.500"
                  borderRadius="full"
                  w="10"
                  h="10"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CheckIcon color={"white"} />
                </Flex>
                <Text>
                  The data export has been prepared and should have already been
                  downloaded by your browser.
                </Text>
                <Text>
                  You can also download the data again by clicking the following
                  button.
                </Text>
                <Button
                  as="a"
                  download={exportDataMutation.data?.filename}
                  size="sm"
                  leftIcon={<DownloadIcon />}
                  href={exportDataMutation.data?.url}
                >
                  Download Data
                </Button>
              </>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="brand"
            mr={3}
            onClick={() => exportDataMutation.reset()}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
