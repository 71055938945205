import { getAllLegacyApplicationVersions } from "../backend";
import { getAllSessions } from "../session-management";

/**
 * protects the given content from being interpreted as a formula in a CSV file, see https://owasp.org/www-community/attacks/CSV_Injection
 * @param content the content to be written to the CSV file
 * @returns the content with a single quote prepended if it starts with a character that could be used to inject a formula in a CSV file
 */
function prevent_csv_injection(content?: string) {
  if (!content) return "";
  const firstChar = content.charAt(0);
  // check that the content does not start with any of the characters used to inject formulas in CSV files
  if (["=", "@", "-", "+", "\t", "\r"].includes(firstChar)) {
    return `'${content}`;
  }

  return content;
}

export async function generateDataExport(
  startDate: Date,
  endDate: Date,
  organizationId: string,
) {
  console.log("exporting data for timespan", startDate, endDate);

  // fetch all sessions for the given timespan
  const allSessions = await getAllSessions({
    from: startDate,
    to: endDate,
    organizationId,
  });

  // fetch application details
  const applicationBuildIds = [
    ...new Set(allSessions.map((session) => session.appId)),
  ];
  const allApplicationBuilds = await getAllLegacyApplicationVersions({
    id_in: applicationBuildIds,
  });

  // merge all data into a csv and download it
  const data = allSessions.map((session) => {
    const appVersion = allApplicationBuilds.find(
      (applicationBuild) => applicationBuild.id === session.appId,
    );
    return [
      session.id,
      session.userIdentifier,
      session.experienceStartedDateTime ?? "",
      session.sessionTerminatedDateTime ?? "",
      session.deviceType,
      session.appTargetPlatform,
      appVersion?.id,
      // prevent CSV injection in app's name by forcing it to be a string if it starts with one of the dangerous characters, see https://owasp.org/www-community/attacks/CSV_Injection
      prevent_csv_injection(appVersion?.name),
      appVersion?.version,
    ];
  });
  data.unshift([
    "Session ID",
    "User ID",
    "Start Time",
    "End Time",
    "Device Type",
    "Platform",
    "Application ID",
    "Application Name",
    "Application Version",
  ]);

  const filename = `portal-sessions-${startDate.toISOString()}-${endDate.toISOString()}.csv`;
  return { url: exportToCsv(filename, data), filename: filename };
}

type CSVCol = Date | string | number | undefined;
type CSVRow = ArrayLike<CSVCol>;

function generateCsv(rows: CSVRow[]) {
  const processRow = function (row: CSVRow) {
    let finalVal = "";
    for (let j = 0; j < row.length; j++) {
      let innerValue = !row[j] ? "" : row[j]?.toString();
      if (row[j] instanceof Date) {
        innerValue = row[j]?.toLocaleString();
      }
      let result = innerValue?.replace(/"/g, '""');
      const searchresult = result?.search(/("|,|\n)/g);
      if (searchresult && searchresult >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  let csvFile = "";
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }
  return csvFile;
}

function downloadFile(file: Blob, filename: string) {
  const url = URL.createObjectURL(file);
  if ("msSaveBlob" in navigator) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (navigator as any).msSaveBlob(file, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  return url;
}

/**
 * https://stackoverflow.com/a/24922761/1142028
 */
export function exportToCsv(filename: string, rows: CSVRow[]) {
  const csvFile = generateCsv(rows);

  const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
  return downloadFile(blob, filename);
}
