import { ColumnSort, SortingState } from "@tanstack/react-table";

export const OrderingParam = {
  /**
   * Turns the sorting options as provided by react-table into a query string suitable for
   * the portal backend. I.e it turns
   *
   * [{id: "fieldname", desc: true}, {id: "secondfield", desc: false}]
   *
   * into
   *
   * "-fieldname,secondfield"
   */
  encode(sortBy: SortingState) {
    return (sortBy || [])
      .map((sortOption) => `${sortOption.desc ? "-" : ""}${sortOption.id}`)
      .join(",");
  },

  /**
   * Turns the ordering query string suitable for the portal backend into a set of sorting
   * options suitable for react-table.
   * I.e it turns
   *
   * "-fieldname,secondfield"
   *
   * into
   *
   * [{id: "fieldname", desc: true}, {id: "secondfield", desc: false}]
   *
   */
  decode(
    queryParam?: string | null | (string | null)[],
  ): SortingState | undefined {
    if (!queryParam) {
      return undefined;
    }

    const queryParams: string[] = [queryParam]
      .flat()
      .filter((v): v is string => v !== null);

    return queryParams
      .map((queryParam) =>
        queryParam.split(",").map<ColumnSort>((orderingParam) => ({
          desc: orderingParam.startsWith("-"),
          id: orderingParam.replace(/^-/, ""),
        })),
      )
      .flat();
  },
};
