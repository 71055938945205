import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Organization } from "../backend";
import { getOrganization, getOrganizationByDomain } from "../backend/api";
import { ErrorResponse } from "../backend/types";
import { loadDataFromElement } from "../utils/hydrate-data";

export function getQueryKeyForOrganization(
  organizationId: string | number | null,
) {
  return ["organizations", organizationId?.toString()];
}

export const queryKeyForActiveOrganization =
  getQueryKeyForOrganization("active");

export function useActiveOrganizationQuery(
  options: Partial<
    UseQueryOptions<Organization, AxiosError<ErrorResponse>>
  > = {},
) {
  return useQuery<Organization, AxiosError<ErrorResponse>>({
    queryKey: queryKeyForActiveOrganization,
    queryFn: () => getOrganizationByDomain(window.location.hostname),
    retry: false,
    // load the active organization data (json) from a script tag with id "active-organization" if available
    initialData: loadDataFromElement<Organization | undefined>(
      "active-organization",
      undefined,
    ),
    ...options,
  });
}

export function useOrganizationQuery(
  organizationId: string | number,
  options: Partial<UseQueryOptions<Organization>> = {},
) {
  return useQuery<Organization>({
    queryKey: getQueryKeyForOrganization(organizationId),
    queryFn: () => getOrganization(organizationId),
    retry: false,
    ...options,
  });
}
