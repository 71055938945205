import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  ReservedInstance,
  UpdateReservedInstance,
} from "../../session-management";
import { reservedInstanceSchema } from "../ReservedInstances/reservedInstanceSchema";

export const useReservedInstanceForm = (
  reservedInstance?: Partial<ReservedInstance>,
) => {
  const form = useForm<UpdateReservedInstance>({
    resolver: yupResolver(reservedInstanceSchema),
    defaultValues: {
      ...reservedInstance,
      description: reservedInstance?.description ?? "",
    },
  });

  return form;
};
