import escapeHtml from "escape-html";
import { Descendant, Node as SlateNode, Text } from "slate";
import { jsx } from "slate-hyperscript";
import { LinkElement } from "./types";

const serialize = (node: SlateNode): string => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<b>${string}</b>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    if (node.code) {
      string = `<code>${string}</code>`;
    }
    if (node.underlined) {
      string = `<u>${string}</u>`;
    }
    if (node.strikethrough) {
      string = `<s>${string}</s>`;
    }
    return string;
  }

  const children: string = (node.children ?? [])
    .map((n) => serialize(n))
    .join("");

  switch (node.type) {
    case "list-item":
      return `<li>${children}</li>`;
    case "bulleted-list":
      return `<ul>${children}</ul>`;
    case "numbered-list":
      return `<ol>${children}</ol>`;
    case "blockquote":
      return `<blockquote>${children}</blockquote>`;
    case "paragraph":
      return `<p>${children}</p>`;
    case "heading-one":
      return `<h1>${children}</h1>`;
    case "heading-two":
      return `<h2>${children}</h2>`;
    case "heading-three":
      return `<h3>${children}</h3>`;
    case "heading-four":
      return `<h4>${children}</h4>`;
    case "heading-five":
      return `<h5>${children}</h5>`;
    case "heading-six":
      return `<h6>${children}</h6>`;
    case "address":
      return `<address>${children}</address>`;
    case "formatted":
      return `<pre>${children}</pre>`;
    case "link":
      return `<a href="${escapeHtml(
        (node as LinkElement).url,
      )}">${children}</a>`;
    default:
      return children;
  }
};

const deserialize = (
  el: Node,
  markAttributes: Record<string, unknown> = {},
) => {
  if (el.nodeType === Node.TEXT_NODE) {
    if (["\n\n", "\r\n", "\t\n", "\n\t", "\n"].includes(el.nodeValue ?? ""))
      return null;
    return jsx("text", markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };

  // define attributes for text nodes
  switch (el.nodeName) {
    case "STRONG":
    case "B":
      nodeAttributes.bold = true;
      break;
    case "EM":
      nodeAttributes.italic = true;
      break;
    case "CODE":
      nodeAttributes.code = true;
      break;
    case "U":
      nodeAttributes.underlined = true;
      break;
    case "S":
      nodeAttributes.strikethrough = true;
      break;
  }

  const children: Descendant[] = Array.from(el.childNodes)
    .map((node) => deserialize(node, nodeAttributes))
    // type guard to filter out null values
    .filter<Descendant>((ele): ele is Descendant => !!ele)
    .flat();

  if (children.length === 0) {
    children.push(jsx("text", nodeAttributes, ""));
  }

  switch (el.nodeName) {
    case "BODY":
      return jsx("fragment", {}, children);
    case "UL":
      return jsx("element", { type: "bulleted-list" }, children);
    case "OL":
      return jsx("element", { type: "numbered-list" }, children);
    case "LI":
      return jsx("element", { type: "list-item" }, children);
    case "BLOCKQUOTE":
      return jsx("element", { type: "blockquote" }, children);
    case "P":
      return jsx("element", { type: "paragraph" }, children);
    case "H1":
      return jsx("element", { type: "heading-one" }, children);
    case "H2":
      return jsx("element", { type: "heading-two" }, children);
    case "H3":
      return jsx("element", { type: "heading-three" }, children);
    case "H4":
      return jsx("element", { type: "heading-four" }, children);
    case "H5":
      return jsx("element", { type: "heading-five" }, children);
    case "H6":
      return jsx("element", { type: "heading-six" }, children);
    case "PRE":
      return jsx("element", { type: "formatted" }, children);
    case "ADDRESS":
      return jsx("element", { type: "address" }, children);
    case "A":
      return jsx(
        "element",
        { type: "link", url: (el as HTMLLinkElement).getAttribute("href") },
        children,
      );
    default:
      return children;
  }
};

export function htmlToSlate(html?: string) {
  return [
    deserialize(
      new DOMParser().parseFromString(
        !html || html === "" ? "<p />" : html,
        "text/html",
      ).body,
    ),
  ]
    .flat()
    .filter<Descendant>((ele): ele is Descendant => !!ele);
}

export function slateToHtml(nodes: Descendant[]) {
  return nodes.map(serialize).join("\n");
}
