import { Heading, HStack, Skeleton, Spacer, Stack } from "@chakra-ui/react";
import { generatePath, Outlet, useParams } from "react-router-dom";
import {
  DeleteButton,
  NavigationTab,
  NavigationTabs,
  OptionsButton,
} from "../components";
import { useGroupQuery } from "../hooks";
import { namedRoutes } from "../routes";
import { useUserGroupDeleteConfirm } from "./hooks/useUserGroupDeleteConfirm";
import { isManageableGroup } from "./utils";

export function UserGroupDetails() {
  const { groupId = "" } = useParams();
  const userGroupQuery = useGroupQuery({ groupId });
  const confirmUserGroupDelete = useUserGroupDeleteConfirm();

  return (
    <Stack spacing={6}>
      <HStack>
        <Skeleton isLoaded={userGroupQuery.isSuccess}>
          <Heading>{userGroupQuery.data?.name}&apos;s details</Heading>
        </Skeleton>
        <Spacer />
        <OptionsButton label="Click on this button to display possible actions">
          <DeleteButton
            isDisabled={!isManageableGroup(userGroupQuery.data)}
            onClick={confirmUserGroupDelete(userGroupQuery.data)}
          >
            Delete user group
          </DeleteButton>
        </OptionsButton>
      </HStack>
      <NavigationTabs>
        <NavigationTab
          to={generatePath(namedRoutes.userGroup.overview, { groupId })}
          label="Details"
        />
        <NavigationTab
          to={generatePath(namedRoutes.userGroup.systemAccess, { groupId })}
          label="Platform permissions"
        />
        <NavigationTab
          to={generatePath(namedRoutes.userGroup.access, { groupId })}
          label="Application permissions"
        />
      </NavigationTabs>
      <Outlet />
    </Stack>
  );
}
