import {
  Button,
  ButtonGroup,
  Divider,
  Heading,
  Icon,
  Link,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { AxiosError } from "axios";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import ReactMarkdown from "react-markdown";
import { deleteApplicationBuild } from "../../backend/api";
import {
  ApplicationBuild,
  ApplicationBuildId,
  ApplicationId,
  XRPlatformType,
} from "../../backend/types";
import { DeleteIconButton, NoData, PaginatedTable } from "../../components";
import { UploadIcon } from "../../components/icons";
import { useConfirm } from "../../confirm-dialog";
import { usePaginationQueryParams, useSortingQueryParams } from "../../hooks";
import { useApplicationBuildsPaginatedQuery } from "../../hooks/useApplicationBuildsQuery";
import { OrderingParam } from "../../utils/ordering-param";
import { useApplicationDetailsContext } from "../ApplicationDetailsContext";
import { useApplicationDeleteConfirm } from "../hooks/useApplicationDeleteConfirm";
import { ApplicationBuildDisplay } from "./LazyApplicationBuildDisplay";
import { LazyApplicationDisplayName } from "./LazyApplicationDisplayName";

const columnHelper = createColumnHelper<ApplicationBuild>();

const markdownTheme: Parameters<typeof ChakraUIRenderer>[0] = {
  h1: (props) => <Heading as="h1" size="xl" {...props} />,
  h2: (props) => <Heading as="h2" size="lg" {...props} />,
  h3: (props) => <Heading as="h3" size="md" {...props} />,
  h4: (props) => <Heading as="h4" size="sm" {...props} />,
  h5: (props) => <Heading as="h5" size="xs" {...props} />,
  h6: (props) => <Heading as="h6" size="xs" fontWeight={"normal"} {...props} />,
};

const markdownRenderer = ChakraUIRenderer(markdownTheme);

export function ApplicationBuildsTable({
  applicationId,
  xrPlatforms,
  activeApplicationBuildId,
  onChange,
  uploadNewBuild,
}: {
  activeApplicationBuildId?: ApplicationBuildId;
  applicationId: ApplicationId;
  xrPlatforms: XRPlatformType[];
  onChange: (build?: ApplicationBuild) => void;
  uploadNewBuild?: () => void;
}) {
  const { application } = useApplicationDetailsContext();
  const toast = useToast();
  const confirmApplicationDeletion = useApplicationDeleteConfirm();
  const { confirm, cancelConfirm } = useConfirm();
  const [pagination, setPagination] = usePaginationQueryParams();
  const [sorting, setSorting] = useSortingQueryParams([
    { desc: true, id: "version" },
  ]);
  const applicationBuildsQuery = useApplicationBuildsPaginatedQuery({
    supported_xr_platform: xrPlatforms,
    application: applicationId,
    ordering: useMemo(() => OrderingParam.encode(sorting), [sorting]),
    page: pagination.pageIndex + 1,
    page_size: pagination.pageSize,
  });

  const { mutateAsync: deleteBuildAsync } = useMutation<
    void,
    AxiosError,
    ApplicationBuild
  >({
    mutationFn: async (build) => deleteApplicationBuild(build.id),
    onSuccess: (_, build) => {
      toast({
        title: (
          <Text>
            <ApplicationBuildDisplay applicationBuild={build} /> deleted.
          </Text>
        ),
        description: (
          <Text>
            The build <ApplicationBuildDisplay applicationBuild={build} /> of
            application{" "}
            <LazyApplicationDisplayName applicationId={build.application} />
            has been successfully deleted
          </Text>
        ),
        status: "success",
        duration: 3500,
        isClosable: true,
        position: "top-right",
      });

      applicationBuildsQuery.refetch();
    },
  });
  const deleteApplicationBuildHandler = useCallback(
    (build: ApplicationBuild) => {
      confirm({
        title: "Confirm deletion of build version",
        body: (
          <Stack>
            <Text>
              You&apos;re about to delete the build{" "}
              <ApplicationBuildDisplay applicationBuild={build} />
            </Text>
            <Text>Please be aware that this action cannot be undone.</Text>
            <Divider />
            <Text>
              In case you want to delete the entire application with all builds
              instead, you can do so{" "}
              <Link
                onClick={() => {
                  cancelConfirm();
                  confirmApplicationDeletion(application)();
                }}
              >
                here...
              </Link>
            </Text>
          </Stack>
        ),
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
      }).then(() => deleteBuildAsync(build));
    },
    [
      application,
      deleteBuildAsync,
      cancelConfirm,
      confirm,
      confirmApplicationDeletion,
    ],
  );
  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "active",
        header: "Active",
        cell: (props) => <Radio value={props.row.original.id.toString()} />,
      }),
      columnHelper.accessor("version", { header: "Version" }),
      columnHelper.display({
        id: "info",
        header: "Info",
        cell: (props) =>
          (
            props.row.original.executable_path ??
            props.row.original.package_name ??
            ""
          )
            .split("/")
            .at(-1) ?? "build.zip",
        enableSorting: false,
      }),
      columnHelper.accessor("created_date", {
        header: "Date",
        cell: (props) => dayjs(props.getValue()).format("L"),
      }),
      columnHelper.accessor("changelog", {
        header: "Changelog",
        cell: (props) => (
          <ReactMarkdown
            components={markdownRenderer}
            children={props.getValue()}
            skipHtml
          />
        ),
      }),
      columnHelper.display({
        id: "actions",
        header: "Actions",
        cell: (props) => (
          <ButtonGroup size="xs" isAttached variant={"ghost"}>
            <DeleteIconButton
              isDisabled={activeApplicationBuildId === props.row.original.id}
              aria-label={"Delete"}
              title={"Delete"}
              size="xs"
              onClick={() => {
                deleteApplicationBuildHandler(props.row.original);
              }}
            />
          </ButtonGroup>
        ),
      }),
    ],
    [activeApplicationBuildId, deleteApplicationBuildHandler],
  );
  const dynamicColumns = useMemo(
    () =>
      applicationBuildsQuery.isLoading
        ? columns.map((column) => ({
            ...column,
            cell: () => (
              <Skeleton>
                <Text>Loading</Text>
              </Skeleton>
            ),
          }))
        : columns,
    [applicationBuildsQuery.isLoading, columns],
  );
  const table = useReactTable({
    data: useMemo<ApplicationBuild[]>(
      () =>
        applicationBuildsQuery.isLoading
          ? Array(pagination.pageSize).fill({} as ApplicationBuild)
          : (applicationBuildsQuery.data?.results ?? []),
      [
        applicationBuildsQuery.data?.results,
        applicationBuildsQuery.isLoading,
        pagination.pageSize,
      ],
    ),

    columns: dynamicColumns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualSorting: true,
    manualPagination: true,
    pageCount: applicationBuildsQuery.data?.count
      ? Math.ceil(applicationBuildsQuery.data?.count / pagination.pageSize)
      : undefined,
  });

  if (applicationBuildsQuery.isError) {
    return <Text color="red">Could not load data.</Text>;
  }

  if (applicationBuildsQuery.data?.count === 0) {
    return (
      <NoData
        alignSelf={"baseline"}
        maxHeight={"lg"}
        title="No Builds."
        text="No builds have been uploaded for this application (yet)."
        width="max-content"
        callToAction={
          uploadNewBuild ? (
            <Button
              leftIcon={<Icon as={UploadIcon} />}
              onClick={uploadNewBuild}
              colorScheme="brand"
            >
              Upload build
            </Button>
          ) : undefined
        }
      />
    );
  }

  return (
    <RadioGroup
      value={activeApplicationBuildId?.toString()}
      onChange={(value) => {
        onChange(
          applicationBuildsQuery.data?.results.find(
            (version) => version.id.toString() === value,
          ),
        );
      }}
    >
      <PaginatedTable table={table} />
    </RadioGroup>
  );
}
