import { BoxProps, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { ActionMeta, MultiValue, Select } from "chakra-react-select";
import { useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { ApplicationPermission, PermissionSubject } from "../backend";
import { ApplicationId } from "../backend/types";
import { useApplicationPermissionMutation } from "../hooks";

type PermissionOption = {
  value: ApplicationPermission;
  label: string;
};

const viewOptions: PermissionOption[] = [
  { value: "view", label: "View" },
  { value: "stream", label: "Stream" },
  { value: "download", label: "Download" },
];

const editOptions: PermissionOption[] = [{ value: "change", label: "Change" }];

const groupedOptions = [
  {
    label: "View",
    options: viewOptions,
  },
  {
    label: "Edit",
    options: editOptions,
  },
];

export function PermissionSelect({
  subject,
  applicationId,
  permissions,
  organizationId,
  validUntil,
}: {
  validUntil?: Date;
  organizationId: number;
  applicationId: ApplicationId;
  subject: PermissionSubject;
  permissions: ApplicationPermission[];
} & BoxProps) {
  const permissionMutation = useApplicationPermissionMutation(applicationId);
  const [activeSelections, setActiveSelections] = useState<PermissionOption[]>(
    [],
  );

  useMemo(() => {
    const allOptions = [...viewOptions, ...editOptions];
    const selectedOptions = allOptions.filter((item) =>
      permissions?.includes(item.value),
    );
    setActiveSelections(selectedOptions);
  }, [permissions]);

  const persistPermissionChanges = useDebouncedCallback(
    (permissions: ApplicationPermission[]) => {
      // persist changes
      permissionMutation.mutate({
        permissions,
        organizationId,
        subject,
        validUntil,
      });
    },
    500,
    {
      leading: true,
    },
  );

  const onChange = (
    value: MultiValue<PermissionOption>,
    action: ActionMeta<PermissionOption>,
  ) => {
    let perms: PermissionOption[] = [...value];
    if (action.action === "select-option") {
      // automatically grant view permission if stream or download is granted
      if (
        perms.some(
          (item) => item.value === "stream" || item.value === "download",
        ) &&
        !perms.some((item) => item.value === "view")
      ) {
        perms.push({ value: "view", label: "View" });
      }
    } else if (
      action.action === "deselect-option" ||
      action.action === "remove-value" ||
      action.action === "pop-value"
    ) {
      // automatically revoke stream or download permission, if view permission is revoked
      if (!perms.some((item) => item.value === "view")) {
        perms = perms.filter(
          (item) => !(item.value === "stream" || item.value === "download"),
        );
      }
    }

    setActiveSelections(perms);

    persistPermissionChanges(perms.map((item) => item.value));
  };

  return (
    <FormControl isInvalid={permissionMutation.isError}>
      <Select
        isMulti
        name="permissions"
        placeholder="Click to assign permissions..."
        value={activeSelections}
        options={groupedOptions}
        onChange={onChange}
        selectedOptionStyle="check"
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        size="sm"
        isLoading={permissionMutation.isPending}
      />
      <FormErrorMessage>
        {permissionMutation.error?.response?.data
          ? Object.values(permissionMutation.error?.response?.data)
              .flat()
              .join(", ")
          : permissionMutation.error?.message}
      </FormErrorMessage>
    </FormControl>
  );
}
