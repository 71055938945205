import { Heading, Stack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { UserGroupApplicationPermissionManagement } from "./components";

export function UserGroupAccess() {
  const { groupId = "" } = useParams();

  return (
    <Stack spacing={6} width="fit-content">
      <Heading size="md">Permissions</Heading>
      <UserGroupApplicationPermissionManagement id={parseInt(groupId)} />
    </Stack>
  );
}
