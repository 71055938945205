import {
  CircularProgress,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { ValidationError } from "yup";
import { Application } from "../backend";
import { updateApplicationCloudRenderingConfiguration } from "../backend/api";
import { applicationUrlRewriteRuleSchema } from "../backend/schema";
import {
  ApplicationCloudRenderingConfiguration,
  ApplicationUrlRewriteRule,
} from "../backend/types";
import {
  AddButton,
  DeleteButton,
  EditButton,
  OptionsButton,
} from "../components";
import { useConfirm } from "../confirm-dialog";
import { useApplicationCloudRenderingConfigurationQuery } from "../hooks";
import { getQueryKeyForApplicationCloudRenderingConfiguration } from "../hooks/useApplicationCloudRenderingConfigurationQuery";
import { RewriteRuleCreateForm } from "./url-rewrite-rules/RewriteRuleCreateForm";
import { RewriteRuleEditForm } from "./url-rewrite-rules/RewriteRuleEditForm";

export function ApplicationUrlRewriteRules({
  application,
}: {
  application: Application;
}) {
  return (
    <Stack spacing={4}>
      <Heading size="sm" as="h3">
        (Remote) URL Rewriting
      </Heading>

      <Text>
        If your app opens URLs, you can rewrite them so they work even when
        opened on your local machine. Portal will automatically forward URLs
        opened on the remote rendering machine to your local machine and apply
        the rewrite rules defined below.
      </Text>

      <UrlRewriteRuleManager application={application} />
    </Stack>
  );
}

export function UrlRewriteRuleManager({
  application,
}: {
  application: Application;
}) {
  const queryClient = useQueryClient();
  const [selectedRewriteRuleIndex, setSelectedRewriteRuleIndex] = useState<
    number | null
  >(null);
  const dialogState = useDisclosure({
    onClose: () => {
      setSelectedRewriteRuleIndex(null);
    },
  });
  const { data, isLoading } = useApplicationCloudRenderingConfigurationQuery(
    application.id,
  );
  const {
    mutateAsync: updateRewriteRulesAsync,
    ...updateRewriteRulesMutation
  } = useMutation({
    mutationFn: (rules: Array<ApplicationUrlRewriteRule>) =>
      updateApplicationCloudRenderingConfiguration(application.id, {
        url_rewrites: { rules },
      }),
    onMutate: (rules) => {
      // backup the current state
      const previousData =
        queryClient.getQueryData<ApplicationCloudRenderingConfiguration>(
          getQueryKeyForApplicationCloudRenderingConfiguration(application.id),
        );

      // Optimistically update the UI
      queryClient.setQueryData<ApplicationCloudRenderingConfiguration>(
        getQueryKeyForApplicationCloudRenderingConfiguration(application.id),
        {
          ...previousData,
          vm_size: previousData?.vm_size ?? null,
          url_rewrites: { ...previousData?.url_rewrites, rules },
        },
      );

      return { previousData };
    },
    onError: (_error, _variables, context) => {
      // rollback the optimistic update
      queryClient.setQueryData<ApplicationCloudRenderingConfiguration>(
        getQueryKeyForApplicationCloudRenderingConfiguration(application.id),
        context?.previousData,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationCloudRenderingConfiguration(
          application.id,
        ),
      });
    },
  });

  const rewriteRules = useMemo<Array<ApplicationUrlRewriteRule>>(() => {
    if (!data?.url_rewrites?.rules) {
      return [];
    }

    return (
      (data.url_rewrites?.rules ?? [])
        // filter out any invalid rules
        .map((rule) => {
          try {
            return applicationUrlRewriteRuleSchema.validateSync(rule);
          } catch (e) {
            console.debug(
              "Invalid rewrite rule",
              rule,
              (e as ValidationError).message,
            );
            return null;
          }
        })
        .filter((rule): rule is ApplicationUrlRewriteRule => !!rule)
    );
  }, [data]);

  const { confirm } = useConfirm();

  const confirmDeletion = useCallback(
    (ruleIndex: number) => {
      confirm({
        title: "Delete rewrite rule?",
        body: `Do you really want to delete the rewrite rule "${rewriteRules[ruleIndex].name}"?`,
        onConfirm: () =>
          updateRewriteRulesAsync(
            rewriteRules.filter((_, idx) => idx !== ruleIndex),
          ).then(() => {}),
      });
    },
    [confirm, rewriteRules, updateRewriteRulesAsync],
  );

  if (isLoading) {
    return <CircularProgress isIndeterminate />;
  }

  return (
    <>
      <AddButton
        onClick={() => {
          setSelectedRewriteRuleIndex(null);
          dialogState.onOpen();
        }}
        size="sm"
        alignSelf={"end"}
      >
        Add URL rewrite rule
      </AddButton>
      {rewriteRules.length > 0 && (
        <Stack spacing={4}>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Enabled?</Th>
                <Th>Name</Th>
                <Th>Rule</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {rewriteRules.map((rule, idx) => (
                <Tr key={idx}>
                  <Td>
                    <Switch
                      isDisabled={updateRewriteRulesMutation.isPending}
                      isChecked={rule.enabled}
                      onChange={(e) =>
                        // enable the rule
                        updateRewriteRulesMutation.mutate(
                          rewriteRules.map((innerRule, innerIdx) =>
                            innerIdx === idx
                              ? { ...innerRule, enabled: e.target.checked }
                              : innerRule,
                          ),
                        )
                      }
                    />
                  </Td>
                  <Td>{rule.name}</Td>
                  <Td>
                    {rule.match} ➡️ {rule.replace}
                  </Td>
                  <Td>
                    <OptionsButton label="Options">
                      <EditButton
                        variant="ghost"
                        width="full"
                        onClick={() => {
                          setSelectedRewriteRuleIndex(idx);
                          dialogState.onOpen();
                        }}
                        fontWeight="normal"
                        fontSize="sm"
                      >
                        Edit rule
                      </EditButton>
                      <DeleteButton onClick={() => confirmDeletion(idx)}>
                        Delete rule
                      </DeleteButton>
                    </OptionsButton>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Stack>
      )}
      <Modal isOpen={dialogState.isOpen} onClose={dialogState.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedRewriteRuleIndex
              ? `Update ${rewriteRules[selectedRewriteRuleIndex].name}`
              : "Create new rewrite rule"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody paddingBottom={6}>
            {selectedRewriteRuleIndex === null ? (
              <RewriteRuleCreateForm
                applicationId={application.id}
                existingRules={rewriteRules}
                onSuccess={dialogState.onClose}
              />
            ) : (
              <RewriteRuleEditForm
                applicationId={application.id}
                existingRules={rewriteRules}
                ruleIndex={selectedRewriteRuleIndex}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
