import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import {
  LegacyApplicationVersion,
  LegacyApplicationVersionListFilters,
  PaginatedLegacyApplicationVersionsList,
} from "../backend";
import {
  getAllLegacyApplicationVersions,
  getLegacyApplicationVersionsPaginated,
} from "../backend/api";

const baseQueryKeyForLegacyApplicationVersions = [
  "legacy-application-versions",
] as const;

/**
 * @deprecated /api/applications/<application-version-id>/ is deprecated and should not be used
 */
export function getQueryKeyForLegacyApplicationVersion(
  applicationVersionId: number | string,
) {
  return [
    ...baseQueryKeyForLegacyApplicationVersions,
    applicationVersionId.toString(),
  ];
}

export function getQueryKeyForCurrentApplicationVersions(
  params: LegacyApplicationVersionListFilters = {},
) {
  return [...baseQueryKeyForLegacyApplicationVersions, params];
}

/**
 * @deprecated /api/applications/ is deprecated and should not be used
 */

export function useCurrentApplicationVersionsListQuery(
  params: LegacyApplicationVersionListFilters = {},
  queryOptions: Partial<
    UseQueryOptions<PaginatedLegacyApplicationVersionsList>
  > = {},
) {
  const queryClient = useQueryClient();

  return useQuery<PaginatedLegacyApplicationVersionsList>({
    queryKey: getQueryKeyForCurrentApplicationVersions(params),
    queryFn: async () => {
      const legacyApplicationVersionsResponse =
        await getLegacyApplicationVersionsPaginated(params);
      legacyApplicationVersionsResponse.results.forEach((app) =>
        queryClient.setQueryData<LegacyApplicationVersion>(
          getQueryKeyForLegacyApplicationVersion(app.id),
          app,
        ),
      );
      return legacyApplicationVersionsResponse;
    },
    ...queryOptions,
  });
} /**
 * @deprecated /api/applications/ is deprecated and should not be used
 */

export function useAllCurrentApplicationVersionsQuery(
  params: Omit<LegacyApplicationVersionListFilters, "page"> = {},
  queryOptions: Partial<UseQueryOptions<LegacyApplicationVersion[]>> = {},
) {
  const queryClient = useQueryClient();

  return useQuery<LegacyApplicationVersion[]>({
    queryKey: getQueryKeyForCurrentApplicationVersions(params),
    queryFn: async () => {
      const legacyApplicationVersions =
        await getAllLegacyApplicationVersions(params);
      legacyApplicationVersions.forEach((app) =>
        queryClient.setQueryData<LegacyApplicationVersion>(
          getQueryKeyForLegacyApplicationVersion(app.id),
          app,
        ),
      );
      return legacyApplicationVersions;
    },
    ...queryOptions,
  });
}
