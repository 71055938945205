import axios, { CreateAxiosDefaults } from "axios";
import QueryString from "query-string";
import { config } from "../config";

const settings: CreateAxiosDefaults = {
  baseURL: config.backendEndpoint!,
  paramsSerializer: (params) => QueryString.stringify(params),
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
};
export const axiosInstance = axios.create(settings);
export const axiosInstanceGeneric = axios.create({
  ...settings,
  baseURL: config.backendGenericEndpoint!,
});

export default axiosInstance;
