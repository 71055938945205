import {
  createEnumDelimitedArrayParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import {
  XRPlatformType,
  androidXRPlatformTypes,
  windowsXRPlatformTypes,
} from "../backend/types";

const PlatformArrayEnumParam = createEnumDelimitedArrayParam<XRPlatformType>(
  windowsXRPlatformTypes.concat(androidXRPlatformTypes),
);

export function usePlatformsQueryParam() {
  return useQueryParam("platforms", withDefault(PlatformArrayEnumParam, []));
}
