import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { StringParam, useQueryParams } from "use-query-params";
import { Organization } from "../backend";
import { getOrganizationBySubdomain } from "../backend/api";
import { OrganizationLogo } from "../components";
import { loadDataFromElement } from "../utils/hydrate-data";
import { SSOButtonGroup } from "./SSOButtonGroup";
import { authProviders } from "./authProviders";

const domain = loadDataFromElement(
  "domain",
  window.location.hostname.replace("api.", ""),
);

export function OrganizationalLoginForm() {
  const findOrganizationBySudomainMutation = useMutation<
    Organization,
    AxiosError,
    string
  >({
    mutationFn: (subdomain) => getOrganizationBySubdomain(subdomain),
  });
  const [subdomain, setSubdomain] = useState<string>("");
  const [query] = useQueryParams({
    next: StringParam,
  });
  return (
    <VStack spacing={16}>
      <OrganizationLogo maxWidth={"3xs"} />

      <Stack
        justifyContent={"center"}
        spacing={10}
        alignItems={{ base: "normal", md: "flex-start" }}
        width="full"
        direction={{ base: "column", md: "row" }}
      >
        {authProviders.length && (
          <>
            <Stack spacing="6" flexBasis={"full"}>
              <Stack spacing="2">
                <Heading size={{ base: "xs", md: "sm" }}>Sign up</Heading>
                <Heading
                  size={{ base: "2xs", md: "xs" }}
                  fontWeight={"normal"}
                  color="chakra-subtle-text"
                >
                  using
                </Heading>
              </Stack>
              <SSOButtonGroup />
            </Stack>
            <Box position="relative" height={{ base: "auto", md: "full" }}>
              <Divider
                orientation="horizontal"
                display={{ base: "block", md: "none" }}
              />
              <Divider
                orientation="vertical"
                display={{ base: "none", md: "block" }}
              />
              <AbsoluteCenter
                bg="chakra-body-bg"
                fontSize={"sm"}
                color="chakra-subtle-text"
                axis={"both"}
              >
                or
              </AbsoluteCenter>
            </Box>
          </>
        )}
        <Box flexBasis={"full"} maxWidth="container.sm">
          <form
            method="post"
            onSubmit={async (event) => {
              event.preventDefault();
              try {
                const organization =
                  await findOrganizationBySudomainMutation.mutateAsync(
                    subdomain,
                  );
                // redirect to next parameter with organization's domain
                window.location.href = `${window.location.protocol}//${organization.domain}${query.next ?? "/auth/login"}`;
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <Stack spacing="6">
              <Stack spacing="2">
                <Heading size={{ base: "xs", md: "sm" }}>Sign in</Heading>
                <Heading
                  size={{ base: "2xs", md: "xs" }}
                  color="chakra-subtle-text"
                  fontWeight={"normal"}
                >
                  to your organization
                </Heading>
              </Stack>
              <Stack spacing="5">
                <FormControl
                  isInvalid={findOrganizationBySudomainMutation.isError}
                >
                  <InputGroup>
                    <Input
                      placeholder="my-organization"
                      value={subdomain}
                      onChange={(evt) => setSubdomain(evt.target.value)}
                      required
                    />
                    <InputRightAddon children={`.${domain}`} />
                  </InputGroup>
                  <FormErrorMessage>
                    {findOrganizationBySudomainMutation.error?.response
                      ?.status === 404
                      ? "This is not a valid subdomain of any organization."
                      : "Something went wrong."}
                  </FormErrorMessage>
                </FormControl>

                <Button
                  colorScheme="brand"
                  type="submit"
                  isLoading={
                    findOrganizationBySudomainMutation.isPending ||
                    findOrganizationBySudomainMutation.isSuccess
                  }
                >
                  Go to organization
                </Button>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </VStack>
  );
}
