import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { PermissionString } from "../backend/types";
import { AuthorizingAlert, FullPageCenteredContainer } from "../components";
import { usePermissions } from "../hooks/usePermissions";
import { namedRoutes } from "../routes";

const anyControlPanelPermission: PermissionString[] = [
  "core.change_organization",
  "core.view_user",
  "core.view_usergroup",
  "assets.add_application",
];

export function RequireAnyControlPanelPermission({
  children,
}: PropsWithChildren): JSX.Element {
  const { hasPermission, isLoading } = usePermissions();

  // if we are currently checking the user's permissions, wait for it to finish before doing anything
  if (isLoading) {
    return (
      <FullPageCenteredContainer>
        <AuthorizingAlert />
      </FullPageCenteredContainer>
    );
  }

  // if the user has no access to the control panel, point him to the frontend instead
  if (!anyControlPanelPermission.some((perm) => hasPermission(perm))) {
    return <Navigate to={namedRoutes.accessDenied} />;
  }

  return <>{children}</>;
}
