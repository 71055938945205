import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { SessionsStatDto } from "../../session-management";
import { usePreviousTimeSpan, useSessionsStatsQuery } from "../hooks";
import { PeriodStatCompare } from "./PeriodStatCompare";
import { TimeSpanProps } from "./types";

dayjs.extend(duration);

type SessionCountFilters = Parameters<
  typeof useSessionsStatsQuery<Pick<SessionsStatDto, "count" | "key">[]>
>[0];

export function SessionCountInTimeSpan({
  from,
  to,
  title,
  filters = {},
}: {
  title: string;
  filters?: Omit<SessionCountFilters, "from" | "to">;
} & TimeSpanProps) {
  const countOfSessionsInTimeSpan = useSessionsStatsQuery({
    from,
    to,
    ...filters,
  });
  const countOfSessionsInPreviousTimeSpan = useSessionsStatsQuery({
    ...usePreviousTimeSpan(from, to),
    ...filters,
  });

  return (
    <PeriodStatCompare
      currentPeriodStatQuery={countOfSessionsInTimeSpan}
      previousPeriodStatQuery={countOfSessionsInPreviousTimeSpan}
      title={title}
      label={`# of sessions`}
    />
  );
}
