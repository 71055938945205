import { Heading, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useActiveOrganizationQuery, useUserQuery } from "../hooks";
import {
  UserBasicInformation,
  UserGroupMemberships,
  UserOrganizationMemberships,
} from "./components";

export function UserInfo() {
  const { userId = "" } = useParams();
  const userQuery = useUserQuery({
    userId,
    options: { retry: false, throwOnError: true },
  });
  const activeOrganizationQuery = useActiveOrganizationQuery();

  return (
    <>
      <Heading size="sm">Basic Information</Heading>
      <UserBasicInformation userId={userId} />
      <Heading size="sm">Groups</Heading>
      <Text>
        Use groups to manage a user&apos;s access, e.g. granting them access to
        applications the group has access to or making them an organization
        admin.
      </Text>
      <UserGroupMemberships userId={userId} />
      <Heading size="sm">Organization Memberships</Heading>
      {(userQuery.data?.organizations.length ?? 0) > 1 ? (
        <Text>
          Other than being a member of{" "}
          {activeOrganizationQuery.data?.name ?? "Loading ..."}, this user is
          also part of the following organizations and may have different access
          in those.
        </Text>
      ) : (
        <Text>
          This user appears to only be part of{" "}
          {activeOrganizationQuery.data?.name ?? "Loading ..."} right now.
        </Text>
      )}

      <UserOrganizationMemberships userId={userId} />
    </>
  );
}
