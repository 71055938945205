import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { backendClient, PaginatedUsersList, User } from "../backend";
import { QueryParameters } from "../utils/types";
import { getQueryForUser } from "./useUserQuery";

async function fetchUsers(queryParams: QueryParameters = {}) {
  const response = await backendClient.get<PaginatedUsersList>(`/api/users/`, {
    params: queryParams,
  });
  return response.data;
}

export function useUsersQuery(
  filters: QueryParameters = {},
  queryOptions: Partial<UseQueryOptions<PaginatedUsersList>> = {},
) {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["users", filters],
    queryFn: async () => {
      const usersResponse = await fetchUsers(filters);
      // put the groups into the cache
      usersResponse.results.forEach((user) =>
        queryClient.setQueryData<User>(getQueryForUser(user.id).queryKey, user),
      );
      return usersResponse;
    },
    ...queryOptions,
  });
}
