import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { SessionsStatDto } from "../../session-management";
import {
  UseSessionsStatsQueryProps,
  useSessionsStatsQuery,
} from "./useSessionsStatsQuery";

dayjs.extend(utc);

export function useSessionsCountInTimespanQuery({
  startDate,
  endDate,
  unitOfTime = "day",
  userGroupId,
  ...filters
}: {
  startDate: Date;
  endDate: Date;
  unitOfTime: "month" | "day" | "year";
} & Omit<
  UseSessionsStatsQueryProps<Pick<SessionsStatDto, "count" | "key">[]>,
  "from" | "to" | "select"
>) {
  // divide the given timespan into the defined buckets and query the session count for each of those days, once for cloud rendered, once for locally-rendered sessions
  return useSessionsStatsQuery({
    from: startDate,
    to: endDate,
    groupBy: unitOfTime,
    userGroupId,
    queryOptions: {
      select: (data) => {
        // fill in gaps in the data with 0 values
        // construct a list of the requested time intervals
        const timeIntervals = [];
        // FIXME: service returns timestamps in UTC data but our timestamps are timezone aware
        // we need to convert the timestamps to UTC before comparing them
        let currentDate = dayjs(startDate).utc().startOf("day");
        const _endDate = dayjs(endDate).utc().startOf("day");
        if (unitOfTime === "month") {
          currentDate = currentDate.set("date", 1);
          _endDate.set("date", 1);
        } else if (unitOfTime === "year") {
          currentDate = currentDate.set("date", 1).set("month", 0);
          _endDate.set("date", 1).set("month", 0);
        }
        while (currentDate <= _endDate) {
          timeIntervals.push(currentDate);
          currentDate = dayjs(currentDate).add(1, unitOfTime);
        }

        // fill in gaps in the data with 0 values
        return timeIntervals.map((intervalStart) => {
          const dataPoint = data.find((d) => {
            // FIXME: remove conversion to UTC once we have timezone aware data
            return dayjs(d.key).utc(true).isSame(intervalStart);
          });
          if (dataPoint) {
            return { count: dataPoint.count, key: intervalStart.toISOString() };
          }
          return {
            count: 0,
            key: intervalStart.toISOString(),
          };
        });
      },
    },
    ...filters,
  });
}
