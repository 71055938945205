import { useMemo } from "react";
import { useMatches } from "react-router-dom";
import { useBrandingQuery } from "./useBranding";

export type Match = ReturnType<typeof useMatches>[0];

export type MatchWithHandle = Omit<Match, "handle"> & {
  handle: Record<string, unknown>;
};

function routeHasHandle(
  route: Match | MatchWithHandle,
): route is MatchWithHandle {
  return !!route.handle;
}

export function useMatchesWithHandle() {
  const matches = useMatches();
  return useMemo(() => matches.filter(routeHasHandle), [matches]);
}

export function usePageTitleSuffix() {
  const { data: branding } = useBrandingQuery();

  return useMemo(() => {
    const controlPanelSuffix = "Control Panel";
    return branding?.product_name
      ? `${branding.product_name} ${controlPanelSuffix}`
      : controlPanelSuffix;
  }, [branding?.product_name]);
}

export function usePageTitleFromRoutes() {
  const matchesWithHandles = useMatchesWithHandle();
  return useMemo(() => {
    // first get rid of any matches that don't have handle and title
    const matchesWithTitle = matchesWithHandles.filter(
      (match): match is MatchWithHandle & { handle: { title: string } } =>
        !!match.handle.title,
    );

    // get the most significant page title
    return matchesWithTitle.reverse().at(0)?.handle?.title;
  }, [matchesWithHandles]);
}
