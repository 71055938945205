import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import { Outlet, Route } from "react-router-dom";

import { NavigationTab, NavigationTabs, PageTitle } from "../components";
import { RouterErrorElement } from "../components/ErrorBoundary";
import { useBrandingQuery } from "../hooks";
import { SentryRoutes } from "../sentry";
import { OrganizationAuthProvidersForm } from "./OrganizationAuthProvidersForm";
import { OrganizationBrandingForm } from "./OrganizationBrandingForm";
import { OrganizationDetailsForm } from "./OrganizationDetailsForm";

const sections = [
  {
    label: "General",
    suffix: "",
  },
  {
    label: "Branding",
    suffix: "branding",
  },
  {
    label: "Authentication & SSO",
    suffix: "auth",
  },
];

export function OrganizationSettings() {
  const brandingQuery = useBrandingQuery();

  return (
    <SentryRoutes>
      <Route
        path="/"
        element={
          <Box width={["max", "max", "100%"]}>
            <Stack spacing={6}>
              <Box>
                <Heading size="lg">Organization Settings</Heading>
                <Text>Manage your organization&apos;s settings.</Text>
              </Box>
              <NavigationTabs>
                {sections.map((section, idx) => (
                  <NavigationTab
                    to={section.suffix}
                    key={idx}
                    label={section.label}
                  />
                ))}
              </NavigationTabs>
              <Stack spacing={8}>
                <Outlet />
              </Stack>
            </Stack>
          </Box>
        }
      >
        <Route
          index
          element={
            <Stack spacing={4}>
              <Text>Manage general information about your organization</Text>
              <OrganizationDetailsForm />
            </Stack>
          }
        />
        <Route
          path="branding"
          element={
            <>
              <PageTitle title="Branding Settings" />
              <Stack spacing={4}>
                <Text>
                  Manage your organization&apos;s branding to match your
                  corporate identity.
                </Text>
                <OrganizationBrandingForm />
              </Stack>
            </>
          }
        />
        <Route
          path="auth"
          errorElement={<RouterErrorElement />}
          element={
            <>
              <PageTitle title="Authentication / SSO Settings" />
              <Stack spacing={4}>
                <Text>
                  Manage how your users can sign into{" "}
                  {brandingQuery.data?.product_name}.
                </Text>
                <Text>
                  You can choose to use the provided user management to let your
                  users log into {brandingQuery.data?.product_name} or configure
                  an external provider to perform authentication or leave the
                  choice up to your users.
                </Text>
                <Text>
                  Select your type of auth provider and configure it with the
                  appropriate set of parameters to get started.
                </Text>
                <OrganizationAuthProvidersForm />
              </Stack>
            </>
          }
        />
      </Route>
    </SentryRoutes>
  );
}
