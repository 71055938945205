import { useColorMode, useColorModeValue, useToken } from "@chakra-ui/react";
import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import { getTimeSpanDisplay } from "./utils";

export function useChartOptions(): ApexOptions {
  const [axisColor] = useToken("colors", [
    useColorModeValue("gray.600", "whiteAlpha.600"),
  ]);
  const [fontFamily] = useToken("fonts", ["body"]);
  const [fontSizeAxisLabels] = useToken("fontSizes", ["sm"]);
  const [fontSizeLegend] = useToken("space", ["56"]);
  const [fontWeightAxisLabels] = useToken("fontWeights", ["normal"]);
  const { colorMode } = useColorMode();

  return {
    chart: {
      toolbar: {
        show: false,
      },
      dropShadow: { enabled: false },
    },
    tooltip: {
      theme: colorMode,
      style: {
        fontFamily,
      },
    },
    markers: {
      size: 0,
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      labels: {
        style: {
          colors: axisColor,
          fontSize: fontSizeAxisLabels,
          fontWeight: fontWeightAxisLabels,
          fontFamily,
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
    },
    yaxis: {
      labels: {
        style: { colors: axisColor, fontFamily, fontSize: fontSizeAxisLabels },
      },
      title: {
        style: {
          color: axisColor,
          fontSize: fontSizeAxisLabels,
          fontWeight: fontWeightAxisLabels,
          fontFamily,
        },
      },
    },
    legend: {
      show: false,
      fontFamily,
      fontSize: fontSizeLegend,
    },
    grid: {
      show: true,
    },
  };
}

export const useTimeSeriesBarChartOptions = ({
  yAxisTitle,
  unitOfTime,
}: {
  yAxisTitle?: string;
  unitOfTime: "day" | "month" | "year";
}): ApexOptions => {
  const [bodyText] = useToken("colors", ["chakra-body-text"]);
  const chartOptions = useChartOptions();

  return useMemo(
    () => ({
      ...chartOptions,
      xaxis: {
        ...chartOptions.xaxis,
        type: unitOfTime === "day" ? "datetime" : "category",
        labels: {
          ...chartOptions.xaxis?.labels,
          formatter: (value: string) => getTimeSpanDisplay(value, unitOfTime),
        },
      },
      yaxis: {
        ...chartOptions.yaxis,
        show: true,
        title: {
          ...(!chartOptions.yaxis || "length" in chartOptions.yaxis
            ? {}
            : chartOptions.yaxis?.title),
          text: yAxisTitle,
        },
      },
      legend: {
        ...chartOptions.legend,
        show: true,
        labels: {
          ...chartOptions.legend?.labels,
          colors: [bodyText],
        },
      },
    }),
    [bodyText, chartOptions, unitOfTime, yAxisTitle],
  );
};
