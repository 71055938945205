import {
  Alert,
  AlertIcon,
  Box,
  Collapse,
  HStack,
  Icon,
  Progress,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import prettyBytes from "pretty-bytes";
import { useCallback, useState } from "react";
import { FileError, FileRejection } from "react-dropzone";
import {
  FaFileArchive as ArchiveIcon,
  FaFile as FileIcon,
} from "react-icons/fa";
import { ChunkedUploadState } from "../../chunked-file-upload";
import { DeleteIconButton } from "../../components";
import { Dropzone } from "../../components/FileDropzone";

const FILE_TYPES_ZIP = {
  "application/x-zip-compressed": [".zip"],
  "application/zip": [".zip"],
};
const FILE_TYPE_APK = {
  "application/vnd.android.package-archive": [".apk"],
};

export function ApplicationArchiveUpload({
  uploadState: {
    file,
    setFile,
    error: uploadError,
    isUploading,
    progress: uploadProgress,
    result: uploadResult,
  },
  isDisabled = false,
}: {
  uploadState: ChunkedUploadState;
  isDisabled?: boolean;
}) {
  const [error, setError] = useState<FileError | undefined>();

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      let _file, _error;
      if (acceptedFiles.length) {
        _file = acceptedFiles.slice().pop();
      } else if (fileRejections.length) {
        _file = fileRejections.slice().pop()!.file;
        _error = fileRejections.slice().pop()!.errors.slice().pop()!;
      }
      setFile(_file);
      setError(_error);
    },
    [setFile],
  );

  return (
    <>
      <Text>Please upload your application archive below.</Text>
      <Collapse in={!file || !!error}>
        <Dropzone
          onDrop={onDrop}
          multiple={false}
          accept={{
            ...FILE_TYPES_ZIP,
            ...FILE_TYPE_APK,
          }}
        />
      </Collapse>
      {file && (
        <Box>
          <HStack
            spacing={4}
            padding={5}
            borderWidth={1}
            borderColor={error && "red"}
          >
            <Icon
              boxSize={8}
              as={
                Object.keys(FILE_TYPES_ZIP).includes(file.type)
                  ? ArchiveIcon
                  : FileIcon
              }
            />
            <Stack>
              <Text>{file.name}</Text>
            </Stack>
            <Spacer />
            <Text>{prettyBytes(file.size)}</Text>
            <DeleteIconButton
              aria-label="Delete uploaded file"
              isDisabled={isUploading || isDisabled}
              variant="ghost"
              size="sm"
              onClick={() => {
                // TODO: Add animation when clearing file
                setFile(undefined);
              }}
            />
          </HStack>
          {(error || uploadError) && (
            <Alert status="error">
              <AlertIcon />
              {error?.message ?? uploadError?.message}
            </Alert>
          )}
          {isUploading && <Progress size="sm" value={uploadProgress * 100} />}
          {uploadResult && (
            <Alert status="success">
              <AlertIcon />
              Application upload complete
            </Alert>
          )}
        </Box>
      )}
    </>
  );
}
