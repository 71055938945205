import { useMemo } from "react";
import { loadDataFromElement } from "../utils/hydrate-data";
import PermissionDenied from "./403";
import NotFound from "./404";
import ServerError from "./500";

export function CatchAllRoute() {
  const http_status_code = useMemo(
    () => loadDataFromElement<number>("http_status_code", 200),
    [],
  );

  if (http_status_code === 404) {
    return <NotFound />;
  }

  if (http_status_code === 403) {
    return <PermissionDenied />;
  }

  if (http_status_code === 500) {
    return <ServerError />;
  }

  return <NotFound />;
}
