import { Box, chakra, forwardRef } from "@chakra-ui/react";
import isHotkey from "is-hotkey";
import isUrl from "is-url";
import React, { useCallback, useMemo } from "react";
import {
  Descendant,
  Editor,
  Range,
  Element as SlateElement,
  Transforms,
  createEditor,
} from "slate";
import { withHistory } from "slate-history";
import { Editable, Slate, withReact } from "slate-react";
import { EditableProps } from "slate-react/dist/components/editable";
import { Toolbar } from "./Toolbar";
import { htmlToSlate } from "./html-serializer";
import { renderElement, renderLeaf, renderPlaceholder } from "./render";
import { toggleMark, wrapLink } from "./utils";

const ChakraEditable = chakra(Editable);

function withInlines(editor: Editor) {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element: SlateElement) =>
    element.type === "link" || isInline(element);

  editor.insertText = (text: string) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data: DataTransfer) => {
    const html = data.getData("text/html");
    const text = data.getData("text/plain");

    if (html) {
      Transforms.insertFragment(editor, htmlToSlate(html));
    } else if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
}

// @refresh reset
const HOTKEYS: { [hotkey: string]: string } = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

interface RichTextBlockProps {
  value: Descendant[];
  onChange: (newValue: Descendant[]) => void;
  editor?: Editor;
}

export type RichTextEditorProps = RichTextBlockProps &
  Omit<EditableProps, "value" | "onChange">;

export const RichTextEditor = forwardRef(
  (
    {
      readOnly = false,
      value,
      onChange,
      editor: baseEditor,
      ...props
    }: RichTextEditorProps,
    ref,
  ) => {
    const editor = useMemo(
      () => withInlines(withHistory(withReact(baseEditor ?? createEditor()))),
      [baseEditor],
    );

    const onKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        const { selection } = editor;

        for (const hotkey in HOTKEYS) {
          if (isHotkey(hotkey, event)) {
            event.preventDefault();
            const mark = HOTKEYS[hotkey];
            toggleMark(editor, mark);
          }
        }

        // Default left/right behavior is unit:'character'.
        // This fails to distinguish between two cursor positions, such as
        // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
        // Here we modify the behavior to unit:'offset'.
        // This lets the user step into and out of the inline without stepping over characters.
        // You may wish to customize this further to only use unit:'offset' in specific cases.
        if (selection && Range.isCollapsed(selection)) {
          const { nativeEvent } = event;
          if (isHotkey("left", nativeEvent)) {
            event.preventDefault();
            Transforms.move(editor, { unit: "offset", reverse: true });
            return;
          }
          if (isHotkey("right", nativeEvent)) {
            event.preventDefault();
            Transforms.move(editor, { unit: "offset" });
            return;
          }
        }
      },
      [editor],
    );

    return (
      <Box ref={ref}>
        <Slate editor={editor} initialValue={value} onChange={onChange}>
          {!readOnly && <Toolbar />}
          <ChakraEditable
            onKeyDown={onKeyDown}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            renderPlaceholder={renderPlaceholder}
            placeholder="Enter some rich text…"
            spellCheck
            minHeight={48}
            resize="vertical"
            paddingX={!readOnly ? 2 : 0}
            paddingY={!readOnly ? 2 : 0}
            readOnly={readOnly}
            {...props}
          />
        </Slate>
      </Box>
    );
  },
);
