import { Input } from "@chakra-ui/react";

const csrfInputName = "csrfmiddlewaretoken";
const csrfTokenField = document.getElementsByName(csrfInputName)[0] as
  | HTMLInputElement
  | undefined;
const csrfToken = csrfTokenField?.value;
if (csrfTokenField) {
  csrfTokenField?.remove();
}

export function CSRFTokenInput() {
  return <Input type="hidden" name={csrfInputName} value={csrfToken} />;
}
