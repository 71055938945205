import { Navigate } from "react-router-dom";
import { useApplicationDetailsContext } from "./ApplicationDetailsContext";

export function ApplicationConfiguration() {
  const { application } = useApplicationDetailsContext();

  return (
    <Navigate to={`/control-panel/applications/${application.id}/builds`} />
  );
}
