export const namedRoutes = {
  landing: "/control-panel/",
  dashboard: "/control-panel/dashboard/",
  login: "/control-panel/login",
  oauth2Callback: "/control-panel/oauth2/callback",
  logout: "/control-panel/logout",
  logoutSuccessful: "/auth/logout",
  application: {
    list: "/control-panel/applications",
    overview: "/control-panel/applications/:applicationId",
    activity: "/control-panel/applications/:applicationId/activity",
    configuration: "/control-panel/applications/:applicationId/configuration", // legacy url, use builds now
    builds: "/control-panel/applications/:applicationId/builds",
    access: "/control-panel/applications/:applicationId/access",
    integrations: "/control-panel/applications/:applicationId/integrations",
    categorization: "/control-panel/applications/:applicationId/categorization",
    cloudRendering:
      "/control-panel/applications/:applicationId/cloud-rendering",
  },
  applicationCategory: {
    list: "/control-panel/applications/categories",
    overview: "/control-panel/applications/categories/:applicationCategoryId",
  },
  user: {
    list: "/control-panel/users",
    invitations: "/control-panel/users/invitations",
    overview: "/control-panel/users/:userId",
    activity: "/control-panel/users/:userId/activity",
    access: "/control-panel/users/:userId/access",
  },
  userGroup: {
    list: "/control-panel/users/groups",
    overview: "/control-panel/users/groups/:groupId",
    activity: "/control-panel/users/groups/:groupId/activity",
    systemAccess: "/control-panel/users/groups/:groupId/platform-permissions",
    access: "/control-panel/users/groups/:groupId/access",
  },
  organization: {
    overview: "/control-panel/organization",
    settings: "/control-panel/organization/settings",
    select: "/control-panel/core/organizations/select/",
  },
  cloudRenderingSettings: "/control-panel/cloud-rendering",
  session: { list: "/control-panel/sessions" },
  changePassword: {
    request: "/auth/change-password/",
    done: "/auth/change-password/done/",
  },
  organizationalLogin: "/auth/organizational-login/*",
  resetPassword: {
    complete: "/auth/reset-password/complete/",
    request: "/auth/reset-password/",
    set: "/auth/reset-password/set/",
    requested: "/auth/reset-password/done/",
  },
  setupAccount: "/auth/setup-account/",
  accessDenied: "/control-panel/access-denied/",
  sso: {
    collectEmail: "/auth/sso/collect-email/",
    validateEmail: "/auth/sso/validate-email/",
    error: "/auth/sso/error/",
  },
  oauth: {
    authorize: "/oauth/authorize/",
    authorizationSuccess: "/oauth/authorize/success/",
    authorizationError: "/oauth/authorize/error/",
  },
  privacy: {
    consent: "/auth/terms/consent",
  },
} as const;

export const routerBasename = "";
