import {
  FormHelperText,
  Heading,
  HStack,
  InputGroup,
  InputRightElement,
  Skeleton,
  Spacer,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { array, InferType, object, string } from "yup";
import { DeleteButton, OptionsButton } from "../components";
import { usePromptDirtyForm } from "../hooks";
import {
  getQueryKeyForApplicationCategory,
  useApplicationCategoryQuery,
} from "../hooks/useApplicationCategoriesQuery";
import { useApplicationCategoryDeleteConfirm } from "./hooks/useApplicationCategoryDeleteConfirm";

import {
  Alert,
  AlertIcon,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback, useEffect } from "react";
import { updateApplicationCategory } from "../backend/api";
import { DevTool } from "../hookform-devtools";
import { ApplicationCategoryMemberships } from "./components/ApplicationCategoryMemberships";

const applicationCategoryInfoSchema = object({
  name: string()
    .defined()
    .required()
    .min(3, "Name must be at least 3 characters long")
    .max(100, "Name cannot be longer than 100 characters."),
  applications: array(string().uuid().defined()).defined().default([]),
}).noUnknown();

type ApplicationCategoryInfoSchema = InferType<
  typeof applicationCategoryInfoSchema
>;

export function ApplicationCategoryDetails() {
  const { applicationCategoryId = "" } = useParams();
  const { data: applicationCategory, isSuccess } = useApplicationCategoryQuery(
    Number(applicationCategoryId),
    {
      retry: false,
      throwOnError: true,
    },
  );
  const confirmApplicationCategoryDeletion =
    useApplicationCategoryDeleteConfirm();
  const { handleSubmit, formState, register, control, reset, watch } =
    useForm<ApplicationCategoryInfoSchema>({
      resolver: yupResolver(applicationCategoryInfoSchema),
      mode: "onChange",
      defaultValues: applicationCategory,
    });
  useEffect(() => {
    applicationCategory && reset(applicationCategory);
  }, [applicationCategory, reset]);
  const queryClient = useQueryClient();

  const { mutateAsync, error } = useMutation<
    ApplicationCategoryInfoSchema,
    AxiosError,
    ApplicationCategoryInfoSchema & { id: number }
  >({
    mutationFn: async ({ id: categoryId, ...values }) =>
      await updateApplicationCategory(categoryId, values),
    // make sure data is reloaded after modification
    onSuccess: (_, category) => {
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationCategory(category.id),
      });
      reset(category);
    },
  });

  usePromptDirtyForm({ formState });

  const onSubmit = useCallback(
    (values: ApplicationCategoryInfoSchema) =>
      mutateAsync({ id: parseInt(applicationCategoryId), ...values }),
    [applicationCategoryId, mutateAsync],
  );

  // Debounced callback
  const autoSave = useDebouncedCallback(() => {
    handleSubmit(onSubmit)();
  }, 1000);

  // auto submit form if category name changes, see https://stackoverflow.com/a/70119332/1142028
  useEffect(() => {
    const subscription = watch((data, info) => {
      if (info.name === "name" && info.type === "change") {
        autoSave();
      }
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, autoSave]);

  return (
    <Stack spacing={6}>
      <HStack>
        <Skeleton isLoaded={isSuccess}>
          <Heading>{applicationCategory?.name}&apos;s details</Heading>
        </Skeleton>
        <Spacer />
        <OptionsButton label="Click on this button to display user actions">
          <DeleteButton
            onClick={confirmApplicationCategoryDeletion(applicationCategory)}
          >
            Delete Category
          </DeleteButton>
        </OptionsButton>
      </HStack>
      {applicationCategory ? (
        <>
          <form>
            <Stack spacing={4}>
              <FormControl isInvalid={!!formState.errors.name} flexGrow={1}>
                <FormLabel htmlFor="name">Name</FormLabel>
                <InputGroup>
                  <Input {...register("name")} />
                  <InputRightElement>
                    <Spinner
                      display={formState.isSubmitting ? undefined : "none"}
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {formState.errors.name?.message}
                </FormErrorMessage>
              </FormControl>

              {error && (
                <Alert status="error" variant="subtle">
                  <AlertIcon />
                  {error.message}
                </Alert>
              )}

              <FormControl flexGrow={1}>
                <FormLabel>Applications</FormLabel>
                <Stack spacing={2}>
                  <FormHelperText>
                    The following applications are part of this category. They
                    will be shown to users in the order defined here. You can
                    drag and drop them to reorder them.
                  </FormHelperText>
                </Stack>
              </FormControl>
              <ApplicationCategoryMemberships
                applicationCategoryId={parseInt(applicationCategoryId)}
              />
            </Stack>
          </form>
          <DevTool control={control} placement="bottom-left" />
        </>
      ) : (
        <Spinner />
      )}
    </Stack>
  );
}
