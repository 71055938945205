import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  useMergeRefs,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Ref, useCallback, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { InferType, array, number, object, string } from "yup";
import { ValidationErrorResponse } from "../../backend";
import { inviteUserToOrganization } from "../../backend/api";
import { FormRootErrorMessage, FormSubmitButton } from "../../components";
import { useUserGroupsQuery } from "../../dashboard/hooks/useUserGroups";
import { DevTool } from "../../hookform-devtools";
import { useActiveOrganizationQuery } from "../../hooks";
import { extractFormSubmissionErrors } from "../../utils/extractFormSubmissionErrors";
import { UserGroupMultiSelect } from "./UserGroupMultiSelect";

const userInvitationSchema = object({
  email: string()
    .email("A valid e-mail address is required.")
    .required("E-mail cannot be blank."),
  groups: array().of(number().defined()).default([]),
}).noUnknown();

type UserInvitationSchema = InferType<typeof userInvitationSchema>;

export function UserInvitationForm({
  firstFieldRef,
  onSuccess,
}: {
  firstFieldRef?: Ref<HTMLInputElement>;
  onSuccess?: () => void;
}) {
  const userGroupsQuery = useUserGroupsQuery();
  const knownUserGroups = useMemo(
    () =>
      userGroupsQuery.data?.results
        // filter out the everyone group (it will anyhow be assigned)
        .filter((grp) => grp.name !== "Everyone") ?? [],
    [userGroupsQuery.data],
  );
  const toast = useToast({ position: "top" });
  const {
    control,
    formState,
    handleSubmit,
    register,
    watch,
    setError,
    reset,
    setValue,
  } = useForm<UserInvitationSchema>({
    mode: "onSubmit",
    resolver: yupResolver(
      userInvitationSchema.concat(
        object({
          groups: array()
            .of(
              number()
                .oneOf(
                  knownUserGroups.map((group) => group.id),
                  "Not a known group.",
                )
                .defined(),
            )
            .default([]),
        }),
      ),
    ),
  });
  const inputRef = useMergeRefs(register("email").ref, firstFieldRef);
  const organizationQuery = useActiveOrganizationQuery();
  const queryClient = useQueryClient();
  const { mutateAsync, ...createUserInvitationMutation } = useMutation<
    void,
    AxiosError<ValidationErrorResponse>,
    UserInvitationSchema
  >({
    mutationFn: async ({ email, groups }) => {
      await inviteUserToOrganization(
        email,
        organizationQuery.data ? organizationQuery.data.id : 0,
        groups,
      );
    },
    onSuccess: (_, { email }) => {
      toast({
        title: "User invited",
        status: "success",
        description: `User ${email} has been invited to join the organization.`,
        duration: 5000,
        isClosable: true,
      });
      reset();
      queryClient.invalidateQueries({ queryKey: ["pendingInvitations"] });
      onSuccess?.();
    },
    onError: (error) => {
      if (error.response?.status === 400) {
        if (error.response.data.email) {
          setError("email", {
            message: error.response.data.email.join(", "),
          });
        } else if (error.response.data.non_field_errors) {
          setError("email", {
            message: error.response.data.non_field_errors.join(", "),
          });
        }
      } else if (error.response?.status === 500) {
        setError("root", {
          type: "server",
          message: "An unexpected error occurred. Please try again later.",
        });
      }
    },
  });
  const onSubmit = useCallback(
    async (values: UserInvitationSchema) => {
      try {
        await mutateAsync(values);
      } catch (err) {
        extractFormSubmissionErrors(err, userInvitationSchema).forEach(
          ([field, message]) => setError(field, { type: "server", message }),
        );
      }
    },
    [mutateAsync, setError],
  );

  useEffect(() => {
    // reset the mutation errors whenever data has changed
    const subscription = watch(() => {
      if (createUserInvitationMutation.isError) {
        createUserInvitationMutation.reset();
      }
      return () => subscription.unsubscribe();
    });
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <FormControl isInvalid={!!formState.errors.email}>
          <FormLabel htmlFor="email">E-mail address</FormLabel>
          <Input
            placeholder="Enter the e-mail address to invite..."
            id="email"
            {...register("email")}
            ref={inputRef}
          />
          <FormHelperText>
            The user will receive an invitation via e-mail to join the
            organization. Once the user accepted the invitation, you can manage
            their permissions and access.
          </FormHelperText>
          <FormErrorMessage>{formState.errors.email?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!formState.errors.groups}>
          <FormLabel htmlFor="groups">User Groups</FormLabel>
          <Controller
            name="groups"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <UserGroupMultiSelect
                isLoading={userGroupsQuery.isLoading}
                userGroups={knownUserGroups.filter(
                  (group) => !field.value.find((g) => g === group.id),
                )}
                size="sm"
                {...field}
                value={field.value.map((userGroupId) => {
                  const userGroup = knownUserGroups.find(
                    (g) => g.id === userGroupId,
                  )!;
                  return {
                    value: userGroup,
                    label: userGroup.name,
                  };
                })}
                onChange={(values) =>
                  setValue(
                    "groups",
                    values.map((opt) => opt.value.id),
                    { shouldValidate: true },
                  )
                }
              />
            )}
          />
          <FormHelperText>
            You can optionally invite the users to a set of user groups to get
            them started with some access.
          </FormHelperText>
          <FormErrorMessage>
            {formState.errors.groups?.message}
          </FormErrorMessage>
        </FormControl>
        <FormRootErrorMessage formState={formState} />
        <FormSubmitButton formState={formState} alignSelf="end" />
      </Stack>
      <DevTool control={control} />
    </form>
  );
}
