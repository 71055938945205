import { AddIcon, CloseIcon, Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Skeleton,
  Spacer,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  UseTabProps,
  useDisclosure,
  useMultiStyleConfig,
  useTab,
  useToast,
} from "@chakra-ui/react";
import { QueryKey, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import {
  LuBan,
  LuRefreshCw,
  LuRefreshCwOff,
  LuTrendingUp,
} from "react-icons/lu";
import { partition } from "remeda";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";
import {
  triggerApplicationBuildMetadataDetection,
  triggerApplicationBuildValidationAndOptimization,
  updateApplicationLaunchConfiguration,
} from "../backend/api";
import {
  ApplicationBuild,
  ApplicationBuildId,
  ApplicationLaunchConfiguration,
  PortalBackendPaginatedResult,
  PortalError,
  XRPlatformType,
  xrPlatformTypes,
} from "../backend/types";
import { NoData, OptionsButton } from "../components";
import { SwapIcon, UploadIcon } from "../components/icons";
import { useConfirm } from "../confirm-dialog";
import {
  getQueryKeyForApplicationBuild,
  useApplicationBuildQuery,
} from "../hooks/useApplicationBuildsQuery";
import { useIsSuperuser } from "../hooks/usePermissions";
import { usePlatformsQueryParam } from "../hooks/usePlatformsSupportQueryParams";
import { queryClient } from "../queryClient";
import { AddApplicationBuildWizard } from "./AddApplicationBuildWizard";
import { ApplicationBuildDeploymentInstructions } from "./ApplicationBuildDeploymentInstructions";
import { ApplicationBuildEditor } from "./ApplicationBuildEditor";
import { useApplicationDetailsContext } from "./ApplicationDetailsContext";
import {
  ApplicationBuildDisplay,
  ApplicationBuildsTable,
  LazyApplicationBuildDisplay,
  XRPlatformsDisplay,
} from "./components";
import {
  getQueryKeyForApplicationLaunchConfigurationsQuery,
  useApplicationLaunchConfigurationsQuery,
} from "./hooks/useApplicationLaunchConfigurationsQuery";

const AddBuildTab = forwardRef<HTMLButtonElement, UseTabProps>(
  ({ onClick, ...props }, ref) => {
    const tabProps = useTab({ ...props, ref });

    const styles = useMultiStyleConfig("Tabs", tabProps);

    return (
      <Button __css={styles.tab} {...tabProps} onClick={onClick}>
        {tabProps.children}
      </Button>
    );
  },
);

export function ApplicationBuilds() {
  const [showUnsupportedPlatforms, setShowUnsupportedPlatforms] = useQueryParam(
    "showUnsupportedPlatforms",
    withDefault(BooleanParam, false),
  );
  const isSuperuser = useIsSuperuser();
  const [activeXRPlatforms, setActiveXRPlatforms] = usePlatformsQueryParam();
  const [previousApplicationBuild, setPreviousApplicationBuild] = useState<
    ApplicationBuild | undefined | null
  >();
  const { application } = useApplicationDetailsContext();
  const toast = useToast();
  const { confirm } = useConfirm();
  const applicationLaunchConfigurationsQuery =
    useApplicationLaunchConfigurationsQuery(application.id, {
      throwOnError: true,
      select: (data) => {
        // merge windows launch configurations if they use the same build
        const [windowsLaunchConfigurations, otherLaunchConfigurations] =
          partition(data.results, ({ xr_platform: type }) =>
            type.startsWith("win"),
          );

        const result: [
          ApplicationLaunchConfiguration["xr_platform"][],
          ApplicationBuildId | null,
        ][] = [];
        // merge the windows xr platforms if they share the same build
        if (
          windowsLaunchConfigurations.length > 1 &&
          windowsLaunchConfigurations
            .map(({ application_build }) => application_build)
            .every(
              (buildId) =>
                buildId === windowsLaunchConfigurations[0].application_build,
            )
        ) {
          result.push([
            windowsLaunchConfigurations.map(({ xr_platform: type }) => type),
            windowsLaunchConfigurations[0].application_build,
          ]);
        } else {
          windowsLaunchConfigurations
            .sort((a, b) => a.xr_platform.localeCompare(b.xr_platform))
            .forEach(({ xr_platform: type, application_build }) => {
              result.push([[type], application_build]);
            });
        }

        // re-add the other launch configurations
        otherLaunchConfigurations
          .sort((a, b) => a.xr_platform.localeCompare(b.xr_platform))
          .forEach(({ xr_platform: type, application_build }) => {
            result.push([[type], application_build]);
          });

        // move unsupported platforms to the end
        return result?.sort(([, buildA], [, buildB]) => {
          if (buildA === null) {
            return 1;
          }

          if (buildB === null) {
            return -1;
          }

          return 0;
        });
      },
    });
  const filteredLaunchConfigurations = useMemo(() => {
    const result = applicationLaunchConfigurationsQuery.data ?? [];

    if (showUnsupportedPlatforms) {
      const currentlySupportedXRPlatforms =
        result
          .map(([xrPlatforms]) => xrPlatforms)
          .flat()
          .sort((a, b) => a.localeCompare(b)) ?? [];
      const currentlyUnsupportedXRPlatforms = xrPlatformTypes.filter(
        (type) => !currentlySupportedXRPlatforms.includes(type),
      );
      currentlyUnsupportedXRPlatforms.forEach((type) => {
        result.push([[type], null]);
      });
    }

    return applicationLaunchConfigurationsQuery.data ?? [];
  }, [applicationLaunchConfigurationsQuery.data, showUnsupportedPlatforms]);
  const addApplicationBuildDialogState = useDisclosure();
  const changeApplicationBuildDialogState = useDisclosure();
  // the currently selected / shown application build
  const activeApplicationBuildId = useMemo(
    () =>
      applicationLaunchConfigurationsQuery.data?.find(([types]) =>
        types.includes(activeXRPlatforms[0]),
      )?.[1] ?? undefined,
    [activeXRPlatforms, applicationLaunchConfigurationsQuery.data],
  );
  const activeApplicationBuildQuery = useApplicationBuildQuery(
    activeApplicationBuildId ?? 0,
    { enabled: !!activeApplicationBuildId },
  );
  const closeUploadDialog = useCallback(() => {
    addApplicationBuildDialogState.onClose();
  }, [addApplicationBuildDialogState]);
  const triggerApplicationBuildMetadataRefreshMutation = useMutation<
    void,
    AxiosError<PortalError>,
    ApplicationBuildId
  >({
    mutationFn: async (applicationBuildId) => {
      await triggerApplicationBuildMetadataDetection(applicationBuildId);
      // wait for the metadata to be updated, could take a while since this process is async
      await new Promise((resolve) => setTimeout(resolve, 5000));
    },
    onSettled: (_, __, applicationBuildId) => {
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationBuild(applicationBuildId),
      });
    },
  });
  const triggerApplicationBuildOptimizationMutation = useMutation<
    void,
    AxiosError<PortalError>,
    ApplicationBuildId
  >({
    mutationFn: async (applicationBuildId) => {
      await triggerApplicationBuildValidationAndOptimization(
        applicationBuildId,
      );
      // wait for the optimization to be run, could take a while since this process is async
      await new Promise((resolve) => setTimeout(resolve, 60000));
    },
    onSettled: (_, __, applicationBuildId) => {
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationBuild(applicationBuildId),
      });
    },
  });
  const {
    mutateAsync: updateLaunchConfigurationAsync,
    ...updateLaunchConfigurationMutation
  } = useMutation<
    void,
    AxiosError<PortalError>,
    Pick<
      ApplicationLaunchConfiguration,
      "application" | "application_build"
    > & { xrPlatforms: XRPlatformType[] },
    | {
        previousConfiguration?: PortalBackendPaginatedResult<ApplicationLaunchConfiguration>;
        queryKey: QueryKey;
      }
    | undefined
  >({
    mutationFn: async ({
      application: applicationId,
      xrPlatforms,
      ...data
    }) => {
      await Promise.all(
        xrPlatforms.map((xrPlatform) =>
          updateApplicationLaunchConfiguration(applicationId, xrPlatform, data),
        ),
      );
    },
    onMutate: async (data) => {
      const queryKey: QueryKey =
        getQueryKeyForApplicationLaunchConfigurationsQuery(data.application);
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey,
      });

      // Snapshot the previous value
      const previousConfiguration =
        queryClient.getQueryData<
          PortalBackendPaginatedResult<ApplicationLaunchConfiguration>
        >(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData<
        PortalBackendPaginatedResult<ApplicationLaunchConfiguration>
      >(queryKey, (old) =>
        old
          ? {
              ...old,
              results: old.results.map<ApplicationLaunchConfiguration>(
                (result) => {
                  if (data.xrPlatforms.includes(result.xr_platform)) {
                    return {
                      ...result,
                      application_build: data.application_build,
                    };
                  }

                  return result;
                },
              ),
            }
          : undefined,
      );

      // Return a context object with the snapshotted value
      return { previousConfiguration, queryKey };
    },
    onSuccess: (_, data) => {
      toast({
        title: "Build updated",
        description: data.application_build ? (
          <Text>
            Now using build{" "}
            <LazyApplicationBuildDisplay
              applicationBuildId={data.application_build}
            />{" "}
            for <XRPlatformsDisplay xrPlatforms={data.xrPlatforms} /> .
          </Text>
        ) : (
          <Text>
            {application.name} no longer supports{" "}
            <XRPlatformsDisplay xrPlatforms={data.xrPlatforms} />.
          </Text>
        ),
        status: "success",
        duration: 3500,
        isClosable: true,
        position: "top-right",
      });

      applicationLaunchConfigurationsQuery.refetch();

      if (data.application_build === null) {
        // reset the selected platform if the build was removed
        setActiveXRPlatforms([]);
      }
    },
    onError: (error, _, context) => {
      // roll back optimistic update
      context?.queryKey &&
        queryClient.setQueryData<
          PortalBackendPaginatedResult<ApplicationLaunchConfiguration>
        >(context?.queryKey, context?.previousConfiguration);
    },
  });

  useEffect(() => {
    // show unsupported platforms automatically if there are launch configurations but no active builds for any of those
    if (
      applicationLaunchConfigurationsQuery.isSuccess &&
      !showUnsupportedPlatforms &&
      applicationLaunchConfigurationsQuery.data?.length &&
      applicationLaunchConfigurationsQuery.data?.every(
        ([, applicationBuildId]) => !applicationBuildId,
      ) &&
      !activeXRPlatforms.length
    ) {
      setShowUnsupportedPlatforms(true);
    }
  }, [
    activeXRPlatforms.length,
    applicationLaunchConfigurationsQuery.data,
    applicationLaunchConfigurationsQuery.isSuccess,
    setShowUnsupportedPlatforms,
    showUnsupportedPlatforms,
  ]);

  useEffect(() => {
    // automatically select first build if none is selected
    if (
      !activeXRPlatforms.length &&
      applicationLaunchConfigurationsQuery.isSuccess
    ) {
      setActiveXRPlatforms(filteredLaunchConfigurations[0]?.[0]);
    }
  }, [
    activeXRPlatforms,
    applicationLaunchConfigurationsQuery.data,
    applicationLaunchConfigurationsQuery.isSuccess,
    filteredLaunchConfigurations,
    setActiveXRPlatforms,
  ]);

  const removeLaunchModeHandler = useCallback(
    (xrPlatforms: XRPlatformType[]) =>
      confirm({
        title: "Confirm removal of platform support",
        body: (
          <Stack>
            <Text>
              You&apos;re about to change your application&apos;s settings to
              drop support for <XRPlatformsDisplay xrPlatforms={xrPlatforms} />.
              Users will no longer be able to launch your application on these
              platforms.
            </Text>
            <Text>
              Please note that this will{" "}
              <b>not delete uploaded application builds</b>.
            </Text>
            <Divider />
            <Text color="chakra-subtle-text">
              Since the builds are not deleted, you can restore support for{" "}
              <XRPlatformsDisplay xrPlatforms={xrPlatforms} /> later on, if you
              want to.
            </Text>
          </Stack>
        ),
        confirmButtonText: (
          <Text>
            Drop support for <XRPlatformsDisplay xrPlatforms={xrPlatforms} />
          </Text>
        ),
        cancelButtonText: "Cancel",
        onConfirm: async () => {
          await updateLaunchConfigurationAsync({
            application: application.id,
            xrPlatforms: xrPlatforms,
            application_build: null,
          });
        },
      }),
    [application.id, confirm, updateLaunchConfigurationAsync],
  );

  const tabIndex = useMemo(
    () =>
      filteredLaunchConfigurations?.findIndex(([xrPlatforms]) =>
        activeXRPlatforms.every((type) => xrPlatforms.includes(type)),
      ) ?? 0,
    [activeXRPlatforms, filteredLaunchConfigurations],
  );

  useEffect(() => {
    // reset tab index if the tab doesn't exist (anymore)
    if (tabIndex === -1 && applicationLaunchConfigurationsQuery.isSuccess) {
      setActiveXRPlatforms(filteredLaunchConfigurations[0]?.[0]);
    }
  }, [
    tabIndex,
    applicationLaunchConfigurationsQuery.data,
    setActiveXRPlatforms,
    filteredLaunchConfigurations,
    applicationLaunchConfigurationsQuery.isSuccess,
  ]);

  if (applicationLaunchConfigurationsQuery.isLoading) {
    return <CircularProgress isIndeterminate color="brand.300" size={12} />;
  }

  return (
    <>
      {Object.keys(applicationLaunchConfigurationsQuery.data ?? {}).length ===
      0 ? (
        <NoData
          alignSelf={"baseline"}
          maxHeight={"lg"}
          title="No Builds."
          text="No builds have been uploaded for this application (yet)."
          callToAction={
            <Button
              leftIcon={<Icon as={UploadIcon} />}
              onClick={uploadNewBuild.bind(null, null)}
              colorScheme="brand"
            >
              Upload build
            </Button>
          }
        />
      ) : (
        <>
          <HStack w="full">
            <Text whiteSpace={"nowrap"}>
              The application currently offers builds for the following
              platforms.
            </Text>
            <Spacer />
            <FormControl display="flex" alignItems="center" width="auto">
              <FormLabel htmlFor="show-unsupported-platforms" mb="0">
                Show platforms without builds?
              </FormLabel>
              <Switch
                id="show-unsupported-platforms"
                isChecked={showUnsupportedPlatforms}
                onChange={() => setShowUnsupportedPlatforms((value) => !value)}
              />
            </FormControl>
          </HStack>
          <Tabs
            colorScheme="brand"
            isLazy
            index={tabIndex}
            onChange={(index) => {
              if (index === applicationLaunchConfigurationsQuery.data?.length) {
                return;
              }

              setActiveXRPlatforms(
                applicationLaunchConfigurationsQuery.data?.[index]?.[0],
              );
            }}
          >
            <TabList>
              {filteredLaunchConfigurations.map(
                ([xrPlatforms, applicationBuildId], index) => (
                  <Tab
                    fontSize={"sm"}
                    cursor="pointer"
                    as="div"
                    _focusVisible={{ boxShadow: "none" }}
                    key={xrPlatforms.join("_")}
                    color={
                      !applicationBuildId && index !== tabIndex
                        ? "chakra-subtle-text"
                        : undefined
                    }
                  >
                    <HStack
                      as="span"
                      display="inline-flex"
                      role="group"
                      alignItems={"center"}
                    >
                      <XRPlatformsDisplay xrPlatforms={xrPlatforms} />
                      {applicationBuildId && (
                        <Tooltip
                          label={
                            <HStack>
                              <Text>Remove support for</Text>
                              <XRPlatformsDisplay xrPlatforms={xrPlatforms} />
                            </HStack>
                          }
                        >
                          <IconButton
                            _groupHover={{ opacity: 1 }}
                            opacity={index !== tabIndex ? 0 : 1}
                            size="xs"
                            variant="ghost"
                            aria-label="Remove support for build type"
                            icon={<CloseIcon boxSize={2} />}
                            onClick={() => {
                              removeLaunchModeHandler(xrPlatforms);
                            }}
                          />
                        </Tooltip>
                      )}
                    </HStack>
                  </Tab>
                ),
              )}
              {showUnsupportedPlatforms === false && (
                <AddBuildTab
                  isDisabled={true}
                  onClick={uploadNewBuild.bind(null, null)}
                >
                  <HStack>
                    <AddIcon boxSize={2} />
                    <Text>Add platform</Text>
                  </HStack>
                </AddBuildTab>
              )}
            </TabList>
            <TabPanels>
              {applicationLaunchConfigurationsQuery.data?.map(
                ([xrPlatforms, applicationBuildId]) => (
                  <TabPanel key={applicationBuildId} paddingX={0}>
                    <Stack spacing={4}>
                      <HStack paddingLeft={4}>
                        <Heading as="h3" size="sm">
                          {activeApplicationBuildQuery.isSuccess ? (
                            <ApplicationBuildDisplay
                              applicationBuild={
                                activeApplicationBuildQuery.data
                              }
                            />
                          ) : !applicationBuildId ? (
                            <Text>
                              Available builds supporting{" "}
                              <XRPlatformsDisplay xrPlatforms={xrPlatforms} />:
                            </Text>
                          ) : (
                            <Skeleton width={36} height={2} />
                          )}
                        </Heading>
                        <Spacer />

                        <ButtonGroup size="sm" isAttached variant="outline">
                          <Button
                            variant="solid"
                            leftIcon={<Icon as={UploadIcon} />}
                            onClick={uploadNewBuild.bind(
                              null,
                              activeApplicationBuildQuery.data,
                            )}
                          >
                            Upload new build
                          </Button>
                          {applicationBuildId && isSuperuser && (
                            <OptionsButton
                              variant="outline"
                              label="Click on this button to display poweruser actions"
                              borderRight={"none"}
                            >
                              <Text
                                textAlign={"center"}
                                width={"full"}
                                fontSize="sm"
                                fontWeight="bold"
                              >
                                Poweruser Actions
                              </Text>
                              <Button
                                fontWeight="normal"
                                fontSize="sm"
                                variant="ghost"
                                isDisabled={
                                  triggerApplicationBuildMetadataRefreshMutation
                                    .error?.response?.status === 429
                                }
                                onClick={() =>
                                  triggerApplicationBuildMetadataRefreshMutation.mutate(
                                    applicationBuildId,
                                  )
                                }
                                leftIcon={
                                  <Icon
                                    as={
                                      triggerApplicationBuildMetadataRefreshMutation
                                        .error?.response?.status === 429
                                        ? LuRefreshCwOff
                                        : LuRefreshCw
                                    }
                                    boxSize={3}
                                  />
                                }
                                isLoading={
                                  triggerApplicationBuildMetadataRefreshMutation.isPending
                                }
                                width={"full"}
                              >
                                {triggerApplicationBuildMetadataRefreshMutation
                                  .error?.response?.status === 429
                                  ? "Try again in an hour."
                                  : "Refresh Metadata"}
                              </Button>
                              <Button
                                width={"full"}
                                fontWeight="normal"
                                fontSize="sm"
                                variant="ghost"
                                isDisabled={
                                  triggerApplicationBuildOptimizationMutation
                                    .error?.response?.status === 429
                                }
                                onClick={() =>
                                  triggerApplicationBuildOptimizationMutation.mutate(
                                    applicationBuildId,
                                  )
                                }
                                leftIcon={
                                  <Icon
                                    as={
                                      triggerApplicationBuildOptimizationMutation
                                        .error?.response?.status === 429
                                        ? LuBan
                                        : LuTrendingUp
                                    }
                                    boxSize={3}
                                  />
                                }
                                isLoading={
                                  triggerApplicationBuildOptimizationMutation.isPending
                                }
                              >
                                {triggerApplicationBuildOptimizationMutation
                                  .error?.response?.status === 429
                                  ? "Try again in an hour."
                                  : "Optimize Archive"}
                              </Button>
                            </OptionsButton>
                          )}
                          {activeApplicationBuildQuery.isSuccess && (
                            <Button
                              fontWeight="normal"
                              fontSize="sm"
                              onClick={changeApplicationBuildDialogState.onOpen}
                              leftIcon={<Icon as={SwapIcon} boxSize={4} />}
                            >
                              Change build
                            </Button>
                          )}
                        </ButtonGroup>
                      </HStack>
                      {applicationBuildId ? (
                        <>
                          <ApplicationBuildEditor
                            applicationBuildId={applicationBuildId}
                          />
                          {
                            <ApplicationBuildDeploymentInstructions
                              applicationBuildId={applicationBuildId}
                            />
                          }
                        </>
                      ) : (
                        <Box paddingLeft={4}>
                          <ApplicationBuildsTable
                            applicationId={application.id}
                            xrPlatforms={xrPlatforms}
                            onChange={(applicationBuild) => {
                              applicationBuild &&
                                updateLaunchConfigurationMutation.mutate({
                                  application: application.id,
                                  xrPlatforms: xrPlatforms,
                                  application_build: applicationBuild.id,
                                });
                            }}
                            uploadNewBuild={uploadNewBuild.bind(
                              null,
                              undefined,
                            )}
                          />
                        </Box>
                      )}
                    </Stack>
                  </TabPanel>
                ),
              )}
            </TabPanels>
          </Tabs>
        </>
      )}
      <Drawer
        closeOnOverlayClick={false}
        closeOnEsc={false}
        size={"lg"}
        {...addApplicationBuildDialogState}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            Upload new build for {application.name}
          </DrawerHeader>

          <DrawerBody>
            <AddApplicationBuildWizard
              key={
                previousApplicationBuild === null
                  ? "new"
                  : activeXRPlatforms.join("_") +
                    (previousApplicationBuild
                      ? "_" + previousApplicationBuild?.id
                      : "")
              }
              application={application}
              buildSeedData={
                // if an application build is provided, data will be taken from that build;
                // if null, a new build without any base data will be created;
                // if undefined, a new build with the same supported platforms as the active ones will be created
                previousApplicationBuild === null
                  ? undefined
                  : (previousApplicationBuild ?? {
                      supported_xr_platforms: activeXRPlatforms,
                    })
              }
              onCompleted={closeUploadDialog}
            />
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" mr={3} onClick={closeUploadDialog}>
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Drawer size={"xl"} {...changeApplicationBuildDialogState}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            Use a different build for{" "}
            <XRPlatformsDisplay xrPlatforms={activeXRPlatforms} />
          </DrawerHeader>

          <DrawerBody>
            <Stack spacing={4}>
              <Text>
                The following builds support{" "}
                <XRPlatformsDisplay xrPlatforms={activeXRPlatforms} />:
              </Text>
              <ApplicationBuildsTable
                activeApplicationBuildId={activeApplicationBuildId}
                applicationId={application.id}
                xrPlatforms={activeXRPlatforms}
                onChange={(applicationBuild) => {
                  applicationBuild &&
                    updateLaunchConfigurationMutation.mutate({
                      application: application.id,
                      xrPlatforms: activeXRPlatforms,
                      application_build: applicationBuild.id,
                    });
                }}
              />
            </Stack>
          </DrawerBody>

          <DrawerFooter>
            <Button
              variant="outline"
              onClick={changeApplicationBuildDialogState.onClose}
            >
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );

  function uploadNewBuild(
    previousVersion: ApplicationBuild | undefined | null,
  ) {
    setPreviousApplicationBuild(previousVersion);
    addApplicationBuildDialogState.onOpen();
  }
}
