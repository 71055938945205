import { Application } from "../../backend";
import { ExternalLinkButton } from "../../components";
import { useActiveOrganizationQuery, useBrandingQuery } from "../../hooks";

export function ViewApplicationOnPortalButton({
  application,
}: {
  application: Application;
}) {
  const activeOrganizationQuery = useActiveOrganizationQuery();
  const brandingQuery = useBrandingQuery();

  return (
    <ExternalLinkButton
      variant="ghost"
      fontWeight="normal"
      fontSize="sm"
      w="full"
      target="_blank"
      href={`${window.location.protocol}//${activeOrganizationQuery.data?.domain}/apps/${application.id}`}
    >
      View on {brandingQuery.data?.product_name}
    </ExternalLinkButton>
  );
}
