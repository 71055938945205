import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Box,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagProps,
  UseCheckboxGroupProps,
  UseCheckboxProps,
  Wrap,
  useCheckbox,
  useCheckboxGroup,
} from "@chakra-ui/react";
import { XRPlatformType } from "../../backend/types";
import { getXRPlatformDisplayText } from "./getXRPlatformDisplayText";

function LaunchConfigurationCheckbox({
  size = "md",
  ...props
}: UseCheckboxProps & { value: XRPlatformType } & Pick<TagProps, "size">) {
  const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
    useCheckbox(props);
  const displayText = getXRPlatformDisplayText(props.value);

  return (
    <Tag
      as="label"
      size={size}
      variant={state.isChecked ? "solid" : "outline"}
      cursor={"pointer"}
      transition={"all 0.2s"}
      {...htmlProps}
    >
      <input {...getInputProps()} hidden />
      {state.isChecked ? (
        <TagLeftIcon as={CheckCircleIcon} {...getCheckboxProps()} />
      ) : (
        <Box
          borderRadius={"full"}
          width={"1em"}
          height={"1em"}
          marginEnd={"0.5rem"}
          borderColor="currentColor"
          borderWidth={1}
        />
      )}
      <TagLabel {...getLabelProps()}>{displayText}</TagLabel>
    </Tag>
  );
}
export function LaunchConfigurationsPicker({
  values,
  size = "md",
  ...props
}: UseCheckboxGroupProps & {
  values: XRPlatformType[];
  onChange?: (value: XRPlatformType[]) => void;
} & Pick<TagProps, "size">) {
  const { getCheckboxProps, isDisabled } = useCheckboxGroup(props);

  return (
    <Wrap>
      {values.map((value) => (
        <LaunchConfigurationCheckbox
          key={value}
          size={size}
          value={value}
          {...getCheckboxProps({ value })}
          id={"launch-configuration-checkbox-" + value}
          isDisabled={isDisabled}
        />
      ))}
    </Wrap>
  );
}
