import { useDashboardParameters } from "../context";
import { useUserGroupsQuery } from "../hooks/useUserGroups";
import { useAllUsersQuery } from "../hooks/useUsers";
import { PeriodStatCompare } from "./PeriodStatCompare";

export function TotalUsers() {
  const { userGroupId } = useDashboardParameters();
  const { data: userGroups } = useUserGroupsQuery();
  const usersQuery = useAllUsersQuery(
    { groups: userGroupId ? [userGroupId] : undefined },
    {
      enabled: !!userGroupId,
      select: (data) => [{ count: data.length, key: "total" }],
      retry: false,
    },
  );

  return (
    <PeriodStatCompare
      currentPeriodStatQuery={usersQuery}
      title="Total"
      label={`# of users in group (${userGroups?.results.find(
        (grp) => grp.id === userGroupId,
      )?.name})`}
    />
  );
}
