import {
  QueryClient,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import queryString from "query-string";
import {
  Application,
  ApplicationListFilters,
  PaginatedApplicationsList,
} from "../backend";
import { getApplication, getApplicationsPaginated } from "../backend/api";
import { ApplicationId } from "../backend/types";

export const baseQueryKeyForApplications = ["applications"] as const;

export function getQueryKeyForApplications(filters?: ApplicationListFilters) {
  if (!filters) {
    return baseQueryKeyForApplications;
  }

  // if search text is empty, don't send it to the backend
  filters = {
    ...filters,
    fulltext_search: filters?.fulltext_search
      ? filters.fulltext_search
      : undefined,
  };

  return [...baseQueryKeyForApplications, filters];
}

function getQueryForApplications<T = PaginatedApplicationsList>(
  queryClient: QueryClient,
  filters?: ApplicationListFilters,
  queryOptions: Partial<
    UseQueryOptions<PaginatedApplicationsList, AxiosError, T>
  > = {},
) {
  return {
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: getQueryKeyForApplications(filters),
    queryFn: async () => {
      const applicationsResponse = await getApplicationsPaginated(filters);
      // prepopulate the cache with the first page of applications
      populateApplicationDetailsQueryCache(
        queryClient,
        applicationsResponse.results,
      );
      return applicationsResponse;
    },
    ...queryOptions,
  };
}

export function usePaginatedApplicationsQuery(
  filters?: ApplicationListFilters,
) {
  const queryClient = useQueryClient();

  // fetch 10 elements per page by default
  filters = { ...filters, page_size: 10 };

  return useInfiniteQuery({
    queryKey: getQueryKeyForApplications(filters),
    queryFn: async ({ pageParam }) => {
      const applicationsResponse = await getApplicationsPaginated({
        ...filters,
        page: pageParam,
      });
      // prepopulate the cache with the first page of applications
      populateApplicationDetailsQueryCache(
        queryClient,
        applicationsResponse.results,
      );
      return applicationsResponse;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      lastPage.next
        ? Number(queryString.parseUrl(lastPage.next).query.page)
        : undefined,
    getPreviousPageParam: (firstPage) =>
      firstPage.next
        ? Number(queryString.parseUrl(firstPage.next).query.page)
        : undefined,
  });
}

export function useApplicationsQuery<T = PaginatedApplicationsList>(
  filters?: ApplicationListFilters,
  queryOptions: Partial<
    UseQueryOptions<PaginatedApplicationsList, AxiosError, T>
  > = {},
) {
  const queryClient = useQueryClient();

  return useQuery(getQueryForApplications(queryClient, filters, queryOptions));
}

export function getQueryKeyForApplication(id: ApplicationId) {
  return [...baseQueryKeyForApplications, id];
}

export function getQueryForApplication(
  id: ApplicationId,
  queryOptions: Partial<UseQueryOptions<Application, AxiosError>> = {},
): UseQueryOptions<Application, AxiosError> {
  return {
    queryKey: getQueryKeyForApplication(id),
    queryFn: () => getApplication(id),
    ...queryOptions,
    enabled: !!id && (queryOptions.enabled ?? true),
  };
}

export function useApplicationQuery(
  id: ApplicationId,
  queryOptions: Partial<UseQueryOptions<Application, AxiosError>> = {},
) {
  return useQuery(getQueryForApplication(id, queryOptions));
}

function populateApplicationDetailsQueryCache(
  queryClient: ReturnType<typeof useQueryClient>,
  applications: Application[],
) {
  applications.forEach((application) => {
    queryClient.setQueryData(
      getQueryKeyForApplication(application.id),
      application,
    );
  });
}
