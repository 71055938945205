import { DeleteIcon } from "@chakra-ui/icons";
import { chakra } from "@chakra-ui/react";
import { BsFillCloudHaze2Fill as CloudRenderingIcon } from "react-icons/bs";
import { CgServer as ServerIcon } from "react-icons/cg";
import {
  FaAws as AWSIcon,
  FaAndroid as AndroidIcon,
  FaDesktop as DesktopClientIcon,
  FaGoogle as GoogleCloudIcon,
  FaEyeSlash as HideIcon,
  FaGlobeEurope as InternetIcon,
  FaEye as ShowIcon,
  FaWindows as WindowsIcon,
} from "react-icons/fa";
import { FaMeta as MetaIcon } from "react-icons/fa6";
import {
  LuShieldCheck as AccessControlIcon,
  LuHardDrive as FileSizeIcon,
} from "react-icons/lu";
import {
  MdOutgoingMail as ResendIcon,
  MdOutlineSwapHoriz as SwapIcon,
  MdFileUpload as UploadIcon,
} from "react-icons/md";
import {
  SiLenovo as LenovoIcon,
  SiVerizon as VerizonIcon,
  SiVodafone as VodafoneIcon,
} from "react-icons/si";
import { VscAzure as AzureIcon } from "react-icons/vsc";
import InnoactiveHubLogo from "../img/innoactive-hub-logo.svg?react";
import PicoLogo from "../img/pico-logo.svg?react";
import TelefonicaLogo from "../img/telefonica-logo.svg?react";
import WaveLogo from "../img/wave-logo.svg?react";

const WaveIcon = chakra(WaveLogo);
const PicoIcon = chakra(PicoLogo);
const TelefonicaIcon = chakra(TelefonicaLogo);
const InnoactiveHubIcon = chakra(InnoactiveHubLogo);

export {
  AWSIcon,
  AccessControlIcon,
  AndroidIcon,
  AzureIcon,
  CloudRenderingIcon,
  DeleteIcon,
  DesktopClientIcon,
  FileSizeIcon,
  GoogleCloudIcon,
  HideIcon,
  InnoactiveHubIcon,
  InternetIcon,
  LenovoIcon,
  MetaIcon,
  PicoIcon,
  ResendIcon,
  ServerIcon,
  ShowIcon,
  SwapIcon,
  TelefonicaIcon,
  UploadIcon,
  VerizonIcon,
  VodafoneIcon,
  WaveIcon,
  WindowsIcon,
};
