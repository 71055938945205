import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useActiveOrganizationQuery } from ".";
import { Organization } from "../backend";
import { useUserIdsFilter } from "../dashboard/hooks/useUserIdsFilter";
import {
  getSessionsPaginated,
  SessionFilters,
  SessionList,
} from "../session-management";

export interface SessionsQueryFilterProps extends SessionFilters {
  organization?: Organization;
  userIds?: string[];
  includeFailed?: boolean;
}

export type UseSessionsQueryProps<T, TTransformed = T> = Omit<
  SessionsQueryFilterProps,
  "organization" | "userIds"
> & {
  userGroupId?: number;
  queryOptions?: Partial<UseQueryOptions<T, AxiosError, TTransformed>>;
};

export function useSessionsQuery({
  userGroupId,
  queryOptions,
  ...filters
}: UseSessionsQueryProps<SessionList> = {}) {
  const { data: organization } = useActiveOrganizationQuery();
  const { userIds } = useUserIdsFilter(userGroupId);

  return useQuery({
    queryKey: ["sessions", { ...filters, userIds }],
    queryFn: () =>
      getSessionsPaginated({ userIdentifier: userIds, ...filters }),
    retry: false,
    throwOnError: true,
    refetchOnWindowFocus: true,
    enabled: !!organization,
    ...queryOptions,
  });
}
