import { WarningTwoIcon } from "@chakra-ui/icons";
import { Heading, StackProps, VStack } from "@chakra-ui/react";

export function FailedLoadingData(props: StackProps) {
  return (
    <VStack spacing={6} {...props}>
      <WarningTwoIcon color="orange" boxSize={16} />
      <Heading size="lg">Data currently unavailable</Heading>
    </VStack>
  );
}
