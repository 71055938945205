import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { getApplicationPermissionsForUserGroup } from "../../backend/api";
import { GrantedApplicationPermission, PortalError } from "../../backend/types";

export function getQueryKeyForApplicationPermissionsForUserGroup(
  userGroupId: string | number,
) {
  return ["user-groups", userGroupId.toString(), "application-permissions"];
}

export function useApplicationPermissionsForUserGroup(
  userGroupId: string | number,
  queryOptions: Partial<
    UseQueryOptions<GrantedApplicationPermission[], AxiosError<PortalError>>
  > = {},
) {
  return useQuery({
    queryKey: getQueryKeyForApplicationPermissionsForUserGroup(userGroupId),
    queryFn: () => getApplicationPermissionsForUserGroup(userGroupId),
    ...queryOptions,
  });
}
