import {
  chakraComponents,
  OptionProps,
  Props,
  Select,
} from "chakra-react-select";
import { useMemo } from "react";
import { useAuthentication } from "../auth";
import { Organization } from "../backend";
import { OrganizationProfile } from "./GroupProfile";

export function OrganizationSelect({
  filter = () => true,
  ...props
}: Props<OrganizationOption, true> & {
  filter?: (
    value: Organization,
    index: number,
    array: Organization[],
  ) => boolean;
}) {
  const { user: activeUser } = useAuthentication();
  const filteredOrganizations = useMemo(
    () => activeUser?.organizations.filter(filter),
    [activeUser, filter],
  );

  return (
    <Select
      placeholder="Add user to organization(s) ..."
      size="sm"
      isMulti
      isLoading={!filteredOrganizations}
      isDisabled={!filteredOrganizations || !filteredOrganizations.length}
      options={(filteredOrganizations ?? [])?.map((organization) => ({
        value: organization.id,
        label: organization.name,
        organization,
      }))}
      components={customSelectComponents}
      {...props}
    />
  );
}
export type OrganizationOption = {
  value: number;
  label: string;
  organization: Organization;
};
const customSelectComponents = {
  Option: ({ ...props }: OptionProps<OrganizationOption, true>) => (
    <chakraComponents.Option {...props}>
      <OrganizationProfile organization={props.data.organization} />
    </chakraComponents.Option>
  ),
};
