import {
  IconButton,
  IconButtonProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { ReactNode, useRef } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";

export default function OptionsButton({
  label = "Show options",
  children,
  ...props
}: {
  label: string;
  children: ReactNode;
} & Omit<IconButtonProps, "aria-label">) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const popoverRef = useRef(null);
  useOutsideClick({
    ref: popoverRef,
    handler: onClose,
  });

  return (
    <Popover
      autoFocus={false}
      placement="bottom"
      isOpen={isOpen}
      isLazy
      onOpen={onOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <IconButton
          aria-label={label}
          icon={<BsThreeDotsVertical />}
          variant="solid"
          size={"sm"}
          {...props}
        />
      </PopoverTrigger>
      <PopoverContent
        ref={popoverRef}
        w="fit-content"
        _focus={{ boxShadow: "none" }}
      >
        <PopoverArrow />
        <PopoverBody>
          <Stack alignItems="start">{children}</Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
