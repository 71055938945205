import { ApplicationBuild } from "../../backend";
import {
  XRPlatformType,
  androidXRPlatformTypes,
  windowsXRPlatformTypes,
  xrPlatformTypes,
} from "../../backend/types";

/**
 * Given information about the application build, returns a list of potentially supported xr platforms.
 * @param applicationBuildData information about the application build
 * @returns list of potentially supported xr platforms
 */
export function getPossibleXRPlatforms(
  applicationBuildData: Partial<
    Pick<
      ApplicationBuild,
      "executable_path" | "package_name" | "application_archive"
    >
  >,
): XRPlatformType[] {
  if (
    (applicationBuildData.package_name &&
      !applicationBuildData.application_archive?.endsWith(".zip")) ||
    applicationBuildData.application_archive?.endsWith(".apk")
  ) {
    return [...androidXRPlatformTypes];
  }

  if (
    (applicationBuildData.executable_path &&
      !applicationBuildData.application_archive?.endsWith(".apk")) ||
    applicationBuildData.application_archive?.endsWith(".zip")
  ) {
    return [...windowsXRPlatformTypes];
  }

  return [...xrPlatformTypes];
}

/**
 * Given information about the application build, returns a list of default xr platforms presumably supported by this application build.
 * @param applicationBuildData information about the application build
 * @returns list of default xr platforms presumably supported by this application build
 */
export function getDefaultXRPlatforms(
  applicationBuildData: Partial<
    Pick<ApplicationBuild, "application_archive" | "supported_xr_platforms">
  >,
): XRPlatformType[] {
  // use existing xr platforms (but only if they work for the supplied platform)
  if (applicationBuildData.application_archive?.endsWith(".apk")) {
    // TODO: would need to be inferred based on package name which we don't know
    return (
      applicationBuildData.supported_xr_platforms?.filter((xr_platform) =>
        androidXRPlatformTypes.includes(xr_platform),
      ) ?? []
    );
  }

  if (applicationBuildData.application_archive?.endsWith(".zip")) {
    const launchConfigs =
      applicationBuildData.supported_xr_platforms?.filter((xr_platform) =>
        windowsXRPlatformTypes.includes(xr_platform),
      ) ?? [];

    // per default, windows apps are non vr
    if (!launchConfigs.length) {
      return ["win-non-vr"];
    }

    return launchConfigs;
  }

  return [];
}
