import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { destroyVirtualMachine } from "../../session-management";
import { getVirtualMachinesQueryKey } from "./useVirtualMachinesQuery";

export function useDestroyVirtualMachineMutation({
  onSuccess,
  ...options
}: Omit<UseMutationOptions<void, AxiosError, string>, "mutationFn">) {
  const queryClient = useQueryClient();

  const mutation = useMutation<void, AxiosError, string>({
    mutationFn: async (id: string) => {
      await destroyVirtualMachine(id);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: getVirtualMachinesQueryKey(),
      });

      if (onSuccess) onSuccess(data, variables, context);
    },
    ...options,
  });
  return mutation;
}
