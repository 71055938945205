import { Alert, CircularProgress } from "@chakra-ui/react";
import { ApplicationBuildId } from "../backend/types";
import { useApplicationBuildQuery } from "../hooks/useApplicationBuildsQuery";
import { ApplicationBuildEditForm } from "./ApplicationBuildEditForm";

export function ApplicationBuildEditor({
  applicationBuildId,
}: {
  applicationBuildId: ApplicationBuildId;
}) {
  const applicationBuildQuery = useApplicationBuildQuery(applicationBuildId);

  if (applicationBuildQuery.isError) {
    return <Alert status="error">Error loading application build</Alert>;
  }

  if (applicationBuildQuery.isLoading) {
    return <CircularProgress />;
  }

  if (applicationBuildQuery.isSuccess) {
    return (
      <ApplicationBuildEditForm applicationBuild={applicationBuildQuery.data} />
    );
  }
}
