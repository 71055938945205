import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spacer,
  Stack,
  Tag,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { AxiosError } from "axios";
import { useMemo, useState } from "react";
import { deleteApplicationNetworkSecurityRule } from "../../backend/api";
import {
  Application,
  ApplicationNetworkSecurityRule,
  NetworkSecurityRule,
  NetworkSecurityRuleDirection,
  PortalError,
} from "../../backend/types";
import {
  AddButton,
  DeleteButton,
  EditButton,
  OptionsButton,
  PaginatedTable,
} from "../../components";
import { useApplicationNetworkSecurityRules } from "../../hooks";
import {
  getQueryKeyForApplicationNetworkSecurityRules,
  useDefaultApplicationNetworkSecurityRules,
} from "../../hooks/useApplicationNetworkSecurityRules";
import { CreateApplicationNetworkSecurityRuleForm } from "./CreateApplicationNetworkSecurityRuleForm";
import { EditApplicationNetworkSecurityRuleForm } from "./EditApplicationNetworkSecurityRuleForm";

const columnHelper = createColumnHelper<
  NetworkSecurityRule &
    Partial<Pick<ApplicationNetworkSecurityRule, "id" | "application">>
>();

function PortRange({ portFrom, portTo }: { portFrom: number; portTo: number }) {
  if (portFrom === 0 && portTo === 0) {
    return <>Any</>;
  }

  if (portFrom === portTo) return <>{portFrom}</>;

  return (
    <>
      {portFrom} - {portTo}
    </>
  );
}

function isApplicationNetworkSecurityRule(
  rule: NetworkSecurityRule,
): rule is ApplicationNetworkSecurityRule {
  return (rule as ApplicationNetworkSecurityRule).id !== undefined;
}

export function ApplicationNetworkSecurityRulesOverview({
  application,
}: {
  application: Application;
}) {
  const defaultSecurityRulesQuery = useDefaultApplicationNetworkSecurityRules();
  const securityRulesQuery = useApplicationNetworkSecurityRules(application.id);
  const ruleDialogState = useDisclosure();
  const [ruleToEdit, setRuleToEdit] =
    useState<ApplicationNetworkSecurityRule | null>();
  const [ruleToDelete, setRuleToDelete] =
    useState<ApplicationNetworkSecurityRule | null>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("description", {
        header: "Description",
        cell: (props) => (
          <HStack>
            <Tag
              colorScheme={
                isApplicationNetworkSecurityRule(props.row.original)
                  ? "brand"
                  : "gray"
              }
            >
              {isApplicationNetworkSecurityRule(props.row.original)
                ? "Custom"
                : "Default"}
            </Tag>
            <Text>{props.getValue()}</Text>
          </HStack>
        ),
      }),
      columnHelper.accessor("protocol", {
        header: "Protocol",
        cell: (props) => props.getValue().toLocaleUpperCase(),
      }),
      columnHelper.accessor("direction", {
        header: "Direction",
        cell: (props) =>
          props.getValue() === NetworkSecurityRuleDirection.Inbound
            ? "In"
            : "Out",
      }),
      columnHelper.display({
        header: "Port(s)",
        cell: (props) => (
          <PortRange
            portFrom={props.row.original.from_port}
            portTo={props.row.original.to_port}
          />
        ),
      }),
      columnHelper.accessor("cidr_blocks", {
        header: "Source",
        cell: (props) => (
          <Stack as="dl" spacing={1}>
            {props.getValue().map((cidr_block) => (
              <dt key={cidr_block}>{cidr_block}</dt>
            ))}
          </Stack>
        ),
        enableSorting: false,
      }),
      columnHelper.display({
        id: "options",
        cell: (props) => (
          <Flex justifyContent={"end"}>
            <OptionsButton
              label="Click on this button to display user actions"
              isDisabled={!isApplicationNetworkSecurityRule(props.row.original)}
            >
              <EditButton
                variant="ghost"
                width="full"
                onClick={() => {
                  setRuleToDelete(null);
                  isApplicationNetworkSecurityRule(props.row.original) &&
                    setRuleToEdit(props.row.original);
                  ruleDialogState.onOpen();
                }}
                fontWeight="normal"
                fontSize="sm"
                isDisabled={
                  !isApplicationNetworkSecurityRule(props.row.original)
                }
              >
                Edit rule
              </EditButton>
              <DeleteButton
                isDisabled={
                  !isApplicationNetworkSecurityRule(props.row.original)
                }
                onClick={() => {
                  setRuleToEdit(null);
                  isApplicationNetworkSecurityRule(props.row.original) &&
                    setRuleToDelete(props.row.original);
                  ruleDialogState.onOpen();
                }}
              >
                Delete rule
              </DeleteButton>
            </OptionsButton>
          </Flex>
        ),
        header: () => <Flex justifyContent={"end"}>Options</Flex>,
      }),
    ],
    [ruleDialogState],
  );
  const table = useReactTable({
    columns: useMemo(
      () =>
        securityRulesQuery.isLoading
          ? columns.map((col, idx) => ({
              ...col,
              cell: () => <Skeleton key={idx}>Loading</Skeleton>,
            }))
          : columns,
      [columns, securityRulesQuery.isLoading],
    ),
    data: useMemo(
      () =>
        securityRulesQuery.data && defaultSecurityRulesQuery.data
          ? [
              ...securityRulesQuery.data.results,
              ...defaultSecurityRulesQuery.data,
            ]
          : securityRulesQuery.isLoading && defaultSecurityRulesQuery.data
            ? defaultSecurityRulesQuery.data
            : securityRulesQuery.data && defaultSecurityRulesQuery.isLoading
              ? securityRulesQuery.data.results
              : [],
      [
        defaultSecurityRulesQuery.data,
        defaultSecurityRulesQuery.isLoading,
        securityRulesQuery.data,
        securityRulesQuery.isLoading,
      ],
    ),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    autoResetPageIndex: true,
  });
  const queryClient = useQueryClient();
  const deleteNetworkSecurityRuleMutation = useMutation<
    void,
    AxiosError<PortalError>,
    ApplicationNetworkSecurityRule
  >({
    mutationFn: (rule) =>
      deleteApplicationNetworkSecurityRule(application.id, rule.id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationNetworkSecurityRules(application.id),
      });
      ruleDialogState.onClose();
    },
  });

  return (
    <Stack spacing={4}>
      <HStack>
        <Heading size="sm" as="h3">
          Firewall Rules
        </Heading>
        <Spacer />
        <AddButton
          onClick={() => {
            setRuleToDelete(null);
            setRuleToEdit(null);
            ruleDialogState.onOpen();
          }}
          size="sm"
        >
          Add firewall rule
        </AddButton>
      </HStack>
      <Text>
        The following firewall rules will be applied to the cloud rendering
        infrastructure when running the application. You can use this feature,
        e.g. if your application starts a (web)server that needs to be
        accessible from the outside.
      </Text>
      <PaginatedTable table={table} />
      <Modal isOpen={ruleDialogState.isOpen} onClose={ruleDialogState.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {ruleToEdit
              ? `Edit firewall rule ${ruleToEdit.description}`
              : ruleToDelete
                ? `Confirm deletion of firewall rule`
                : "Create new Firewall rule"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {ruleToDelete ? (
              <Text>
                Are you sure you want to delete firwall rule
                {ruleToDelete.description}? This action cannot be undone!
              </Text>
            ) : ruleToEdit ? (
              <EditApplicationNetworkSecurityRuleForm
                rule={ruleToEdit}
                applicationId={application.id}
              />
            ) : (
              <CreateApplicationNetworkSecurityRuleForm
                applicationId={application.id}
                onSuccess={ruleDialogState.onClose}
              />
            )}
            {deleteNetworkSecurityRuleMutation.isError && (
              <Alert status="error">
                <AlertIcon />
                <AlertDescription>
                  {`An error occured while deleting the firewall rule. (${
                    deleteNetworkSecurityRuleMutation.error.response?.data
                      ?.detail ??
                    deleteNetworkSecurityRuleMutation.error.message
                  })`}
                </AlertDescription>
              </Alert>
            )}
          </ModalBody>
          {ruleToDelete && (
            <ModalFooter>
              <DeleteButton
                isLoading={deleteNetworkSecurityRuleMutation.isPending}
                variant="solid"
                mr={3}
                width="auto"
                onClick={() =>
                  deleteNetworkSecurityRuleMutation.mutate(ruleToDelete)
                }
              >
                Delete
              </DeleteButton>
              <Button
                isDisabled={deleteNetworkSecurityRuleMutation.isPending}
                variant="ghost"
                onClick={ruleDialogState.onClose}
              >
                Cancel
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </Stack>
  );
}
