import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { endOfMonth, getWeeksInMonth } from "@internationalized/date";
import { useLocale } from "@react-aria/i18n";
import { useCalendarGrid } from "react-aria";
import { CalendarState } from "react-stately";
import { CalendarCell } from "./CalendarCell";

export function CalendarGrid({ state }: { state: CalendarState }) {
  const { locale } = useLocale();
  const startDate = state.visibleRange.start;
  const endDate = endOfMonth(startDate);
  const { gridProps, headerProps, weekDays } = useCalendarGrid(
    {
      startDate,
      endDate,
    },
    state,
  );

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

  return (
    <Table {...gridProps} variant="unstyled" size="sm">
      <Thead {...headerProps}>
        <Tr>
          {weekDays.map((day, index) => (
            <Th key={index} textAlign="center">
              {day}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <Tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex, startDate)
              .map((date, i) =>
                date ? (
                  <CalendarCell
                    key={i}
                    state={state}
                    date={date}
                    borderLeftRadius={i === 0 ? "lg" : undefined}
                    borderRightRadius={i === 6 ? "lg" : undefined}
                  />
                ) : (
                  <Td key={i} />
                ),
              )}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
