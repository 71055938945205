import { tabsAnatomy } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  extendTheme,
  getToken,
  Theme,
} from "@chakra-ui/react";
import { cssVar } from "@chakra-ui/styled-system";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/raleway/400.css";
import Color from "color";
import generatePalette from "./palette";

const { definePartsStyle: defineTabsPartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const $menuBg = cssVar("menu-bg");
const $popperBg = cssVar("popper-bg");
const $tabsColor = cssVar("tabs-color");

const portalColor = "#04385F";
const hubColor = "#63BED9";

function brandTheme(
  brandingColor: string = "#04385F",
  backgroundColor: string = "#ffffff",
) {
  const brandColors = generatePalette(brandingColor, 500);
  const portalColors = generatePalette(portalColor, 500);
  const innoactiveHubColors = generatePalette(hubColor, 500);
  const bgColor = Color(backgroundColor);
  const isDarkMode = new Color(backgroundColor).isDark();
  const isDarkBrandColor = new Color(brandingColor).isDark();

  return extendTheme({
    semanticTokens: {
      colors: {
        "chakra-subtle-text": { _light: "gray.600", _dark: "whiteAlpha.500" },
        "chakra-subtle-bg": {
          _light: "background.400",
          _dark: "background.700",
        },
        "chakra-border-color": {
          _light: bgColor.darken(0.1).hex(),
          _dark: "whiteAlpha.300",
        },
        "chakra-body-bg": { _light: "background.500", _dark: "background.500" },
        "menu-text": { _light: "gray.600", _dark: "whiteAlpha.700" },
        "brand-bg": "brand.50",
        "brand-text": { _light: "brand.700", _dark: "brand.100" },
        "modal-bg": { _light: "white", _dark: "gray.700" },
        "text-on-brand-bg": {
          _light: isDarkBrandColor ? "white" : "chakra-body-text",
          _dark: isDarkBrandColor ? "white" : "gray.800",
        },
      },
    },
    config: {
      initialColorMode: "light",
      useSystemColorMode: false,
    },
    colors: {
      brand: brandColors,
      hub: innoactiveHubColors,
      portal: portalColors,
      backgroundAlpha: {
        50: bgColor.alpha(0.04).hexa(),
        100: bgColor.alpha(0.06).hexa(),
        200: bgColor.alpha(0.08).hexa(),
        300: bgColor.alpha(0.16).hexa(),
        400: bgColor.alpha(0.24).hexa(),
        500: bgColor.alpha(0.36).hexa(),
        600: bgColor.alpha(0.48).hexa(),
        700: bgColor.alpha(0.64).hexa(),
        800: bgColor.alpha(0.8).hexa(),
        900: bgColor.alpha(0.92).hexa(),
      },
      background: generatePalette(backgroundColor, 500),
    },
    fonts: {
      heading: `'Open Sans', sans-serif`,
      body: `'Raleway', sans-serif`,
    },
    components: {
      Link: {
        baseStyle: {
          textDecoration: "underline",
          _hover: { color: "brand.500" },
        },
      },
      Button: {
        baseStyle: {
          fontWeight: "bold", // Normally, it is "semibold"
        },
        variants: {
          solid: (props: { theme: Theme; colorScheme: string }) => {
            const textColor = getToken(
              "colors",
              props.theme.semanticTokens.colors["chakra-body-text"][
                isDarkMode ? "_dark" : "_light"
              ],
            )(props.theme);
            const textBgColor = getToken(
              "colors",
              props.theme.semanticTokens.colors["chakra-body-bg"][
                isDarkMode ? "_dark" : "_light"
              ],
            )(props.theme);
            const extra =
              props.colorScheme === "brand"
                ? {
                    bg: "brand.500",
                    // find out what's a suitable text color for the branded buttons
                    color:
                      Color(textColor).contrast(Color(brandingColor)) >=
                      Color(textBgColor).contrast(Color(brandingColor))
                        ? "chakra-body-text"
                        : "chakra-body-bg",
                  }
                : {};

            return extra;
          },
        },
      },
      FormLabel: {
        baseStyle: {
          fontWeight: "bold",
          fontSize: "x-small",
          textTransform: "uppercase",
        },
      },
      Stat: {
        baseStyle: {
          label: {
            color: "gray.600",
            _dark: {
              color: "whiteAlpha.600",
            },
          },
        },
      },
      Table: {
        baseStyle: {
          th: {
            color: "gray.700",
            _dark: {
              color: "chakra-body-text",
            },
          },
        },
      },
      Popover: {
        baseStyle: {
          popper: {
            zIndex: "var(--chakra-zIndices-popover)",
          },
          content: {
            [$popperBg.variable]: "var(--chakra-colors-chakra-subtle-bg)",
            _dark: {
              [$popperBg.variable]: "var(--chakra-colors-chakra-subtle-bg)",
            },
          },
        },
      },
      Menu: {
        baseStyle: {
          list: {
            zIndex: "var(--chakra-zIndices-dropdown)",
            [$menuBg.variable]: "var(--chakra-colors-chakra-subtle-bg)",
            _dark: {
              [$menuBg.variable]: "var(--chakra-colors-chakra-subtle-bg)",
            },
          },
        },
      },
      Tabs: defineMultiStyleConfig({
        variants: {
          line: defineTabsPartsStyle((props) => {
            const { colorScheme } = props;

            if (colorScheme === "brand") {
              return {
                tab: {
                  _selected: {
                    [$tabsColor.variable]: `var(--chakra-colors-brand-text)`,
                    _dark: {
                      [$tabsColor.variable]: `var(--chakra-colors-brand-text)`,
                    },
                  },
                  _hover: {
                    borderColor: `var(--chakra-colors-brand-bg)`,
                  },
                },
              };
            }

            return {};
          }),
        },
      }),
    },
  });
}

const baseTheme = brandTheme();

export { baseTheme, brandTheme };
