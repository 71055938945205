import dayjs from "dayjs";
import { useMemo } from "react";

export function DateTimeFormatted({ date }: { date: Date }) {
  const dateParsed = useMemo(() => dayjs(date), [date]);

  let format = "L LT";
  if (dayjs().isSame(dateParsed, "D")) {
    format = "LT";
  }
  return <>{dateParsed.format(format)}</>;
}
