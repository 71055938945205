import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Skeleton,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  MultiValueGenericProps,
  OptionProps,
  Props,
  Select,
  chakraComponents,
} from "chakra-react-select";
import { useEffect, useMemo } from "react";
import {
  FaPlus as AddIcon,
  FaTimes as ClearIcon,
  FaSearch as SearchIcon,
} from "react-icons/fa";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { Application } from "../backend";
import { XRPlatformType, xrPlatformTypes } from "../backend/types";
import {
  AddButton,
  ApplicationDisplay,
  ApplicationDisplaySkeleton,
  LinkButton,
  NoData,
  OptionsButton,
  PaginatedTable,
} from "../components";
import {
  usePaginationQueryParams,
  useSearchQueryParams,
  useSortingQueryParams,
} from "../hooks";
import { useApplicationsQuery } from "../hooks/useApplicationQuery";
import { usePlatformsQueryParam } from "../hooks/usePlatformsSupportQueryParams";
import { namedRoutes } from "../routes";
import { OrderingParam } from "../utils/ordering-param";
import { AddApplicationWizard } from "./AddApplicationWizard";
import { ViewApplicationOnPortalButton } from "./components/ViewApplicationOnPortalButton";
import {
  XRPlatformDisplay,
  XRPlatformsDisplay,
} from "./components/XRPlatformDisplay";
import { useApplicationDeleteConfirm } from "./hooks/useApplicationDeleteConfirm";

const columnHelper = createColumnHelper<Application>();

type XRPlatformOption = {
  value: XRPlatformType;
};

const customComponents = {
  Option: ({ ...props }: OptionProps<XRPlatformOption, true>) => {
    return (
      <chakraComponents.Option {...props}>
        <XRPlatformDisplay xrPlatforms={props.data.value} />
      </chakraComponents.Option>
    );
  },
  MultiValueLabel: ({
    children,
    ...props
  }: MultiValueGenericProps<XRPlatformOption, true>) => (
    <chakraComponents.MultiValueLabel {...props}>
      <XRPlatformDisplay xrPlatforms={props.data.value} />
    </chakraComponents.MultiValueLabel>
  ),
};

function XRPlatformMultiSelect({
  value,
  onChange,
  isLoading,
  ...props
}: Props<XRPlatformOption, true>) {
  return (
    <Select
      isMulti
      placeholder="Filter by supported platform(s)..."
      options={xrPlatformTypes.map((value) => ({
        value,
        label: value,
      }))}
      onChange={onChange}
      selectedOptionStyle="check"
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      size="md"
      components={customComponents}
      {...props}
    />
  );
}

export function ApplicationList() {
  const addApplicationDialogState = useDisclosure();
  const [xrPlatforms, setXRPlatforms] = usePlatformsQueryParam();
  const [sorting, setSorting] = useSortingQueryParams();
  const [pagination, setPagination] = usePaginationQueryParams();
  const { debouncedSearchText, search, setSearch } = useSearchQueryParams();
  const confirmApplicationDeletion = useApplicationDeleteConfirm();

  const {
    data: applicationsResponse,
    isError,
    error,
    isLoading,
  } = useApplicationsQuery(
    {
      // if search term is given, don't override ordering (because results should be ordered by quality of match to search)
      ordering: useMemo(
        () => (debouncedSearchText ? undefined : OrderingParam.encode(sorting)),
        [sorting, debouncedSearchText],
      ),
      fulltext_search: debouncedSearchText,
      page: pagination.pageIndex + 1,
      page_size: pagination.pageSize,
      supported_xr_platform: xrPlatforms,
    },
    { throwOnError: true },
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        header: "Application",
        cell: (props) => {
          const { row } = props;
          const application = row.original;

          const appDisplay = <ApplicationDisplay application={application} />;

          if (application.permissions.change === false) {
            return appDisplay;
          }

          return (
            <Link
              textDecoration={"none"}
              _hover={{ textDecoration: "none", cursor: "pointer" }}
              as={RouterLink}
              to={generatePath(namedRoutes.application.overview, {
                applicationId: application.id.toString(),
              })}
              role="group"
            >
              {appDisplay}
            </Link>
          );
        },
      }),
      columnHelper.display({
        id: "platforms",
        header: "Supported platforms",
        cell: ({ row: { original: application } }) => {
          const supportedXRPlatforms = application.launch_configurations
            // filter out any launch configurations without an active build
            .filter(({ application_build }) => !!application_build)
            .map(({ xr_platform: type }) => type)
            .sort((a, b) => b.localeCompare(a));

          if (!supportedXRPlatforms.length) {
            return (
              <LinkButton
                size="xs"
                to={generatePath(namedRoutes.application.builds, {
                  applicationId: application.id.toString(),
                })}
                variant="link"
              >
                No build, upload one?
              </LinkButton>
            );
          }

          return (
            <XRPlatformsDisplay
              xrPlatforms={application.launch_configurations
                // filter out any launch configurations without an active build
                .filter(({ application_build }) => !!application_build)
                .map(({ xr_platform: type }) => type)
                .sort((a, b) => b.localeCompare(a))}
            />
          );
        },
      }),
      columnHelper.display({
        id: "options",
        cell: ({ row: { original: application } }) => {
          return (
            <Flex justifyContent={"end"}>
              <OptionsButton label="Click on this button to display user actions">
                <ViewApplicationOnPortalButton application={application} />
                <Divider />
                <LinkButton
                  variant="ghost"
                  fontWeight="normal"
                  fontSize="sm"
                  w="full"
                  to={generatePath(namedRoutes.application.overview, {
                    applicationId: application.id?.toString(),
                  })}
                  isDisabled={application.permissions.change === false}
                >
                  Show Details
                </LinkButton>
                <LinkButton
                  variant="ghost"
                  fontWeight="normal"
                  fontSize="sm"
                  w="full"
                  to={generatePath(namedRoutes.application.access, {
                    applicationId: application.id?.toString(),
                  })}
                  isDisabled={application.permissions.change === false}
                >
                  Share / Access Control
                </LinkButton>
                <Button
                  variant="ghost"
                  fontWeight="normal"
                  fontSize="sm"
                  w="full"
                  colorScheme="red"
                  onClick={confirmApplicationDeletion(application)}
                  isDisabled={application.permissions.change === false}
                >
                  Delete Application
                </Button>
              </OptionsButton>
            </Flex>
          );
        },
        header: () => <Flex justifyContent="end">Options</Flex>,
      }),
    ],
    [confirmApplicationDeletion],
  );
  const dynamicColumns = useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            cell: () =>
              column.id === "name" ? (
                <ApplicationDisplaySkeleton />
              ) : (
                <Skeleton>
                  <Text>Loading</Text>
                </Skeleton>
              ),
          }))
        : columns,
    [columns, isLoading],
  );
  const data = useMemo<Application[]>(
    () =>
      isLoading
        ? Array(pagination.pageSize).fill({} as Application)
        : applicationsResponse?.results ?? [],
    [applicationsResponse?.results, isLoading, pagination.pageSize],
  );
  const table = useReactTable({
    data,
    columns: dynamicColumns,
    state: {
      sorting,
      pagination,
    },
    initialState: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualSorting: true,
    manualPagination: true,
    pageCount: applicationsResponse?.count
      ? Math.ceil(applicationsResponse?.count / pagination.pageSize)
      : undefined,
  });

  useEffect(() => {
    // reset pagination if search text or filter changes
    table.resetPageIndex();
  }, [debouncedSearchText, table]);

  if (isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>{JSON.stringify(error)}</AlertDescription>
      </Alert>
    );
  }

  return (
    <>
      <VStack alignItems="normal" spacing={6}>
        <Flex paddingX={4}>
          <HStack spacing={4} flexGrow={1}>
            <InputGroup flexBasis={"2xs"} flexGrow={1}>
              <InputLeftElement pointerEvents="none">
                <Icon as={SearchIcon} color="gray.300" />
              </InputLeftElement>
              <Input
                placeholder="Search by name..."
                value={search}
                onChange={(evt) => setSearch(evt.target.value, "replaceIn")}
              />
              {search && (
                <InputRightElement
                  cursor="pointer"
                  color="gray.300"
                  _hover={{ color: "red" }}
                  onClick={() => setSearch("", "replaceIn")}
                >
                  <Icon as={ClearIcon} />
                </InputRightElement>
              )}
            </InputGroup>
            <Flex width={"21rem"} flexShrink={0}>
              <XRPlatformMultiSelect
                value={xrPlatforms.map((t) => ({
                  value: t,
                }))}
                onChange={(selectedTypes) =>
                  setXRPlatforms(selectedTypes.map((t) => t.value))
                }
                chakraStyles={{
                  container: (provided) => ({ ...provided, width: "full" }),
                }}
              />
            </Flex>
            <Button
              leftIcon={<Icon boxSize={3} as={AddIcon} />}
              onClick={addApplicationDialogState.onOpen}
              colorScheme="brand"
              flexShrink={0}
            >
              Add application
            </Button>
          </HStack>
        </Flex>
        {applicationsResponse && !applicationsResponse.count ? (
          <NoData
            height="md"
            title="Looks like there are no applications (yet). Try adding some."
            callToAction={
              <AddButton onClick={addApplicationDialogState.onOpen}>
                Add application
              </AddButton>
            }
          />
        ) : (
          <PaginatedTable table={table} />
        )}
      </VStack>
      <Drawer
        closeOnOverlayClick={false}
        closeOnEsc={false}
        size={"lg"}
        {...addApplicationDialogState}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            Upload a new application
          </DrawerHeader>

          <DrawerBody>
            <AddApplicationWizard
              onCompleted={addApplicationDialogState.onClose}
            />
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button
              variant="outline"
              mr={3}
              onClick={addApplicationDialogState.onClose}
            >
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
