import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  EditableProps,
  IconButton,
  Input,
  Tooltip,
  forwardRef,
  useColorModeValue,
  useEditable,
  useEditableControls,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { Editor, createEditor } from "slate";
import { HTMLRichTextEditor } from "./RichTextEditor/HTMLRichTextEditor";
import { htmlToSlate } from "./RichTextEditor/html-serializer";
import { resetNodes } from "./RichTextEditor/utils";

function resetNodesUsingHtml(editor: Editor, html?: string) {
  resetNodes(editor, { nodes: htmlToSlate(html) });
}

export function EditableControls() {
  const { isEditing, getSubmitButtonProps, getCancelButtonProps } =
    useEditableControls();

  return isEditing ? (
    <ButtonGroup justifyContent="end" size="sm" w="full" spacing={2} mt={2}>
      <IconButton
        icon={<CheckIcon />}
        aria-label="Save changes"
        {...getSubmitButtonProps()}
      />
      <IconButton
        icon={<CloseIcon boxSize={3} />}
        aria-label="Abort"
        {...getCancelButtonProps()}
      />
    </ButtonGroup>
  ) : null;
}

export const StyledEditable = forwardRef((props: EditableProps, ref) => {
  const backgroundColor = useColorModeValue("gray.100", "gray.700");
  return (
    <Editable
      isPreviewFocusable={true}
      selectAllOnFocus={false}
      ref={ref}
      {...props}
    >
      <Tooltip label="Click to edit" isDisabled={props.isDisabled}>
        <EditablePreview
          px={2}
          py={1}
          cursor={props.isDisabled ? "not-allowed" : "text"}
          _hover={{
            background: backgroundColor,
          }}
          w="full"
        />
      </Tooltip>
      <Input py={2} px={4} as={EditableInput} />
      <EditableControls />
    </Editable>
  );
});

export function EditableRichtext(props: EditableProps) {
  const backgroundColor = useColorModeValue("gray.100", "gray.700");
  const { getSubmitButtonProps, getCancelButtonProps, isEditing, onEdit } =
    useEditable({
      ...props,
      onSubmit: () => {
        setPrevValue(value);
        props.onSubmit && props.onSubmit(value ?? "");
      },
      onCancel: () => {
        resetNodesUsingHtml(editor, prevValue);
        setValue((prevValue ?? "").slice());
        props.onCancel && props.onCancel(prevValue ?? "");
      },
    });
  const [value, setValue] = useState(props.value);
  const [prevValue, setPrevValue] = useState(value);
  const editor = useMemo(() => createEditor(), []);

  return (
    <>
      <Box
        borderWidth={isEditing ? 1 : 0}
        borderRadius={"md"}
        paddingX={2}
        paddingY={1}
        _hover={{
          backgroundColor: isEditing ? undefined : backgroundColor,
        }}
      >
        <Tooltip label="Click to edit" isDisabled={isEditing}>
          <HTMLRichTextEditor
            value={value}
            readOnly={!isEditing}
            onClick={isEditing ? undefined : onEdit}
            onChange={setValue}
            editor={editor}
          />
        </Tooltip>
      </Box>
      {isEditing && (
        <ButtonGroup
          justifyContent="start"
          size="sm"
          w="full"
          spacing={2}
          mt={2}
        >
          <Button variant="solid" {...getSubmitButtonProps()}>
            Save
          </Button>
          <Button variant="ghost" {...getCancelButtonProps()}>
            Cancel
          </Button>
        </ButtonGroup>
      )}
    </>
  );
}
