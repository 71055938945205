import { useCloudRenderingRegionQuery } from "../cloud-rendering/hooks/useCloudRenderingRegionsQuery";
import { ReservedInstance } from "../session-management";

export function RegionDisplay({
  region,
}: {
  region?: ReservedInstance["region"];
}) {
  const regionInfo = useCloudRenderingRegionQuery(region);

  return <>{regionInfo?.displayName ?? "Unknown"}</>;
}
