import {
  Box,
  Checkbox,
  CheckboxGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
  Wrap,
} from "@chakra-ui/react";
import { CalendarDate } from "@internationalized/date";
import { Props, Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { omit } from "remeda";
import { DateRangePicker } from "../../components/date-range-picker/DateRangePicker";
import { WeekDay, isBit, toggleBit } from "../../session-management";
import { useTimeZonesQuery } from "../hooks/useTimeZonesQuery";
import { UpdateReservedInstanceSchema } from "./reservedInstanceSchema";

interface DropDownOptions {
  value: string;
  label: string;
}

const TimeZonesDropDown = ({
  value,
  defaultValue,
  onChange,
  ...props
}: {
  value?: string;
  defaultValue?: string;
  onChange: (value?: string) => void;
} & Omit<
  Props<DropDownOptions, false>,
  "value" | "onChange" | "defaultValue"
>) => {
  const { data: timeZones, isSuccess } = useTimeZonesQuery();

  return (
    <Select
      {...props}
      isLoading={!isSuccess}
      useBasicStyles
      value={timeZones
        ?.map((r) => ({ value: r.id, label: r.displayName }))
        .find((r) => r.value === value)}
      defaultValue={timeZones
        ?.map((r) => ({ value: r.id, label: r.displayName }))
        .find((r) => r.value === defaultValue)}
      onChange={(newValue) => onChange(newValue?.value)}
      options={timeZones?.map((r) => {
        return {
          value: r.id,
          label: r.displayName,
        };
      })}
    />
  );
};

export const ManageReservedInstanceSchedulingTabForm = ({
  formState,
  control,
  register,
  watch,
  resetField,
  getValues,
  setValue,
}: Pick<
  UseFormReturn<UpdateReservedInstanceSchema>,
  | "formState"
  | "control"
  | "register"
  | "watch"
  | "resetField"
  | "getValues"
  | "setValue"
>) => {
  const [dateLimitEnabled, setDateLimitEnabled] = useState(
    !!getValues("fromDate"),
  );
  const [timeLimitEnabled, setTimeLimitEnabled] = useState(
    !!getValues("fromTime"),
  );
  const [weekDayLimitEnabled, setWeekDayLimitEnabled] = useState(
    !!getValues("weekDaysBitmask"),
  );

  register("fromDate");
  register("toDate");

  useEffect(() => {
    if (timeLimitEnabled) {
      resetField("toTime");
      resetField("fromTime");
      return;
    }
    setValue("toTime", null, { shouldDirty: true });
    setValue("fromTime", null, { shouldDirty: true });
  }, [resetField, setValue, timeLimitEnabled]);

  useEffect(() => {
    if (dateLimitEnabled) {
      resetField("fromDate");
      resetField("toDate");

      if (!getValues("fromDate") || !getValues("toDate")) {
        const now = new Date();
        now.setUTCHours(0, 0, 0, 0);
        setValue("fromDate", now.toISOString(), { shouldDirty: true });
        now.setUTCHours(23, 59, 59, 0);
        setValue("toDate", now.toISOString(), { shouldDirty: true });
      }
      return;
    }
    setValue("fromDate", null, { shouldDirty: true });
    setValue("toDate", null, { shouldDirty: true });
  }, [resetField, setValue, dateLimitEnabled, getValues]);

  useEffect(() => {
    if (weekDayLimitEnabled) {
      resetField("weekDaysBitmask");
      return;
    }
    setValue("weekDaysBitmask", null, { shouldDirty: true });
  }, [resetField, setValue, weekDayLimitEnabled]);

  const start = new Date(watch("fromDate") ?? new Date());
  const startDate = new CalendarDate(
    start.getUTCFullYear(),
    start.getUTCMonth() + 1,
    start.getUTCDate(),
  );

  const end = new Date(watch("toDate") ?? new Date());
  const endDate = new CalendarDate(
    end.getUTCFullYear(),
    end.getUTCMonth() + 1,
    end.getUTCDate(),
  );

  return (
    <Stack spacing={4}>
      <Text fontSize="sm">
        Scheduling allows a fine grained control over when reserved instances
        are available. All enabled conditions must match for the rule to be
        applied.
      </Text>

      <FormControl isInvalid={!!formState.errors.timeZoneId}>
        <FormLabel>Time Zone</FormLabel>
        <Controller
          control={control}
          name="timeZoneId"
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          render={({ field: { ref, ...fieldProps } }) => {
            return <TimeZonesDropDown {...fieldProps} />;
          }}
        />
        <FormErrorMessage>
          {formState.errors.timeZoneId?.message}
        </FormErrorMessage>

        <FormHelperText>Time Zone to use for scheduling.</FormHelperText>
      </FormControl>

      <FormControl isInvalid={!!formState.errors.weekDaysBitmask}>
        <Stack>
          <Checkbox
            isChecked={weekDayLimitEnabled}
            onChange={(c) => setWeekDayLimitEnabled(c.target.checked)}
          >
            <FormLabel margin={0}>Week Days</FormLabel>
          </Checkbox>

          <Controller
            control={control}
            name="weekDaysBitmask"
            render={({ field: { value, onChange, ...fieldProps } }) => {
              return (
                <Wrap hidden={!weekDayLimitEnabled}>
                  <CheckboxGroup
                    {...omit(fieldProps, ["ref"])}
                    isDisabled={!weekDayLimitEnabled}
                  >
                    {WeekDay.map((day) => {
                      const isChecked =
                        value === undefined ||
                        value === null ||
                        isBit(value, WeekDay.indexOf(day));
                      return (
                        <Checkbox
                          key={day}
                          paddingRight={2}
                          isChecked={isChecked}
                          onChange={() => {
                            onChange(toggleBit(value, WeekDay.indexOf(day)));
                          }}
                        >
                          {day}
                        </Checkbox>
                      );
                    })}
                  </CheckboxGroup>
                </Wrap>
              );
            }}
          />
        </Stack>
        <FormErrorMessage>
          {formState.errors.weekDaysBitmask?.message}
        </FormErrorMessage>

        <FormHelperText>
          Week days where instances should be available. If not enabled
          instances are available every week day.
        </FormHelperText>
      </FormControl>

      <Divider />

      <FormControl isInvalid={!!formState.errors.fromDate}>
        <Checkbox
          isChecked={dateLimitEnabled}
          onChange={(c) => setDateLimitEnabled(c.target.checked)}
        >
          <FormLabel margin={0}>Date Limitation</FormLabel>
        </Checkbox>
        <Box hidden={!dateLimitEnabled}>
          <DateRangePicker
            isDisabled={!dateLimitEnabled}
            value={{
              start: startDate,
              end: endDate,
            }}
            onChange={(c) => {
              const start = c.start.toDate("utc");
              start.setUTCHours(0, 0, 0, 0);
              const end = c.end.toDate("utc");
              end.setUTCHours(23, 59, 59, 0);

              setValue("fromDate", start.toISOString(), {
                shouldDirty: true,
              });
              setValue("toDate", end.toISOString(), {
                shouldDirty: true,
              });
            }}
            label="Pick a date range for the report"
          />
        </Box>
        <FormErrorMessage>
          {formState.errors.fromDate?.message}
          {formState.errors.toDate?.message}
        </FormErrorMessage>
        <FormHelperText>
          Limit the date range where instances should be available. If not
          enabled instances are available untill changed.
        </FormHelperText>
      </FormControl>

      <Divider />

      <FormControl
        isInvalid={!!formState.errors.fromTime || !!formState.errors.toTime}
      >
        <Checkbox
          isChecked={timeLimitEnabled}
          onChange={(c) => setTimeLimitEnabled(c.target.checked)}
        >
          <FormLabel margin={0}>Time Limitation</FormLabel>
        </Checkbox>
        <HStack paddingTop={2} hidden={!timeLimitEnabled}>
          <FormLabel margin={0}>From:</FormLabel>
          <Controller
            control={control}
            name="fromTime"
            render={({ field: { value, onChange, ...fieldProps } }) => (
              <Input
                {...omit(fieldProps, ["ref"])}
                value={value ?? undefined}
                onChange={(c) => onChange(c.target.value + ":00")}
                type="time"
                disabled={!timeLimitEnabled}
              />
            )}
          />
          <FormLabel margin={0}>To:</FormLabel>
          <Controller
            control={control}
            name="toTime"
            render={({ field: { value, onChange, ...fieldProps } }) => (
              <Input
                {...omit(fieldProps, ["ref"])}
                value={value ?? undefined}
                onChange={(c) => onChange(c.target.value + ":00")}
                type="time"
                disabled={!timeLimitEnabled}
              />
            )}
          />
        </HStack>
        <FormErrorMessage>
          {formState.errors.fromTime?.message}
          {formState.errors.toTime?.message}
        </FormErrorMessage>
        <FormHelperText>
          Limit the time range where instances should be available. If not
          enabled instances are available all a day.
        </FormHelperText>
      </FormControl>
    </Stack>
  );
};
