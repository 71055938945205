import { HStack, Spacer, Stack, StackProps } from "@chakra-ui/react";
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import {
  addApplicationToCategory,
  removeApplicationFromCategory,
} from "../../backend/api";
import { Application, ApplicationId } from "../../backend/types";
import { ApplicationSelect, DeleteIconButton, NoData } from "../../components";
import { LazyApplicationDisplay } from "../../components/ApplicationDisplay";
import { DragHandle } from "../../components/DragHandle";
import {
  getQueryKeyForApplicationCategory,
  useApplicationCategoryQuery,
} from "../../hooks/useApplicationCategoriesQuery";

export function SortableLazyApplicationDisplay({
  applicationId,
  onRemove,
  ...props
}: {
  applicationId: ApplicationId;
  onRemove: () => void;
} & StackProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    setActivatorNodeRef,
  } = useSortable({ id: applicationId });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <HStack ref={setNodeRef} style={style}>
      <DragHandle
        attributes={attributes}
        listeners={listeners}
        cursor={isDragging ? "grabbing" : "grab"}
        padding={2}
        _hover={{ color: "brand-bg" }}
        ref={setActivatorNodeRef}
        isDragging={isDragging}
      />
      <LazyApplicationDisplay
        applicationId={applicationId}
        maxWidth={"lg"}
        {...props}
      />
      <Spacer />
      <DeleteIconButton
        variant="ghost"
        aria-label="Remove application from group"
        onClick={onRemove}
      />
    </HStack>
  );
}

export function ApplicationCategoryMemberships({
  applicationCategoryId,
}: {
  applicationCategoryId: number;
}) {
  const queryClient = useQueryClient();
  const applicationGroupQuery = useApplicationCategoryQuery(
    applicationCategoryId,
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
  useEffect(() => {
    applicationGroupQuery.data &&
      setApplicationIds(applicationGroupQuery.data.applications);
  }, [applicationGroupQuery.data]);
  const [applicationIds, setApplicationIds] = useState<ApplicationId[]>(
    applicationGroupQuery.data?.applications ?? [],
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active.id && over?.id && active.id !== over.id) {
      const oldIndex = applicationIds.findIndex((id) => id === active.id);
      const newIndex = applicationIds.findIndex((id) => id === over.id);
      // use the api to reorder the applications
      updateApplicationOrderMutation.mutate({
        applicationId: active.id.toString(),
        order: newIndex,
      });
      // and immedately update the local state for a smooth UX
      setApplicationIds(arrayMove(applicationIds, oldIndex, newIndex));
    }
  }

  const updateApplicationOrderMutation = useMutation<
    void,
    AxiosError,
    { applicationId: ApplicationId; order: number }
  >({
    mutationFn: async ({ applicationId, order }) => {
      await removeApplicationFromCategory(applicationId, applicationCategoryId);
      await addApplicationToCategory(
        applicationId,
        applicationCategoryId,
        order,
      );
    },
    onSuccess: () =>
      // refetch the category details after adding an app
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationCategory(applicationCategoryId),
      }),
  });

  const addApplicationToCategoryMutation = useMutation<
    void,
    AxiosError,
    { application: Application; order: number },
    { previousApplicationIds: ApplicationId[] }
  >({
    mutationFn: async ({ application, order }) => {
      await addApplicationToCategory(
        application.id,
        applicationCategoryId,
        order,
      );
    },
    onMutate: ({ application }) => {
      const previousApplicationIds = applicationIds;
      // add the application to the local state immediately
      setApplicationIds((ids) => [...ids, application.id]);
      return { previousApplicationIds };
    },
    onSettled(_data, error, _variables, context) {
      if (error && context) {
        // remove the application from the local state if the request fails
        setApplicationIds(context.previousApplicationIds);
      }
      // refetch the category details after adding an app
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationCategory(applicationCategoryId),
      });
    },
  });
  const removeApplicationFromCategoryMutation = useMutation<
    void,
    AxiosError,
    ApplicationId,
    { previousApplicationIds: ApplicationId[] }
  >({
    mutationFn: async (applicationId) => {
      await removeApplicationFromCategory(applicationId, applicationCategoryId);
    },
    onMutate: (applicationId) => {
      const previousApplicationIds = applicationIds;
      // add the application to the local state immediately
      setApplicationIds((ids) => [...ids.filter((id) => id !== applicationId)]);
      return { previousApplicationIds };
    },
    onSettled(_data, error, _variables, context) {
      if (error && context) {
        // re-add the application to the local state if the request fails
        setApplicationIds(context.previousApplicationIds);
      }
      // refetch the category details after adding an app
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationCategory(applicationCategoryId),
      });
    },
  });

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <Stack spacing={4}>
        {applicationIds.length ? (
          <SortableContext
            strategy={verticalListSortingStrategy}
            items={applicationIds}
            disabled={addApplicationToCategoryMutation.isPending}
          >
            {applicationIds.map((applicationId) => (
              <SortableLazyApplicationDisplay
                role="group"
                key={applicationId}
                applicationId={applicationId}
                onRemove={() =>
                  removeApplicationFromCategoryMutation.mutate(applicationId)
                }
              />
            ))}
          </SortableContext>
        ) : (
          <NoData
            height={"xs"}
            title={"No applications"}
            text={
              "It looks like this category does not contain any applications yet. Use the search form below to find and add some."
            }
          />
        )}
        <ApplicationSelect
          onSelect={(application) =>
            application &&
            addApplicationToCategoryMutation.mutate({
              application: application,
              order: applicationIds.length,
            })
          }
          filter={(application) => !applicationIds.includes(application.id)}
        />
      </Stack>
    </DndContext>
  );
}
