import { XRPlatformType } from "../../backend/types";

export function getXRPlatformDisplayText(xrPlatform: XRPlatformType) {
  switch (xrPlatform) {
    case "win-non-vr":
      return "Non-VR";
    case "win-vr":
      return "VR";
    case "pico":
      return "Pico";
    case "quest":
      return "Meta Quest";
    case "wave":
      return "Vive";
    case "vrx":
      return "Lenovo VRX";
    default:
      return xrPlatform;
  }
}
