import { Text, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { deleteApplication } from "../../backend/api";
import { Application } from "../../backend/types";
import { useConfirm } from "../../confirm-dialog";
import { baseQueryKeyForApplicationBuilds } from "../../hooks/useApplicationBuildsQuery";
import { baseQueryKeyForApplications } from "../../hooks/useApplicationQuery";
import { namedRoutes } from "../../routes";
import { ApplicationDeleteConfirmBody } from "../components/ApplicationDeleteConfirmBody";
import { LazyApplicationDisplayName } from "../components/LazyApplicationDisplayName";

export function useApplicationDeleteConfirm() {
  const { confirm } = useConfirm();
  const { mutateAsync: deleteApplicationAsync } = useMutation<
    void,
    AxiosError,
    Pick<Application, "id">
  >({ mutationFn: (application) => deleteApplication(application.id) });
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();

  return useCallback(
    (application: Pick<Application, "id">) => () => {
      confirm({
        title: (
          <Text>
            Delete <LazyApplicationDisplayName applicationId={application.id} />
          </Text>
        ),
        body: <ApplicationDeleteConfirmBody application={application} />,
        confirmButtonText: "Delete",
        onConfirm: () =>
          deleteApplicationAsync(application, {
            onSuccess: (_, app) => {
              navigate(namedRoutes.application.list);
              toast({
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
                description: (
                  <Text>
                    Application{" "}
                    <LazyApplicationDisplayName applicationId={app.id} /> has
                    been deleted.
                  </Text>
                ),
              });
              queryClient.invalidateQueries({
                queryKey: baseQueryKeyForApplications,
              });
              queryClient.invalidateQueries({
                queryKey: baseQueryKeyForApplicationBuilds,
              });
            },
          }),
      });
    },
    [deleteApplicationAsync, confirm, navigate, queryClient, toast],
  );
}
