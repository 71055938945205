import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  HStack,
  IconButton,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
  StackDivider,
} from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";
import {
  MdLink as AddLinkIcon,
  MdFormatBold as BoldIcon,
  MdCode as CodeIcon,
  MdFormatItalic as ItalicIcon,
  MdFormatListBulleted as ListBulletedIcon,
  MdFormatListNumbered as ListNumberedIcon,
  MdFormatQuote as QuoteIcon,
  MdLinkOff as RemoveLinkIcon,
  MdFormatUnderlined as UnderlinedIcon,
} from "react-icons/md";
import { useSlate, useSlateSelection } from "slate-react";
import {
  Address,
  Formatted,
  HeadingFive,
  HeadingFour,
  HeadingOne,
  HeadingSix,
  HeadingThree,
  HeadingTwo,
} from "./components";
import {
  insertLink,
  isBlockActive,
  isLinkActive,
  isMarkActive,
  toggleBlock,
  toggleMark,
  unwrapLink,
} from "./utils";

const ToolbarButton = (props: IconButtonProps) => (
  <IconButton variant="ghost" size="md" {...props} />
);

export const MarkButton = ({
  format,
  icon,
}: {
  format: string;
  icon: ReactElement;
}) => {
  const editor = useSlate();
  return (
    <ToolbarButton
      isActive={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      aria-label={format}
      icon={icon}
    />
  );
};

export const BlockButton = ({
  format,
  icon,
}: {
  format: string;
  icon: ReactElement;
}) => {
  const editor = useSlate();
  return (
    <ToolbarButton
      isActive={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      aria-label={format}
      icon={icon}
    />
  );
};

export const AddLinkButton = () => {
  const editor = useSlate();
  const selection = useSlateSelection();

  const hasValidSelection = useMemo(() => {
    return !!selection && selection.anchor.offset !== selection.focus.offset;
  }, [selection]);

  return (
    <ToolbarButton
      onMouseDown={(event) => {
        event.preventDefault();
        const url = window.prompt("Enter the URL of the link:");
        if (!url) return;
        insertLink(editor, url);
      }}
      isDisabled={!hasValidSelection}
      aria-label="Add link"
      icon={<AddLinkIcon />}
    />
  );
};

export const RemoveLinkButton = () => {
  const editor = useSlate();

  return (
    <ToolbarButton
      isActive={isLinkActive(editor)}
      onMouseDown={() => {
        if (isLinkActive(editor)) {
          unwrapLink(editor);
        }
      }}
      isDisabled={!isLinkActive(editor)}
      aria-label="Remove link"
      icon={<RemoveLinkIcon />}
    />
  );
};

export const FormattingMenuItem = ({
  format,
  ...props
}: { format: string } & MenuItemProps) => {
  const editor = useSlate();
  return (
    <MenuItem
      onClick={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      {...props}
    />
  );
};

export const Toolbar = () => {
  return (
    <HStack borderWidth={"0 0 1px 0"} padding={1} spacing={1} wrap={"wrap"}>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          size="sm"
          variant="ghost"
        >
          Style
        </MenuButton>
        <MenuList>
          <FormattingMenuItem format="paragraph">Normal</FormattingMenuItem>
          <FormattingMenuItem format="heading-one">
            <HeadingOne>Heading 1</HeadingOne>
          </FormattingMenuItem>
          <FormattingMenuItem format="heading-two">
            <HeadingTwo>Heading 2</HeadingTwo>
          </FormattingMenuItem>
          <FormattingMenuItem format="heading-three">
            <HeadingThree>Heading 3</HeadingThree>
          </FormattingMenuItem>
          <FormattingMenuItem format="heading-four">
            <HeadingFour>Heading 4</HeadingFour>
          </FormattingMenuItem>
          <FormattingMenuItem format="heading-five">
            <HeadingFive>Heading 5</HeadingFive>
          </FormattingMenuItem>
          <FormattingMenuItem format="heading-six">
            <HeadingSix>Heading 6</HeadingSix>
          </FormattingMenuItem>
          <FormattingMenuItem format="formatted">
            <Formatted>Formatted</Formatted>
          </FormattingMenuItem>
          <FormattingMenuItem format="address">
            <Address>Address</Address>
          </FormattingMenuItem>
        </MenuList>
      </Menu>
      <MarkButton format="bold" icon={<BoldIcon />} />
      <MarkButton format="italic" icon={<ItalicIcon />} />
      <MarkButton format="underline" icon={<UnderlinedIcon />} />
      <MarkButton format="code" icon={<CodeIcon />} />
      <BlockButton format="block-quote" icon={<QuoteIcon />} />
      <BlockButton format="numbered-list" icon={<ListNumberedIcon />} />
      <BlockButton format="bulleted-list" icon={<ListBulletedIcon />} />
      <StackDivider />
      <AddLinkButton />
      <RemoveLinkButton />
    </HStack>
  );
};
