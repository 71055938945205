import { InfoIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  CircularProgress,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { intersection, omit, pick } from "remeda";
import { parse } from "semver";
import { InferType } from "yup";
import { ApplicationBuild, PortalError } from "../../backend";
import {
  createApplicationBuild,
  updateApplicationBuild,
} from "../../backend/api";
import { XRPlatformType } from "../../backend/types";
import { FormRootErrorMessage } from "../../components";
import { Step, StepProps } from "../../components/Stepper";
import { DevTool } from "../../hookform-devtools";
import { usePromptDirtyForm } from "../../hooks";
import {
  getQueryKeyForApplicationBuildsPaginated,
  useApplicationBuildsPaginatedQuery,
} from "../../hooks/useApplicationBuildsQuery";
import { extractFormSubmissionErrors } from "../../utils/extractFormSubmissionErrors";
import { useAddApplicationContext } from "../AddApplicationContext";
import { LaunchConfigurationsPicker } from "../components";
import { getQueryKeyForApplicationLaunchConfigurationsQuery } from "../hooks/useApplicationLaunchConfigurationsQuery";
import {
  applicationBuildMetaDataSchema,
  applicationBuildVersionSchema,
} from "./schema";
import { getDefaultXRPlatforms, getPossibleXRPlatforms } from "./utils";

type ApplicationBuildMetaDataSchema = InferType<
  typeof applicationBuildMetaDataSchema
>;

function filterApplicationBuildsWithSameSupportedXRPlatforms(
  xrPlatforms: XRPlatformType[],
) {
  return (build: ApplicationBuild) =>
    intersection(build.supported_xr_platforms ?? [], xrPlatforms).length !== 0;
}

export function AddApplicationBuildMetadataStep({ ...props }: StepProps) {
  const queryClient = useQueryClient();
  const { goToNext, goToPrev } = props;
  const {
    state: {
      upload: { fileUrl = "", originalFileName },
      baseData,
      applicationBuild: {
        id: applicationBuildId,
        target_platform: applicationTargetPlatform,
        ...applicationBuildData
      },
    },
    dispatch,
  } = useAddApplicationContext();

  // find all existing builds of the application to ensure the new version number is unique
  const applicationBuildsQuery = useApplicationBuildsPaginatedQuery({
    application: baseData?.application,
    ordering: "-version",
    page_size: 100,
  });
  const mergedApplicationBuildData = {
    ...baseData,
    ...applicationBuildData,
    application_archive: originalFileName,
  };
  const {
    handleSubmit,
    setError,
    register,
    setValue,
    trigger,
    formState: { errors, isSubmitting, isValid, dirtyFields, isDirty },
    control,
    watch,
  } = useForm<ApplicationBuildMetaDataSchema>({
    resolver: (values, context, options) => {
      return yupResolver(
        applicationBuildMetaDataSchema.shape({
          version: applicationBuildVersionSchema.notOneOf(
            (applicationBuildsQuery.data?.results ?? [])
              // only ignore builds that (partially) match the current xr platforms
              .filter(
                filterApplicationBuildsWithSameSupportedXRPlatforms(
                  values.supported_xr_platforms,
                ),
              )
              .map((build) => build.version)
              .filter((version) => version !== applicationBuildData.version),
            // eslint-disable-next-line no-template-curly-in-string
            "Version already exists! Please use a different version.",
          ),
        }),
      )(values, context, options);
    },
    defaultValues: {
      supported_xr_platforms: baseData?.supported_xr_platforms,
    },
    mode: "onChange",
  });

  // prevent the user from closing the tab while uploading
  usePromptDirtyForm({ formState: { isSubmitting, isDirty } });

  const selectedSupportedXRPlatforms = watch("supported_xr_platforms");
  useEffect(() => {
    if (dirtyFields.version) return;
    // auto increment the version number (patch + 1) unless the user changed it
    if (applicationBuildsQuery.data) {
      const nextVersion =
        parse(
          applicationBuildsQuery.data.results.filter(
            filterApplicationBuildsWithSameSupportedXRPlatforms(
              selectedSupportedXRPlatforms,
            ),
          )?.[0]?.version,
        )?.inc("patch").version ?? "1.0.0";
      if (nextVersion) {
        setValue("version", nextVersion);
        trigger("version");
      }
    }
  }, [
    applicationBuildsQuery.data,
    dirtyFields.version,
    setValue,
    trigger,
    selectedSupportedXRPlatforms,
  ]);

  useEffect(() => {
    if (fileUrl) {
      setValue("application_archive", fileUrl, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [setValue, fileUrl, trigger]);

  useEffect(() => {
    if (dirtyFields.supported_xr_platforms || !fileUrl) return;
    // update the default supported launch configurations (if not set yet)
    setValue(
      "supported_xr_platforms",
      getDefaultXRPlatforms({
        application_archive: fileUrl,
        supported_xr_platforms:
          mergedApplicationBuildData.supported_xr_platforms,
      }),
      { shouldDirty: false, shouldValidate: true },
    );
  }, [
    setValue,
    fileUrl,
    dirtyFields.supported_xr_platforms,
    mergedApplicationBuildData.supported_xr_platforms,
  ]);

  const { mutateAsync: upsertApplicationBuildAsync } = useMutation<
    ApplicationBuild,
    AxiosError<PortalError>,
    ApplicationBuildMetaDataSchema
  >({
    mutationFn: async (values: ApplicationBuildMetaDataSchema) => {
      if (!baseData.application) {
        throw new Error("Missing application");
      }
      let applicationBuild: ApplicationBuild;
      if (applicationBuildId) {
        // update existing application
        applicationBuild = await updateApplicationBuild(
          applicationBuildId,
          pick(values, ["version", "changelog", "supported_xr_platforms"]),
        );
      } else {
        // Create new application version
        let omittedKeys: (keyof Pick<
          ApplicationBuild,
          | "package_name"
          | "target_platform"
          | "supported_xr_platforms"
          | "executable_path"
        >)[] = [
          // remove the package name since it will anyhow be identified by the service
          "package_name",
          "target_platform",
        ];
        // also remove supported launch configurations if target platform changed from android to windows or vice versa
        if (values.application_archive.endsWith(".apk")) {
          omittedKeys = [
            ...omittedKeys,
            "supported_xr_platforms",
            "executable_path",
          ];
        } else if (values.application_archive.endsWith(".zip")) {
          omittedKeys = [...omittedKeys, "supported_xr_platforms"];
        }

        applicationBuild = await createApplicationBuild({
          ...omit(mergedApplicationBuildData, omittedKeys),
          ...values,
          application: baseData.application,
        });
      }

      return applicationBuild;
    },

    onSuccess: async (applicationBuild) => {
      dispatch({
        type: "APPLICATION_BUILD_CREATE:SUCCESS",
        data: applicationBuild,
      });
      await queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationLaunchConfigurationsQuery(
          applicationBuild.application,
        ),
      });
      await queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationBuildsPaginated({
          application: applicationBuild.application,
        }).splice(0, 2),
      });
      goToNext && goToNext();
    },
  });

  const onSubmit = useCallback(
    async (values: ApplicationBuildMetaDataSchema) => {
      try {
        await upsertApplicationBuildAsync(values);
      } catch (error) {
        extractFormSubmissionErrors(
          error,
          applicationBuildMetaDataSchema,
        ).forEach(([field, message]) =>
          setError(field, { type: "server", message }),
        );
      }
    },
    [upsertApplicationBuildAsync, setError],
  );

  return (
    <Step
      icon={
        isSubmitting ? (
          <CircularProgress size={8} isIndeterminate bg="modal-bg" />
        ) : undefined
      }
      actionButtons={
        <HStack>
          <Button size="sm" onClick={goToPrev} isDisabled={isSubmitting}>
            Back
          </Button>
          <Tooltip
            hasArrow={true}
            label={
              !fileUrl
                ? "Please wait until your application archive is successfully uploaded"
                : undefined
            }
          >
            <Button
              size="sm"
              colorScheme={"brand"}
              isLoading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              isDisabled={!isValid}
            >
              Save
            </Button>
          </Tooltip>
        </HStack>
      }
      {...props}
      title={"Provide information"}
    >
      <Text>
        While your upload is being processed, please share some details about
        your application.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <FormControl isInvalid={!!errors.application_archive}>
            <Input type="hidden" {...register("application_archive")} />
            <FormErrorMessage>
              {errors.application_archive?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.supported_xr_platforms}>
            <FormLabel htmlFor="supported_xr_platforms">
              Platform Support
            </FormLabel>
            <FormHelperText marginBottom={2}>
              Which (XR) platforms are supported by this build?{" "}
              <Tooltip label="Sessions running on any of the platforms selected here will use the newly uploaded build from now on.">
                <InfoIcon />
              </Tooltip>
            </FormHelperText>
            <Controller
              control={control}
              render={({ field: { ref, ...fieldProps } }) => (
                <LaunchConfigurationsPicker
                  {...fieldProps}
                  values={getPossibleXRPlatforms({
                    ...baseData,
                    ...applicationBuildData,
                    application_archive: originalFileName,
                  })}
                />
              )}
              name="supported_xr_platforms"
            />
            <FormErrorMessage>
              {errors.supported_xr_platforms?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.version}>
            <FormLabel htmlFor="version">Version</FormLabel>
            <InputGroup>
              <InputLeftAddon>v</InputLeftAddon>
              <Input
                id="version"
                placeholder="1.0.x"
                {...register("version")}
              />
            </InputGroup>
            <FormErrorMessage>{errors.version?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.changelog}>
            <FormLabel htmlFor="changelog">Changelog</FormLabel>
            <Textarea
              {...register("changelog")}
              placeholder="Describe the relevant changes of this new version. You can use Markdown."
            />
            <FormErrorMessage>{errors.changelog?.message}</FormErrorMessage>
          </FormControl>

          <FormRootErrorMessage formState={{ errors }} />

          {!fileUrl && (
            <Alert status="info">
              <AlertIcon />
              <AlertDescription>
                Please wait until your application is fully uploaded.
              </AlertDescription>
            </Alert>
          )}

          {/* Hidden submit button to ensure form can be submitted with return key */}
          <Button type="submit" hidden></Button>
        </Stack>
        <DevTool control={control} />
      </form>
    </Step>
  );
}
