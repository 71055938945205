import { useMemo } from "react";
import {
  Capability,
  CapabilityResultType,
} from "../applications/ApplicationCapability";
import { Application } from "../backend";
import { XRPlatformType, windowsXRPlatformTypes } from "../backend/types";

const launchConfigurationsSupportingCloudRendering: XRPlatformType[] =
  windowsXRPlatformTypes;
const launchConfigurationsSupportingVR: XRPlatformType[] = [
  "win-vr",
  "quest",
  "vrx",
  "pico",
  "wave",
];
const launchConfigurationsSupportingWindowsDesktop: XRPlatformType[] =
  windowsXRPlatformTypes;

export function applicationSupportsCloudRendering(
  application: Pick<Application, "launch_configurations">,
): boolean {
  return application.launch_configurations
    .filter(({ application_build: build }) => !!build)
    .some((launch_configuration) =>
      launchConfigurationsSupportingCloudRendering.includes(
        launch_configuration.xr_platform,
      ),
    );
}

export function useApplicationCapabilities(
  application?: Pick<Application, "launch_configurations">,
): Capability[] {
  return useMemo(
    () => [
      {
        type: "cloud-rendering",
        text: "Cloud Rendering",
        result:
          application && applicationSupportsCloudRendering(application)
            ? CapabilityResultType.Yes
            : CapabilityResultType.No,
      },
      {
        type: "non-VR",
        text: "Non-VR mode (e.g. with mouse and keyboard)",
        result:
          application &&
          application.launch_configurations.some(({ xr_platform: type }) =>
            launchConfigurationsSupportingWindowsDesktop.includes(type),
          )
            ? CapabilityResultType.Yes
            : CapabilityResultType.No,
      },
      {
        type: "vr",
        text: "VR-mode",
        result:
          application &&
          application.launch_configurations.some(({ xr_platform: type }) =>
            launchConfigurationsSupportingVR.includes(type),
          )
            ? CapabilityResultType.Yes
            : CapabilityResultType.No,
      },
    ],
    [application],
  );
}
