import { Alert, AlertIcon, HStack, Stack, Text } from "@chakra-ui/react";
import { getLocalTimeZone } from "@internationalized/date";
import dayjs from "dayjs";
import { SessionsTable } from "../../components";
import { DateRangePicker } from "../../components/date-range-picker";
import { useDateRange } from "../../hooks";
import { useSessionListContext } from "../../sessions/SessionsListContext";
import { columns as sessionColumns } from "../../sessions/table-columns";

const columns = sessionColumns
  // remove application column
  .filter((col) => col.id !== "application")
  // move user column to the front
  .sort((a) => (a.id === "user" ? -1 : 0));

type ApplicationSessionOverviewProps = Pick<
  ReturnType<typeof useDateRange>,
  "defaultDateRange" | "minDate" | "maxDate" | "updateDateRange"
>;

// TODO: allow filtering by session properties on table
export function ApplicationSessionOverview({
  defaultDateRange,
  minDate,
  maxDate,
  updateDateRange,
}: ApplicationSessionOverviewProps) {
  const {
    filters: { from, to },
    query: applicationSessionsQuery,
  } = useSessionListContext();
  const totalResults = applicationSessionsQuery.data?.totalResults;

  return (
    <Stack spacing={4}>
      <HStack>
        <Text whiteSpace={"nowrap"}>Showing data for timespan</Text>
        <DateRangePicker
          defaultValue={defaultDateRange}
          minValue={minDate}
          maxValue={maxDate}
          onChange={updateDateRange}
          label="Pick a date range for the report"
        />
        <Text fontSize="sm" whiteSpace={"nowrap"} color="chakra-subtle-text">
          (Timezone: &quot;{getLocalTimeZone()}&quot;)
        </Text>
      </HStack>
      {totalResults === 0 ? (
        <Alert status="info" variant="left-accent">
          <AlertIcon />
          It seems like there was no activity between {dayjs(from).format(
            "L",
          )}{" "}
          and {dayjs(to).format("L")}.
        </Alert>
      ) : (
        <SessionsTable columns={columns} />
      )}
    </Stack>
  );
}
