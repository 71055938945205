import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useMemo } from "react";

dayjs.extend(duration);

export function usePreviousTimeSpan(from: Date, to: Date) {
  const duration = useMemo(
    () => dayjs.duration(to.getTime() - from.getTime(), "milliseconds"),
    [from, to],
  );

  return {
    from: useMemo(
      () => dayjs(from).subtract(duration).subtract(1, "millisecond").toDate(),
      [duration, from],
    ),
    to: useMemo(() => dayjs(from).subtract(1, "millisecond").toDate(), [from]),
  };
}
