import { useDashboardParameters } from "../context";
import { usePreviousTimeSpan } from "../hooks/usePreviousTimeSpan";
import { useSessionsConcurrencyStatsQuery } from "../hooks/useSessionsStatsQuery";
import { PeriodStatCompare } from "./PeriodStatCompare";

export function ConcurrentUsers() {
  const { from, to, ...filters } = useDashboardParameters();
  const currentPeriodStatQuery = useSessionsConcurrencyStatsQuery({
    queryOptions: {
      select: (data) => [{ count: data.maxConcurrentSessions, key: "total" }],
    },
    from,
    to,
    ...filters,
  });
  const previousPeriodStatQuery = useSessionsConcurrencyStatsQuery({
    queryOptions: {
      select: (data) => [{ count: data.maxConcurrentSessions, key: "total" }],
    },
    ...usePreviousTimeSpan(from, to),
    ...filters,
  });

  return (
    <PeriodStatCompare
      currentPeriodStatQuery={currentPeriodStatQuery}
      previousPeriodStatQuery={previousPeriodStatQuery}
      title={"Peak concurrency"}
      label={`# of users`}
    />
  );
}
