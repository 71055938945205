import { Button, chakra, Stack } from "@chakra-ui/react";
import React from "react";
import { SiEpicgames as _EpicGamesIcon } from "react-icons/si";
import _AzureIcon from "../img/azure.svg?react";
import _GoogleIcon from "../img/google.svg?react";
import { authProviders } from "./authProviders";

const icons: Record<string, ReturnType<typeof chakra>> = {
  "google-oauth2": chakra(_GoogleIcon),
  "azuread-oauth2": chakra(_AzureIcon),
  "epic-games": chakra(_EpicGamesIcon),
};

export function SSOButtonGroup() {
  return (
    <Stack spacing="4">
      {authProviders.map(({ name, url, slug }) => (
        <Button
          as="a"
          width="full"
          variant="outline"
          display="flex"
          alignItems={"center"}
          href={url}
          key={slug}
        >
          {slug in icons
            ? React.createElement(icons[slug], {
                height: 5,
                marginRight: 2,
              })
            : null}{" "}
          {name}
        </Button>
      ))}
    </Stack>
  );
}
