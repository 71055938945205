import { Region } from "../session-management";

export function CloudRenderingRegionTypeDisplay({
  type,
}: {
  type: Region["type"];
}) {
  return (
    <>
      {type === "WavelengthZone"
        ? "Wavelength Zone"
        : type === "LocalZone"
        ? "Local Zone"
        : "Region"}
    </>
  );
}
