import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Flex,
} from "@chakra-ui/react";
import { ReactNode, useMemo } from "react";
import { Outlet } from "react-router-dom";
import {
  Match,
  MatchWithHandle,
  useMatchesWithHandle,
} from "../hooks/useMatchesWithHandle";
import { Header } from "./Header";
import { PageTitle } from "./PageTitle";
import { SideBar } from "./SideBar";

type CrumbCreator = (arg: Match) => ReactNode;

type MatchWithCrumb = Omit<MatchWithHandle, "handle"> & {
  handle: { crumb: CrumbCreator };
};

export function Layout() {
  const matchesWithHandles = useMatchesWithHandle();
  const crumbs = useMemo<ReactNode[]>(
    () =>
      matchesWithHandles
        .filter((match): match is MatchWithCrumb => !!match.handle.crumb)
        // now map them into an array of elements, passing the loader
        // data to each one
        .map<ReactNode>((match) => match.handle.crumb(match)),
    [matchesWithHandles],
  );

  return (
    <>
      <PageTitle />
      <Container maxW={1690} display="flex" flexDirection={"column"}>
        <Flex flexGrow="1" minHeight="100vh">
          <Flex flexDirection="column" flexGrow="0" flexShrink="0" width={64}>
            <SideBar position="sticky" top={0} paddingX={6} paddingBottom={4} />
          </Flex>
          <Flex flexDirection="column" flexGrow={1}>
            <Header
              position="sticky"
              top={0}
              bg="chakra-body-bg"
              zIndex="sticky"
            />
            <Box padding={6} paddingLeft={6} borderLeftWidth={1}>
              <Breadcrumb
                spacing="8px"
                separator={<ChevronRightIcon color="gray.500" />}
              >
                {crumbs.length > 1 &&
                  crumbs.map((crumb, index) => (
                    <BreadcrumbItem key={index}>{crumb}</BreadcrumbItem>
                  ))}
              </Breadcrumb>
              <Outlet />
            </Box>
          </Flex>
        </Flex>
      </Container>
    </>
  );
}
