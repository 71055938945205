import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { getVmSizes, VmSizeInformation } from "../../session-management";

export function useVmSizesQuery(
  options: Partial<
    UseQueryOptions<VmSizeInformation[], Error, VmSizeInformation[], QueryKey>
  > = {},
) {
  const result = useQuery({
    queryKey: ["cloud-rendering-vm-sizes"],
    queryFn: getVmSizes,
    staleTime: 1800,
    refetchOnMount: false,
    ...options,
  });
  return result;
}
