import { Stack } from "@chakra-ui/react";
import { Application, ApplicationBuild } from "../backend";
import { Stepper } from "../components/Stepper";
import {
  Provider,
  addApplicationInitialState,
  useAddApplicationState,
} from "./AddApplicationContext";
import {
  AddApplicationBuildMetadataStep,
  FinalizeApplicationBuildStep,
  UploadApplicationBuildStep,
} from "./steps";
import { ValidateApplicationBuildStep } from "./steps/ValidateApplicationBuildStep";

export function AddApplicationBuildWizard({
  application,
  buildSeedData,
  onCompleted,
}: {
  application?: Application;
  buildSeedData?: Partial<ApplicationBuild>;
  onCompleted?: () => void;
}) {
  const [state, dispatch] = useAddApplicationState({
    ...addApplicationInitialState,
    application,
    baseData: {
      application: application?.id,
      version: buildSeedData?.version,
      package_name: buildSeedData?.package_name,
      executable_path: buildSeedData?.executable_path,
      target_platform: buildSeedData?.target_platform,
      supported_xr_platforms: buildSeedData?.supported_xr_platforms,
      supports_arbitrary_cli_args: buildSeedData?.supports_arbitrary_cli_args,
      launch_args: buildSeedData?.launch_args,
    },
  });

  return (
    <Provider value={{ state, dispatch }}>
      <Stack spacing={6}>
        <Stepper
          steps={[
            UploadApplicationBuildStep,
            AddApplicationBuildMetadataStep,
            ValidateApplicationBuildStep,
            FinalizeApplicationBuildStep,
          ]}
          onCompleted={onCompleted}
        />
      </Stack>
    </Provider>
  );
}
