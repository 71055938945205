import * as React from "react";
const SvgTelefonicaLogo = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 2750 2750", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(5.61628,0,0,5.61628,-480.273,-21.0126)" }, /* @__PURE__ */ React.createElement("path", { d: "M156.452,16.987C194.362,10.873 230.052,36.649 236.167,74.562C242.278,112.472 216.502,148.162 178.592,154.277C140.681,160.388 104.992,134.612 98.877,96.702C98.085,91.772 97.82,86.769 98.098,81.783C100.016,49.202 124.247,22.293 156.452,16.987Z", style: {
  fill: "rgb(46,105,179)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M319.146,17.029C357.007,10.885 392.677,36.592 398.824,74.449C404.969,112.31 379.259,147.979 341.401,154.124C303.543,160.272 267.871,134.565 261.726,96.707C261.359,94.447 261.105,92.173 260.959,89.887C258.765,54.263 283.918,22.768 319.146,17.029Z", style: {
  fill: "rgb(46,105,179)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M477.233,18.181C514.586,9.374 552.005,32.513 560.815,69.866C569.623,107.218 546.484,144.64 509.131,153.448C471.778,162.255 434.356,139.116 425.549,101.763C422.807,90.131 423.11,77.99 426.427,66.513C433.399,42.413 452.816,23.939 477.233,18.181Z", style: {
  fill: "rgb(46,105,179)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M314.369,180.917C351.722,172.102 389.146,195.239 397.961,232.588C406.771,269.94 383.639,307.366 346.286,316.18C308.937,324.995 271.511,301.858 262.697,264.509C257.928,244.306 262.443,223.038 274.996,206.511C284.751,193.671 298.674,184.623 314.369,180.917Z", style: {
  fill: "rgb(46,105,179)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M317.098,343.271C355.49,334.737 396.525,365.067 399.341,404.439C404.33,442.277 372.723,479.604 334.588,480.799C298.414,484.039 263.734,454.306 261.09,418.092C257.836,382.484 282.019,350.182 317.098,343.271Z", style: {
  fill: "rgb(46,105,179)",
  fillRule: "nonzero"
} })));
export default SvgTelefonicaLogo;
