import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Heading, Stack, Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { ExternalLinkButton } from "../components";
import { useBrandingQuery } from "../hooks";

export function PasswordResetComplete() {
  const { data: branding } = useBrandingQuery();
  const { state } = useLocation();
  const next = state?.next;

  return (
    <Stack spacing="4">
      <Heading size={{ base: "xs", md: "sm" }}>
        Your password has been set.
      </Heading>
      <Text size={{ base: "xs", md: "sm" }} fontWeight="normal">
        You may go ahead and login now.
      </Text>
      <ExternalLinkButton
        rightIcon={<ArrowForwardIcon />}
        colorScheme="brand"
        href={next ?? "/control-panel/portal-frontend/"}
      >
        {next
          ? "Proceed"
          : `Proceed to ${branding?.company_name} ${branding?.product_name}`}
      </ExternalLinkButton>
    </Stack>
  );
}
