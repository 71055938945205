import { Draft } from "immer";
import { createContext, Dispatch, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { ApplicationBuild } from "../backend";
import { Application, ApplicationId } from "../backend/types";

type AddApplicationState = {
  upload: {
    error?: string;
    fileUrl: string | null;
    inProgress: boolean;
    originalFileName?: string;
  };
  baseData: Partial<
    Pick<
      ApplicationBuild,
      | "version"
      | "package_name"
      | "executable_path"
      | "target_platform"
      | "supported_xr_platforms"
      | "launch_args"
      | "supports_arbitrary_cli_args"
    > & { application: ApplicationId }
  >;
  applicationBuild: Partial<ApplicationBuild>;
  application?: Application;
};

export const addApplicationInitialState: AddApplicationState = {
  upload: {
    error: undefined,
    fileUrl: null,
    inProgress: false,
  },
  applicationBuild: {
    id: undefined,
  },
  baseData: {},
};
interface BaseAction {
  type: string;
}
type ApplicationCreateAction = {
  type: "APPLICATION_CREATE:SUCCESS";
  data: Application;
} & BaseAction;
type ApplicationBuildCreateAction = {
  type: "APPLICATION_BUILD_CREATE:SUCCESS";
  data: ApplicationBuild;
} & BaseAction;
type ApplicationUploadAction = {
  type: "APPLICATION_BUILD_UPLOAD:SUCCESS";
  result: string | null;
} & BaseAction;
type ApplicationUploadStartedAction = {
  type: "APPLICATION_BUILD_UPLOAD:START";
  filename: string;
} & BaseAction;

type Actions =
  | ApplicationCreateAction
  | ApplicationBuildCreateAction
  | ApplicationUploadAction
  | ApplicationUploadStartedAction;

type AddApplicationContext = {
  state: AddApplicationState;
  dispatch: Dispatch<Actions>;
};
const addApplicationContext = createContext({} as AddApplicationContext);
export const { Provider } = addApplicationContext;

export function useAddApplicationContext() {
  return useContext(addApplicationContext);
}

const addApplicationReducer = (
  state: Draft<AddApplicationState>,
  action: Actions,
) => {
  switch (action.type) {
    case "APPLICATION_BUILD_CREATE:SUCCESS":
      state.applicationBuild = action.data;
      break;
    case "APPLICATION_BUILD_UPLOAD:SUCCESS":
      state.upload.fileUrl = action.result;
      state.upload.inProgress = false;
      break;
    case "APPLICATION_BUILD_UPLOAD:START":
      state.upload.originalFileName = action.filename;
      state.upload.inProgress = true;
      break;
    case "APPLICATION_CREATE:SUCCESS":
      state.baseData.application = action.data.id;
      state.application = action.data;
      break;
    default:
      throw new Error();
  }
};

export function useAddApplicationState(
  initialState = addApplicationInitialState,
) {
  return useImmerReducer(addApplicationReducer, initialState);
}
