import { HStack, Skeleton, Text } from "@chakra-ui/react";
import { ApplicationBuild, ApplicationBuildId } from "../../backend/types";
import { useApplicationBuildQuery } from "../../hooks";

export function LazyApplicationBuildDisplay({
  applicationBuildId,
}: {
  applicationBuildId?: ApplicationBuildId;
}) {
  const applicationBuildQuery = useApplicationBuildQuery(
    applicationBuildId ?? 0,
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!applicationBuildId,
    },
  );

  if (applicationBuildQuery.isError) {
    return (
      <Text color="red" as="span">
        Could not load data.
      </Text>
    );
  }

  if (applicationBuildQuery.isPending) {
    return (
      <HStack as="span" display="inline-flex">
        <Skeleton minWidth={48} color="chakra-subtle-text" as="span">
          Loading
        </Skeleton>
        <Skeleton minWidth={12} as="span">
          Loading
        </Skeleton>
      </HStack>
    );
  }

  return (
    <ApplicationBuildDisplay applicationBuild={applicationBuildQuery.data} />
  );
}

export function ApplicationBuildDisplay({
  applicationBuild,
}: {
  applicationBuild: Pick<
    ApplicationBuild,
    "version" | "executable_path" | "package_name"
  >;
}) {
  return (
    <>
      <Text as="span" fontStyle={"italic"}>
        {(
          applicationBuild.executable_path ??
          applicationBuild.package_name ??
          ""
        )

          .split("/")
          .at(-1) ?? "build.zip"}
        {" - "}
      </Text>
      <Text as="span" fontWeight={"bold"}>
        {applicationBuild.version ?? "a.b.c"}
      </Text>
    </>
  );
}
