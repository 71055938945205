import { InferType, array, number, object, ref, string } from "yup";
import {
  ApplicationNetworkSecurityRule,
  NetworkProtocol,
  NetworkSecurityRuleDirection,
  supportedNetworkProtocols,
} from "../../backend/types";

export const defaultCidrBlock = "0.0.0.0/0";

// generate me a regex to validate a CIDR block
// https://stackoverflow.com/a/17871737/104380

export const netwokSecurityRuleSchema = object({
  description: string().required("Name cannot be empty"),
  cidr_blocks: array(
    string()
      .required("CIDR block cannot be empty")
      .matches(
        /^([0-9]{1,3}\.){3}[0-9]{1,3}($|\/[0-9]{1,2})$/,
        "Invalid CIDR block",
      ),
  )
    .defined()
    .test("unique", "Only unique CIDR blocks allowed.", (value) =>
      value ? value.length === new Set(value)?.size : true,
    )
    .min(1, "At least one CIDR block is required"),
  direction: number<NetworkSecurityRuleDirection>()
    .defined()
    .oneOf([
      NetworkSecurityRuleDirection.Inbound,
      NetworkSecurityRuleDirection.Outbound,
    ])
    .default(NetworkSecurityRuleDirection.Inbound)
    .required(),
  from_port: number()
    .defined()
    .min(0)
    .required()
    .typeError("Port must be a number"),
  to_port: number()
    .defined()
    .min(0)
    .required()
    .min(ref("from_port"), "To port must be greater than from port")
    .typeError("Port must be a number"),
  protocol: string<NetworkProtocol>()
    .defined()
    .oneOf([...supportedNetworkProtocols])
    .required(),
}).noUnknown();

export type ApplicationNetworkSecurityRuleSchema = InferType<
  typeof netwokSecurityRuleSchema
>;

export type ApplicationNetworkSecurityRuleProps = {
  rule: ApplicationNetworkSecurityRule;
};
