import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import {
  getApplicationCategoriesPaginated,
  getApplicationCategory,
} from "../backend/api";
import {
  ApplicationCategory,
  ApplicationCategoryFilters,
  PaginatedApplicationCategoriesList,
} from "../backend/types";

export const baseQueryKeyForApplicationCategories = [
  "application-categories",
] as const;

export function getQueryKeyForApplicationCategories(
  filters: ApplicationCategoryFilters = {},
): QueryKey {
  return [...baseQueryKeyForApplicationCategories, filters];
}

export function getQueryKeyForApplicationCategory(id: number | string) {
  return [...baseQueryKeyForApplicationCategories, id.toString()];
}

export function useApplicationCategoriesListQuery(
  filters: ApplicationCategoryFilters = {},
  queryOptions: Partial<
    UseQueryOptions<PaginatedApplicationCategoriesList>
  > = {},
) {
  const queryClient = useQueryClient();

  return useQuery<PaginatedApplicationCategoriesList>({
    queryKey: getQueryKeyForApplicationCategories(filters),
    queryFn: async () => {
      const applicationCategoriesResponse =
        await getApplicationCategoriesPaginated(filters);

      applicationCategoriesResponse.results.forEach((category) =>
        queryClient.setQueryData<typeof category>(
          getQueryKeyForApplicationCategory(category.id),
          category,
        ),
      );
      return applicationCategoriesResponse;
    },
    ...queryOptions,
  });
}

export function useApplicationCategoryQuery(
  id: ApplicationCategory["id"],
  queryOptions: Partial<UseQueryOptions<ApplicationCategory>> = {},
) {
  return useQuery<ApplicationCategory>({
    queryKey: getQueryKeyForApplicationCategory(id),
    queryFn: () => getApplicationCategory(id),
    ...queryOptions,
  });
}
