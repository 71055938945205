import {
  Alert,
  AlertIcon,
  Button,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Link, generatePath } from "react-router-dom";
import { Step, StepProps } from "../../components/Stepper";
import { useStepperContext } from "../../components/StepperContext";
import { namedRoutes } from "../../routes";
import { useAddApplicationContext } from "../AddApplicationContext";

export function FinalizeApplicationStep({ goToPrev, ...props }: StepProps) {
  const {
    state: {
      baseData: { application: applicationId },
    },
  } = useAddApplicationContext();
  const { onCompleted } = useStepperContext();

  return (
    <Step
      actionButtons={
        <HStack>
          <Button
            size="sm"
            variant="ghost"
            isDisabled={!goToPrev}
            onClick={goToPrev ? goToPrev : undefined}
          >
            Back
          </Button>
          {applicationId && (
            <>
              <Button
                size="sm"
                colorScheme="brand"
                as={Link}
                to={generatePath(namedRoutes.application.access, {
                  applicationId: applicationId.toString(),
                })}
                onClick={() => onCompleted && onCompleted()}
              >
                Share
              </Button>
              <Button
                size="sm"
                as={Link}
                to={generatePath(namedRoutes.application.overview, {
                  applicationId: applicationId.toString(),
                })}
                onClick={() => onCompleted && onCompleted()}
              >
                Finish
              </Button>
            </>
          )}
        </HStack>
      }
      {...props}
      title="Share"
    >
      <Stack spacing={4}>
        <Alert status="success">
          <AlertIcon />
          <Text>Your new application has been successfully saved!</Text>
        </Alert>
        <Text>
          Per default, only you have access to this application. You can choose
          to grant access to other users by clicking the &quot;Share&quot;
          button below.
        </Text>
      </Stack>
    </Step>
  );
}
