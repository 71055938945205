import {
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
} from "@chakra-ui/react";
import { forwardRef } from "react";
import { HideIcon, ShowIcon } from "./icons";

export const ObfuscatedSecretInput = forwardRef(
  ({ size, ...inputProps }: InputProps, ref) => {
    const { isOpen: show, onToggle } = useDisclosure({
      defaultIsOpen: inputProps.value === "" && inputProps.defaultValue === "",
    });

    return (
      <InputGroup size={size}>
        <Input
          ref={ref}
          pr="4.5rem"
          {...inputProps}
          css={{ WebkitTextSecurity: show ? "none" : "disc" }}
        />
        <InputRightElement>
          <IconButton
            size={size}
            onClick={onToggle}
            aria-label={show ? "Hide" : "Show"}
            icon={show ? <HideIcon /> : <ShowIcon />}
          />
        </InputRightElement>
      </InputGroup>
    );
  },
);
