import { Grid, GridItem, Link, Stack } from "@chakra-ui/react";
import { Fragment } from "react";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { PermissionExpiry } from "../../applications/components";
import { Application, ApplicationId, UserGroup } from "../../backend/types";
import {
  ApplicationDisplay,
  ApplicationSelect,
  PermissionListLoader,
  PermissionSelect,
} from "../../components";
import { ApplicationDisplaySkeleton } from "../../components/ApplicationDisplay";
import { NoDataHorizontal } from "../../components/NoData";
import {
  useActiveOrganizationQuery,
  useGrantAccessToSubjectOnApplicationMutation,
} from "../../hooks";
import { useApplicationsQuery } from "../../hooks/useApplicationQuery";
import { namedRoutes } from "../../routes";
import { useApplicationPermissionsForUserGroup } from "../hooks/useUserGroupApplicationPermissionsQuery";

export function UserGroupApplicationPermissionManagement({
  id: userGroupId,
  callToActionNoPermissions,
}: Pick<UserGroup, "id"> & { callToActionNoPermissions?: JSX.Element }) {
  const { data: organization } = useActiveOrganizationQuery();
  const { data: permissions, isLoading } =
    useApplicationPermissionsForUserGroup(userGroupId);
  const applicationIds =
    permissions?.map((permission) => permission.application) ?? [];
  const { data: applications, isLoading: isLoadingApplications } =
    useApplicationsQuery(
      {
        id_in: applicationIds,
        page_size: applicationIds.length,
      },
      {
        enabled: !!permissions,
        // transform the data so we can easily get an application by id
        select: (data) =>
          data.results.reduce<{ [key: ApplicationId]: Application }>(
            (acc, val) => ({ ...acc, [val.id]: val }),
            {},
          ),
      },
    );
  const grantAccessToApplicationMutation =
    useGrantAccessToSubjectOnApplicationMutation(
      { id: userGroupId, type: "group" },
      ["view"],
    );

  if (isLoading) {
    return <PermissionListLoader type="application" />;
  }

  if (permissions && !permissions?.length) {
    return (
      <Stack spacing={6}>
        <NoDataHorizontal
          height={32}
          title={"No permissions"}
          text={
            "This user group does not have access to any application (yet)."
          }
          callToAction={callToActionNoPermissions}
        />
        <ApplicationSelect
          size="sm"
          onSelect={(application) =>
            organization &&
            application &&
            grantAccessToApplicationMutation.mutate({
              application: application.id,
              organization: organization.id,
            })
          }
          filters={{ permission: "change" }}
          filter={(application) =>
            !permissions?.find((p) => p.application === application.id)
          }
          placeholder="Type to search for applications to grant access to ..."
        />
      </Stack>
    );
  }

  return (
    <Stack spacing={4}>
      <Grid
        gridTemplateColumns="max-content max-content 1fr"
        gridTemplateRows="auto"
        columnGap={4}
        rowGap={4}
      >
        {permissions?.map((applicationPermission) => {
          const expiryDate = applicationPermission.valid_until
            ? new Date(applicationPermission.valid_until)
            : undefined;

          return (
            <Fragment key={applicationPermission.application}>
              <GridItem>
                {isLoadingApplications ? (
                  <ApplicationDisplaySkeleton size="small" />
                ) : (
                  <Link
                    textDecoration={"none"}
                    _hover={{ textDecoration: "none", cursor: "pointer" }}
                    as={RouterLink}
                    to={generatePath(namedRoutes.application.overview, {
                      applicationId: applicationPermission.application,
                    })}
                    role="group"
                  >
                    <ApplicationDisplay
                      size="small"
                      application={
                        applications &&
                        applications[applicationPermission.application]
                      }
                    />
                  </Link>
                )}
              </GridItem>
              <GridItem alignSelf={"center"}>
                <PermissionSelect
                  applicationId={applicationPermission.application}
                  organizationId={applicationPermission.organization}
                  permissions={applicationPermission.permissions}
                  subject={{ type: "group", id: userGroupId }}
                  validUntil={expiryDate}
                />
              </GridItem>
              <GridItem>
                <PermissionExpiry
                  applicationId={applicationPermission.application}
                  organizationId={applicationPermission.organization}
                  permissions={applicationPermission.permissions}
                  subject={{ type: "group", id: userGroupId }}
                  validUntil={expiryDate}
                />
              </GridItem>
            </Fragment>
          );
        })}
      </Grid>
      <ApplicationSelect
        size="sm"
        onSelect={(application) =>
          organization &&
          application &&
          grantAccessToApplicationMutation.mutate({
            application: application.id,
            organization: organization.id,
          })
        }
        filter={(application) =>
          !permissions?.find((p) => p.application === application.id)
        }
        placeholder="Type to search for applications to grant access to ..."
      />
    </Stack>
  );
}
