import dayjs from "dayjs";
import { createContext, useContext } from "react";
import { TimeSpanProps } from "./charts/types";

export const DashboardContext = createContext<
  TimeSpanProps & {
    userGroupId?: number;
  }
>({
  from: dayjs().subtract(3, "months").startOf("month").toDate(),
  to: new Date(),
});

export function useDashboardParameters() {
  return useContext(DashboardContext);
}
