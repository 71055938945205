import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { fetchApplicationCloudRenderingConfiugration } from "../backend/api";
import {
  ApplicationCloudRenderingConfiguration,
  ApplicationId,
} from "../backend/types";
import { getQueryKeyForApplication } from "./useApplicationQuery";

export function getQueryKeyForApplicationCloudRenderingConfiguration(
  applicationId: ApplicationId,
) {
  return [
    ...getQueryKeyForApplication(applicationId),
    "cloud-rendering-configuration",
  ];
}

export function useApplicationCloudRenderingConfigurationQuery(
  applicationId: ApplicationId,
  queryOptions: Partial<
    UseQueryOptions<ApplicationCloudRenderingConfiguration>
  > = {},
) {
  return useQuery({
    queryKey:
      getQueryKeyForApplicationCloudRenderingConfiguration(applicationId),
    queryFn: () => fetchApplicationCloudRenderingConfiugration(applicationId),
    ...queryOptions,
  });
}
