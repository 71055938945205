import { Divider, Stack, Text, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { UserGroup } from "../../backend";
import { deleteUserGroup } from "../../backend/api";
import { useConfirm } from "../../confirm-dialog";
import { namedRoutes } from "../../routes";

export function useUserGroupDeleteConfirm() {
  const { confirm } = useConfirm();
  const { mutateAsync: deleteUserGroupAsync } = useMutation<
    void,
    AxiosError,
    UserGroup
  >({
    mutationFn: (userGroup) => deleteUserGroup(userGroup.id),
  });
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();

  return useCallback(
    (userGroup?: UserGroup) => () => {
      if (!userGroup) return;

      confirm({
        title: `Delete user group "${userGroup.name}"`,
        body: (
          <Stack>
            <Text>
              You are about to delete the user group <i>{userGroup.name}</i>{" "}
              with all of its associated permissions. Users part of this user
              group will lose access to any content this group has access to
              unless the access is granted to the user (again) through another
              group membership or directly.
            </Text>
            <Divider />
            <Text>Please be aware that this action cannot be undone.</Text>
          </Stack>
        ),
        confirmButtonText: "Delete",
        onConfirm: () =>
          deleteUserGroupAsync(userGroup, {
            onSuccess: (_, userGroup) => {
              navigate(namedRoutes.userGroup.list);
              toast({
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
                description: `User group "${userGroup.name}" has been deleted.`,
              });
              queryClient.invalidateQueries({ queryKey: ["groups"] });
            },
          }),
      });
    },
    [confirm, navigate, queryClient, toast, deleteUserGroupAsync],
  );
}
