import { Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";
import { ReactNode } from "react";
import { AuthProvider as BaseAuthProvider } from "react-oauth2-code-pkce";
import { FullPageCenteredContainer } from "../components";
import { AuthenticationProvider } from "./AuthenticationProvider";
import { useAuthConfig } from "./useAuthConfig";

export function AuthProvider({ children }: { children: ReactNode }) {
  const { data: authConfig, error } = useAuthConfig();

  if (error) {
    return (
      <FullPageCenteredContainer>
        <Alert status="error" maxW={"container.sm"}>
          <AlertIcon />
          <AlertTitle>{error.message}</AlertTitle>
        </Alert>
      </FullPageCenteredContainer>
    );
  }

  if (!authConfig) {
    return (
      <FullPageCenteredContainer>
        <Alert status="loading" maxW={"container.sm"}>
          <AlertIcon />
          <AlertTitle>Loading configuration ...</AlertTitle>
        </Alert>
      </FullPageCenteredContainer>
    );
  }

  return (
    <BaseAuthProvider authConfig={authConfig}>
      <AuthenticationProvider>{children}</AuthenticationProvider>
    </BaseAuthProvider>
  );
}
