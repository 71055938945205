import { Box, Button, HStack, StackProps } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { OrganizationSelect } from ".";
import { Organization } from "../backend";
import { OrganizationOption } from "./OrganizationSelect";

export function AddToOrganizationSelect({
  filterOrganizations = () => true,
  addToOrganizationMutation,
  placeholder = "Add to organization(s) ...",
  ...props
}: {
  filterOrganizations?: (organization: Organization) => boolean;
  addToOrganizationMutation: ReturnType<
    typeof useMutation<void, AxiosError, Organization[]>
  >;
  placeholder?: string;
} & StackProps) {
  const [organizationsToBeAdded, setOrganizationsToBeAdded] = useState<
    OrganizationOption[]
  >([]);

  return (
    <HStack {...props}>
      <Box flexGrow={1}>
        <OrganizationSelect
          onChange={(values) => {
            setOrganizationsToBeAdded(values.slice());
            addToOrganizationMutation.reset();
          }}
          value={organizationsToBeAdded}
          filter={filterOrganizations}
          placeholder={placeholder}
          isDisabled={addToOrganizationMutation.isPending}
        />
      </Box>
      <Button
        size="sm"
        isDisabled={!organizationsToBeAdded.length}
        isLoading={addToOrganizationMutation.isPending}
        onClick={async () => {
          await addToOrganizationMutation.mutateAsync(
            organizationsToBeAdded.map((org) => org.organization),
          );
          setOrganizationsToBeAdded([]);
        }}
      >
        Add to{" "}
        {organizationsToBeAdded.length > 1 ? "organizations" : "organization"}
      </Button>
    </HStack>
  );
}
