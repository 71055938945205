import { EmailIcon, LinkIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { PortalError } from "../backend";
import {
  createApplicationExternalReference,
  getApplicationExternalReferences,
  updateApplicationExternalReference,
} from "../backend/api";
import {
  ApplicationExternalReference,
  ApplicationId,
  PortalBackendPaginatedResult,
} from "../backend/types";
import { MessageFromAxiosError } from "../components/MessageFromAxiosError";
import { getQueryKeyForApplication } from "../hooks/useApplicationQuery";

const requestAccessReferenceType = "request_access";

function getQueryKeyForApplicationExternalReferences(
  applicationId: ApplicationId,
  filters: Record<string, unknown> = {},
) {
  return [
    ...getQueryKeyForApplication(applicationId),
    "external-references",
    filters,
  ];
}

export function ExternalRequestAccessManagement({
  applicationId,
}: {
  applicationId: ApplicationId;
}) {
  const [externalRequestAccessEnabled, setExternalRequestAccessEnabled] =
    useState(false);
  const [externalRequestAccessUrl, setExternalRequestAccessUrl] =
    useState<string>();
  const queryKey = useMemo(
    () =>
      getQueryKeyForApplicationExternalReferences(applicationId, {
        type: requestAccessReferenceType,
      }),
    [applicationId],
  );
  const externalReferencesQuery = useQuery<
    PortalBackendPaginatedResult<ApplicationExternalReference>,
    AxiosError<PortalError>
  >({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey,
    queryFn: async () => {
      const applicationExternalReferences =
        await getApplicationExternalReferences(applicationId, {
          type: requestAccessReferenceType,
        });

      const requestAccessUrlReference =
        applicationExternalReferences?.results.find(
          (reference) => reference.type === requestAccessReferenceType,
        );
      setExternalRequestAccessEnabled(
        requestAccessUrlReference?.is_active ?? false,
      );
      setExternalRequestAccessUrl(requestAccessUrlReference?.url);

      return applicationExternalReferences;
    },
  });
  const requestAccessUrlReference = externalReferencesQuery.data?.results.find(
    (reference) => reference.type === requestAccessReferenceType,
  );
  const queryClient = useQueryClient();
  const updateExternalRequestAccessUrlMutation = useMutation<
    ApplicationExternalReference | void,
    AxiosError<PortalError>,
    Pick<ApplicationExternalReference, "url" | "is_active">
  >({
    mutationFn: (data) => {
      if (requestAccessUrlReference) {
        return updateApplicationExternalReference(
          applicationId,
          requestAccessReferenceType,
          data,
        );
      }
      return createApplicationExternalReference(applicationId, {
        ...data,
        type: requestAccessReferenceType,
      });
    },
    onMutate: () => {
      // cancel any ongoing queries
      queryClient.cancelQueries({ queryKey });
    },
    onSuccess: (upsertedReference) => {
      queryClient.setQueryData<
        PortalBackendPaginatedResult<ApplicationExternalReference>
      >(queryKey, (data) => {
        if (data) {
          return {
            ...data,
            results: upsertedReference
              ? // updating or creation case
                data.results.map((existingReference) =>
                  existingReference.type === requestAccessReferenceType
                    ? upsertedReference
                    : existingReference,
                )
              : // deletion case
                data.results.filter(
                  (existingReference) =>
                    existingReference.type !== requestAccessReferenceType,
                ),
          };
        }
        return data;
      });
    },
  });

  const saveChangedRequestAccessUrlDebounced = useDebouncedCallback(
    (data: Pick<ApplicationExternalReference, "url" | "is_active">) =>
      updateExternalRequestAccessUrlMutation.mutate(data),
    1000,
  );

  return (
    <FormControl
      width="auto"
      isInvalid={updateExternalRequestAccessUrlMutation.isError}
    >
      {externalReferencesQuery.isLoading ? (
        <Skeleton width="full" height={10} />
      ) : (
        <HStack spacing={0}>
          <ButtonGroup size="sm" isAttached variant="outline">
            <Button
              isLoading={
                !externalRequestAccessEnabled &&
                updateExternalRequestAccessUrlMutation.isPending
              }
              leftIcon={<EmailIcon />}
              onClick={() => {
                setExternalRequestAccessEnabled(false);
                requestAccessUrlReference &&
                  updateExternalRequestAccessUrlMutation.mutate({
                    ...requestAccessUrlReference,
                    is_active: false,
                  });
                if (updateExternalRequestAccessUrlMutation.isError) {
                  setExternalRequestAccessUrl(requestAccessUrlReference?.url);
                  updateExternalRequestAccessUrlMutation.reset();
                }
              }}
              {...(externalRequestAccessEnabled === false
                ? { colorScheme: "brand", variant: "solid" }
                : {})}
            >
              E-Mail Notification
            </Button>
            <Button
              isLoading={
                externalRequestAccessEnabled &&
                updateExternalRequestAccessUrlMutation.isPending
              }
              leftIcon={<LinkIcon />}
              onClick={() => {
                setExternalRequestAccessEnabled(true);
                requestAccessUrlReference &&
                  updateExternalRequestAccessUrlMutation.mutate({
                    ...requestAccessUrlReference,
                    is_active: true,
                  });
              }}
              {...(externalRequestAccessEnabled
                ? { colorScheme: "brand", variant: "solid", borderRadius: 0 }
                : {})}
            >
              External URL
            </Button>
          </ButtonGroup>
          {externalRequestAccessEnabled && (
            <InputGroup size="sm">
              {updateExternalRequestAccessUrlMutation.isPending && (
                <InputRightElement>
                  <CircularProgress isIndeterminate size={4} />
                </InputRightElement>
              )}
              <Input
                type="url"
                borderLeft={0}
                size="sm"
                placeholder="Enter URL here"
                value={externalRequestAccessUrl}
                onChange={(e) => {
                  setExternalRequestAccessUrl(e.target.value);
                  if (e.target.value !== undefined) {
                    saveChangedRequestAccessUrlDebounced({
                      url: e.target.value,
                      is_active: true,
                    });
                  }
                }}
              />
            </InputGroup>
          )}
        </HStack>
      )}
      <FormErrorMessage>
        {updateExternalRequestAccessUrlMutation.isError && (
          <MessageFromAxiosError
            error={updateExternalRequestAccessUrlMutation.error}
          />
        )}
      </FormErrorMessage>
      {externalRequestAccessEnabled && (
        <FormHelperText>
          Ensure the URL leads directly to the relevant page or form to
          facilitate a smooth request process.
        </FormHelperText>
      )}
    </FormControl>
  );
}
