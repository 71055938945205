import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { getApplicationLaunchConfigurations } from "../../backend/api";
import {
  ApplicationId,
  ApplicationLaunchConfiguration,
  PortalBackendPaginatedResult,
} from "../../backend/types";
import { getQueryKeyForApplication } from "../../hooks/useApplicationQuery";

export function getQueryKeyForApplicationLaunchConfigurationsQuery(
  applicationId: ApplicationId,
) {
  return [...getQueryKeyForApplication(applicationId), "launch-configurations"];
}
export function useApplicationLaunchConfigurationsQuery<
  T = PortalBackendPaginatedResult<ApplicationLaunchConfiguration>,
>(
  applicationId: ApplicationId,
  queryOptions: Partial<
    UseQueryOptions<
      PortalBackendPaginatedResult<ApplicationLaunchConfiguration>,
      AxiosError,
      T
    >
  > = {},
) {
  return useQuery({
    queryKey: getQueryKeyForApplicationLaunchConfigurationsQuery(applicationId),
    queryFn: () => getApplicationLaunchConfigurations(applicationId),
    ...queryOptions,
  });
}
