import { yupResolver } from "@hookform/resolvers/yup";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { InferType, array, boolean, string } from "yup";
import { fetchApplicationBuildExecutables } from "../../backend/api";
import {
  ApplicationBuild,
  ApplicationBuildId,
  TargetPlatform,
  androidXRPlatformTypes,
  knownTargetPlatforms,
  windowsXRPlatformTypes,
} from "../../backend/types";
import { getQueryKeyForApplicationBuild } from "../../hooks/useApplicationBuildsQuery";
import {
  applicationBuildMetaDataSchema,
  xrPlatformSchema,
} from "../steps/schema";

export const applicationBuildSchema = applicationBuildMetaDataSchema
  .omit(["application_archive"])
  .shape({
    target_platform: string<TargetPlatform>()
      .oneOf([...knownTargetPlatforms])
      .required(),
    package_name: string()
      .defined()
      .nullable()
      .when("target_platform", {
        is: "windows",
        then: (schema) => schema.default(null),
        otherwise: (schema) =>
          schema.required(
            "Android applications require a package_name to be specified.",
          ),
      }),
    executable_path: string()
      .defined()
      .nullable()
      .when("target_platform", {
        is: "android",
        then: (schema) => schema.defined().default(null),
        otherwise: (schema) =>
          schema.required(
            "Windows applications must specify the path to the executable.",
          ),
      }),
    launch_args: string().defined().nullable(),
    supports_arbitrary_cli_args: boolean().default(true),
    supported_xr_platforms: array(
      xrPlatformSchema
        .when("target_platform", {
          is: "windows",
          then: (schema) => schema.oneOf([...windowsXRPlatformTypes]),
          otherwise: (schema) => schema.oneOf([...androidXRPlatformTypes]),
        })
        .defined(),
    )
      .default([])
      .min(1, "At least one mode is required"),
  })
  .noUnknown();

export type ApplicationBuildSchema = InferType<typeof applicationBuildSchema>;

export function useApplicationBuildExecutablesQuery(
  applicationBuildId?: ApplicationBuildId,
  queryOptions: Partial<UseQueryOptions<string[], AxiosError>> = {},
) {
  return useQuery({
    queryKey: [
      ...getQueryKeyForApplicationBuild(applicationBuildId ?? 0),
      "executables",
    ],
    queryFn: () => fetchApplicationBuildExecutables(applicationBuildId ?? 0),
    enabled: !!applicationBuildId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...queryOptions,
  });
}

export function useApplicationBuildForm(applicationBuild?: ApplicationBuild) {
  const applicationExecutablesQuery = useApplicationBuildExecutablesQuery(
    applicationBuild?.id,
    {
      enabled:
        !!applicationBuild && applicationBuild.target_platform === "windows",
    },
  );
  const applicationExecutables = useMemo(
    () => applicationExecutablesQuery.data ?? [],
    [applicationExecutablesQuery.data],
  );

  return useForm<ApplicationBuildSchema>({
    values: applicationBuild,
    resolver: yupResolver(
      applicationExecutables.length
        ? applicationBuildSchema.shape({
            executable_path: string()
              .defined()
              .when("target_platform", {
                is: "android",
                then: (schema) => schema.defined().default(null),
                otherwise: (schema) =>
                  schema
                    .required(
                      "Windows applications must specify the path to the executable.",
                    )
                    .nullable()
                    .oneOf(
                      applicationExecutables,
                      // eslint-disable-next-line no-template-curly-in-string
                      "The only detected executables are: ${values}",
                    ),
              }),
            supported_xr_platforms: array(
              xrPlatformSchema.when("target_platform", {
                is: "android",
                then: (schema) => schema.oneOf([...androidXRPlatformTypes]),
                otherwise: (schema) =>
                  schema.oneOf([...windowsXRPlatformTypes]),
              }),
            )
              .default([])
              .min(1, "At least one platform is required"),
          })
        : applicationBuildSchema,
    ),
    mode: "onChange",
  });
}
