import { loadDataFromElement } from "./utils/hydrate-data";

interface Configuration {
  backendEndpoint?: string;
  backendGenericEndpoint?: string;
  sessionManagementServiceEndpoint?: string;
  sentryDSN?: string;
  version?: string;
}

export const config = loadDataFromElement<Configuration>("runtime-config", {});

export default config;
