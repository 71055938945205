import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { createVirtualMachine } from "../../session-management";
import { VirtualMachine } from "../../session-management/types";
import { CreateVirtualMachine } from "../VirtualMachines/virtualMachineSchema";
import { getVirtualMachinesQueryKey } from "./useVirtualMachinesQuery";

export function useCreateVirtualMachineMutation({
  onSuccess,
  ...options
}: Omit<
  UseMutationOptions<VirtualMachine, AxiosError, CreateVirtualMachine>,
  "mutationFn"
>) {
  const queryClient = useQueryClient();
  const mutation = useMutation<
    VirtualMachine,
    AxiosError,
    CreateVirtualMachine
  >({
    mutationFn: (virtualMachine) => createVirtualMachine(virtualMachine),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: getVirtualMachinesQueryKey(),
      });
      if (onSuccess) onSuccess(data, variables, context);
    },
    ...options,
  });
  return mutation;
}
