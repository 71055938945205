import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, IconButton, forwardRef } from "@chakra-ui/react";
import { DateValue, createCalendar } from "@internationalized/date";
import { useLocale } from "@react-aria/i18n";
import { ReactNode, useRef } from "react";
import { CalendarAria, CalendarProps, useCalendar } from "react-aria";
import { CalendarState, useCalendarState } from "react-stately";
import { omit } from "remeda";
import { CalendarGrid } from "./CalendarGrid";

export function Calendar(props: CalendarProps<DateValue>) {
  const { locale } = useLocale();
  const state = useCalendarState({
    ...props,
    locale,
    createCalendar,
  });
  const ref = useRef(null);
  const calendarAria = useCalendar(props, state);

  return (
    <CalendarContainer ref={ref} {...calendarAria} state={state}>
      <CalendarGrid state={state} />
    </CalendarContainer>
  );
}

export const CalendarContainer = forwardRef(
  (
    {
      calendarProps,
      prevButtonProps,
      nextButtonProps,
      title,
      children,
      state: { focusNextPage, focusPreviousPage },
    }: CalendarAria & {
      children: ReactNode;
      state: Pick<CalendarState, "focusNextPage" | "focusPreviousPage">;
    },
    ref,
  ) => (
    <Box {...calendarProps} margin={0} ref={ref}>
      <Flex justifyContent="space-between" alignItems="center" pb={4}>
        <IconButton
          size="sm"
          icon={<ChevronLeftIcon />}
          {...omit(prevButtonProps, ["onPress", "onFocusChange"])}
          aria-label={prevButtonProps["aria-label"] ?? "Go to previous month"}
          onClick={() => focusPreviousPage()}
        />
        <Heading as="h3" size="sm" whiteSpace="nowrap">
          {title}
        </Heading>
        <IconButton
          size="sm"
          icon={<ChevronRightIcon />}
          {...omit(nextButtonProps, ["onPress", "onFocusChange"])}
          aria-label={nextButtonProps["aria-label"] ?? "Go to next month"}
          onClick={() => focusNextPage()}
        />
      </Flex>
      <Flex>{children}</Flex>
    </Box>
  ),
);
