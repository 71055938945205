import {
  Alert,
  AlertDescription,
  AlertIcon,
  Heading,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { AxiosError } from "axios";
import { useMemo } from "react";
import { Application } from "../backend";
import { updateApplicationCloudRenderingConfiguration } from "../backend/api";
import { ApplicationCloudRenderingConfiguration } from "../backend/types";
import { ErrorBoundary, PaginatedTable } from "../components";
import {
  useApplicationCloudRenderingConfigurationQuery,
  useMergedQueriesState,
  useVmSizesQuery,
} from "../hooks";
import { getQueryKeyForApplicationCloudRenderingConfiguration } from "../hooks/useApplicationCloudRenderingConfigurationQuery";
import { VmSizeInformation } from "../session-management";

const columnHelper = createColumnHelper<VmSizeInformation>();

const columns = [
  columnHelper.accessor("name", {
    id: "radio",
    cell: (props) => (
      <Radio
        value={props.getValue()}
        isDisabled={!props.row.original.isEnabled}
      />
    ),
    header: () => null,
    meta: {
      props: { padding: 0, paddingLeft: 4 },
    },
  }),
  columnHelper.accessor("displayName", {
    header: "Name",
  }),
  columnHelper.accessor("graphicsCardDisplayName", {
    header: "Graphics Card",
  }),
  columnHelper.accessor("minVCpu", {
    header: "vCPU",
    cell: (props) => `≥ ${props.getValue()} cores`,
  }),
  columnHelper.accessor("minRam", {
    header: "RAM",
    cell: (props) => `≥ ${props.getValue()} GB`,
  }),
];

type MutationContext = {
  previousConfiguration?: ApplicationCloudRenderingConfiguration;
  queryKey: QueryKey;
};

export function ApplicationPerformanceRequirements({
  application,
}: {
  application: Application;
}) {
  return (
    <Stack spacing={4}>
      <Heading size="sm" as="h3">
        Performance Requirements
      </Heading>
      <Text>
        If your app has specific performance requirements, you can change the
        performance tier below to run the app on different (e.g. more powerful)
        hardware when using cloud rendering.
      </Text>
      <ErrorBoundary>
        <Alert status="warning" variant="left-accent">
          <AlertIcon />
          <AlertDescription>
            Please be aware that higher performance tiers for cloud rendering
            may incur additional cost!
          </AlertDescription>
        </Alert>
        <VmSizeOptionsTable application={application} />
      </ErrorBoundary>
    </Stack>
  );
}

export function VmSizeOptionsTable({
  application,
}: {
  application: Application;
}) {
  const queryClient = useQueryClient();
  const toast = useToast({
    position: "top",
    isClosable: true,
  });
  const vmSizesQuery = useVmSizesQuery({
    throwOnError: true,
    retry: false,
    refetchOnWindowFocus: false,
    // this data changes very rarely, so good enough to cache for an hour
    gcTime: 3600,
    staleTime: 3600,
  });
  const applicationCloudRenderingConfigurationQuery =
    useApplicationCloudRenderingConfigurationQuery(application.id, {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!application.id,
    });
  const { isLoading } = useMergedQueriesState([
    vmSizesQuery,
    applicationCloudRenderingConfigurationQuery,
  ]);
  const table = useReactTable({
    columns: useMemo(
      () =>
        isLoading
          ? columns.map((col, idx) => ({
              ...col,
              cell: () => <Skeleton key={idx}>Loading...</Skeleton>,
            }))
          : columns,
      [isLoading],
    ),
    data: useMemo(
      () =>
        isLoading
          ? Array(5).fill({} as VmSizeInformation)
          : vmSizesQuery.data ?? [],
      [vmSizesQuery.data, isLoading],
    ),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
    autoResetPageIndex: true,
  });

  const updateCloudRenderingConfigurationMutation = useMutation<
    ApplicationCloudRenderingConfiguration,
    AxiosError,
    Pick<ApplicationCloudRenderingConfiguration, "vm_size">,
    MutationContext
  >({
    mutationFn: (configuration) =>
      updateApplicationCloudRenderingConfiguration(
        application.id,
        configuration,
      ),
    onMutate: async (newConfig) => {
      const queryKey: QueryKey =
        getQueryKeyForApplicationCloudRenderingConfiguration(application.id);
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey,
      });

      // Snapshot the previous value
      const previousConfiguration =
        queryClient.getQueryData<ApplicationCloudRenderingConfiguration>(
          queryKey,
        );

      // Optimistically update to the new value
      queryClient.setQueryData<ApplicationCloudRenderingConfiguration>(
        queryKey,
        (old) => ({
          ...old,
          url_rewrites: old?.url_rewrites ?? null,
          ...newConfig,
        }),
      );

      // Return a context object with the snapshotted value
      return { previousConfiguration, queryKey };
    },
    onSettled(_0, _1, _2, context) {
      // When the mutation is completed or errored, refetch the data
      context?.queryKey &&
        queryClient.invalidateQueries({
          queryKey: context?.queryKey,
        });
    },
    onError: (error, _, context) => {
      // roll back optimistic update
      context?.queryKey &&
        queryClient.setQueryData<ApplicationCloudRenderingConfiguration>(
          context?.queryKey,
          context?.previousConfiguration,
        );
      toast({
        title: "Could not update cloud rendering performance settings",
        description: error.message,
        status: "error",
      });
    },
    onSuccess: ({ vm_size }) => {
      const vmSizeInfo = vmSizesQuery.data?.find((vm) => vm.name === vm_size);
      toast({
        title: "Updated cloud rendering performance settings",
        description: `Application ${application.name} will be rendered on ${vmSizeInfo?.displayName} from now on.`,
        status: "success",
      });
    },
  });

  return (
    <RadioGroup
      isDisabled={updateCloudRenderingConfigurationMutation.isPending}
      value={
        applicationCloudRenderingConfigurationQuery.data?.vm_size ??
        vmSizesQuery.data?.find((vmSize) => vmSize.isDefault)?.name
      }
      onChange={(vmSize) =>
        updateCloudRenderingConfigurationMutation.mutate({ vm_size: vmSize })
      }
    >
      <PaginatedTable table={table} />
    </RadioGroup>
  );
}
