import { useNavigate } from "react-router-dom";
import { HttpErrorFallback } from "../components/ErrorFallback";
import { namedRoutes } from "../routes";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <HttpErrorFallback
      statusCode={404}
      message="Request failed with error code 404"
      resetButtonLabel={`Back to Control Panel`}
      resetErrorBoundary={() => {
        navigate(namedRoutes.landing);
      }}
    />
  );
}
