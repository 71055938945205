import {
  chakra,
  forwardRef,
  Heading,
  HeadingProps,
  Text,
  TextProps,
} from "@chakra-ui/react";
import { ComponentProps } from "react";

export const HeadingOne = forwardRef((props: HeadingProps, ref) => (
  <Heading ref={ref} as="h1" size="2xl" {...props} />
));

export const HeadingTwo = forwardRef((props: HeadingProps, ref) => (
  <Heading ref={ref} as="h2" size="xl" {...props} />
));

export const HeadingThree = forwardRef((props: HeadingProps, ref) => (
  <Heading ref={ref} as="h3" size="lg" {...props} />
));

export const HeadingFour = forwardRef((props: HeadingProps, ref) => (
  <Heading ref={ref} as="h4" size="md" {...props} />
));

export const HeadingFive = forwardRef((props: HeadingProps, ref) => (
  <Heading ref={ref} as="h5" size="sm" {...props} />
));

export const HeadingSix = forwardRef((props: HeadingProps, ref) => (
  <Heading ref={ref} as="h6" size="xs" {...props} />
));

export const Formatted = forwardRef((props: TextProps, ref) => (
  <Text ref={ref} as="pre" size="sm" {...props} />
));

export const Address = forwardRef((props: TextProps, ref) => (
  <chakra.address ref={ref} size="sm" {...props} />
));

export const Blockquote = forwardRef(
  (props: ComponentProps<"blockquote">, ref) => (
    <chakra.blockquote
      ref={ref}
      marginX={4}
      marginY={2}
      paddingX={4}
      paddingY={1}
      borderLeftWidth={2}
      {...props}
    />
  ),
);
