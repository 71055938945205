import { Skeleton } from "@chakra-ui/react";
import { ApplicationId } from "../../backend/types";
import { useApplicationQuery } from "../../hooks";

export function LazyApplicationDisplayName({
  applicationId,
}: {
  applicationId: ApplicationId;
}) {
  const applicationQuery = useApplicationQuery(applicationId);

  return (
    <Skeleton as="span" isLoaded={!applicationQuery.isLoading}>
      {applicationQuery.isError
        ? "Unknown application"
        : applicationQuery.data?.name ?? "Sample application"}
    </Skeleton>
  );
}
