import { Skeleton, Stack } from "@chakra-ui/react";
import { Session } from "@sentry/react";
import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { ApplicationDisplaySkeleton, NoData, PaginatedTable } from ".";

import { useSessionListContext } from "../sessions/SessionsListContext";
import { columns as sessionColumns } from "../sessions/table-columns";
import { UserProfileSkeleton } from "./UserProfile";

// TODO: allow filtering by other session properties on table
export function SessionsTable({
  columns = sessionColumns,
}: {
  columns?: typeof sessionColumns;
} = {}) {
  const {
    query: sessionsQuery,
    pagination,
    setPagination,
  } = useSessionListContext();

  const { isLoading, isSuccess } = sessionsQuery;
  const data = useMemo(
    () =>
      isLoading
        ? Array(pagination.pageSize).fill({} as Session)
        : sessionsQuery.data?.items ?? [],
    [isLoading, pagination.pageSize, sessionsQuery.data?.items],
  );
  const table = useReactTable({
    columns: useMemo(
      () =>
        isLoading
          ? columns.map((column) => ({
              ...column,
              cell: () =>
                column.id === "application" ? (
                  <ApplicationDisplaySkeleton />
                ) : column.id === "user" ? (
                  <UserProfileSkeleton />
                ) : (
                  <Skeleton height={4} w="full" />
                ),
            }))
          : columns,
      [isLoading, columns],
    ),
    data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [{ desc: true, id: "startTime" }],
    },
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    manualPagination: true,
    pageCount: sessionsQuery.data?.totalPages,
    autoResetPageIndex: false,
  });

  return (
    <Stack spacing={4}>
      {sessionsQuery.data?.totalResults === 0 && isSuccess && (
        <NoData
          height="sm"
          title="No sessions found"
          text="It seems like there were no sessions. Try adjusting the filters."
        />
      )}
      <PaginatedTable table={table} />
    </Stack>
  );
}
