import { Flex } from "@chakra-ui/react";
import { ReactNode } from "react";

export function FullPageCenteredContainer({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Flex
      height="100vh"
      width="100vw"
      overflow="hidden"
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      {children}
    </Flex>
  );
}
