import { useGroupsQuery } from "./useGroupsQuery";

const searchResultsPageSize = 10;

export function useSearchGroupQuery(
  searchText = "",
  pageSize = searchResultsPageSize,
) {
  return useGroupsQuery({
    fulltext_search: searchText,
    page_size: pageSize,
  });
}
