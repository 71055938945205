import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  HStack,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo } from "react";
import { Link, generatePath } from "react-router-dom";
import { UserGroup } from "../../backend";
import {
  GroupProfile,
  LinkButton,
  OptionsButton,
  PaginatedTable,
} from "../../components";
import {
  useGroupsQuery,
  usePaginationQueryParams,
  useSortingQueryParams,
} from "../../hooks";
import { namedRoutes } from "../../routes";
import { OrderingParam } from "../../utils/ordering-param";
import { useUserGroupDeleteConfirm } from "../hooks/useUserGroupDeleteConfirm";
import { isManageableGroup } from "../utils";

const columnHelper = createColumnHelper<UserGroup>();

const defaultSorting: SortingState = [{ desc: false, id: "name" }];

export function UserGroupOverview({ search }: { search: string }) {
  const [sorting, setSorting] = useSortingQueryParams(defaultSorting);
  const [pagination, setPagination] = usePaginationQueryParams();

  const {
    data: userGroupsResponse,
    isError,
    error,
    isLoading,
  } = useGroupsQuery(
    {
      ordering: useMemo(
        () =>
          OrderingParam.encode(
            sorting.map((sort) => ({
              ...sort,
              id: sort.id === "name" ? "display_name" : sort.id,
            })),
          ),
        [sorting],
      ),
      fulltext_search: search,
      page: pagination.pageIndex + 1,
      page_size: pagination.pageSize,
    },
    { retry: false, throwOnError: true },
  );
  const confirmUserGroupDelete = useUserGroupDeleteConfirm();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "User Group",
        cell: (props) => (
          <Link
            to={generatePath(namedRoutes.userGroup.overview, {
              groupId: props.row.original.id.toString(),
            })}
          >
            <GroupProfile
              group={props.row.original}
              _hover={{ textDecoration: "underline" }}
              cursor="pointer"
            />
          </Link>
        ),
      }),
      columnHelper.display({
        id: "options",
        cell: (props) => (
          <Flex justifyContent={"end"}>
            <OptionsButton label="Click on this button to display user actions">
              <LinkButton
                to={generatePath(namedRoutes.userGroup.overview, {
                  groupId: props.row.original.id?.toString(),
                })}
                variant="ghost"
                fontWeight="normal"
                fontSize="sm"
                w="full"
              >
                Show Details
              </LinkButton>
              <Button
                variant="ghost"
                fontWeight="normal"
                fontSize="sm"
                w="full"
                colorScheme="red"
                onClick={confirmUserGroupDelete(props.row.original)}
                isDisabled={!isManageableGroup(props.row.original)}
              >
                Delete Group
              </Button>
            </OptionsButton>
          </Flex>
        ),
        header: () => <Flex justifyContent="end">Options</Flex>,
      }),
    ],
    [confirmUserGroupDelete],
  );
  const table = useReactTable({
    data: useMemo<UserGroup[]>(
      () =>
        isLoading
          ? Array(pagination.pageSize).fill({} as UserGroup)
          : userGroupsResponse?.results ?? [],
      [userGroupsResponse?.results, isLoading, pagination.pageSize],
    ),
    columns: useMemo(
      () =>
        isLoading
          ? columns.map((column) => ({
              ...column,
              cell: () =>
                column.id === "name" ? (
                  <HStack>
                    <SkeletonCircle size="8" />
                    <SkeletonText noOfLines={1} w="100%" />
                  </HStack>
                ) : (
                  <Skeleton>
                    <Text>Loading</Text>
                  </Skeleton>
                ),
            }))
          : columns,
      [columns, isLoading],
    ),
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualSorting: true,
    manualPagination: true,
    pageCount: userGroupsResponse?.count
      ? Math.ceil(userGroupsResponse?.count / pagination.pageSize)
      : undefined,
  });

  useEffect(() => {
    // reset pagination if search text changes
    table.resetPageIndex();
  }, [search, table]);

  if (isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>{JSON.stringify(error)}</AlertDescription>
      </Alert>
    );
  }

  return <PaginatedTable table={table} />;
}
