import {
  Divider,
  Link,
  ListItem,
  SkeletonText,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { Application } from "../../backend/types";
import { useConfirm } from "../../confirm-dialog";
import { useApplicationBuildsPaginatedQuery } from "../../hooks/useApplicationBuildsQuery";
import { namedRoutes } from "../../routes";
import { ApplicationBuildDisplay } from "./LazyApplicationBuildDisplay";
import { LazyApplicationDisplayName } from "./LazyApplicationDisplayName";

export function ApplicationDeleteConfirmBody({
  application,
}: {
  application: Pick<Application, "id">;
}) {
  const applicationBuildsQuery = useApplicationBuildsPaginatedQuery({
    application: application.id,
    page_size: 200,
  });
  const { cancelConfirm } = useConfirm();
  const applicationHasBuilds = (applicationBuildsQuery.data?.count ?? 0) > 0;

  return (
    <Stack>
      <Text>
        You are about to <b>permanently delete the application</b>{" "}
        <i>
          <LazyApplicationDisplayName applicationId={application.id} />
        </i>{" "}
        from any organization it&apos;s shared with.
      </Text>
      {applicationHasBuilds && (
        <Text>
          Along with the application all of the following builds will be
          deleted:
        </Text>
      )}
      {applicationBuildsQuery.isLoading && <SkeletonText />}
      <UnorderedList paddingLeft={5}>
        {applicationBuildsQuery.data?.results.map((appBuild) => (
          <ListItem key={appBuild.id}>
            <ApplicationBuildDisplay applicationBuild={appBuild} />
          </ListItem>
        ))}
      </UnorderedList>
      <Text fontWeight={"bold"}>
        Please be aware that this action cannot be undone.
      </Text>
      {applicationHasBuilds && (
        <>
          <Divider />
          <Text>
            In case you just want to delete one single build,{" "}
            <Link
              as={RouterLink}
              to={generatePath(namedRoutes.application.builds, {
                applicationId: application.id.toString(),
              })}
              onClick={cancelConfirm}
            >
              click here instead
            </Link>
          </Text>
        </>
      )}
    </Stack>
  );
}
