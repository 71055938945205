import {
  HStack,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import { User } from "../../backend";

export function useUserColumns(
  columns: (ColumnDef<User, string> | ColumnDef<User, unknown>)[],
  isLoading: boolean,
) {
  return useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            cell: () =>
              column.id === "email" ? (
                <HStack>
                  <SkeletonCircle size="8" />
                  <SkeletonText noOfLines={2} w="100%" />
                </HStack>
              ) : (
                <Skeleton>
                  <Text>Loading</Text>
                </Skeleton>
              ),
          }))
        : columns,
    [columns, isLoading],
  );
}
