import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { PortalError, UserGroupApplicationPermission } from "../backend";
import { getUserGroupPermissionsForApplication } from "../backend/api";
import { ApplicationId } from "../backend/types";

export function getQueryKeyForGroupPermissionsForApplication(
  applicationId: ApplicationId,
) {
  return ["group_permissions", applicationId];
}

function getQueryForGroupPermissionsForApplication(
  applicationId: ApplicationId,
) {
  return {
    queryKey: getQueryKeyForGroupPermissionsForApplication(applicationId),
    queryFn: () => getUserGroupPermissionsForApplication(applicationId),
    retry: false,
    placeholderData: [],
  };
}

export function useGroupPermissionsForApplicationQuery(
  applicationId: ApplicationId,
) {
  return useQuery<UserGroupApplicationPermission[], AxiosError<PortalError>>(
    getQueryForGroupPermissionsForApplication(applicationId),
  );
}
