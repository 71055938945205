import { PaginationState } from "@tanstack/react-table";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { NumberParam, useQueryParams, withDefault } from "use-query-params";

type DefaultPaginationState = {
  defaultPage?: number;
  defaultPageSize?: number;
};

export function usePaginationQueryParams({
  defaultPageSize = 10,
  defaultPage = 1,
}: DefaultPaginationState = {}): [
  PaginationState,
  Dispatch<SetStateAction<PaginationState>>,
] {
  const [{ page, page_size }, setPaginationQuery] = useQueryParams(
    {
      page: useMemo(() => withDefault(NumberParam, defaultPage), [defaultPage]),
      page_size: useMemo(
        () => withDefault(NumberParam, defaultPageSize),
        [defaultPageSize],
      ),
    },
    { updateType: "replaceIn" },
  );
  const [pagination, setPagination] = usePaginationState({
    defaultPage: page,
    defaultPageSize: page_size,
  });
  const { pageIndex, pageSize } = pagination;

  useEffect(() => {
    // update pagination query parameters
    setPaginationQuery({
      page: pageIndex + 1,
      page_size: pageSize,
    });
  }, [pageIndex, pageSize, setPaginationQuery]);

  return [pagination, setPagination];
}

export function usePaginationState({
  defaultPageSize = 10,
  defaultPage = 1,
}: DefaultPaginationState = {}) {
  return useState<PaginationState>({
    pageIndex: defaultPage - 1,
    pageSize: defaultPageSize,
  });
}
