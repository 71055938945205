import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import humanFormat from "human-format";
import { useDashboardParameters } from "../context";
import { usePreviousTimeSpan } from "../hooks/usePreviousTimeSpan";
import { useSessionsStatsQuery } from "../hooks/useSessionsStatsQuery";
import { PeriodStatCompare } from "./PeriodStatCompare";

dayjs.extend(duration);

export function NumberOfCloudRenderedMinutes() {
  const { from, to, ...filters } = useDashboardParameters();
  const currentPeriodStatQuery = useSessionsStatsQuery({
    queryOptions: {
      select: (stats) => [
        {
          count: Math.round((stats[0]?.totalDurationSeconds ?? 0) / 60),
          key: "total",
        },
      ],
    },
    from,
    to,
    renderType: "CloudRendered",
    ...filters,
  });
  const previousPeriodStatQuery = useSessionsStatsQuery({
    queryOptions: {
      select: (stats) => [
        {
          count: Math.round((stats[0]?.totalDurationSeconds ?? 0) / 60),
          key: "total",
        },
      ],
    },
    ...usePreviousTimeSpan(from, to),
    renderType: "CloudRendered",
    ...filters,
  });

  return (
    <PeriodStatCompare
      currentPeriodStatQuery={currentPeriodStatQuery}
      previousPeriodStatQuery={previousPeriodStatQuery}
      title={"Cloud rendering"}
      label={`minutes`}
      render={(stat) => <>{humanFormat(stat)}</>}
    />
  );
}
