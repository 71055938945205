import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { Fragment, useMemo, useState } from "react";
import {
  Controller,
  SubmitHandler,
  UseFormReturn,
  useWatch,
} from "react-hook-form";
import { FaTimes as RemoveIcon } from "react-icons/fa";
import { NetworkSecurityRuleDirection } from "../../backend/types";
import { AddButton, FormSubmitButton } from "../../components";
import { DevTool } from "../../hookform-devtools";
import {
  ApplicationNetworkSecurityRuleSchema,
  defaultCidrBlock,
} from "./ApplicationNetworkdSecurityRuleSchema";

export function ApplicationNetworkSecurityRuleForm({
  form: { formState, handleSubmit, register, control, setValue, watch },
  onSubmit,
}: {
  form: UseFormReturn<ApplicationNetworkSecurityRuleSchema>;
  onSubmit: SubmitHandler<ApplicationNetworkSecurityRuleSchema>;
}) {
  const [portSettingMode, setPortSettingMode] = useState<
    "any" | "range" | "single"
  >(
    watch("to_port") === watch("from_port")
      ? watch("to_port") === 0
        ? "any"
        : "single"
      : "range",
  );
  const direction = useWatch({ control, name: "direction" });
  const isInbound = useMemo(
    () => direction === NetworkSecurityRuleDirection.Inbound,
    [direction],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit, console.error)}>
      <Stack spacing={4}>
        <FormControl isInvalid={!!formState.errors.description}>
          <FormLabel>Name / Description</FormLabel>
          <Input type="text" {...register("description")} />
          <FormErrorMessage>
            {formState.errors.description?.message}
          </FormErrorMessage>
        </FormControl>
        <Stack spacing={4}>
          <FormControl isInvalid={!!formState.errors.protocol}>
            <FormLabel>Protocol</FormLabel>
            <Controller
              control={control}
              name="protocol"
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  value={field.value.toString()}
                  onChange={(nextValue) => {
                    field.onChange(nextValue);

                    if (nextValue === "any") {
                      setPortSettingMode("any");
                      setValue("from_port", 0, { shouldDirty: true });
                      setValue("to_port", 0, { shouldDirty: true });
                    }
                  }}
                >
                  <Stack direction="row">
                    <Radio value="any">Any</Radio>
                    <Radio value="tcp">TCP</Radio>
                    <Radio value="udp">UDP</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
            <FormErrorMessage>
              {formState.errors.protocol?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!formState.errors.direction}>
            <FormLabel>Direction</FormLabel>
            <Controller
              control={control}
              name="direction"
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  value={field.value.toString()}
                  onChange={(nextValue) => field.onChange(Number(nextValue))}
                >
                  <Stack direction="row">
                    <Radio
                      value={NetworkSecurityRuleDirection.Inbound.toString()}
                    >
                      Inbound
                    </Radio>
                    <Radio
                      value={NetworkSecurityRuleDirection.Outbound.toString()}
                    >
                      Outbound
                    </Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
            <FormErrorMessage>
              {formState.errors.direction?.message}
            </FormErrorMessage>
          </FormControl>
        </Stack>
        <FormControl isInvalid={!!formState.errors.to_port}>
          <FormLabel>Port(s)</FormLabel>
          <Tooltip
            label="Disabled because protocol is Any"
            hidden={watch("protocol") !== "any"}
          >
            <Stack spacing={4}>
              <RadioGroup
                value={portSettingMode}
                isDisabled={watch("protocol") === "any"}
                onChange={(value) => {
                  setPortSettingMode(value as "any" | "range" | "single");
                  if (value === "any") {
                    setValue("from_port", 0, { shouldDirty: true });
                    setValue("to_port", 0, { shouldDirty: true });
                  } else if (value === "range") {
                    setValue("from_port", 1, { shouldDirty: true });
                    setValue("to_port", 1, { shouldDirty: true });
                  }
                }}
              >
                <HStack spacing={4}>
                  <Radio value="any">Any</Radio>
                  <Radio value="single">Single</Radio>
                  <Radio value="range">Range</Radio>
                </HStack>
              </RadioGroup>
              {portSettingMode === "single" && (
                <Input
                  size="sm"
                  type="number"
                  width={40}
                  value={watch("to_port")}
                  onChange={(event) => {
                    setValue("to_port", Number(event.target.value), {
                      shouldDirty: true,
                    });
                    setValue("from_port", Number(event.target.value), {
                      shouldDirty: true,
                    });
                  }}
                />
              )}
              {portSettingMode === "range" && (
                <HStack spacing={4}>
                  <Input size="sm" type="number" {...register("from_port")} />
                  <Text>-</Text>
                  <Input size="sm" type="number" {...register("to_port")} />
                </HStack>
              )}
              {portSettingMode !== "any" && (
                <FormHelperText>
                  Specify the{" "}
                  {portSettingMode === "range"
                    ? "range of ports"
                    : portSettingMode === "single"
                    ? "port"
                    : ""}{" "}
                  which should be accessible.
                </FormHelperText>
              )}
            </Stack>
          </Tooltip>
          <FormErrorMessage>
            {formState.errors.to_port?.message ??
              formState.errors.from_port?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!formState.errors.cidr_blocks}>
          <FormLabel>
            {isInbound ? "Traffic Source(s)" : "Traffic Destination(s)"}
          </FormLabel>
          <Controller
            control={control}
            name="cidr_blocks"
            render={({ field: { value, onChange, ...fieldProps } }) => (
              <Stack spacing={4}>
                {value.map((cidr_block, idx) => (
                  <Fragment key={idx}>
                    <InputGroup>
                      <Input
                        isInvalid={
                          (!!formState.errors.cidr_blocks &&
                            !!formState.errors.cidr_blocks[idx]) ||
                          !!formState.errors.cidr_blocks?.message
                        }
                        {...fieldProps}
                        value={cidr_block}
                        onChange={(event) =>
                          onChange(
                            value.map((_, i) =>
                              i === idx ? event.target.value : _,
                            ),
                          )
                        }
                      />
                      <InputRightElement
                        onClick={() =>
                          onChange(value.filter((_, i) => i !== idx))
                        }
                      >
                        <IconButton
                          aria-label="Remove CIDR Block"
                          variant="ghost"
                          icon={<Icon as={RemoveIcon} />}
                          colorScheme="gray"
                          _hover={{ color: "red" }}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      {!!formState.errors.cidr_blocks &&
                        !!formState.errors.cidr_blocks.length &&
                        formState.errors.cidr_blocks[idx]?.message}
                    </FormErrorMessage>
                  </Fragment>
                ))}
                <FormErrorMessage>
                  {!!formState.errors.cidr_blocks &&
                    !formState.errors.cidr_blocks.length &&
                    formState.errors.cidr_blocks?.message}
                </FormErrorMessage>
                <AddButton
                  size="sm"
                  onClick={() => onChange([...value, defaultCidrBlock])}
                  colorScheme="gray"
                >
                  Add CIDR Block
                </AddButton>
              </Stack>
            )}
          />
          <FormHelperText>
            {isInbound
              ? `Determines the traffic that can reach your instance. Specify a single IP address, or an IP address range in CIDR notation (for example, 203.0.113.5/32). If connecting from behind a firewall, you'll need the IP address range used by the client computers.`
              : `Determines the traffic that can leave your instance, and where it can go. Specify a single IP address, or an IP address range in CIDR notation (for example, 203.0.113.5/32). If connecting from behind a firewall, you'll need the IP address range used by the client computers.`}
          </FormHelperText>
        </FormControl>
        <FormSubmitButton alignSelf={"end"} formState={formState} />
      </Stack>
      <DevTool control={control} />
    </form>
  );
}
