import { Button, usePopoverContext } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { removeUserFromGroup } from "../backend/api";
import { getQueryKeyForGroup } from "../hooks/useGroupQuery";
import { getQueryKeyForUser } from "../hooks/useUserQuery";

export function RemoveUserFromGroupButton({
  groupId,
  userId,
}: {
  groupId: number | string;
  userId: number | string;
}) {
  const queryClient = useQueryClient();
  const popover = usePopoverContext();

  return (
    <Button
      variant="ghost"
      fontWeight="normal"
      fontSize="sm"
      w="full"
      colorScheme="red"
      onClick={async () => {
        await removeUserFromGroup(groupId, userId);
        queryClient.invalidateQueries({
          queryKey: getQueryKeyForUser(userId).slice(0, 2),
        });
        queryClient.invalidateQueries({
          queryKey: getQueryKeyForGroup(groupId).slice(0, 2),
        });
        popover.onClose();
      }}
    >
      Remove user from group
    </Button>
  );
}
