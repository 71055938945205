import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { getAllUsers, User } from "../../backend";
import { UsersQueryFilters } from "../../backend/api";
import { useActiveOrganizationQuery } from "../../hooks";
import { getQueryKeyForOrganization } from "../../hooks/useActiveOrganization";

export function useAllUsersQuery<T = User[]>(
  filters: Omit<UsersQueryFilters, "organization">,
  { enabled, ...options }: Partial<UseQueryOptions<User[], Error, T>> = {
    enabled: true,
  },
) {
  const { data: organization } = useActiveOrganizationQuery();

  return useQuery<User[], Error, T>({
    queryKey: [
      ...getQueryKeyForOrganization(organization?.id ?? null),
      "users",
      filters,
    ],
    queryFn: async () =>
      await getAllUsers({ ...filters, organization: organization?.id ?? -1 }),
    enabled: !!organization?.id && enabled,
    ...options,
  });
}
