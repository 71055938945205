import { Box, Button, Flex, HStack, Stack } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { AxiosError } from "axios";
import { useCallback, useMemo, useState } from "react";
import { Link, generatePath } from "react-router-dom";
import { UserGroup } from "../../backend";
import { addUsersToGroup } from "../../backend/api";
import {
  GroupProfile,
  LinkButton,
  OptionsButton,
  PaginatedTable,
  RemoveUserFromGroupButton,
  UserGroupSelect,
} from "../../components";
import { UserGroupOption } from "../../components/UserGroupSelect";
import { useActiveOrganizationQuery, useUserQuery } from "../../hooks";
import { getQueryKeyForUser } from "../../hooks/useUserQuery";
import { namedRoutes } from "../../routes";

const columnHelper = createColumnHelper<UserGroup>();

export function UserGroupMemberships({ userId }: { userId: number | string }) {
  const queryClient = useQueryClient();
  const userQuery = useUserQuery({
    userId,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  });
  const activeOrganizationQuery = useActiveOrganizationQuery();
  const userGroupFilter = useCallback(
    (group: UserGroup) =>
      !userQuery.data?.groups.find(
        (assignedUserGroup) => assignedUserGroup.id === group.id,
      ),
    [userQuery],
  );
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        header: "User Group",
        cell: (props) => (
          <Link
            to={generatePath(namedRoutes.userGroup.overview, {
              groupId: props.row.original.id.toString(),
            })}
          >
            <GroupProfile
              group={props.row.original}
              _hover={{ textDecoration: "underline" }}
              cursor="pointer"
            />
          </Link>
        ),
      }),
      columnHelper.display({
        id: "options",
        cell: (props) => (
          <Flex justifyContent={"end"}>
            <OptionsButton label="Click on this button to display user actions">
              <LinkButton
                to={generatePath(namedRoutes.userGroup.overview, {
                  groupId: props.row.original.id.toString(),
                })}
                fontWeight="normal"
                fontSize="sm"
                variant="ghost"
                w="full"
              >
                Show group&apos;s details
              </LinkButton>
              <RemoveUserFromGroupButton
                groupId={props.row.original.id}
                userId={userId}
              />
            </OptionsButton>
          </Flex>
        ),
        header: () => <Flex justifyContent={"end"}>Options</Flex>,
      }),
    ],
    [userId],
  );
  const table = useReactTable({
    columns,
    data: useMemo(
      () =>
        userQuery.data?.groups.filter(
          (group) => group.organization === activeOrganizationQuery.data?.id,
        ) ?? [],
      [activeOrganizationQuery.data?.id, userQuery.data?.groups],
    ),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
    autoResetPageIndex: true,
  });

  const [groupsToBeAdded, setGroupsToBeAdded] = useState<UserGroupOption[]>([]);
  const addUserToGroupMutation = useMutation<
    void,
    AxiosError,
    UserGroupOption[]
  >({
    mutationFn: async (groupIds) => {
      await Promise.all(
        groupIds.map((groupId) =>
          addUsersToGroup(groupId.value, [parseInt(userId.toString())]),
        ),
      );
    },
    onSuccess: () =>
      // refetch user details after adding groups
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForUser(userId).slice(0, 2),
      }),
  });

  return (
    <Stack maxW={"lg"} spacing={3}>
      <HStack>
        <Box flexGrow={1}>
          <UserGroupSelect
            onChange={(values) => setGroupsToBeAdded(values.slice())}
            value={groupsToBeAdded}
            filter={userGroupFilter}
          />
        </Box>
        <Button
          size="sm"
          isDisabled={!groupsToBeAdded.length}
          isLoading={addUserToGroupMutation.isPending}
          onClick={async () => {
            await addUserToGroupMutation.mutateAsync(groupsToBeAdded);
            setGroupsToBeAdded([]);
          }}
        >
          Add to {groupsToBeAdded.length > 1 ? "groups" : "group"}
        </Button>
      </HStack>
      <PaginatedTable table={table} />
    </Stack>
  );
}
