import { UseQueryResult } from "@tanstack/react-query";
import { createContext, useContext } from "react";
import { usePaginationQueryParams } from "../hooks";
import { SessionFilters, SessionList } from "../session-management";

export type SessionsFilters = Omit<SessionFilters, "page" | "pageSize">;

interface ISessionListContext {
  filters: SessionsFilters;
  query: UseQueryResult<SessionList>;
  pagination: ReturnType<typeof usePaginationQueryParams>[0];
  setPagination: ReturnType<typeof usePaginationQueryParams>[1];
}

const sessionList: ISessionListContext = {} as ISessionListContext;

export const SessionsListContext = createContext(sessionList);

export const useSessionListContext = () => useContext(SessionsListContext);
