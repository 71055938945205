import { useContext, useEffect } from "react";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";
import { useLocation } from "react-router-dom";
import { namedRoutes } from "../routes";

export default function Logout() {
  const { logOut }: IAuthContext = useContext(AuthContext);
  const { state } = useLocation();

  useEffect(() => {
    const url = `${window.location.protocol}//${window.location.host}${state?.from?.pathname || namedRoutes.landing}`;

    window.location.assign(`/auth/logout/?next=${encodeURIComponent(url)}`);
    logOut();
  }, [logOut, state?.from]);

  return null;
}
