import { FormControl, FormErrorMessage, Icon, Stack } from "@chakra-ui/react";
import { useQueries } from "@tanstack/react-query";
import {
  DropdownIndicatorProps,
  GroupBase,
  OptionBase,
  OptionProps,
  Select,
  chakraComponents,
} from "chakra-react-select";
import { useCallback, useState } from "react";
import { FaSearch as SearchIcon } from "react-icons/fa";
import { useDebounce } from "use-debounce";
import { PermissionSubject } from "../backend";
import { Application } from "../backend/types";
import { UserOrGroupProfile } from "../components";
import {
  getQueryForGroup,
  getQueryForUser,
  useActiveOrganizationQuery,
  useApplicationPermissionMutation,
  useGroupPermissionsForApplicationQuery,
  useSearchGroupQuery,
  useSearchUserQuery,
  useUserPermissionsForApplicationQuery,
} from "../hooks";
import { ApplicationPermissionManagement } from "./components/";

interface PermissionSubjectOption extends OptionBase {
  value: PermissionSubject;
}

const customComponents = {
  Option: ({
    ...props
  }: OptionProps<
    PermissionSubjectOption,
    false,
    GroupBase<PermissionSubjectOption>
  >) => {
    return (
      <chakraComponents.Option {...props}>
        <UserOrGroupProfile subject={props.data.value} />
      </chakraComponents.Option>
    );
  },
  DropdownIndicator: (
    props: DropdownIndicatorProps<
      PermissionSubjectOption,
      false,
      GroupBase<PermissionSubjectOption>
    >,
  ) => (
    <chakraComponents.DropdownIndicator {...props}>
      <Icon as={SearchIcon} fontSize="md" />
    </chakraComponents.DropdownIndicator>
  ),
};

export function ShareViaSearch({
  applicationId,
}: {
  applicationId: Application["id"];
}) {
  const { data: organization } = useActiveOrganizationQuery();
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText] = useDebounce(searchText, 300);

  const { data: { results: users } = {}, isLoading: areUsersLoading } =
    useSearchUserQuery(debouncedSearchText);
  const { data: { results: userGroups } = {}, isLoading: areGroupsLoading } =
    useSearchGroupQuery(debouncedSearchText);

  const isLoading = areUsersLoading || areGroupsLoading;

  const userPermissionsForApp =
    useUserPermissionsForApplicationQuery(applicationId).data;
  // FIXME: would be better to have one api call to get all the users
  const filteredUsers = useQueries({
    queries: (userPermissionsForApp ?? []).map((item) => {
      return getQueryForUser(item.user);
    }),
    combine: (results) => {
      const existingUsersWithPermission = results
        .filter((query) => !!query.data)
        .map((query) => ({ type: "user", ...query.data! }));

      return (
        users
          ?.filter(
            (user) =>
              !existingUsersWithPermission.some((item) => item.id === user.id),
          )
          .map<PermissionSubject>((user) => ({ type: "user", ...user })) ?? []
      );
    },
  });

  const groupPermissionsForApp =
    useGroupPermissionsForApplicationQuery(applicationId).data;
  // FIXME: would be better to have one api call to get all the groups
  const filteredUserGroups = useQueries({
    queries: (groupPermissionsForApp ?? []).map((item) => {
      return getQueryForGroup(item.group);
    }),
    combine: (results) => {
      const existingGroupsWithPermission = results
        .filter((query) => !!query.data)
        .map((query) => ({ type: "group", ...query.data! }));

      return (
        userGroups
          ?.filter(
            (group) =>
              !existingGroupsWithPermission.some(
                (item) => item.id === group.id,
              ),
          )
          .map<PermissionSubject>((group) => ({ type: "group", ...group })) ??
        []
      );
    },
  });
  const permissionMutation = useApplicationPermissionMutation(applicationId);

  const onSubjectSelect = useCallback(
    (subject: PermissionSubject) => {
      organization &&
        permissionMutation.mutate({
          subject,
          organizationId: organization.id,
          permissions: ["view"],
        });
    },
    [organization, permissionMutation],
  );

  return (
    <Stack spacing={6} width="fit-content" minWidth={"container.md"}>
      <FormControl isInvalid={permissionMutation.isError}>
        <Select<
          PermissionSubjectOption,
          false,
          GroupBase<PermissionSubjectOption>
        >
          isMulti={false}
          isLoading={isLoading}
          placeholder="Find users or groups"
          inputValue={searchText}
          onInputChange={setSearchText}
          blurInputOnSelect
          backspaceRemovesValue
          // We don't want the value to ever be shown
          value={null}
          onChange={(option) => {
            if (option) {
              onSubjectSelect(option?.value);
            }
          }}
          components={customComponents}
          options={[
            {
              label: "Users",
              options: filteredUsers.map((v) => ({ value: v })),
            },
            {
              label: "Groups",
              options: filteredUserGroups.map((v) => ({ value: v })),
            },
          ]}
          // Need to disable the built-in filtering as we're loading data via the API, see https://github.com/TanStack/query/discussions/3277#discussioncomment-4861535
          filterOption={null}
          hasStickyGroupHeaders
          // don't append the search icon as a addon
          useBasicStyles
        />
        <FormErrorMessage>
          {permissionMutation.error?.response?.data
            ? Object.values(permissionMutation.error?.response?.data)
                .flat()
                .join(", ")
            : permissionMutation.error?.message}
        </FormErrorMessage>
      </FormControl>
      <ApplicationPermissionManagement applicationId={applicationId} />
    </Stack>
  );
}
