import {
  Badge,
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Link,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { InputWithCopyToClipboard } from "../components";
import { useApplicationDetailsContext } from "./ApplicationDetailsContext";

export function ApplicationIntegrations() {
  const { application } = useApplicationDetailsContext();

  return (
    <>
      <Stack spacing={8} width="fit-content">
        <Stack spacing={4}>
          <Heading size="sm">Access Control Plugin</Heading>
          <FormControl width="auto" isInvalid={!application.oauth2_client_id}>
            <FormLabel htmlFor="identity" whiteSpace={"nowrap"} marginRight={0}>
              OAuth2 Client ID
            </FormLabel>
            {application.oauth2_client_id && (
              <InputWithCopyToClipboard
                id="identity"
                isReadOnly
                value={application.oauth2_client_id}
              />
            )}
            <FormErrorMessage>
              No OAuth2 credentials have been setup for this application. Please
              contact support.
            </FormErrorMessage>
            <FormHelperText>
              <Stack spacing={2}>
                <Text>
                  To protect your application&apos;s from unauthorized use,
                  Portal&apos;s access control plugins need to be integrated
                  within your application and configured with the above OAuth2
                  Client ID.
                </Text>
                <Text>
                  Take a look at our{" "}
                  <Link href="https://docs.innoactive.io/iasup/how-to-implement-access-control">
                    documentation
                  </Link>{" "}
                  to learn more about integrating access control within your
                  application.
                </Text>
              </Stack>
            </FormHelperText>
          </FormControl>
        </Stack>
        <Stack spacing={4}>
          <Heading size="sm">API Access</Heading>
          <Text color="chakra-subtle-text">
            You can use the application&apos;s identifiers to upload new builds
            / versions for this app via Portal&apos;s API, e.g. when integrating
            with CI/CD pipelines.
          </Text>
          <HStack>
            <FormControl width="auto">
              <FormLabel htmlFor="id" whiteSpace={"nowrap"} marginRight={0}>
                Application ID
              </FormLabel>
              <Box maxW="xs">
                <InputWithCopyToClipboard
                  id="id"
                  isReadOnly
                  value={application.id}
                  width={96}
                />
              </Box>
            </FormControl>
            <Spacer />
            <FormControl width="auto">
              <FormLabel
                htmlFor="identity"
                whiteSpace={"nowrap"}
                marginRight={0}
              >
                <Badge variant="subtle" colorScheme="gray" fontSize="2xs">
                  Deprecated
                </Badge>{" "}
                Application Identity
              </FormLabel>
              <Box maxW="xs">
                <InputWithCopyToClipboard
                  id="identity"
                  isReadOnly
                  value={application.legacy_identity}
                  width={28}
                />
              </Box>
            </FormControl>
          </HStack>
        </Stack>
      </Stack>
    </>
  );
}
