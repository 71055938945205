import { Icon, Stack, Text, VStack } from "@chakra-ui/react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import {
  FaInbox as DropzoneIcon,
  FaLevelDownAlt as DropIcon,
} from "react-icons/fa";

export function Dropzone({
  onDrop,
  multiple,
  accept,
  instructionText = "Click or drag file (.zip, .apk) to this area to upload",
}: Pick<DropzoneOptions, "onDrop" | "multiple" | "accept"> & {
  instructionText?: string;
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    accept,
  });

  return (
    <Stack spacing={4}>
      <VStack
        cursor="pointer"
        _hover={{ borderColor: "gray.400" }}
        borderWidth={2}
        borderStyle="dashed"
        padding={!isDragActive ? 3 : 6}
        transition="all 300ms"
        borderColor={isDragActive ? "green.200" : "gray.200"}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <>
            <Icon boxSize={10} color="gray.500" as={DropIcon} />
            <Text>Drop the file here ...</Text>
          </>
        ) : (
          <>
            <Icon boxSize={10} color="gray.200" as={DropzoneIcon} />
            <Text>{instructionText}</Text>
          </>
        )}
      </VStack>
    </Stack>
  );
}
