import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
} from "@chakra-ui/react";
import { useCloudRenderingRegionQuery } from "../hooks/useCloudRenderingRegionsQuery";
import { FixedIpsTab } from "./FixedIpsTab";
import { GeneralTab } from "./GeneralTab";

export const EditRegionModal = ({
  isOpen,
  onClose,
  region,
}: {
  isOpen: boolean;
  onClose(): void;
  region: string;
}) => {
  const regionDetails = useCloudRenderingRegionQuery(region);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{regionDetails?.displayName}</ModalHeader>

        <ModalCloseButton />
        <ModalBody>
          <Tabs>
            <TabList>
              <Tab>General</Tab>
              {regionDetails?.type !== "Unmanaged" && (
                <Tooltip
                  label="Fixed IPs are only available for AWS regions."
                  isDisabled={regionDetails?.cloudProvider === "AWS"}
                >
                  <Tab isDisabled={regionDetails?.cloudProvider !== "AWS"}>
                    Fixed IPs
                  </Tab>
                </Tooltip>
              )}
            </TabList>

            <TabPanels>
              <TabPanel paddingX={0} paddingY={4}>
                <GeneralTab region={region} />
              </TabPanel>
              {regionDetails?.type !== "Unmanaged" && (
                <TabPanel paddingX={0} paddingY={4}>
                  <FixedIpsTab region={region} />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
