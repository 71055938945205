import { Button, ButtonProps, forwardRef, Icon } from "@chakra-ui/react";
import { useMemo } from "react";
import { FieldValues, FormState } from "react-hook-form";
import {
  FaCheck as CheckmarkIcon,
  FaExclamationTriangle as WarningIcon,
} from "react-icons/fa";

export const FormSubmitButton = forwardRef(
  (
    {
      formState: {
        isSubmitSuccessful,
        isSubmitting,
        isDirty,
        isValid,
        isSubmitted,
      },
      ...props
    }: {
      formState: Omit<
        FormState<FieldValues>,
        "errors" | "dirtyFields" | "touchedFields"
      >;
    } & ButtonProps,
    ref,
  ) => {
    const isValidationFailed = useMemo(
      () => !isValid && isDirty && isSubmitted,
      [isDirty, isSubmitted, isValid],
    );

    return (
      <Button
        ref={ref}
        mt={4}
        isLoading={isSubmitting}
        leftIcon={
          isSubmitSuccessful && !isDirty ? (
            <Icon as={CheckmarkIcon} />
          ) : isValidationFailed ? (
            <Icon as={WarningIcon} />
          ) : undefined
        }
        isDisabled={!isDirty}
        colorScheme={
          isSubmitSuccessful && !isDirty
            ? "green"
            : isValidationFailed
            ? "yellow"
            : isDirty
            ? "blue"
            : undefined
        }
        type="submit"
        {...props}
      >
        {isSubmitSuccessful && !isDirty
          ? "Saved"
          : isValidationFailed
          ? "Validation failed!"
          : "Save"}
      </Button>
    );
  },
);
