import { ReactNode, useContext, useEffect } from "react";
import { AuthContext } from "react-oauth2-code-pkce";
import { useThrottledCallback } from "use-debounce";
import {
  genericBackendClient as genericPortalApiClient,
  backendClient as portalApiClient,
} from "../backend";
import { useActiveOrganizationQuery, useCurrentUserQuery } from "../hooks";
import sessionManagementApiClient from "../session-management/";
import { AuthenticationContext } from "./auth";

const apiClients = [
  portalApiClient,
  genericPortalApiClient,
  sessionManagementApiClient,
];

export function AuthenticationProvider({ children }: { children: ReactNode }) {
  const { token, logIn: login, loginInProgress } = useContext(AuthContext);
  const { data: organization } = useActiveOrganizationQuery();
  const unauthorizedHandler = useThrottledCallback(async (error) => {
    // If we're unauthorized, user must login again (but only do this if not yet refreshing)
    if (error?.response?.status === 401) {
      login();
    }
    throw error;
  }, 250);

  useEffect(() => {
    if (!token) return;

    // ensure the token is sent with requests to portal and session management api
    apiClients.forEach((apiClient) => {
      apiClient.defaults.headers.common.Authorization = `Bearer ${token}`;
    });

    // handle 401s
    const interceptorHandlers = [portalApiClient].map((client) =>
      client.interceptors.response.use(
        (response) => response,
        unauthorizedHandler,
      ),
    );

    return () => {
      interceptorHandlers.forEach((interceptor) =>
        portalApiClient.interceptors.response.eject(interceptor),
      );
    };
  }, [token, unauthorizedHandler]);

  // send organization id with requests to session management api
  useEffect(() => {
    apiClients.forEach((apiClient) => {
      apiClient.defaults.headers.common["Portal-Organization-Id"] =
        organization?.id;
    });
  }, [organization?.id]);

  const {
    data: user,
    error,
    isLoading: isPendingAuthentication,
  } = useCurrentUserQuery({
    enabled: !!token && !!organization,
    retry: false,
  });

  return (
    <AuthenticationContext.Provider
      value={{
        token,
        error: error?.response?.data.message ?? error?.message,
        user,
        isPendingAuthentication: isPendingAuthentication || loginInProgress,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}
