import { useToast } from "@chakra-ui/react";
import { useCallback } from "react";
import { DevTool } from "../../hookform-devtools";
import { ReservedInstance } from "../../session-management";
import {
  useReservedInstanceForm,
  useUpdateReservedInstanceMutation,
} from "../hooks";
import { ManageReservedInstanceForm } from "./ManageReservedInstanceForm";
import { UpdateReservedInstanceSchema } from "./reservedInstanceSchema";

export const EditReservedInstanceForm = ({
  reservedInstance,
  onForwardSubmit,
}: {
  reservedInstance: ReservedInstance;
  onForwardSubmit: () => void;
}) => {
  const toast = useToast();
  const updateReservedInstanceMutation = useUpdateReservedInstanceMutation({});
  const form = useReservedInstanceForm(reservedInstance);

  const onSubmit = useCallback(
    (values: UpdateReservedInstanceSchema) =>
      updateReservedInstanceMutation.mutateAsync(
        {
          id: reservedInstance.id,
          change: values,
        },
        {
          onSuccess: () => {
            onForwardSubmit();
            toast({
              title: "Created",
              description: `Saved reserved instances.`,
              status: "success",
              duration: 4000,
              isClosable: true,
              position: "top-right",
            });
          },
          onError: () => {
            toast({
              title: "Error",
              description: `Could not save reserved instances. Please try again later.`,
              status: "error",
              duration: 4000,
              isClosable: true,
              position: "top-right",
            });
          },
        },
      ),
    [
      onForwardSubmit,
      reservedInstance.id,
      toast,
      updateReservedInstanceMutation,
    ],
  );

  return (
    <>
      <ManageReservedInstanceForm
        form={form}
        onSubmit={onSubmit}
        hasSaveError={updateReservedInstanceMutation.isError}
      />
      <DevTool control={form.control} placement="bottom-left" />
    </>
  );
};
