import axios from "axios";
import { FieldValues, UseFormSetError } from "react-hook-form";
import { entries, isArray, isString } from "remeda";
import { ObjectSchema } from "yup";
import { PortalError } from "../backend";

type FormErrorPath<TFieldValues extends FieldValues> = Parameters<
  UseFormSetError<TFieldValues>
>[0];

type FormErrorList<TFieldValues extends FieldValues> = [
  FormErrorPath<TFieldValues>,
  string,
][];

export function extractFormSubmissionErrors<TFieldValues extends FieldValues>(
  err: unknown,
  schema: ObjectSchema<TFieldValues>,
): FormErrorList<TFieldValues> {
  if (axios.isAxiosError<PortalError>(err)) {
    const errors = err.response?.data ?? {};
    const { detail, message, ...fieldErrors } = errors;
    const nonFieldError = detail ?? message;
    if (nonFieldError) {
      return [
        [
          "root",
          isString(nonFieldError)
            ? nonFieldError
            : "An unexpected error occurred.",
        ],
      ];
    } else {
      return entries(fieldErrors)
        .filter(([_, error]) => error && error.length > 0)
        .map(([field, error]) => {
          return [
            isFormErrorPath(field, schema)
              ? field
              : field === "non_field_errors"
                ? "root"
                : `root.${field}`,
            isString(error)
              ? error
              : isArray(error)
                ? error.join(" ")
                : "An unexpected error occurred.",
          ];
        });
    }
  } else {
    return [["root", err?.toString() ?? "An unexpected error occurred."]];
  }
}
function isFormErrorPath<TFieldValues extends FieldValues>(
  fieldName: string,
  schema: ObjectSchema<TFieldValues>,
): fieldName is FormErrorPath<TFieldValues> {
  return fieldName === "root" || Object.keys(schema.fields).includes(fieldName);
}
