import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useMemo, useState } from "react";
import { Navigate } from "react-router-dom";
import { requestPasswordReset } from "../backend/api";
import { namedRoutes } from "../routes";

type RequestPasswordResetResponse = Awaited<
  ReturnType<typeof requestPasswordReset>
>;

export function RequestPasswordResetForm() {
  const [email, setEmail] = useState("");
  const requestPasswordResetMutation = useMutation<
    RequestPasswordResetResponse,
    AxiosError<RequestPasswordResetResponse | { email: string[] }>,
    string
  >({
    mutationFn: (email: string) => requestPasswordReset(email),
  });
  const { globalError, fieldError } = useMemo(() => {
    if (
      requestPasswordResetMutation.error &&
      requestPasswordResetMutation.error.response
    ) {
      if (requestPasswordResetMutation.error.response.status === 500) {
        return {
          globalError: "An unexpected error occurred. Please try again later.",
          fieldError: undefined,
        };
      }

      if ("detail" in requestPasswordResetMutation.error.response.data) {
        return {
          globalError: requestPasswordResetMutation.error.response.data.detail,
          fieldError: undefined,
        };
      } else if ("email" in requestPasswordResetMutation.error.response.data) {
        return {
          globalError: undefined,
          fieldError:
            requestPasswordResetMutation.error.response.data.email.join(", "),
        };
      }
    }
    return { globalError: undefined, fieldError: undefined };
  }, [requestPasswordResetMutation.error]);

  if (requestPasswordResetMutation.isSuccess) {
    return <Navigate to={namedRoutes.resetPassword.requested} />;
  }

  return (
    <Stack spacing="6">
      <Heading size={{ base: "xs", md: "sm" }}>Request password reset</Heading>
      <Stack spacing="5">
        {globalError && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>{globalError}</AlertDescription>
          </Alert>
        )}
        <Text>
          Forgot your password? Enter your email address below, and we&apos;ll
          email instructions for setting a new one.
        </Text>
        <FormControl isInvalid={!!fieldError}>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            id="email"
            name="email"
            type="email"
            required
            onChange={(e) => setEmail(e.target.value)}
            isDisabled={requestPasswordResetMutation.isPending}
          />
          <FormErrorMessage>{fieldError}</FormErrorMessage>
        </FormControl>

        <Button
          colorScheme="brand"
          isLoading={requestPasswordResetMutation.isPending}
          onClick={() => requestPasswordResetMutation.mutate(email)}
        >
          Reset password
        </Button>
      </Stack>
    </Stack>
  );
}
