import {
  Alert,
  AlertIcon,
  Button,
  Checkbox,
  Heading,
  SkeletonText,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { PortalError } from "../backend";
import { consentToTermsAndPrivacy, getTermsAndPrivacy } from "../backend/api";
import { MessageFromAxiosError } from "../components/MessageFromAxiosError";
import { useActiveOrganizationQuery } from "../hooks";
import { loadDataFromElement } from "../utils/hydrate-data";

function useTermsAndPrivacyQuery() {
  return useQuery<
    Awaited<ReturnType<typeof getTermsAndPrivacy>>,
    AxiosError<PortalError>
  >({
    queryKey: ["terms-and-privacy"],
    queryFn: getTermsAndPrivacy,
    retry: false,
  });
}

const next = loadDataFromElement<string>("next", "/");

export function TermsConsentPage() {
  const termsAndPrivacyQuery = useTermsAndPrivacyQuery();
  const consentToTermsandPrivacyMutation = useMutation<
    void,
    AxiosError<PortalError>,
    number
  >({
    mutationFn: async (organizationId: number) => {
      await consentToTermsAndPrivacy(organizationId);
    },
    onSuccess: () => {
      setIsPrivacyPolicyAccepted(true);
      setIsTermsOfUseAccepted(true);
      window.location.href = next;
    },
  });
  const activeOrganizationQuery = useActiveOrganizationQuery();
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);
  const [isTermsOfUseAccepted, setIsTermsOfUseAccepted] = useState(false);

  return (
    <Stack spacing={8}>
      {termsAndPrivacyQuery.isError && (
        <Alert status="error">
          <AlertIcon />
          Error: {termsAndPrivacyQuery.error.message}
        </Alert>
      )}
      <Text>
        To proceed, please accept the following terms of use and data privacy
        statement.
      </Text>
      <Stack spacing={1}>
        <Heading>Terms of use</Heading>
        <SkeletonText
          isLoaded={!termsAndPrivacyQuery.isLoading}
          noOfLines={Math.ceil(Math.random() * 10)}
        />
        {termsAndPrivacyQuery.isSuccess && (
          <Text
            dangerouslySetInnerHTML={{
              __html: termsAndPrivacyQuery.data.terms_content,
            }}
          />
        )}
      </Stack>
      <Stack spacing={1}>
        <Heading>Privacy Policy</Heading>
        <SkeletonText
          isLoaded={!termsAndPrivacyQuery.isLoading}
          noOfLines={Math.ceil(Math.random() * 10)}
        />
        {termsAndPrivacyQuery.isSuccess && (
          <Text
            dangerouslySetInnerHTML={{
              __html: termsAndPrivacyQuery.data.privacy_content,
            }}
          />
        )}
      </Stack>
      <Stack spacing={2}>
        <Checkbox
          checked={isTermsOfUseAccepted}
          onChange={(e) => setIsTermsOfUseAccepted(e.target.checked)}
        >
          Accept Terms of Use
        </Checkbox>
        <Checkbox
          checked={isPrivacyPolicyAccepted}
          onChange={(e) => setIsPrivacyPolicyAccepted(e.target.checked)}
        >
          Accept Privacy Policy
        </Checkbox>
        {consentToTermsandPrivacyMutation.isError && (
          <Alert status="error">
            <AlertIcon />
            <MessageFromAxiosError
              error={consentToTermsandPrivacyMutation.error}
              message="Error while consenting: "
            />
          </Alert>
        )}
        <Button
          marginTop={4}
          alignSelf={"start"}
          colorScheme="brand"
          onClick={() =>
            activeOrganizationQuery.data?.id &&
            consentToTermsandPrivacyMutation.mutate(
              activeOrganizationQuery.data?.id,
            )
          }
          isDisabled={!(isPrivacyPolicyAccepted && isTermsOfUseAccepted)}
          isLoading={consentToTermsandPrivacyMutation.isPending}
        >
          Proceed
        </Button>
      </Stack>
    </Stack>
  );
}
