import { Alert, AlertIcon, AlertProps } from "@chakra-ui/react";

export function AuthorizingAlert(props: AlertProps) {
  return (
    <Alert status="info" maxW={"container.sm"} {...props}>
      <AlertIcon />
      Authorizing ...
    </Alert>
  );
}
