import { useQuery } from "@tanstack/react-query";
import { getFixedIps } from "../../session-management";

export function getFixedIpsQueryKey(region?: string) {
  return region ? ["fixed-ips", region] : ["fixed-ips"];
}

export function useFixedIpsQuery(region?: string) {
  return useQuery({
    queryKey: getFixedIpsQueryKey(region),
    queryFn: () => getFixedIps(region),
    retry: false,
    throwOnError: true,
  });
}
