import {
  Button,
  ButtonProps,
  Flex,
  FormControl,
  FormErrorMessage,
  Stack,
  usePopoverContext,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CellContext,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { AxiosError } from "axios";
import { useCallback, useMemo } from "react";
import { generatePath } from "react-router-dom";
import { useAuthentication } from "../../auth";
import { Application, Organization } from "../../backend";
import {
  fetchUserPlatformPermissions,
  updateApplication,
} from "../../backend/api";
import {
  ExternalLinkButton,
  OptionsButton,
  PaginatedTable,
} from "../../components";
import { AddToOrganizationSelect } from "../../components/AddToOrganizationSelect";
import { baseColumns } from "../../components/OrganizationMembershipTable";
import { useActiveOrganizationQuery } from "../../hooks";
import {
  getQueryForApplication,
  getQueryKeyForApplication,
} from "../../hooks/useApplicationQuery";
import { namedRoutes, routerBasename } from "../../routes";

function RemoveApplicationFromOrganizationButton({
  orgId,
  application,
  ...props
}: {
  orgId: number;
  application: Application;
} & ButtonProps) {
  const queryClient = useQueryClient();
  // we need access to the popover context to close the popover we're running in
  const popover = usePopoverContext();
  const removeApplicationFromOrganizationMutation = useMutation<
    Application,
    AxiosError,
    number
  >({
    mutationFn: (orgId) =>
      updateApplication(application.id, {
        organizations: application.organizations.filter((org) => org !== orgId),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplication(application.id),
      });
      popover.onClose();
    },
  });

  return (
    <Button
      variant="ghost"
      fontWeight="normal"
      fontSize="sm"
      w="full"
      colorScheme="red"
      onClick={() => removeApplicationFromOrganizationMutation.mutate(orgId)}
      isLoading={removeApplicationFromOrganizationMutation.isPending}
      {...props}
    >
      Revoke access from organization
    </Button>
  );
}

export function ApplicationOrganizationMemberships({
  application,
}: {
  application: Application;
}) {
  const { data: organization } = useActiveOrganizationQuery();
  const { user } = useAuthentication();
  const queryClient = useQueryClient();
  const columns = useMemo(
    () =>
      baseColumns.map((column) => {
        if (column.id === "options") {
          column.cell = (props: CellContext<Organization, unknown>) => (
            <Flex justifyContent={"end"}>
              <OptionsButton label="Click on this button to display user actions">
                <ExternalLinkButton
                  variant="ghost"
                  fontWeight="normal"
                  fontSize="sm"
                  w="full"
                  href={
                    window.location.origin.replace(
                      organization?.subdomain ?? "",
                      props.row.original.subdomain,
                    ) +
                    routerBasename +
                    generatePath(namedRoutes.application.access, {
                      applicationId: application.id.toString(),
                    })
                  }
                  isDisabled={organization?.id === props.row.original.id}
                  target="_blank"
                >
                  Manage access within organization
                </ExternalLinkButton>
                <RemoveApplicationFromOrganizationButton
                  application={application}
                  orgId={props.row.original.id}
                  isDisabled={organization?.id === props.row.original.id}
                />
              </OptionsButton>
            </Flex>
          );
        }

        return column;
      }),
    [application, organization?.id, organization?.subdomain],
  );
  const data = useMemo(
    () =>
      (user?.organizations ?? []).filter(
        (org) =>
          application.organizations.includes(org.id) &&
          org.id !== organization?.id,
      ),
    [application.organizations, organization?.id, user?.organizations],
  );
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
    autoResetPageIndex: true,
  });
  const filterOrganizations = useCallback(
    (org: Organization) =>
      !application.organizations.find(
        (assignedOrganization) => assignedOrganization === org.id,
      ),
    [application.organizations],
  );
  const addApplicationToOrganizationsMutation = useMutation<
    void,
    AxiosError,
    Organization[]
  >({
    mutationFn: async (orgs) => {
      if (!user?.id) throw new Error("Unknown User");
      // check if the user has permissions to add the application to the organization (for each of the organizations)
      await Promise.all(
        orgs.map((org) =>
          fetchUserPlatformPermissions(user?.id, org.id).then((perms) => {
            if (!perms.includes("assets.add_application"))
              throw new Error(
                `User does not have permission to add application to organization "${org.name}"`,
              );
          }),
        ),
      );

      await updateApplication(application.id, {
        organizations: [
          ...new Set([
            ...application.organizations,
            ...orgs.map((org) => org.id),
          ]),
        ],
      });
    },
    onSuccess: () =>
      // refetch application details after adding organizations
      queryClient.invalidateQueries(getQueryForApplication(application.id)),
  });

  return (
    <Stack maxW={"lg"} spacing={3}>
      <FormControl isInvalid={addApplicationToOrganizationsMutation.isError}>
        <AddToOrganizationSelect
          addToOrganizationMutation={addApplicationToOrganizationsMutation}
          filterOrganizations={filterOrganizations}
        />
        <FormErrorMessage>
          {addApplicationToOrganizationsMutation.error?.message}
        </FormErrorMessage>
      </FormControl>
      <PaginatedTable table={table} />
    </Stack>
  );
}
