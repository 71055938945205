import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { ObfuscatedSecretInput } from "../../components";
import { AuthProviderSchema } from "../OrganizationAuthProvidersForm";
import { AuthProviderDisplayNameInput } from "./AuthProviderDisplayNameInput";
import { RedirectUriInput } from "./RedirectUriInput";
import { ProviderSlug } from "./utils";

export function AzureADAuthProviderForm() {
  const {
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<AuthProviderSchema>();
  const slug: ProviderSlug = "azuread-oauth2";

  return (
    <Stack spacing={4}>
      <Text>
        Please see the{" "}
        <Link
          textDecor={"underline"}
          href="https://docs.microsoft.com/en-us/azure/active-directory/develop/quickstart-register-app#register-an-application"
        >
          official documentation from Microsoft
        </Link>{" "}
        on how to obtain the values below.
      </Text>
      <RedirectUriInput providerSlug={slug} />
      <FormControl
        isInvalid={!!errors["azuread-oauth2"]?.SOCIAL_AUTH_AZUREAD_OAUTH2_KEY}
        isDisabled={isSubmitting}
      >
        <FormLabel htmlFor="azuread-oauth2.SOCIAL_AUTH_AZUREAD_OAUTH2_KEY">
          Application (client) ID
        </FormLabel>
        <Input
          placeholder="11111111-1111-1111-1111-111111111"
          {...register("azuread-oauth2.SOCIAL_AUTH_AZUREAD_OAUTH2_KEY")}
        />
        <FormHelperText>
          The <Text as="i">Application (client) ID</Text> as provided by Azure
          for the app registration.
        </FormHelperText>
        <FormErrorMessage>
          {errors["azuread-oauth2"]?.SOCIAL_AUTH_AZUREAD_OAUTH2_KEY?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={
          !!errors["azuread-oauth2"]?.SOCIAL_AUTH_AZUREAD_OAUTH2_SECRET
        }
        isDisabled={isSubmitting}
      >
        <FormLabel htmlFor="azuread-oauth2.SOCIAL_AUTH_AZUREAD_OAUTH2_SECRET">
          Client Secret
        </FormLabel>
        <ObfuscatedSecretInput
          placeholder="Enter client secret ..."
          {...register("azuread-oauth2.SOCIAL_AUTH_AZUREAD_OAUTH2_SECRET")}
        />
        <FormHelperText>
          The <Text as="i">Client Secret</Text> configured within Azure for the
          app registration. Please refer to the{" "}
          <Link href="https://docs.microsoft.com/en-us/azure/active-directory/develop/quickstart-register-app#add-a-client-secret">
            official documentation on how to obtain
          </Link>
          .
        </FormHelperText>
        <FormErrorMessage>
          {errors["azuread-oauth2"]?.SOCIAL_AUTH_AZUREAD_OAUTH2_SECRET?.message}
        </FormErrorMessage>
      </FormControl>
      <AuthProviderDisplayNameInput providerSlug={slug} />
    </Stack>
  );
}
