import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { DateTimeParam, useQueryParams, withDefault } from "use-query-params";

type DateRange = {
  fromDate: Date;
  toDate: Date;
};

const defaultToDate = new Date();

export function useDateTimeRangeQueryParams({
  fromDate,
  toDate = defaultToDate,
}: DateRange): [DateRange, Dispatch<SetStateAction<DateRange>>] {
  const [{ from_date, to_date }, setDateTimeRangeQuery] = useQueryParams(
    {
      from_date: useMemo(
        () => withDefault(DateTimeParam, fromDate),
        [fromDate],
      ),
      to_date: useMemo(() => withDefault(DateTimeParam, toDate), [toDate]),
    },
    { updateType: "replaceIn" },
  );
  const [dateTimeRange, setDateTimeRange] = useState<DateRange>({
    fromDate: from_date,
    toDate: to_date,
  });
  const { fromDate: fromDateState, toDate: toDateState } = dateTimeRange;

  useEffect(() => {
    // update datetime range query parameters
    setDateTimeRangeQuery({
      from_date: fromDateState,
      to_date: toDateState,
    });
  }, [fromDateState, toDateState, setDateTimeRangeQuery]);

  return [dateTimeRange, setDateTimeRange];
}
