import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { backendClient } from "../../backend";
import { useActiveOrganizationQuery } from "../../hooks";
import { AuthProviderData, ProviderSlug } from "./utils";

export function getQueryKeyForConfiguredAuthProviders(
  organizationId: number,
  params: {
    slug?: ProviderSlug;
    [key: string]: object | string | undefined;
  } = {},
) {
  return ["organizations", organizationId, "auth-providers", params];
}

export function useConfiguredAuthProviders(
  params: {
    slug?: ProviderSlug;
    [key: string]: object | string | undefined;
  } = {},
  options: Partial<UseQueryOptions<AuthProviderData[], AxiosError>> = {
    refetchOnWindowFocus: false,
  },
) {
  const { data: organization } = useActiveOrganizationQuery();
  return useQuery<AuthProviderData[], AxiosError>({
    queryKey: getQueryKeyForConfiguredAuthProviders(
      organization?.id ?? 0,
      params,
    ),
    queryFn: async () => {
      const response = await backendClient.get<{ results: AuthProviderData[] }>(
        `/api/organizations/${organization?.id}/auth-providers/`,
        { params },
      );
      return response.data.results;
    },
    enabled: !!organization?.id,
    ...options,
  });
}
