import {
  Alert,
  AlertIcon,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { UserGroup } from "../backend";
import { updateUserGroupInformation } from "../backend/api";
import { Editable } from "../components";
import { DevTool } from "../hookform-devtools";
import { useGroupQuery } from "../hooks";
import { getQueryKeyForGroup } from "../hooks/useGroupQuery";
import { GroupUserMemberships } from "./components";
import { UserGroupInfoSchema, userGroupInfoSchema } from "./schema";
import { isEveryoneGroup, isManageableGroup } from "./utils";

export function UserGroupInfo() {
  const { groupId = "" } = useParams();
  const userGroupQuery = useGroupQuery({
    groupId,
    queryOptions: { retry: false, throwOnError: true },
  });
  const { control, formState, handleSubmit, reset } =
    useForm<UserGroupInfoSchema>({
      mode: "onChange",
      resolver: yupResolver(userGroupInfoSchema),
    });
  const queryClient = useQueryClient();
  const { mutate: updateUserGroupAsync, ...updateGroupInfoMutation } =
    useMutation<UserGroup, AxiosError, UserGroupInfoSchema>({
      mutationFn: (groupData) => updateUserGroupInformation(groupId, groupData),
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: getQueryKeyForGroup(groupId).slice(0, 2),
        }),
    });
  const onSubmit = useCallback(
    (values: UserGroupInfoSchema) => {
      updateUserGroupAsync(values);
    },
    [updateUserGroupAsync],
  );

  // prefill form values when data is available
  useEffect(() => {
    reset({ name: userGroupQuery.data?.name });
  }, [userGroupQuery.data, reset]);

  if (!isEveryoneGroup(userGroupQuery.data)) {
    return (
      <>
        <Heading size="sm">Basic Information</Heading>
        <FormControl
          isInvalid={!!formState.errors.name || updateGroupInfoMutation.isError}
          maxW="lg"
        >
          <FormLabel htmlFor="name">Name</FormLabel>
          <Skeleton isLoaded={userGroupQuery.isSuccess}>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <Editable
                  id="name"
                  placeholder="Enter group name..."
                  {...field}
                  onSubmit={() => handleSubmit(onSubmit)()}
                  isDisabled={
                    updateGroupInfoMutation.isPending ||
                    userGroupQuery.isLoading ||
                    !isManageableGroup(userGroupQuery.data)
                  }
                />
              )}
            />
          </Skeleton>
          <FormErrorMessage>
            {formState.errors.name?.message ??
              updateGroupInfoMutation.error?.message}
          </FormErrorMessage>
        </FormControl>
        <Heading size="sm">Users</Heading>

        <Text>
          The following users are part of the {userGroupQuery.data?.name} group
          and inherit its permissions:
        </Text>
        <GroupUserMemberships groupId={groupId} />
        <DevTool control={control} placement="bottom-left" />
      </>
    );
  } else {
    return (
      <Alert status="info">
        <AlertIcon />
        <Stack>
          <Text>
            Every user is part of the {userGroupQuery.data?.name} group.
          </Text>
          <Text>
            This group can be used to setup default permission and access for
            all users.
          </Text>
        </Stack>
      </Alert>
    );
  }
}
