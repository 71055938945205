import { useQueries, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  backendClient,
  PaginatedUserIdsList,
  PaginationFilters,
  UserGroup,
} from "../backend";
import { getUserIdsWithinGroup } from "../backend/api";
import { QueryParameters } from "../utils/types";
import { getQueryForUser } from "./useUserQuery";

type GroupQueryOptions = Partial<UseQueryOptions<UserGroup, AxiosError>>;

export function getQueryForGroup(
  groupId: number | string,
  queryParams: QueryParameters = {},
  queryOptions: GroupQueryOptions = {},
) {
  return {
    queryKey: getQueryKeyForGroup(groupId, queryParams),
    queryFn: async () =>
      (
        await backendClient.get<UserGroup>(`/api/groups/${groupId}/`, {
          params: queryParams,
        })
      ).data,
    ...queryOptions,
  };
}

export function getQueryKeyForGroup(
  groupId: string | number,
  queryParams: QueryParameters = {},
) {
  return ["group", groupId.toString(), queryParams];
}

export function useGroupQuery({
  groupId,
  queryParams = {},
  queryOptions = {},
}: {
  groupId: number | string;
  queryParams?: QueryParameters;
  queryOptions?: GroupQueryOptions;
}) {
  return useQuery(getQueryForGroup(groupId, queryParams, queryOptions));
}

export function useGroupUsersQuery(
  groupId: number | string,
  queryParams: PaginationFilters = {},
  queryOptions: Partial<UseQueryOptions<PaginatedUserIdsList>> = {},
) {
  const userIdsInGroupQuery = useQuery({
    queryKey: [...getQueryKeyForGroup(groupId, queryParams), "users"],
    queryFn: async () => getUserIdsWithinGroup(groupId, queryParams),
    ...queryOptions,
  });

  return useQueries({
    queries: (userIdsInGroupQuery.data?.results ?? []).map((userId) =>
      getQueryForUser(userId),
    ),
    combine: (results) => ({
      isSuccess: results.every((r) => r.isSuccess),
      isLoading: results.some((r) => r.isPending),
      count: userIdsInGroupQuery.data?.count,
      data: results.filter((r) => r.isSuccess).map((r) => r.data),
    }),
  });
}
