import {
  Box,
  BoxProps,
  useFormControl,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { ReactElement } from "react";

export function FakeInput({
  children,
  ...props
}: BoxProps & { children: ReactElement }) {
  const styles = useMultiStyleConfig("Input", props);
  const input = useFormControl<HTMLInputElement>({});

  return (
    <Box
      display="flex"
      alignItems="center"
      // required to get a highlight when an input within the fake input is focussed
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      _focusWithin={(styles.field as any)._focusVisible}
      {...input}
      __css={styles.field}
      {...props}
    >
      {children}
    </Box>
  );
}
// required for InputGroup to apply the correct style
FakeInput.id = "Input";
