import { HttpErrorFallback } from "../components/ErrorFallback";
import { useBrandingQuery } from "../hooks";

export function AccessDenied() {
  const { data: branding } = useBrandingQuery();

  return (
    <HttpErrorFallback
      statusCode={403}
      message="No access to Control Panel"
      resetButtonLabel={`Go to ${branding?.product_name ?? "XR Portal"}`}
      resetErrorBoundary={() => {
        window.location.href = "/control-panel/portal-frontend/";
      }}
    />
  );
}
