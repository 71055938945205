import {
  ErrorBoundary as SentryErrorBoundary,
  captureException,
} from "@sentry/react";
import { useEffect } from "react";
import { useRouteError } from "react-router";
import { ErrorFallback, RouterErrorFallback } from "./ErrorFallback";

export function RouterErrorElement() {
  const error = useRouteError() as Error;

  // ensure the error captured on the router is sent to Sentry
  // see https://github.com/getsentry/sentry-javascript/discussions/6912#discussioncomment-5242133
  useEffect(() => {
    captureException(error, { level: "error" });
  }, [error]);

  return <RouterErrorFallback error={error} />;
}

export function ErrorBoundary({ children }: { children: React.ReactNode }) {
  return (
    <SentryErrorBoundary
      fallback={({ error, resetError }) => (
        <ErrorFallback error={error} resetErrorBoundary={resetError} />
      )}
    >
      {children}
    </SentryErrorBoundary>
  );
}
