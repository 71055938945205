import { Grid, GridItem, Skeleton } from "@chakra-ui/react";
import { Fragment } from "react";
import { ApplicationDisplaySkeleton } from "./ApplicationDisplay";
import { UserProfileSkeleton } from "./UserProfile";

export function PermissionListLoader({
  type,
}: {
  type: "application" | "group" | "user" | "generic";
}) {
  return (
    <Grid
      gridTemplateColumns="max-content max-content 1fr"
      gridTemplateRows="auto"
      columnGap={4}
      rowGap={2}
      alignItems={"center"}
    >
      {[...Array(10).keys()].map((key) => (
        <Fragment key={key}>
          <GridItem>
            {type === "application" ? (
              <ApplicationDisplaySkeleton size={"small"} />
            ) : type === "user" ? (
              <UserProfileSkeleton />
            ) : type === "group" ? (
              <UserProfileSkeleton />
            ) : (
              <Skeleton width={72} height={12} />
            )}
          </GridItem>
          <GridItem>
            <Skeleton height={8} w={96} />
          </GridItem>
          <GridItem>
            <Skeleton height={8} w={36} />
          </GridItem>
        </Fragment>
      ))}
    </Grid>
  );
}
