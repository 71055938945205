import {
  Box,
  Button,
  ButtonProps,
  Divider,
  Heading,
  LinkProps as HrefProps,
  Icon,
  Image,
  Skeleton,
  SkeletonText,
  Spacer,
  StackProps,
  Text,
  VStack,
  forwardRef,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import {
  FaUnlockAlt as AdminIcon,
  FaDesktop as ApplicationsIcon,
  FaChartBar as DashboardIcon,
  FaExternalLinkAlt as ExternalLinkIcon,
  FaRegQuestionCircle as HelpIcon,
  FaInfo as InfoIcon,
  FaList as ListIcon,
  FaCog as SettingsIcon,
  FaUsers as UsersIcon,
} from "react-icons/fa";
import { LinkProps, NavLink as RouterLink } from "react-router-dom";
import { fetchServiceInfo } from "../backend/api";
import { useActiveOrganizationQuery, useBrandingQuery } from "../hooks";
import { usePermissions } from "../hooks/usePermissions";
import { namedRoutes } from "../routes";
import { OrganizationSwitcher } from "./OrganizationSwitcher";
import { CloudRenderingIcon } from "./icons";

const BaseMenuLink = forwardRef<ButtonProps, "button">((props, ref) => (
  <Button
    justifyContent="normal"
    variant="ghost"
    fontSize="sm"
    color="menu-text"
    size="lg"
    textDecorationColor="transparent"
    ref={ref}
    {...props}
  />
));

function MenuLink({ to, ...props }: ButtonProps & LinkProps) {
  return (
    <BaseMenuLink
      as={RouterLink}
      _activeLink={{
        outlineColor: "brand-bg",
      }}
      to={to}
      {...props}
    />
  );
}

function ExternalMenuLink({ href, ...props }: ButtonProps & HrefProps) {
  return <BaseMenuLink as="a" target="_blank" href={href} {...props} />;
}

export function SideBar(props: StackProps) {
  const organizationQuery = useActiveOrganizationQuery();
  const { data: branding } = useBrandingQuery();
  const backendServiceInfoQuery = useQuery({
    queryKey: ["backendServiceInfo"],
    queryFn: () => fetchServiceInfo(),
    retry: false,
    refetchOnWindowFocus: false,
  });
  const permissions = usePermissions();

  return (
    <VStack
      paddingTop={4}
      spacing={6}
      alignItems="normal"
      textAlign="left"
      height="100vh"
      {...props}
    >
      <Box flexShrink={0}>
        {branding?.logo ? (
          <Skeleton isLoaded={!!branding?.logo}>
            <Image src={branding?.logo} height={12} objectFit="contain" />
          </Skeleton>
        ) : (
          <>
            <Heading as="h1" size="sm">
              <SkeletonText
                noOfLines={1}
                isLoaded={organizationQuery.isSuccess}
              >
                {organizationQuery.data?.name}
              </SkeletonText>
            </Heading>
          </>
        )}
      </Box>

      <Divider />

      <VStack as="nav" alignItems="stretch" spacing={2} flexGrow={1}>
        <OrganizationSwitcher
          size="lg"
          fontSize="sm"
          variant="outline"
          textAlign="left"
          color="menu-text"
          paddingX={6}
        />

        {permissions.view_user && (
          <MenuLink
            leftIcon={<Icon as={DashboardIcon} />}
            as={RouterLink}
            to={namedRoutes.dashboard}
          >
            Dashboard
          </MenuLink>
        )}
        {permissions.add_application && (
          <MenuLink
            leftIcon={<Icon as={ApplicationsIcon} />}
            as={RouterLink}
            to={namedRoutes.application.list}
          >
            Applications
          </MenuLink>
        )}
        {(permissions.view_user ||
          permissions.change_user ||
          permissions.change_usergroup) && (
          <MenuLink
            leftIcon={<Icon as={UsersIcon} />}
            as={RouterLink}
            to={namedRoutes.user.list}
          >
            Users &amp; Groups
          </MenuLink>
        )}
        {permissions.change_organization && (
          <MenuLink
            leftIcon={<Icon as={CloudRenderingIcon} />}
            as={RouterLink}
            to={namedRoutes.cloudRenderingSettings}
          >
            Cloud Rendering
          </MenuLink>
        )}
        {permissions.view_user && (
          <MenuLink
            leftIcon={<Icon as={ListIcon} />}
            as={RouterLink}
            to={namedRoutes.session.list}
          >
            Sessions
          </MenuLink>
        )}
        {permissions.change_organization && (
          <MenuLink
            leftIcon={<Icon as={SettingsIcon} />}
            as={RouterLink}
            to={namedRoutes.organization.settings}
          >
            Settings
          </MenuLink>
        )}
        <Divider />
        <ExternalMenuLink
          href="https://www.innoactive.io/support"
          leftIcon={<Icon as={HelpIcon} />}
        >
          Support
        </ExternalMenuLink>
        {permissions.is_superuser && (
          <ExternalMenuLink
            as="a"
            href={"/control-panel/django-admin/"}
            leftIcon={<Icon as={AdminIcon} />}
            target="_blank"
          >
            Admin Area
          </ExternalMenuLink>
        )}
        <ExternalMenuLink
          as="a"
          href="/control-panel/portal-frontend/"
          leftIcon={<Icon as={ExternalLinkIcon} />}
          target="_blank"
        >
          <Text maxWidth="full" whiteSpace="normal" lineHeight={1.5}>
            {branding?.product_name}
          </Text>
        </ExternalMenuLink>
      </VStack>
      <Spacer />
      <VStack>
        <Skeleton isLoaded={backendServiceInfoQuery.isFetched}>
          <BaseMenuLink isDisabled leftIcon={<Icon as={InfoIcon} />}>
            Version {backendServiceInfoQuery.data?.version}
          </BaseMenuLink>
        </Skeleton>
      </VStack>
    </VStack>
  );
}
