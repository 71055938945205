import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  HStack,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useRef } from "react";
import { CSRFTokenInput } from "../login/CSRFTokenInput";
import { loadDataFromElement } from "../utils/hydrate-data";

const error = loadDataFromElement<
  | {
      error: string;
      error_description: string;
      error_uri: string;
    }
  | undefined
>("error", undefined);
const oauthApplicationName = loadDataFromElement<string>(
  "oauth-application-name",
  "",
);
type AllowFormData = {
  client_id?: string | null;
  redirect_uri?: string | null;
  scope?: string | null;
};
const formInitialData = loadDataFromElement<AllowFormData>("form-initial", {});
const formData = loadDataFromElement<AllowFormData>("form-data", {});
const mergedFormData = { ...formInitialData, ...formData };

export function AuthorizationView() {
  const allowRef = useRef<HTMLInputElement>(null);

  if (error) {
    return (
      <Alert
        status="error"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Invalid Authorization Request!
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          {error.error_description}
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <form method="post">
      <Stack spacing={6}>
        <Heading size="md">Authorize {oauthApplicationName}?</Heading>
        <Text>
          By authorizing <b>{oauthApplicationName}</b>, you explicitly grant
          permission to access any of your data.
        </Text>
        <HStack>
          <Button
            type="submit"
            flexGrow={1}
            onClick={() => {
              allowRef.current!.value = "0";
            }}
          >
            Cancel
          </Button>
          <Button
            colorScheme="brand"
            type="submit"
            flexGrow={1}
            onClick={() => {
              allowRef.current!.value = "1";
            }}
          >
            Authorize
          </Button>
        </HStack>
      </Stack>
      {Object.entries(mergedFormData).map(([key, value]) => (
        <Input key={key} type="hidden" name={key} value={value ?? ""} />
      ))}
      <Input type="hidden" name="allow" value="0" ref={allowRef} />
      <CSRFTokenInput />
    </form>
  );
}
