import {
  QueryClient,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ApplicationPermission, PermissionSubject } from "../backend";
import { updateApplicationPermissions } from "../backend/api";
import {
  ApplicationId,
  GrantedApplicationPermission,
  UserApplicationPermission,
  UserGroupApplicationPermission,
  ValidationErrorResponse,
} from "../backend/types";
import { getQueryKeyForApplicationPermissionsForUserGroup } from "../groups/hooks/useUserGroupApplicationPermissionsQuery";
import { getQueryKeyForApplicationPermissionsForUser } from "../users/hooks/useUserApplicationPermissionsQuery";
import { getQueryKeyForGroupPermissionsForApplication } from "./useGroupApplicationPermissionsQuery";
import { getQueryKeyForUserPermissionsForApplication } from "./useUserApplicationPermissionsQuery";

export function useApplicationPermissionMutation(applicationId: ApplicationId) {
  const queryClient = useQueryClient();
  return useMutation<
    void,
    AxiosError<ValidationErrorResponse>,
    {
      subject: PermissionSubject;
      permissions: ApplicationPermission[];
      organizationId: number;
      validUntil?: Date;
    },
    {
      // subject is user case
      previousApplicationPermissionsForUserGroup?: GrantedApplicationPermission[];
      previousGroupPermissionsForApplication?: UserGroupApplicationPermission[];
      // subject is group case
      previousApplicationPermissionsForUser?: GrantedApplicationPermission[];
      previousUserPermissionsForApplication?: UserApplicationPermission[];
    }
  >({
    mutationFn: ({ ...permissionData }) =>
      updateApplicationPermissions({
        applicationId,
        ...permissionData,
      }),
    onMutate: async ({ subject, permissions, organizationId, validUntil }) =>
      optimisticallyUpdateApplicationPermissions(
        { permissions, applicationId, organizationId, subject, validUntil },
        queryClient,
      ),
    onError: (_, { subject }, context) => {
      // If the mutation fails,
      // use the context returned from onMutate to roll back
      restorePreviousApplicationPermissions(
        subject,
        queryClient,
        applicationId,
        context,
      );
    },
    onSuccess: (_, { subject }) => {
      invalidateApplicationPermissionQueries(
        subject,
        queryClient,
        applicationId,
      );
    },
  });
}

export async function optimisticallyUpdateApplicationPermissions(
  permissionData: {
    applicationId: ApplicationId;
    organizationId: number;
    permissions: ApplicationPermission[];
    subject: PermissionSubject;
    validUntil?: Date;
  },
  queryClient: QueryClient,
): Promise<ApplicationPermissionMutationContext | undefined> {
  const { applicationId, organizationId, permissions, subject, validUntil } =
    permissionData;

  if (permissionData.subject.type === "group") {
    const queryKeyForApplicationPermissionsForUserGroup =
      getQueryKeyForApplicationPermissionsForUserGroup(
        permissionData.subject.id,
      );
    const queryKeyForGroupPermissionsForApplication =
      getQueryKeyForGroupPermissionsForApplication(applicationId);

    // cancel any current queries for the permissions
    await queryClient.cancelQueries({
      queryKey: queryKeyForApplicationPermissionsForUserGroup,
    });
    await queryClient.cancelQueries({
      queryKey: queryKeyForGroupPermissionsForApplication,
    });

    // snapshot current permissions
    const previousApplicationPermissionsForUserGroup = queryClient.getQueryData<
      GrantedApplicationPermission[]
    >(queryKeyForApplicationPermissionsForUserGroup);
    const previousGroupPermissionsForApplication = queryClient.getQueryData<
      UserGroupApplicationPermission[]
    >(queryKeyForGroupPermissionsForApplication);

    // optimistically update the permissions
    queryClient.setQueryData<GrantedApplicationPermission[]>(
      queryKeyForApplicationPermissionsForUserGroup,
      (perms) => {
        const upsertedPermission: GrantedApplicationPermission = {
          application: applicationId,
          organization: organizationId,
          permissions,
          valid_until: validUntil ? validUntil?.toISOString() : null,
        };
        const newPerms = [...(perms ?? [])];
        const index = newPerms.findIndex(
          (perm) => perm.application === applicationId,
        );
        if (index === -1) {
          newPerms.unshift(upsertedPermission);
        } else if (!permissions.length) {
          // if permissions are empty, remove the permission
          newPerms.splice(index, 1);
        } else {
          newPerms[index] = upsertedPermission;
        }

        return newPerms;
      },
    );
    queryClient.setQueryData<UserGroupApplicationPermission[]>(
      queryKeyForGroupPermissionsForApplication,
      (perms) => {
        const upsertedPermission: UserGroupApplicationPermission = {
          group: subject.id,
          organization: organizationId,
          permissions,
          valid_until: validUntil ? validUntil?.toISOString() : null,
        };

        const newPerms = [...(perms ?? [])];
        const index = newPerms.findIndex((perm) => perm.group === subject.id);
        if (index === -1) {
          newPerms.push(upsertedPermission);
          // re-order the list of perms by group id (as this is also what the api does)
          newPerms.sort((a, b) => a.group - b.group);
        } else if (!permissions.length) {
          // if permissions are empty, remove the permission
          newPerms.splice(index, 1);
        } else {
          newPerms[index] = upsertedPermission;
        }

        return newPerms;
      },
    );

    // return the previous permissions to be able to revert the optimistic update
    return {
      previousApplicationPermissionsForUserGroup,
      previousGroupPermissionsForApplication,
    };
  }
  if (subject.type === "user") {
    const queryKeyForApplicationPermissionsForUser =
      getQueryKeyForApplicationPermissionsForUser(subject.id);
    const queryKeyForUserPermissionsForApplication =
      getQueryKeyForUserPermissionsForApplication(applicationId);

    // cancel any current queries for the permissions
    await queryClient.cancelQueries({
      queryKey: queryKeyForApplicationPermissionsForUser,
    });
    await queryClient.cancelQueries({
      queryKey: queryKeyForUserPermissionsForApplication,
    });

    // snapshot current permissions
    const previousApplicationPermissionsForUser = queryClient.getQueryData<
      GrantedApplicationPermission[]
    >(queryKeyForApplicationPermissionsForUser);
    const previousUserPermissionsForApplication = queryClient.getQueryData<
      UserApplicationPermission[]
    >(queryKeyForUserPermissionsForApplication);

    // optimistically update the permissions
    queryClient.setQueryData<GrantedApplicationPermission[]>(
      queryKeyForApplicationPermissionsForUser,
      (perms) => {
        const upsertedPermission: GrantedApplicationPermission = {
          application: applicationId,
          organization: organizationId,
          permissions,
          valid_until: validUntil ? validUntil?.toISOString() : null,
        };
        const newPerms = [...(perms ?? [])];
        const index = newPerms.findIndex(
          (perm) => perm.application === applicationId,
        );
        if (index === -1) {
          newPerms.push(upsertedPermission);
        } else if (!permissions.length) {
          // if permissions are empty, remove the permission
          newPerms.splice(index, 1);
        } else {
          newPerms[index] = upsertedPermission;
        }

        return newPerms;
      },
    );
    queryClient.setQueryData<UserApplicationPermission[]>(
      queryKeyForUserPermissionsForApplication,
      (perms) => {
        const upsertedPermission: UserApplicationPermission = {
          user: subject.id,
          organization: organizationId,
          permissions,
          valid_until: validUntil ? validUntil?.toISOString() : null,
        };

        const newPerms = [...(perms ?? [])];
        const index = newPerms.findIndex((perm) => perm.user === subject.id);
        if (index === -1) {
          newPerms.push(upsertedPermission);
          // re-order the list of perms by user id (as this is also what the api does)
          newPerms.sort((a, b) => a.user - b.user);
        } else if (!permissions.length) {
          // if permissions are empty, remove the permission
          newPerms.splice(index, 1);
        } else {
          newPerms[index] = upsertedPermission;
        }

        return newPerms;
      },
    );

    // return the previous permissions to be able to revert the optimistic update
    return {
      previousApplicationPermissionsForUser,
      previousUserPermissionsForApplication,
    };
  }
}

type ApplicationPermissionMutationContext = {
  // subject is user case
  previousApplicationPermissionsForUserGroup?: GrantedApplicationPermission[];
  previousGroupPermissionsForApplication?: UserGroupApplicationPermission[];
  // subject is group case
  previousApplicationPermissionsForUser?: GrantedApplicationPermission[];
  previousUserPermissionsForApplication?: UserApplicationPermission[];
};

export function restorePreviousApplicationPermissions(
  subject: PermissionSubject,
  queryClient: QueryClient,
  applicationId: ApplicationId,
  context?: ApplicationPermissionMutationContext,
) {
  if (subject.type === "group") {
    queryClient.setQueryData(
      getQueryKeyForApplicationPermissionsForUserGroup(subject.id),
      context?.previousApplicationPermissionsForUserGroup,
    );
    queryClient.setQueryData(
      getQueryKeyForGroupPermissionsForApplication(applicationId),
      context?.previousGroupPermissionsForApplication,
    );
  }
  if (subject.type === "user") {
    queryClient.setQueryData(
      getQueryKeyForApplicationPermissionsForUser(subject.id),
      context?.previousApplicationPermissionsForUser,
    );
    queryClient.setQueryData(
      getQueryKeyForUserPermissionsForApplication(applicationId),
      context?.previousUserPermissionsForApplication,
    );
  }
}

export function invalidateApplicationPermissionQueries(
  subject: PermissionSubject,
  queryClient: QueryClient,
  applicationId: ApplicationId,
) {
  if (subject.type === "group") {
    queryClient.invalidateQueries({
      queryKey: getQueryKeyForApplicationPermissionsForUserGroup(subject.id),
    });
    queryClient.invalidateQueries({
      queryKey: getQueryKeyForGroupPermissionsForApplication(applicationId),
    });
  }
  if (subject.type === "user") {
    queryClient.invalidateQueries({
      queryKey: getQueryKeyForApplicationPermissionsForUser(subject.id),
    });
    queryClient.invalidateQueries({
      queryKey: getQueryKeyForUserPermissionsForApplication(applicationId),
    });
  }
}
