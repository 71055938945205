import { exportToCsv } from "../../dashboard/export";
import { FixedIp } from "../../session-management";

export const FixedIpDownload = (fixedIps: FixedIp[]) => {
  const data = fixedIps?.map((c) => [c.region, c.ip]);

  data.unshift(["Region", "Ip"]);

  exportToCsv("fixed_ips.csv", data);
};
