import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import { Outlet, Route } from "react-router-dom";

import { useMemo } from "react";
import { NavigationTab, NavigationTabs } from "../components";
import { RouterErrorElement } from "../components/ErrorBoundary";
import { useIsSuperuser } from "../hooks/usePermissions";
import { SentryRoutes } from "../sentry";
import { RegionsTable } from "./Regions/RegionsTable";
import { ReservedInstancesTable } from "./ReservedInstances/ReservedInstancesTable";
import { VirtualMachinesTable } from "./VirtualMachines/VirtualMachinesTable";
import { CloudRenderingPolicies } from "./policies";

export function CloudRendering() {
  const isSuperuser = useIsSuperuser();

  const sections = useMemo(() => {
    const sect = [
      {
        label: "Regions",
        suffix: "regions",
      },
      {
        label: "Reserved Instances",
        suffix: "reserved-instances",
      },
      {
        label: "Virtual Machines",
        suffix: "virtual-machines",
        condition: isSuperuser,
      },
      {
        label: "Policies",
        suffix: "policies",
      },
    ];

    return sect.filter((s) => s.condition !== false);
  }, [isSuperuser]);

  return (
    <SentryRoutes>
      <Route
        path="/"
        element={
          <Box width={["max", "max", "100%"]}>
            <Stack spacing={6}>
              <Box>
                <Heading size="lg">Cloud Rendering</Heading>
                <Text>
                  Manage your organization&apos;s Cloud Rendering settings.
                </Text>
              </Box>
              <NavigationTabs>
                {sections.map((section, idx) => (
                  <NavigationTab
                    to={section.suffix}
                    key={idx}
                    label={section.label}
                  />
                ))}
              </NavigationTabs>
              <Stack spacing={8}>
                <Outlet />
              </Stack>
            </Stack>
          </Box>
        }
        errorElement={<RouterErrorElement />}
      >
        <Route path="reserved-instances" element={<ReservedInstancesTable />} />
        <Route path="regions" element={<RegionsTable />} />
        {isSuperuser && (
          <Route path="virtual-machines" element={<VirtualMachinesTable />} />
        )}
        <Route path="policies" element={<CloudRenderingPolicies />} />
      </Route>
    </SentryRoutes>
  );
}
