import { Box } from "@chakra-ui/react";
import { useRef } from "react";
import { useDateSegment } from "react-aria";
import { DateFieldState, DateSegment as _DateSegment } from "react-stately";

export function DateSegment({
  segment,
  state,
}: {
  segment: _DateSegment;
  state: DateFieldState;
}) {
  const ref = useRef(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <Box
      {...segmentProps}
      ref={ref}
      style={{
        ...segmentProps.style,
        fontVariantNumeric: "tabular-nums",
        boxSizing: "content-box",
      }}
      minWidth={
        segment.maxValue != null
          ? String(segment.maxValue).length + "ch"
          : undefined
      }
      paddingX="0.5"
      textAlign="end"
      outline="none"
      rounded="sm"
      color={
        segment.isPlaceholder
          ? "gray.400"
          : !segment.isEditable
          ? "gray.500"
          : "chakra-body-text"
      }
      _focus={{
        background: "brand-bg",
        color: "text-on-brand-bg",
      }}
    >
      {segment.text}
    </Box>
  );
}
