import { Stack, Text, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../../backend";
import { removeUserFromOrganization } from "../../backend/api";
import { useConfirm } from "../../confirm-dialog";
import { useActiveOrganizationQuery } from "../../hooks";
import { namedRoutes } from "../../routes";

export function useUserRemoveFromOrgConfirm() {
  const { confirm } = useConfirm();
  const { mutateAsync: removeUserFromOrganizationAsync } = useMutation({
    mutationFn: async (user: User) => {
      if (!activeOrganizationQuery.data?.id) {
        throw Error("Missing organization context");
      }
      await removeUserFromOrganization(
        activeOrganizationQuery.data?.id,
        user.id,
      );
    },
  });
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const activeOrganizationQuery = useActiveOrganizationQuery();

  return useCallback(
    (user?: User) => () => {
      if (!user) return;

      confirm({
        title: `Remove ${user.full_name} from ${activeOrganizationQuery.data?.name}`,
        body: (
          <Stack>
            <Text>
              You are about to remove the user <i>{user.full_name}</i> (
              {user.email}) from this organization.
            </Text>
            <Text>
              By removing the user from the organization, they will lose access
              to all content available within the organization.
            </Text>
          </Stack>
        ),
        confirmButtonText: "Remove",
        onConfirm: async () => {
          try {
            const result = await removeUserFromOrganizationAsync(user, {
              onSuccess: (_, user) => {
                navigate(namedRoutes.user.list);
                toast({
                  status: "success",
                  position: "top-right",
                  duration: 5000,
                  isClosable: true,
                  description: `User ${user.full_name} (${user.email}) has been removed from the organization.`,
                });
                queryClient.invalidateQueries({ queryKey: ["users"] });
              },
            });
            return result;
          } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.detail) {
              if (e.response?.status === 403) {
                throw Error(
                  `You lack permissions to remove ${user.full_name} (${user.email}) from the organization. Please reach out to an administrator.`,
                );
              }
            }

            throw e;
          }
        },
      });
    },
    [
      activeOrganizationQuery.data?.name,
      confirm,
      navigate,
      queryClient,
      toast,
      removeUserFromOrganizationAsync,
    ],
  );
}
