import { useColorMode } from "@chakra-ui/react";
import Color from "color";
import { useEffect } from "react";
import { useBrandingQuery } from "../hooks";

export const ColorModeConfiguration = () => {
  const { data: branding } = useBrandingQuery();
  const { setColorMode } = useColorMode();

  useEffect(() => {
    const currentMode = Color(branding?.background || undefined).isDark()
      ? "dark"
      : "light";
    if (branding?.background) setColorMode(currentMode);
  }, [setColorMode, branding]);

  return null;
};
