import { useUsersQuery } from "./useUsersQuery";

const searchResultsPageSize = 10;

export function useSearchUserQuery(
  searchText = "",
  pageSize = searchResultsPageSize,
) {
  return useUsersQuery({
    fulltext_search: searchText,
    page_size: pageSize,
  });
}
