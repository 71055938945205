import { Heading } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { ErrorBoundary } from "../components";
import { useDateRange } from "../hooks";
import { SessionListContextProvider } from "../sessions/SessionListContextProvider";
import { UserSessionOverview } from "./components";

export function UserActivity() {
  const { userId = "" } = useParams();
  const {
    startDate,
    endDate,
    updateDateRange,
    defaultDateRange,
    minDate,
    maxDate,
  } = useDateRange({
    fromDate: useMemo(
      () => dayjs().subtract(30, "days").startOf("day").toDate(),
      [],
    ),
    toDate: useMemo(() => dayjs().endOf("day").toDate(), []),
  });

  return (
    <ErrorBoundary>
      <Heading size="md">Sessions</Heading>
      <SessionListContextProvider
        userIdentifier={[userId]}
        from={startDate}
        to={endDate}
      >
        <UserSessionOverview
          updateDateRange={updateDateRange}
          defaultDateRange={defaultDateRange}
          minDate={minDate}
          maxDate={maxDate}
        />
      </SessionListContextProvider>
    </ErrorBoundary>
  );
}
