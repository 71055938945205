import { HStack, Skeleton, Stack, StackProps, Text } from "@chakra-ui/react";
import { ApplicationPreviewImage } from "../applications/components/ApplicationPreviewImage";
import { Application } from "../backend";
import {
  ApplicationBuild,
  ApplicationBuildId,
  ApplicationId,
} from "../backend/types";
import { useApplicationBuildQuery } from "../hooks/useApplicationBuildsQuery";
import { useApplicationQuery } from "../hooks/useApplicationQuery";
import { TargetPlatformDisplay } from "./TargetPlatformDisplay";

export function ApplicationDisplaySkeleton({
  size = "large",
}: {
  size?: "small" | "large";
}) {
  return (
    <HStack spacing={4}>
      <Skeleton
        height={size === "small" ? 7 : 14}
        width={size === "small" ? 12 : 24}
        borderRadius={size === "small" ? "sm" : "md"}
      />
      <Stack spacing={2} flexGrow={1} lineHeight={4}>
        <Skeleton w={64} h={4} />
        {size === "large" && <Skeleton w="full" h={4} />}
      </Stack>
    </HStack>
  );
}

export function LazyApplicationForApplicationBuildDisplay({
  applicationBuildId,
  size = "large",
  ...props
}: {
  applicationBuildId: ApplicationBuildId;
  size?: "small" | "large";
} & StackProps) {
  const applicationBuildQuery = useApplicationBuildQuery(applicationBuildId, {
    retry: false,
    refetchOnWindowFocus: false,
  });
  const applicationQuery = useApplicationQuery(
    applicationBuildQuery.data?.application ?? "",
    { enabled: applicationBuildQuery.isSuccess },
  );

  if (applicationBuildQuery.isError) {
    return (
      <ApplicationDisplay
        application={{
          images: [],
          panoramic_preview_image: null,
          name: "Unknown application",
          target_platform: "unknown",
        }}
        cursor="not-allowed"
        opacity={0.5}
        size={size}
        {...props}
      />
    );
  }

  if (!applicationBuildQuery.data) {
    return <ApplicationDisplaySkeleton size={size} />;
  }

  return (
    <ApplicationDisplay
      application={applicationQuery.data}
      size={size}
      {...props}
    />
  );
}

export function LazyApplicationDisplay({
  applicationId,
  size = "large",
  ...props
}: {
  applicationId: ApplicationId;
  size?: "small" | "large";
} & StackProps) {
  const { data: application, isPending } = useApplicationQuery(applicationId, {
    retry: false,
  });

  if (isPending) {
    return <ApplicationDisplaySkeleton size={size} />;
  }

  return (
    <ApplicationDisplay application={application} size={size} {...props} />
  );
}

export function ApplicationDisplay({
  application = {
    images: [],
    panoramic_preview_image: null,
    name: "Unknown application",
    target_platform: "unknown",
  },
  size = "large",
  ...props
}: {
  application?: Pick<
    Application,
    "name" | "images" | "panoramic_preview_image"
  > &
    Partial<Pick<ApplicationBuild, "target_platform">>;
  size?: "small" | "large";
} & StackProps) {
  return (
    <HStack spacing={size === "large" ? 4 : 2} {...props}>
      <ApplicationPreviewImage
        _groupHover={{
          outlineColor: "brand.500",
          outlineStyle: "solid",
          outlineWidth: "thin",
        }}
        application={application}
        width={size === "small" ? 12 : 24}
        height={size === "small" ? 7 : 14}
        borderRadius={size === "small" ? "sm" : "md"}
      />
      <Stack
        flexShrink={0}
        spacing={2}
        _groupHover={{ color: "brand.500" }}
        lineHeight={4}
      >
        <Text>{application.name}</Text>
        {size === "large" && application.target_platform && (
          <TargetPlatformDisplay platform={application.target_platform} />
        )}
      </Stack>
    </HStack>
  );
}
