import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react";
import { FieldValues, FormState } from "react-hook-form";

export function FormRootErrorMessage<TFieldValues extends FieldValues>({
  formState: {
    errors: { root: rootError },
  },
}: {
  formState: Pick<FormState<TFieldValues>, "errors">;
}) {
  if (!rootError) return null;

  return (
    <Alert status="error">
      <AlertIcon />
      <AlertDescription>{rootError.message}</AlertDescription>
    </Alert>
  );
}
