import { Organization } from "../backend";
import { useCurrentUserQuery, UserQueryOptions } from "./useUserQuery";

export function useOrganizationQuery({
  organizationId,
  options = {},
}: {
  organizationId: number | string;
  options?: UserQueryOptions<Organization | undefined>;
}) {
  return useCurrentUserQuery<Organization | undefined>({
    select: (user) =>
      user.organizations.find((org) => org.id === organizationId),
    ...options,
  });
}
