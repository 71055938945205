import {
  Box,
  Grid,
  GridItem,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { ApplicationImageDetails } from "../../backend";
import { updateApplicationImageOrder } from "../../backend/api";
import { Application, ApplicationId } from "../../backend/types";
import { useApplicationQuery } from "../../hooks";
import { getQueryKeyForApplication } from "../../hooks/useApplicationQuery";
import { ApplicationImageUpload } from "./ApplicationImageUpload";

function SortableItem({
  children,
  id,
}: {
  children: React.ReactNode;
  id: number | string;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </Box>
  );
}

export function ApplicationImageList({
  applicationId,
}: {
  applicationId: ApplicationId;
}) {
  const [imageOrder, setImageOrder] = useState<number[] | undefined>(undefined);
  const applicationQuery = useApplicationQuery(applicationId);
  const orderedApplicationImages = useMemo(() => {
    const applicationImages = applicationQuery.data?.images ?? [];
    if (!imageOrder) return applicationImages;
    return imageOrder
      .map((id) => applicationImages.find((image) => image.id === id))
      .concat(
        applicationImages.filter((image) => !imageOrder.includes(image.id)),
      )
      .filter((image): image is ApplicationImageDetails => !!image);
  }, [applicationQuery.data?.images, imageOrder]);
  const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const toast = useToast();
  const { mutate: updateImageOrder } = useMutation({
    mutationFn: (orderedImages: ApplicationImageDetails[]) =>
      updateApplicationImageOrder(
        applicationId,
        orderedImages.map((image) => image.id),
      ),
    // When mutate is called:
    onMutate: async (orderedImages) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      const queryKey = getQueryKeyForApplication(applicationId);
      await queryClient.cancelQueries({
        queryKey,
      });

      // Snapshot the previous value
      const previousAppDetails =
        queryClient.getQueryData<Application>(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData<Application>(queryKey, (old) => {
        if (!old) return old;
        return {
          ...(old ?? {}),
          images: orderedImages,
        };
      });

      // Return a context object with the snapshotted value
      return { previousAppDetails, queryKey };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, orderedImages, context) => {
      toast({
        title: "Failed to update image order",
        description: "Please try again later.",
        status: "error",
      });
      context?.queryKey &&
        queryClient.setQueryData(
          context?.queryKey,
          context?.previousAppDetails,
        );
    },
    // Always refetch after error or success:
    onSettled: (data, error, variables, context) => {
      context?.queryKey &&
        queryClient.invalidateQueries({ queryKey: context?.queryKey });
    },
  });

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // still allow clicking on the draggable elements see https://github.com/clauderic/dnd-kit/issues/591#issuecomment-1017050816
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      // still allow clicking on the draggable elements see https://github.com/clauderic/dnd-kit/issues/591#issuecomment-1017050816
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (active.id && over?.id && active.id !== over.id) {
        const oldIndex = orderedApplicationImages.findIndex(
          (appImage) => appImage.id === active.id,
        );
        const newIndex = orderedApplicationImages.findIndex(
          (appImage) => appImage.id === over.id,
        );
        const reorderedApplicationImages = arrayMove(
          orderedApplicationImages,
          oldIndex,
          newIndex,
        );
        // Optimistically update to the new value
        setImageOrder(reorderedApplicationImages.map((image) => image.id));
        updateImageOrder(reorderedApplicationImages);
      }
    },
    [orderedApplicationImages, updateImageOrder],
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[]}
    >
      <SortableContext
        items={orderedApplicationImages}
        strategy={rectSortingStrategy}
      >
        <Grid templateColumns="repeat(5, 1fr)" gap={4}>
          {[...orderedApplicationImages].map((image, _, allImages) => (
            <GridItem key={image.id}>
              <SortableItem id={image.id}>
                <ApplicationImageUpload
                  applicationId={applicationId}
                  image={image}
                  padding={0.5}
                  width="full"
                  height={40}
                  borderRadius="md"
                  objectFit={"cover"}
                  _hover={{
                    transform: "scaleX(1.05) scaleY(1.05)",
                    boxShadow: "md",
                  }}
                  transition="all 300ms"
                  cursor="pointer"
                  onClick={(evt) => {
                    if (image) {
                      setPreviewImageUrl(image?.image);
                      evt.stopPropagation();
                    }
                  }}
                />
              </SortableItem>
            </GridItem>
          ))}
          <GridItem key={"uploader"}>
            <ApplicationImageUpload
              applicationId={applicationId}
              image={undefined}
              padding={0.5}
              width="full"
              height={40}
              borderRadius="md"
              objectFit={"cover"}
              _hover={{
                transform: "scaleX(1.05) scaleY(1.05)",
                boxShadow: "md",
              }}
              transition="all 300ms"
              cursor="pointer"
            />
          </GridItem>
        </Grid>
      </SortableContext>
      <Modal
        size="4xl"
        isOpen={!!previewImageUrl}
        onClose={() => setPreviewImageUrl(null)}
        isCentered
      >
        <ModalOverlay backgroundColor="backgroundAlpha.700" />
        <ModalContent background="transparent" width="auto">
          <ModalCloseButton top="-2rem" right="-2rem" />
          <ModalBody display="flex" justifyContent="center" padding={0}>
            {previewImageUrl && (
              <Image
                maxHeight={"90vh"}
                src={previewImageUrl}
                borderRadius="md"
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </DndContext>
  );
}
