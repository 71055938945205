import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useRef } from "react";
import {
  FaPlus as AddIcon,
  FaTimes as ClearIcon,
  FaSearch as SearchIcon,
} from "react-icons/fa";
import { Link, generatePath } from "react-router-dom";
import TimeAgo from "react-timeago";
import { User } from "../backend";
import {
  DeleteButton,
  LinkButton,
  OptionsButton,
  PaginatedTable,
  UserProfile,
} from "../components";
import {
  usePaginationQueryParams,
  useSearchQueryParams,
  useSortingQueryParams,
} from "../hooks";
import { useUsersQuery } from "../hooks/useUsersQuery";
import { namedRoutes } from "../routes";
import { OrderingParam } from "../utils/ordering-param";
import { PendingInvitationsDrawer } from "./components/PendingInvitationsDrawer";
import { UserInvitationForm } from "./components/UserInvitationForm";
import { useUserColumns } from "./hooks/useUserColumns";
import { useUserRemoveFromOrgConfirm } from "./hooks/useUserRemoveFromOrgConfirm";

const columnHelper = createColumnHelper<User>();

const defaultSorting: SortingState = [{ desc: false, id: "email" }];

export function UserList() {
  const [sorting, setSorting] = useSortingQueryParams(defaultSorting);
  const [pagination, setPagination] = usePaginationQueryParams();
  const { debouncedSearchText, search, setSearch } = useSearchQueryParams();
  const userInvitationDialogState = useDisclosure();
  const firstFieldRef = useRef<HTMLInputElement>(null);
  const showPendingInvitationsDialogState = useDisclosure();
  const confirmUserRemovalFromOrg = useUserRemoveFromOrgConfirm();

  const {
    data: usersResponse,
    isError,
    error,
    isLoading,
  } = useUsersQuery({
    ordering: useMemo(() => OrderingParam.encode(sorting), [sorting]),
    fulltext_search: debouncedSearchText,
    page: pagination.pageIndex + 1,
    page_size: pagination.pageSize,
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor("email", {
        id: "email",
        header: "User",
        cell: (props) => (
          <Link
            to={generatePath(namedRoutes.user.overview, {
              userId: props.row.original.id.toString(),
            })}
          >
            <UserProfile
              user={props.row.original}
              textProps={{ _hover: { textDecoration: "underline" } }}
              cursor="pointer"
            />
          </Link>
        ),
      }),
      columnHelper.accessor("last_login", {
        header: "Last Login",
        cell: (props) =>
          props.row.original.last_login ? (
            <TimeAgo date={props.row.original.last_login} />
          ) : (
            "Never"
          ),
      }),
      columnHelper.display({
        id: "options",
        cell: (props) => (
          <Flex justifyContent={"end"}>
            <OptionsButton label="Click on this button to display user actions">
              <LinkButton
                to={generatePath(namedRoutes.user.overview, {
                  userId: props.row.original.id?.toString(),
                })}
                variant="ghost"
                fontWeight="normal"
                fontSize="sm"
                w="full"
              >
                Show Details
              </LinkButton>
              <DeleteButton
                rightIcon={undefined}
                onClick={confirmUserRemovalFromOrg(props.row.original)}
              >
                Remove from organization
              </DeleteButton>
            </OptionsButton>
          </Flex>
        ),
        header: () => <Flex justifyContent="end">Options</Flex>,
      }),
    ],
    [confirmUserRemovalFromOrg],
  );

  const userColumns = useUserColumns(columns, isLoading);
  const data = useMemo<User[]>(
    () =>
      isLoading
        ? Array(pagination.pageSize).fill({} as User)
        : (usersResponse?.results ?? []),
    [usersResponse?.results, isLoading, pagination.pageSize],
  );
  const table = useReactTable({
    data,
    columns: userColumns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualSorting: true,
    manualPagination: true,
    pageCount: usersResponse?.count
      ? Math.ceil(usersResponse?.count / pagination.pageSize)
      : undefined,
  });

  useEffect(() => {
    // reset pagination if search text changes
    table.resetPageIndex();
  }, [debouncedSearchText, table]);

  if (isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>{JSON.stringify(error)}</AlertDescription>
      </Alert>
    );
  }

  return (
    <Stack spacing={6}>
      <Flex justifyContent="space-between" gap={2}>
        <HStack>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={SearchIcon} color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Filter by email, last or first name..."
              minWidth={"md"}
              value={search}
              onChange={(evt) => setSearch(evt.target.value)}
              // Prevent LastPass AutoFill, see https://support.lastpass.com/s/document-item?language=en_US&bundleId=lastpass&topicId=LastPass/c_lp_prevent_fields_from_being_filled_automatically.html&_LANG=enus
              data-lpignore="true"
            />
            {search && (
              <InputRightElement
                cursor="pointer"
                color="gray.300"
                _hover={{ color: "red" }}
                onClick={() => setSearch("")}
              >
                <Icon as={ClearIcon} />
              </InputRightElement>
            )}
          </InputGroup>
        </HStack>

        <ButtonGroup isAttached>
          <Popover
            isOpen={userInvitationDialogState.isOpen}
            initialFocusRef={firstFieldRef}
            onOpen={userInvitationDialogState.onOpen}
            onClose={userInvitationDialogState.onClose}
            placement="right"
            closeOnBlur={false}
          >
            <PopoverTrigger>
              <Button
                leftIcon={<Icon boxSize={3} as={AddIcon} />}
                onClick={userInvitationDialogState.onOpen}
                colorScheme="brand"
              >
                Invite user
              </Button>
            </PopoverTrigger>
            <PopoverContent p={5}>
              <PopoverArrow />
              <PopoverCloseButton />
              <UserInvitationForm
                firstFieldRef={firstFieldRef}
                onSuccess={userInvitationDialogState.onClose}
              />
            </PopoverContent>
          </Popover>
          <OptionsButton
            size="md"
            label="Click on this button to display user actions"
          >
            <Button
              onClick={showPendingInvitationsDialogState.onOpen}
              variant="ghost"
              fontWeight="normal"
              fontSize="sm"
              w="full"
            >
              Manage pending invitations
            </Button>
          </OptionsButton>
        </ButtonGroup>
      </Flex>
      <PaginatedTable table={table} />
      <PendingInvitationsDrawer {...showPendingInvitationsDialogState} />
    </Stack>
  );
}
