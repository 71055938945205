import {
  Button,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Skeleton,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import { DeleteButton, OptionsButton, PaginatedTable } from "../../components";
import { useConfirm } from "../../confirm-dialog";
import { FixedIp } from "../../session-management";
import {
  useCreateFixedIpsMutation,
  useDeleteFixedIpsMutation,
  useFixedIps,
} from "../hooks";
import { FixedIpDownload } from "./FixedIpUtils";

export const FixedIpsTab = ({ region }: { region: string }) => {
  const toast = useToast();
  const fixedIps = useFixedIps(region);
  const columnHelper = createColumnHelper<FixedIp>();
  const [requestMode, setRequestMode] = useState(false);
  const createFixedIpMutation = useCreateFixedIpsMutation({
    onSuccess: () => {
      toast({
        title: "Requested Fixed IP",
        description: `Requested new fixed IPs.`,
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });

      setRequestMode(false);
      setFixedIpsNumber(1);
    },
    onError: () => {
      toast({
        title: "Error",
        description: `Could not request fixed IPs. Please try again later.`,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    },
  });
  const { mutateAsync } = useDeleteFixedIpsMutation();
  const [fixedIpsNumber, setFixedIpsNumber] = useState(1);
  const { confirm } = useConfirm();
  const confirmFixedIpDeletion = useCallback(
    (ipAddress: FixedIp) =>
      confirm({
        title: `Delete fixed IP`,
        body: (
          <Stack>
            <Text>
              You are about to delete the fixed IP address{" "}
              <code>{ipAddress.ip}</code>.
            </Text>
            <Text>
              Please be aware that this action cannot be undone and you will
              likely not get the same fixed IP address again if you choose to
              request a new one later on.
            </Text>
          </Stack>
        ),
        confirmButtonText: "Delete",
        onConfirm: () =>
          mutateAsync({
            id: ipAddress.id,
            region,
          }),
      }),
    [confirm, mutateAsync, region],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("ip", {
        header: "Ip",
        cell: (props) => {
          if (!props.row.original.ip)
            return (
              <Stack direction="row" spacing={5} align="center">
                <Spinner />
                <Text>Please wait while we provision a fixed IP address</Text>
              </Stack>
            );
          return <Text>{props.row.original.ip}</Text>;
        },
      }),
      columnHelper.display({
        id: "options",
        cell: (props) => (
          <Flex justifyContent={"end"}>
            <OptionsButton label="Click on this button to display user actions">
              <DeleteButton
                onClick={() => confirmFixedIpDeletion(props.row.original)}
              >
                Delete
              </DeleteButton>
            </OptionsButton>
          </Flex>
        ),
        header: () => <Flex justifyContent={"end"}>Options</Flex>,
      }),
    ],
    [columnHelper, confirmFixedIpDeletion],
  );

  const table = useReactTable({
    columns: useMemo(
      () =>
        fixedIps.isLoading
          ? columns.map((col, idx) => ({
              ...col,
              cell: () => <Skeleton key={idx}>Loading</Skeleton>,
            }))
          : columns,
      [columns, fixedIps.isLoading],
    ),
    data: useMemo(
      () =>
        fixedIps.isLoading
          ? Array(5).fill({} as FixedIp)
          : [...(fixedIps.data ?? [])],
      [fixedIps.data, fixedIps.isLoading],
    ),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
    state: { columnOrder: ["isCurrentlyActive"] },
    autoResetPageIndex: true,
  });

  return (
    <Stack>
      <Text fontSize="sm">
        Fixed IPs can be used to limit the range of IP addresses for port
        openings. One fixed IP can be used for one Virtual Machine at a time and
        therefore also for one session. After the Virtual Machine is deleted,
        the fixed IP can be reused for another session. If you reserve fixed IPs
        they will be available only in your organization.
      </Text>
      <Button
        hidden={requestMode}
        colorScheme="brand"
        onClick={() => {
          setRequestMode(true);
        }}
      >
        Request new fixed IPs
      </Button>
      <Stack direction="row" spacing={5} flex={1} hidden={!requestMode}>
        <NumberInput
          flex={1}
          step={1}
          defaultValue={1}
          min={1}
          max={50}
          onChange={(valueString) => setFixedIpsNumber(Number(valueString))}
          value={fixedIpsNumber}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Button
          colorScheme="brand"
          isLoading={createFixedIpMutation.isPending}
          onClick={() => {
            createFixedIpMutation.mutateAsync({
              region: region,
              count: fixedIpsNumber,
            });
          }}
        >
          Save
        </Button>
      </Stack>

      <PaginatedTable table={table} />
      {(fixedIps.data?.length ?? 0) > 0 && (
        <Tooltip
          label="Waiting for all IPs to be available"
          hidden={fixedIps.allIpsAvailable}
        >
          <Button
            isDisabled={!fixedIps.allIpsAvailable}
            onClick={() => {
              FixedIpDownload(fixedIps.data ?? []);
            }}
          >
            Download Fixed IPs csv
          </Button>
        </Tooltip>
      )}
    </Stack>
  );
};
