import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { CSRFTokenInput } from "./CSRFTokenInput";
import { getFormErrors } from "./getFormErrors";

// load the form errors (json) from a script tag with id "form-errors" if available
const formErrors = getFormErrors();

export function CollectEmailForm() {
  return (
    <form method="post">
      <Stack spacing="6">
        <Heading size={{ base: "xs", md: "sm" }}>Account setup</Heading>
        <Text>
          To finish setting up your account, please provide your email address.
          We will send you a link to verify your email address.
        </Text>
        <Stack spacing="5">
          {formErrors.__all__ && (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>
                {formErrors.__all__.join(", ")}
              </AlertDescription>
            </Alert>
          )}
          <FormControl isInvalid={"email" in formErrors}>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input id="email" name="email" type="email" required />
            <FormErrorMessage>{formErrors.email?.join(", ")}</FormErrorMessage>
            <FormHelperText>
              Your email address will only be used for account-related purposes,
              e.g. in case you forget your password.
            </FormHelperText>
          </FormControl>
          <Button colorScheme="brand" type="submit">
            Finish account setup
          </Button>
        </Stack>
      </Stack>
      <CSRFTokenInput />
    </form>
  );
}
