import {
  Box,
  Flex,
  Heading,
  HStack,
  Skeleton,
  Text,
  useToken,
} from "@chakra-ui/react";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useDashboardParameters } from "../context";
import { useSessionsStatsQuery } from "../hooks/useSessionsStatsQuery";
import { useAllUsersQuery } from "../hooks/useUsers";
import { useChartOptions } from "./chart-options";
import { FailedLoadingData } from "./FailedLoadingData";

export function MostActiveUsers() {
  const [textColor] = useToken("colors", ["chakra-body-text"]);
  const filters = useDashboardParameters();
  const sessionStatsByUsersQuery = useSessionsStatsQuery({
    ...filters,
    groupBy: "user",
  });
  const chartOptions = useChartOptions();

  const mostActiveUsers = useMemo(
    () =>
      sessionStatsByUsersQuery.data
        ?.sort((a, b) => b.count - a.count)
        .slice(0, 10) ?? [],
    [sessionStatsByUsersQuery.data],
  );

  const userIds = useMemo(
    () => mostActiveUsers.map((s) => parseInt(s.key)),
    [mostActiveUsers],
  );
  // fetch names of the users
  const usersQuery = useAllUsersQuery(
    {
      userIds,
    },
    { enabled: sessionStatsByUsersQuery.isSuccess },
  );

  const { categories, series } = useMemo(
    () => ({
      categories:
        mostActiveUsers.map(
          (stat) =>
            usersQuery.data?.find((user) => user.id === parseInt(stat.key))
              ?.full_name ?? "Unknown user",
        ) ?? [],
      series: [
        {
          name: "Session count",
          data: mostActiveUsers.map((stat) => stat.count ?? 0) ?? [],
        },
      ],
    }),
    [mostActiveUsers, usersQuery.data],
  );

  return (
    <Flex direction={"column"}>
      <HStack>
        <Heading size="sm">Most active users</Heading>
        <Text size="sm" as="h4" color="menu-text">
          Top 10
        </Text>
      </HStack>
      <Flex w="100%">
        <Box minH="350px" w="100%" flexGrow={1}>
          {sessionStatsByUsersQuery.isError && (
            <FailedLoadingData h="100%" justifyContent={"center"} />
          )}
          {!sessionStatsByUsersQuery.isError && (
            <Skeleton
              h="100%"
              w="100%"
              isLoaded={!sessionStatsByUsersQuery.isLoading}
            >
              <ReactApexChart
                type="bar"
                height="500px"
                series={series}
                options={{
                  ...chartOptions,
                  chart: {
                    ...chartOptions.chart,
                    dropShadow: { enabled: false },
                  },
                  xaxis: { ...chartOptions.xaxis, categories },
                  yaxis: {
                    ...chartOptions.yaxis,
                    show: true,
                    labels: {
                      ...(!chartOptions.yaxis
                        ? {}
                        : "length" in chartOptions.yaxis
                        ? {}
                        : chartOptions.yaxis.labels),
                      show: true,
                      style: {
                        ...(!chartOptions.yaxis
                          ? {}
                          : "length" in chartOptions.yaxis
                          ? {}
                          : chartOptions.yaxis.labels?.style),
                        colors: textColor,
                      },
                    },
                  },
                  plotOptions: {
                    bar: {
                      columnWidth: "70%",
                      barHeight: "50%",
                      horizontal: true,
                    },
                  },
                }}
              />
            </Skeleton>
          )}
        </Box>
      </Flex>
    </Flex>
  );
}
