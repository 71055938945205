import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { deleteReservedInstance } from "../../session-management";
import { getReservedInstancesQueryKey } from "./useReservedInstancesQuery";

export function useDeleteReservedInstanceMutation({
  onSuccess,
  ...options
}: Partial<UseMutationOptions<void, Error, string>>) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (id: string) => {
      await deleteReservedInstance(id);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: getReservedInstancesQueryKey(),
      });

      if (onSuccess) onSuccess(data, variables, context);
    },
    ...options,
  });
  return mutation;
}
