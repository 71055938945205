import { Props, Select } from "chakra-react-select";
import { useCloudRenderingRegionsQuery } from "../cloud-rendering/hooks";
import { Region } from "../session-management";

interface DropDownOptions {
  value: string;
  label: string;
}

export const RegionsDropDown = ({
  value,
  defaultValue,
  onChange,
  ...props
}: {
  value?: string;
  defaultValue?: string;
  onChange: (value?: Region) => void;
} & Omit<
  Props<DropDownOptions, false>,
  "value" | "onChange" | "defaultValue"
>) => {
  const { data: regions, isSuccess } = useCloudRenderingRegionsQuery({
    allowedCloudProviders: ["AWS", "Azure", "GoogleCloud"],
  });
  return (
    <Select
      {...props}
      isLoading={!isSuccess}
      useBasicStyles
      value={regions
        ?.map((r) => ({ value: r.name, label: r.displayName }))
        .find((r) => r.value === value)}
      defaultValue={regions
        ?.map((r) => ({ value: r.name, label: r.displayName }))
        .find((r) => r.value === defaultValue)}
      onChange={(newValue) =>
        onChange(regions?.find((r) => r.name === newValue?.value))
      }
      options={regions?.map((r) => {
        return {
          value: r.name,
          label: `${r.displayName} (${r.cloudProvider})`,
        };
      })}
    />
  );
};
