import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Stack,
  StackProps,
  Switch,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Controller,
  FormProvider,
  UseFormReturn,
  useForm,
  useWatch,
} from "react-hook-form";
import { entries, fromEntries } from "remeda";
import { InferType, boolean, object, string } from "yup";
import { Organization } from "../backend";
import {
  createOrganizationAuthProvider,
  updateOrganization,
  updateOrganizationAuthProvider,
} from "../backend/api";
import { FormSubmitButton } from "../components";
import { DevTool } from "../hookform-devtools";
import {
  useActiveOrganizationQuery,
  useBrandingQuery,
  usePromptDirtyForm,
} from "../hooks";
import { queryKeyForActiveOrganization } from "../hooks/useActiveOrganization";
import { flattenObjectKeys, isPlainObject } from "../utils/flatten-object";
import {
  AzureADAuthProviderForm,
  GoogleOAuth2AuthProviderForm,
  OIDCAuthProviderForm,
} from "./auth-providers";
import { SAMLAuthProviderForm } from "./auth-providers/SAMLAuthProviderForm";
import {
  getQueryKeyForConfiguredAuthProviders,
  useConfiguredAuthProviders,
} from "./auth-providers/useConfiguredAuthProviders";
import {
  AuthProviderData,
  EnabledProviderSlug,
  ProviderSlug,
  azureadSchema,
  googleOAuth2Schema,
  oidcSchema,
  providerSlugs,
  samlSchema,
} from "./auth-providers/utils";

const defaultProviderDisplayNames: { [slug in ProviderSlug]: string } = {
  "google-oauth2": "Google (Workspace)",
  "azuread-oauth2": "Azure Active Directory (AD)",
  oidc: "OpenID Connect",
  saml: "SAML",
};

export type AuthSettingsForm = UseFormReturn<AuthProviderSchema>;
export type AuthSettingsFormProps = {
  form: AuthSettingsForm;
};

const baseAuthProviderSchema = object({
  display_name: string().defined().required(),
});

const authProvidersSchema = object({
  "builtin-auth-enabled": boolean().required(),
  "azuread-oauth2-enabled": boolean().required(),
  "azuread-oauth2": baseAuthProviderSchema
    .concat(azureadSchema)
    .when("azuread-oauth2-enabled", {
      is: true,
      then: (schema) => schema.required(),
      otherwise: () => object(),
    }),
  "google-oauth2-enabled": boolean().required(),
  "google-oauth2": baseAuthProviderSchema
    .concat(googleOAuth2Schema)
    .when("google-oauth2-enabled", {
      is: true,
      then: (schema) => schema.required(),
      otherwise: () => object(),
    }),
  "oidc-enabled": boolean().required(),
  oidc: baseAuthProviderSchema.concat(oidcSchema).when("oidc-enabled", {
    is: true,
    then: (schema) => schema.required(),
    otherwise: () => object(),
  }),
  "saml-enabled": boolean().required(),
  saml: baseAuthProviderSchema.concat(samlSchema).when("saml-enabled", {
    is: true,
    then: (schema) => schema.required(),
    otherwise: () => object(),
  }),
});

function Formset({ children, ...props }: StackProps) {
  return (
    <Stack
      spacing={4}
      borderRadius="md"
      border="1px"
      borderColor="chakra-border-color"
      padding={4}
      {...props}
    >
      {children}
    </Stack>
  );
}

const authProviderForms: {
  slug: ProviderSlug;
  description: string;
  form: React.ReactElement;
}[] = [
  {
    description:
      "Use Azure's Active Directory to authenticate users within your organization.",
    slug: "azuread-oauth2",
    form: <AzureADAuthProviderForm />,
  },
  {
    description:
      "If your organization is using Google (Workspace), this is the way to go.",
    slug: "google-oauth2",
    form: <GoogleOAuth2AuthProviderForm />,
  },
  {
    description:
      "Allow your users to login via an Identity Provider (IdP) that supports the OpenID Connect protocol.",
    slug: "oidc",
    form: <OIDCAuthProviderForm />,
  },
  {
    description:
      "For existing Identity Providers (IdPs) that support the SAML protocol.",
    slug: "saml",
    form: <SAMLAuthProviderForm />,
  },
];

export type AuthProviderSchema = InferType<typeof authProvidersSchema>;

function useAuthProvidersFormData() {
  const { data: organization, isLoading: isLoadingOrg } =
    useActiveOrganizationQuery();
  const { data: authProviders, isLoading: isLoadingAuthProviders } =
    useConfiguredAuthProviders(
      {},
      { refetchOnWindowFocus: false, retry: false, throwOnError: true },
    );

  return useMemo(
    () => ({
      data:
        organization && authProviders
          ? {
              organization,
              authProviders,
            }
          : undefined,
      isLoading: isLoadingOrg || isLoadingAuthProviders,
    }),
    [authProviders, isLoadingAuthProviders, isLoadingOrg, organization],
  );
}

export function OrganizationAuthProvidersForm() {
  const [showDisableBuiltInAuthWarning, setShowDisableBuiltInAuthWarning] =
    useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const brandingQuery = useBrandingQuery();
  const queryClient = useQueryClient();
  // we need to know which organization we're dealing with
  const { data, isLoading } = useAuthProvidersFormData();
  const form = useForm<AuthProviderSchema>({
    mode: "onChange",
    resolver: yupResolver(authProvidersSchema),
    defaultValues: Object.fromEntries(
      Object.entries(defaultProviderDisplayNames).map(([key, value]) => [
        `${key}.display_name`,
        value,
      ]),
    ),
  });

  usePromptDirtyForm({ formState: form.formState });

  const { reset, handleSubmit, formState, setValue, control, setError, watch } =
    form;

  const unmanagedAuthProviders =
    data?.authProviders.filter(
      (p) => providerSlugs.includes(p.slug) === false,
    ) ?? [];
  const enabledAuthProviders = useWatch({
    control,
    name: providerSlugs.map<EnabledProviderSlug>((s) => `${s}-enabled`),
  }).concat(unmanagedAuthProviders.map((ap) => ap.enabled));
  const isBuiltInAuthEnabled =
    useWatch({
      control: control,
      name: "builtin-auth-enabled",
    }) ?? !data?.organization?.active_auth_provider;
  const cannotDisableBuiltInAuth = useMemo(
    () => enabledAuthProviders.filter(Boolean).length !== 1,
    [enabledAuthProviders],
  );

  const { mutateAsync: updateAuthProvidersAsync } = useMutation<
    Organization | undefined,
    AxiosError,
    AuthProviderSchema
  >({
    mutationFn: async (values) => {
      // nothing to do if we don't have organization information
      if (!data?.organization.id) {
        return;
      }

      // loop over all providers, check if enabled, get data, save it
      const updatedAuthProviders = await Promise.all(
        providerSlugs.map(async (slug) => {
          const slugEnabledKey = (slug + "-enabled") as EnabledProviderSlug;
          const isProviderEnabled = values[slugEnabledKey];
          let response;
          const existingAuthProvider = data?.authProviders?.find(
            (p) => p.slug === slug,
          );
          if (!isProviderEnabled && existingAuthProvider) {
            response = await updateOrganizationAuthProvider(
              data?.organization.id,
              existingAuthProvider.id,
              { enabled: false },
            );
            return response;
          } else {
            const { display_name, ...settings } = values[slug];
            // get the data to be persisted
            const providerData: Omit<AuthProviderData, "id"> = {
              display_name,
              slug,
              settings,
              enabled: isProviderEnabled,
            };
            if (existingAuthProvider) {
              response = await updateOrganizationAuthProvider(
                data?.organization.id,
                existingAuthProvider.id,
                providerData,
              );
            } else {
              response = await createOrganizationAuthProvider(
                data?.organization.id,
                providerData,
              );
            }
            return response;
          }
        }),
      );

      // also update the active auth provider on the organization (if desired)
      return await updateOrganization(data.organization.id, {
        active_auth_provider: isBuiltInAuthEnabled
          ? null
          : (updatedAuthProviders.find((p) => p?.enabled)?.id ??
            unmanagedAuthProviders.find((p) => p.enabled)?.id ??
            null),
      });
    },
    // make sure data is reloaded after modification
    onSuccess: (updatedOrganization) => {
      queryClient.setQueryData(
        queryKeyForActiveOrganization,
        updatedOrganization,
      );
      updatedOrganization &&
        queryClient.invalidateQueries({
          queryKey: getQueryKeyForConfiguredAuthProviders(
            updatedOrganization.id,
          ),
        });
      queryClient.invalidateQueries({
        queryKey: queryKeyForActiveOrganization,
      });
    },
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    // This effect will ensure the form values are correctly initialized as soon as the data is available.
    // see https://github.com/react-hook-form/react-hook-form/issues/2492#issuecomment-771578524 and https://tkdodo.eu/blog/react-query-and-forms
    const allProviderSettings = data?.authProviders.reduce<object>(
      (allSettings, provider) => {
        return {
          ...allSettings,
          // enabled flag for auth provider
          [provider.slug + "-enabled"]: provider.enabled,
          // prefix and flatten all settings for the auth provider
          ...(isPlainObject(provider.settings)
            ? fromEntries(
                entries(flattenObjectKeys(provider.settings)).map(
                  ([key, value]) => [`${provider.slug}.${key}`, value],
                ),
              )
            : {}),
          [provider.slug + ".display_name"]: provider.display_name,
        };
      },
      {},
    );

    const values = {
      ...Object.fromEntries(
        authProviderForms.map((authProvider) => [
          `${authProvider.slug}-enabled`,
          false,
        ]),
      ),
      ...allProviderSettings,
      "builtin-auth-enabled": !data.organization.active_auth_provider,
    };
    reset(values, { keepIsSubmitted: true });
  }, [data, reset]);

  const onSubmit = useCallback(
    async (values: AuthProviderSchema) => {
      try {
        await updateAuthProvidersAsync(values);
      } catch (err) {
        console.error(err);
      }
    },
    [updateAuthProvidersAsync],
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Heading size="md">Built-In Authentication</Heading>
          <Formset spacing={0}>
            <FormControl
              isInvalid={!!formState.errors["builtin-auth-enabled"]}
              display="flex"
              columnGap={3}
              alignItems={"center"}
            >
              <Controller
                control={control}
                name="builtin-auth-enabled"
                render={({ field }) => (
                  <Switch
                    {...field}
                    id="builtin-auth-enabled"
                    value={field.name}
                    isDisabled={isLoading}
                    isChecked={
                      field.value ?? !data?.organization?.active_auth_provider
                    }
                    onChangeCapture={(evt) => {
                      if (field.value) {
                        evt.stopPropagation();

                        if (cannotDisableBuiltInAuth) {
                          setError("builtin-auth-enabled", {
                            type: "custom",
                            message:
                              "Can only be disabled if exactly one external auth provider is configured and enabled below.",
                          });
                        } else {
                          setShowDisableBuiltInAuthWarning(true);
                        }
                      }
                    }}
                  />
                )}
              />
              <FormLabel htmlFor="builtin-auth-enabled" mb="0">
                <Text textTransform={"none"} fontSize="md" fontWeight="bold">
                  Use Portal User Management
                </Text>
                <Text textTransform={"none"} fontSize="sm" color="GrayText">
                  Users will be able to log in with their dedicated user account
                  for {brandingQuery.data?.product_name ?? "XR Portal"}.
                </Text>
              </FormLabel>
              <FormErrorMessage>
                {formState.errors["builtin-auth-enabled"]?.message}
              </FormErrorMessage>
            </FormControl>
          </Formset>

          <Heading size="md" pt={6}>
            Single-Sign-On / External Authentication
          </Heading>
          <Text fontSize="sm" color="GrayText">
            Users will be able to log in with any of the configured auth
            providers below.
          </Text>
          <Formset>
            <Stack spacing={10} mt={1}>
              <FormProvider {...form}>
                {authProviderForms.map(({ slug, description, form }) => (
                  <Stack spacing={2} key={slug}>
                    <Controller
                      control={control}
                      name={`${slug}-enabled`}
                      render={({ field }) => (
                        <>
                          <FormControl
                            isInvalid={!!formState.errors[`${slug}-enabled`]}
                            display="flex"
                            columnGap={3}
                            alignItems={"center"}
                          >
                            <Switch
                              id={`${slug}-enabled`}
                              isDisabled={isLoading}
                              isChecked={field.value}
                              onChangeCapture={() => {
                                if (!isBuiltInAuthEnabled) {
                                  if (field.value) {
                                    // enable built-in auth again
                                    setValue("builtin-auth-enabled", true, {
                                      shouldDirty: true,
                                      shouldValidate: true,
                                    });
                                  } else {
                                    // disable all other auth providers. Only one can be the active one if built-in auth is disabled
                                    providerSlugs
                                      .filter((_slug) => _slug !== slug)
                                      .forEach((_slug) =>
                                        setValue(`${_slug}-enabled`, false),
                                      );
                                  }
                                }
                              }}
                              {...field}
                              value={field.name}
                            />
                            <Stack spacing={1}>
                              <FormLabel htmlFor={`${slug}-enabled`} mb="0">
                                <Text
                                  textTransform={"none"}
                                  fontSize="md"
                                  fontWeight="bold"
                                >
                                  {watch(`${slug}.display_name`)}
                                </Text>
                                <Text
                                  textTransform={"none"}
                                  fontSize="sm"
                                  color="GrayText"
                                >
                                  {description}.
                                </Text>
                              </FormLabel>
                            </Stack>
                          </FormControl>
                          <Collapse in={field.value} animateOpacity>
                            <Box
                              pl={8}
                              pt={4}
                              ml={4}
                              borderLeft="1px"
                              borderColor="gray.200"
                            >
                              {form}
                            </Box>
                          </Collapse>
                          <FormErrorMessage>
                            {formState.errors[`${slug}-enabled`]?.message}
                          </FormErrorMessage>
                        </>
                      )}
                    />
                  </Stack>
                ))}
                {unmanagedAuthProviders.map(({ slug, display_name }) => (
                  <Stack spacing={2} key={slug}>
                    <Checkbox isDisabled isChecked>
                      <Text fontWeight="bold">{display_name}</Text>
                      <Text fontSize="sm" color="GrayText">
                        This provider can only be configured by administrators.
                      </Text>
                    </Checkbox>
                  </Stack>
                ))}
              </FormProvider>
            </Stack>
          </Formset>
          <FormSubmitButton formState={formState} alignSelf="end" mt={4} />
        </Stack>
      </form>
      <AlertDialog
        isOpen={showDisableBuiltInAuthWarning}
        leastDestructiveRef={cancelRef}
        onClose={() => setShowDisableBuiltInAuthWarning(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Disable Built-In Authentication
            </AlertDialogHeader>

            <AlertDialogBody>
              Please ensure you have thoroughly tested your auth provider before
              disabling the built-in authentication. Afterwards, users
              (including organization managers like you) will only be able to
              login using SSO.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => setShowDisableBuiltInAuthWarning(false)}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  setValue("builtin-auth-enabled", false, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  setShowDisableBuiltInAuthWarning(false);
                }}
                ml={3}
              >
                Disable
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {false && <DevTool control={control} placement="bottom-left" />}
    </>
  );
}
