import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Skeleton,
  Stack,
  Switch,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { InferType, boolean, object, string } from "yup";
import { updateUserInformation } from "../../backend/api";
import { Editable } from "../../components";
import { DevTool } from "../../hookform-devtools";
import { useUserQuery } from "../../hooks";
import { getQueryKeyForUser } from "../../hooks/useUserQuery";
import { extractFormSubmissionErrors } from "../../utils/extractFormSubmissionErrors";

const userInfoSchema = object({
  first_name: string().required(),
  last_name: string().required(),
  is_active: boolean().defined().required(),
}).noUnknown();

type UserInfoSchema = InferType<typeof userInfoSchema>;

export function UserBasicInformation({ userId }: { userId: number | string }) {
  const userQuery = useUserQuery({
    userId,
    options: {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  });
  const queryClient = useQueryClient();
  const { control, formState, reset, watch, handleSubmit, setError } =
    useForm<UserInfoSchema>({
      resolver: yupResolver(userInfoSchema),
    });
  const { mutateAsync, ...updateUserInfoMutation } = useMutation({
    mutationFn: (userInfo: UserInfoSchema) =>
      updateUserInformation(userId, userInfo),
    onSuccess: (user) =>
      queryClient.setQueryData(getQueryKeyForUser(userId), user),
  });
  const onSubmit = useCallback(
    async (values: UserInfoSchema) => {
      try {
        await mutateAsync(values);
      } catch (err) {
        extractFormSubmissionErrors(err, userInfoSchema).forEach(
          ([field, message]) => setError(field, { type: "server", message }),
        );
      }
    },
    [mutateAsync, setError],
  );

  // prefill form values when data is available
  useEffect(() => {
    reset(userQuery.data);
  }, [userQuery.data, reset]);

  // auto submit form if activation status changes, see https://stackoverflow.com/a/70119332/1142028
  useEffect(() => {
    const subscription = watch((data, info) => {
      if (info.name === "is_active" && info.type === "change") {
        handleSubmit(onSubmit)();
      }
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, onSubmit]);

  return (
    <>
      <Grid
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(5, 1fr)"
        gap={4}
      >
        <GridItem rowSpan={2} colSpan={1}>
          <Stack>
            <FormControl isInvalid={!!formState.errors.first_name}>
              <FormLabel htmlFor="first_name">First Name</FormLabel>
              <Skeleton isLoaded={userQuery.isSuccess}>
                <Controller
                  control={control}
                  name="first_name"
                  render={({ field }) => (
                    <Editable
                      id="first_name"
                      placeholder="Enter first name..."
                      {...field}
                      onSubmit={() => handleSubmit(onSubmit)()}
                      isDisabled={updateUserInfoMutation.isPending}
                    />
                  )}
                />
              </Skeleton>
              <FormErrorMessage>
                {formState.errors.first_name?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!formState.errors.last_name}>
              <FormLabel htmlFor="last_name">Last Name</FormLabel>
              <Skeleton isLoaded={userQuery.isSuccess}>
                <Controller
                  control={control}
                  name="last_name"
                  render={({ field }) => (
                    <Editable
                      id="last_name"
                      placeholder="Enter last name..."
                      {...field}
                      onSubmit={() => handleSubmit(onSubmit)()}
                      isDisabled={updateUserInfoMutation.isPending}
                    />
                  )}
                />
              </Skeleton>
              <FormErrorMessage>
                {formState.errors.last_name?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="email">E-Mail Address</FormLabel>
              <Skeleton isLoaded={userQuery.isSuccess}>
                <Editable isDisabled id="email" value={userQuery.data?.email} />
              </Skeleton>
            </FormControl>
          </Stack>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl isInvalid={!!formState.errors.is_active}>
            <Flex alignItems={"center"}>
              <FormLabel htmlFor="is_active" mb="0">
                User account activated?
              </FormLabel>
              <Controller
                control={control}
                name="is_active"
                render={({ field }) => (
                  <Switch
                    id="is_active"
                    isChecked={field.value}
                    {...field}
                    value="is_active"
                  />
                )}
              />
            </Flex>
            {!formState.errors.is_active && (
              <FormHelperText>
                Deactivated users cannot login or access any application.
              </FormHelperText>
            )}
            <FormErrorMessage>
              {formState.errors.is_active?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
      <DevTool control={control} placement="bottom-left" />
    </>
  );
}
