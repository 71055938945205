import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  CircularProgress,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Select,
  Stack,
  Text,
  forwardRef,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useId } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaClock } from "react-icons/fa";
import { FormSubmitButton } from "../../components";
import { DevTool } from "../../hookform-devtools";
import { useActiveOrganizationQuery } from "../../hooks";
import {
  getOrganizationCloudRenderingPolicies,
  updateOrganizationCloudRenderingPolicies,
} from "../../session-management";
import {
  CloudXREncryptionPolicy,
  cloudRenderingPoliciesSchema,
  cloudXREnryptionPolicyOptions,
} from "../../session-management/schema";
import { OrganizationCloudRenderingPolicies } from "../../session-management/types";

function getQueryKeyForOrganizationCloudRenderingPolicies() {
  return ["organization", "cloud-rendering-policies"];
}

const cloudXREncryptionPolicyLabels: Record<CloudXREncryptionPolicy, string> = {
  Disallowed: "Prevent users from using encryption when streaming to VR",
  OptIn: "Opt-In: VR streams are unencrypted but users can enable encryption",
  OptOut:
    "Opt-Out: Encrypt VR streams per default but allow users to disable encryption",
  Enforced: "Force encryption in all VR streams",
};

export function CloudRenderingPolicies() {
  const {
    data: organization,
    isPending: isOrganizationQueryPending,
    isError: isOrganizationQueryError,
  } = useActiveOrganizationQuery();
  const {
    data: policies,
    isPending: isPoliciesQueryPending,
    isError: isPoliciesQueryError,
  } = useQuery({
    queryKey: getQueryKeyForOrganizationCloudRenderingPolicies(),
    queryFn: () => getOrganizationCloudRenderingPolicies(),
    enabled: !!organization?.id,
    throwOnError: true,
  });

  if (isOrganizationQueryPending || isPoliciesQueryPending) {
    return <CircularProgress />;
  }

  if (isOrganizationQueryError || isPoliciesQueryError) {
    return <Text>Failed to load organization data</Text>;
  }

  return (
    <Stack spacing={4}>
      <Text>
        Use the following policies to configure limits and optimize usage
        pattern for cloud-rendering according to your organization&apos;s needs.
      </Text>
      <CloudRenderingPoliciesForm policies={policies} />
    </Stack>
  );
}

const retentionPeriodOptions = [
  "00:05:00",
  "00:10:00",
  "00:15:00",
  "00:20:00",
  "00:30:00",
  "00:45:00",
  "01:00:00",
  "01:30:00",
  "02:00:00",
];

const sessionDurationOptions = [
  "00:15:00",
  "00:30:00",
  "00:45:00",
  "01:00:00",
  "01:30:00",
  "02:00:00",
];

function CloudRenderingPoliciesForm({
  policies,
}: {
  policies: OrganizationCloudRenderingPolicies;
}) {
  const form = useForm({
    defaultValues: policies,
    resolver: yupResolver(cloudRenderingPoliciesSchema),
    mode: "onChange",
  });

  const { mutateAsync: updatePoliciesAsync } = useMutation({
    mutationFn: (policies: OrganizationCloudRenderingPolicies) =>
      updateOrganizationCloudRenderingPolicies(policies),
  });

  const submitHandler = useCallback(
    async (values: OrganizationCloudRenderingPolicies) => {
      try {
        await updatePoliciesAsync(values, {
          onSuccess: () => {
            form.reset(values);
          },
        });
      } catch (err) {
        console.error(err);
      }
    },
    [form, updatePoliciesAsync],
  );

  return (
    <form onSubmit={form.handleSubmit(submitHandler)}>
      <Stack spacing={6}>
        <Heading as="h3" size="md">
          Limits
        </Heading>

        <Accordion defaultIndex={0}>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Text as="legend" fontWeight="bold">
                  Cloud Rendering
                </Text>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel py={6}>
              <Stack spacing={4}>
                <FormControl
                  isInvalid={
                    !!form.formState.errors.cloudRenderingSettings
                      ?.maxSoftSessionRunTime
                  }
                >
                  <FormLabel>Soft session duration limit</FormLabel>
                  <DurationInput
                    {...form.register(
                      "cloudRenderingSettings.maxSoftSessionRunTime",
                    )}
                    recommendedOptions={sessionDurationOptions}
                    placeholder="(Default)"
                  />
                  <FormErrorMessage>
                    {
                      form.formState.errors.cloudRenderingSettings
                        ?.maxSoftSessionRunTime?.message
                    }
                  </FormErrorMessage>
                  <FormHelperText>
                    The duration after which a warning will be displayed to the
                    user that the session is about to end. This is a soft limit.
                  </FormHelperText>
                </FormControl>
                <FormControl
                  isInvalid={
                    !!form.formState.errors.cloudRenderingSettings
                      ?.maxHardSessionRunTime
                  }
                >
                  <FormLabel>Hard session duration limit</FormLabel>
                  <DurationInput
                    {...form.register(
                      "cloudRenderingSettings.maxHardSessionRunTime",
                    )}
                    recommendedOptions={sessionDurationOptions}
                    placeholder="(Default)"
                  />
                  <FormErrorMessage>
                    {
                      form.formState.errors.cloudRenderingSettings
                        ?.maxHardSessionRunTime?.message
                    }
                  </FormErrorMessage>
                  <FormHelperText>
                    The maximum duration a session can run before being
                    automatically terminated. This is a hard limit.
                  </FormHelperText>
                </FormControl>
                <FormControl
                  isInvalid={
                    !!form.formState.errors.cloudRenderingSettings
                      ?.sessionInactivityTimeoutTime
                  }
                >
                  <FormLabel>Session inactivity timeout</FormLabel>
                  <DurationInput
                    {...form.register(
                      "cloudRenderingSettings.sessionInactivityTimeoutTime",
                    )}
                    recommendedOptions={sessionDurationOptions}
                    placeholder="(Default)"
                  />
                  <FormErrorMessage>
                    {
                      form.formState.errors.cloudRenderingSettings
                        ?.sessionInactivityTimeoutTime?.message
                    }
                  </FormErrorMessage>
                  <FormHelperText>
                    The maximum time a session can remain active without any
                    user input (mouse, keyboard, or headset movement) before
                    it&apos;s automatically terminated due to inactivity.
                  </FormHelperText>
                </FormControl>
                <FormControl
                  isInvalid={
                    !!form.formState.errors.cloudRenderingSettings
                      ?.reuseHoldBackTime
                  }
                >
                  <FormLabel>Retention Period</FormLabel>
                  <DurationInput
                    {...form.register(
                      "cloudRenderingSettings.reuseHoldBackTime",
                    )}
                    recommendedOptions={retentionPeriodOptions}
                    placeholder="(Default)"
                  />
                  <FormErrorMessage>
                    {
                      form.formState.errors.cloudRenderingSettings
                        ?.reuseHoldBackTime?.message
                    }
                  </FormErrorMessage>
                  <FormHelperText>
                    The retention period defines how long rendering nodes will
                    be kept around after a session ends. During the retention
                    period, session loading times are shorter.
                  </FormHelperText>
                </FormControl>
              </Stack>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Text as="legend" fontWeight="bold">
                  On-Premise Rendering
                </Text>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel py={6}>
              <Stack spacing={4}>
                <FormControl
                  isInvalid={
                    !!form.formState.errors.unmanagedNodesSettings
                      ?.maxSoftSessionRunTime
                  }
                >
                  <FormLabel>Soft session duration limit</FormLabel>
                  <DurationInput
                    {...form.register(
                      "unmanagedNodesSettings.maxSoftSessionRunTime",
                    )}
                    recommendedOptions={sessionDurationOptions}
                    placeholder="(Default)"
                  />
                  <FormErrorMessage>
                    {
                      form.formState.errors.unmanagedNodesSettings
                        ?.maxSoftSessionRunTime?.message
                    }
                  </FormErrorMessage>
                  <FormHelperText>
                    The duration after which a warning will be displayed to the
                    user that the session is about to end. This is a soft limit.
                  </FormHelperText>
                </FormControl>
                <FormControl
                  isInvalid={
                    !!form.formState.errors.unmanagedNodesSettings
                      ?.maxHardSessionRunTime
                  }
                >
                  <FormLabel>Hard session duration limit</FormLabel>
                  <DurationInput
                    {...form.register(
                      "unmanagedNodesSettings.maxHardSessionRunTime",
                    )}
                    recommendedOptions={sessionDurationOptions}
                    placeholder="(Default)"
                  />
                  <FormErrorMessage>
                    {
                      form.formState.errors.unmanagedNodesSettings
                        ?.maxHardSessionRunTime?.message
                    }
                  </FormErrorMessage>
                  <FormHelperText>
                    The maximum duration a session can run before being
                    automatically terminated. This is a hard limit.
                  </FormHelperText>
                </FormControl>
                <FormControl
                  isInvalid={
                    !!form.formState.errors.unmanagedNodesSettings
                      ?.sessionInactivityTimeoutTime
                  }
                >
                  <FormLabel>Session inactivity timeout</FormLabel>
                  <DurationInput
                    {...form.register(
                      "unmanagedNodesSettings.sessionInactivityTimeoutTime",
                    )}
                    recommendedOptions={sessionDurationOptions}
                    placeholder="(Default)"
                  />
                  <FormErrorMessage>
                    {
                      form.formState.errors.unmanagedNodesSettings
                        ?.sessionInactivityTimeoutTime?.message
                    }
                  </FormErrorMessage>
                  <FormHelperText>
                    The maximum time a session can remain active without any
                    user input (mouse, keyboard, or headset movement) before
                    it&aps;s automatically terminated due to inactivity.
                  </FormHelperText>
                </FormControl>
                <FormControl
                  isInvalid={
                    !!form.formState.errors.unmanagedNodesSettings
                      ?.reuseHoldBackTime
                  }
                >
                  <FormLabel>Retention Period</FormLabel>
                  <DurationInput
                    {...form.register(
                      "unmanagedNodesSettings.reuseHoldBackTime",
                    )}
                    recommendedOptions={retentionPeriodOptions}
                    placeholder="(Default)"
                  />
                  <FormErrorMessage>
                    {
                      form.formState.errors.unmanagedNodesSettings
                        ?.reuseHoldBackTime?.message
                    }
                  </FormErrorMessage>
                  <FormHelperText>
                    The retention period defines how long rendering nodes will
                    be kept around after a session ends. During the retention
                    period, session loading times are shorter.
                  </FormHelperText>
                </FormControl>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Text as="legend" fontWeight="bold">
          Security
        </Text>
        <FormControl isInvalid={!!form.formState.errors.cloudXREncryption}>
          <FormLabel>Encryption for VR Streaming</FormLabel>
          <Controller
            name="cloudXREncryption"
            control={form.control}
            render={({ field }) => (
              <Select
                {...field}
                onChange={(e) => field.onChange(e.currentTarget.value)}
              >
                {cloudXREnryptionPolicyOptions.map((value) => (
                  <option key={value} value={value}>
                    {cloudXREncryptionPolicyLabels[value]}
                  </option>
                ))}
              </Select>
            )}
          />
          <FormHelperText>
            Control the use of encryption for VR streaming.
          </FormHelperText>
          <FormErrorMessage>
            {form.formState.errors.cloudXREncryption?.message}
          </FormErrorMessage>
        </FormControl>
        <FormSubmitButton formState={form.formState} alignSelf={"start"} />
      </Stack>
      <DevTool control={form.control} />
    </form>
  );
}

const DurationInput = forwardRef(
  (
    {
      recommendedOptions,
      ...inputProps
    }: Omit<InputProps, "ref"> & { recommendedOptions?: string[] },
    ref,
  ) => {
    const id = useId();

    return (
      <InputGroup w={"2xs"}>
        <Input ref={ref} type="string" step="300" {...inputProps} list={id} />
        {recommendedOptions && (
          <datalist id={id}>
            {recommendedOptions.map((option) => (
              <option key={option} value={option} />
            ))}
          </datalist>
        )}
        <InputRightElement>
          <Icon as={FaClock} color={"gray.300"} />
        </InputRightElement>
      </InputGroup>
    );
  },
);
