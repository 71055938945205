import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  Collapse,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  IconButton,
  Input,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  StackDivider,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Select } from "chakra-react-select";
import { useCallback, useEffect, useMemo } from "react";
import { Controller } from "react-hook-form";
import {
  MdDownload as DownloadIcon,
  MdInfoOutline as InfoIcon,
} from "react-icons/md";
import {
  ApplicationBuild,
  ApplicationStatus,
  XRPlatformType,
} from "../backend/types";
import {
  InputWithCopyToClipboard,
  StatusBadge,
  TargetPlatformDisplay,
} from "../components";
import { DevTool } from "../hookform-devtools";

import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import prettyBytes from "pretty-bytes";
import { useDebouncedCallback } from "use-debounce";
import {
  updateApplicationBuild,
  updateApplicationLaunchConfiguration,
} from "../backend/api";
import { LazyApplicationDisplay } from "../components/ApplicationDisplay";
import {
  AccessControlIcon,
  FileSizeIcon,
  InnoactiveHubIcon,
} from "../components/icons";
import { useConfirm } from "../confirm-dialog";
import { useApplicationQuery } from "../hooks";
import {
  getQueryKeyForApplicationBuild,
  useApplicationBuildQuery,
  useLaunchConfigurationsForApplicationBuildQuery,
} from "../hooks/useApplicationBuildsQuery";
import { queryClient } from "../queryClient";
import { extractFormSubmissionErrors } from "../utils/extractFormSubmissionErrors";
import {
  ApplicationBuildDisplay,
  LaunchConfigurationsPicker,
  XRPlatformsDisplay,
} from "./components";
import {
  LaunchCommand,
  LaunchConfigurationPreview,
} from "./components/LaunchConfigurationPreview";
import {
  ApplicationBuildSchema,
  applicationBuildSchema,
  useApplicationBuildExecutablesQuery,
  useApplicationBuildForm,
} from "./hooks/useApplicationBuildForm";
import { getQueryKeyForApplicationLaunchConfigurationsQuery } from "./hooks/useApplicationLaunchConfigurationsQuery";
import { applicationBuildMetaDataSchema } from "./steps/schema";
import { getPossibleXRPlatforms } from "./steps/utils";

export function ApplicationBuildEditForm({
  applicationBuild,
}: {
  applicationBuild: ApplicationBuild;
}) {
  // launch configurations this build is used in
  const launchConfigurationsQuery =
    useLaunchConfigurationsForApplicationBuildQuery(applicationBuild.id);
  const versioningInformationState = useDisclosure();
  const {
    formState,
    register,
    control,
    watch,
    handleSubmit,
    setError,
    reset,
    resetField,
  } = useApplicationBuildForm(applicationBuild);
  const { mutateAsync } = useMutation<
    ApplicationBuildSchema,
    AxiosError,
    Partial<ApplicationBuild> & { id: number | string }
  >({
    mutationFn: async ({ id, ...values }) =>
      await updateApplicationBuild(id, values),
    // make sure data is reloaded after modification
    onSuccess: async (data, { id }) => {
      await queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationBuild(id),
      });
      await queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationLaunchConfigurationsQuery(
          applicationBuild.application,
        ),
      });
      reset(data);
    },
  });
  const { confirm } = useConfirm();

  const onSubmit = useCallback(
    async ({ ...values }) => {
      if (!applicationBuild) {
        await Promise.reject("missing application build");
      }
      try {
        await mutateAsync({ ...values, id: applicationBuild.id });
      } catch (err) {
        extractFormSubmissionErrors(err, applicationBuildSchema).forEach(
          ([field, message]) => {
            setError(field, { type: "server", message });
            !field.startsWith("root") &&
              resetField(field as keyof ApplicationBuildSchema, {
                keepError: true,
              });
          },
        );
      }
    },
    [applicationBuild, mutateAsync, resetField, setError],
  );

  /**
   * Ensure that when the user tries to remove a xr platform that is actively used by some application, they are prompted to confirm the removal.
   */
  const onChangeLaunchConfigurations = useCallback(
    (
      xrPlatforms: XRPlatformType[],
      onChange: (xrPlatforms: XRPlatformType[]) => void,
    ) => {
      const previousXrPlatforms = applicationBuild.supported_xr_platforms;
      const xrPlatformsToBeRemoved = previousXrPlatforms.filter(
        (xrPlatform) => !xrPlatforms.includes(xrPlatform),
      );

      // before applying the change, make sure we don't remove a selected xr platform for which the build is actively used on some application
      const protectedXrPlatforms = (
        launchConfigurationsQuery.data?.results.map(
          ({ xr_platform: xrPlatform }) => xrPlatform,
        ) ?? []
      ).filter((xrPlatform) => xrPlatformsToBeRemoved.includes(xrPlatform));

      if (protectedXrPlatforms.length > 0) {
        const launchConfigurationsToBeDropped =
          launchConfigurationsQuery.data?.results.filter(
            ({ xr_platform: type }) => protectedXrPlatforms.includes(type),
          ) ?? [];
        // prompt the user to confirm removal of the protected xr platforms (which means breaking these launch configurations for any applications that use them)
        confirm({
          title: "Remove protected xr platform(s)?",
          body: (
            <Stack spacing={4}>
              <Text>
                You are trying to remove support for{" "}
                <XRPlatformsDisplay xrPlatforms={protectedXrPlatforms} /> from
                build{" "}
                <ApplicationBuildDisplay applicationBuild={applicationBuild} />
              </Text>
              <Text>
                Since this build is still in use with the following
                applications, support would also be dropped for these:
              </Text>
              {launchConfigurationsToBeDropped
                .map((launchConfiguration) => launchConfiguration.application)
                // ensure the same application doesn't show multiple times
                .filter(
                  (applicationId, index, self) =>
                    index ===
                    self.findIndex(
                      (innerApplicationId) =>
                        innerApplicationId === applicationId,
                    ),
                )
                .map((applicationId) => (
                  <LazyApplicationDisplay
                    key={applicationId}
                    applicationId={applicationId}
                  />
                ))}
            </Stack>
          ),
          confirmButtonText: (
            <Text>
              Drop support for{" "}
              <XRPlatformsDisplay xrPlatforms={protectedXrPlatforms} />
            </Text>
          ),
          onConfirm: async () => {
            // unset the build for any launch configurations of any of the apps using this build for the selected xr platform
            await Promise.all(
              launchConfigurationsToBeDropped.map(
                ({ application: applicationId, xr_platform: xrPlatform }) =>
                  updateApplicationLaunchConfiguration(
                    applicationId,
                    xrPlatform,
                    {
                      application_build: null,
                    },
                  ),
              ),
            );

            // reload the launch configurations
            await queryClient.invalidateQueries({
              queryKey: getQueryKeyForApplicationLaunchConfigurationsQuery(
                applicationBuild.application,
              ),
            });
          },
        })
          .then(async () => {
            // actually update the form and change the build's xr platforms --> which will then lead to autosave
            onChange(xrPlatforms);
          })
          .catch(() => {
            // reset the xr platforms to the previous state
            resetField("supported_xr_platforms");
          });
        return;
      }

      // no protected xr platforms, apply the change
      onChange(xrPlatforms);
    },
    [
      applicationBuild,
      confirm,
      launchConfigurationsQuery.data?.results,
      resetField,
    ],
  );

  // Debounced callback
  const delayedAutoSave = useDebouncedCallback(() => {
    handleSubmit(onSubmit)();
  }, 1000);

  useEffect(() => {
    const subscription = watch((data, info) => {
      if (info.type === "change") {
        if (
          info.name === "supports_arbitrary_cli_args" ||
          info.name === "executable_path"
        ) {
          // instant save
          handleSubmit(onSubmit)();
        } else if (info.name === "supported_xr_platforms") {
          // check whether a change in the supported xr platforms requires to also drop a launch configuration
          applicationBuildMetaDataSchema
            .validate(data)
            .then((newData) =>
              onChangeLaunchConfigurations(newData.supported_xr_platforms, () =>
                handleSubmit(onSubmit)(),
              ),
            )
            // nothing to do if the validation fails
            .catch(() => {});
        } else {
          // delayed autosave
          delayedAutoSave();
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [
    delayedAutoSave,
    formState.isValid,
    handleSubmit,
    onChangeLaunchConfigurations,
    onSubmit,
    watch,
  ]);

  const applicationExecutablesQuery = useApplicationBuildExecutablesQuery(
    applicationBuild.id,
  );
  const applicationExecutables = useMemo(
    () => applicationExecutablesQuery.data ?? [],
    [applicationExecutablesQuery.data],
  );
  const applicationBuildQuery = useApplicationBuildQuery(applicationBuild.id);
  const applicationQuery = useApplicationQuery(applicationBuild.application, {
    enabled: applicationBuildQuery.isSuccess,
  });
  const { isWindows, isAndroid } = useMemo(
    () => ({
      isWindows: applicationBuild.target_platform === "windows",
      isAndroid: applicationBuild.target_platform === "android",
    }),
    [applicationBuild],
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input type="hidden" isDisabled {...register("target_platform")} />
        <Stack spacing={4}>
          <Card variant="outline">
            <CardBody>
              <HStack spacing={6}></HStack>
              <Grid
                columnGap={6}
                rowGap={2}
                gridTemplateColumns={"1fr repeat(5, auto)"}
              >
                <GridItem>
                  <FormControl>
                    <FormLabel htmlFor="application_archive">
                      Application {isAndroid ? "Package" : "Archive"}
                    </FormLabel>
                    <Tooltip
                      label={
                        <>
                          You can download this build&apos;s{" "}
                          {isAndroid ? "package" : "archive"} for inspection. To
                          update the application archive, please upload a new
                          build instead.
                        </>
                      }
                    >
                      <Link
                        href={applicationBuild.application_archive}
                        download
                      >
                        <Button
                          size="sm"
                          variant="outline"
                          leftIcon={<Icon as={DownloadIcon} />}
                        >
                          {new URL(
                            applicationBuild.application_archive,
                          ).pathname
                            .split(/[\\/]/)
                            .pop()}
                        </Button>
                      </Link>
                    </Tooltip>
                  </FormControl>
                </GridItem>
                {applicationBuild.metadata.archive_size && (
                  <GridItem>
                    <FormControl>
                      <FormLabel>
                        {isAndroid ? "Package" : "Archive"} Size
                      </FormLabel>
                      <HStack spacing={2} paddingY={1}>
                        <Icon boxSize="12px" as={FileSizeIcon} />
                        <PrettyBytes
                          bytes={applicationBuild.metadata.archive_size}
                        />
                      </HStack>
                    </FormControl>
                  </GridItem>
                )}
                {applicationBuild.target_platform === "windows" &&
                  applicationBuild.metadata.archive_size && (
                    <GridItem>
                      <FormControl>
                        <FormLabel>Optimization Status</FormLabel>
                        <Box paddingY={0.5}>
                          <Tooltip
                            label={
                              applicationBuild.metadata.archive_size > 0
                                ? "This application's archive has been optimized for faster loading times when streaming"
                                : "This application's archive is pending optimization for faster loading times when streaming"
                            }
                          >
                            {applicationBuild.metadata.archive_size > 0 ? (
                              <Badge colorScheme={"green"}>Done</Badge>
                            ) : (
                              <Badge colorScheme={"yellow"}>Pending</Badge>
                            )}
                          </Tooltip>
                        </Box>
                      </FormControl>
                    </GridItem>
                  )}
                {applicationBuild.metadata.uses_access_control ||
                applicationBuild.metadata.uses_hub_sdk ? (
                  <GridItem>
                    <FormControl>
                      <FormLabel>Detected features</FormLabel>

                      <Box paddingY={0.5}>
                        <ApplicationBuildMetadata
                          metadata={applicationBuild.metadata}
                        />
                      </Box>
                    </FormControl>
                  </GridItem>
                ) : null}
                <GridItem>
                  <FormControl>
                    <FormLabel>Platform</FormLabel>
                    <Box paddingY={1}>
                      <TargetPlatformDisplay
                        color="inherit"
                        platform={applicationBuild.target_platform}
                      />
                    </Box>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>Status</FormLabel>
                    <Box paddingY={0.5}>
                      <StatusBadge status={applicationBuild.status} />
                    </Box>
                  </FormControl>
                </GridItem>
              </Grid>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <Heading as="h3" size="sm">
                Launch configuration
              </Heading>
            </CardHeader>
            <CardBody>
              <Stack spacing={3}>
                <FormControl
                  isInvalid={!!formState.errors.supported_xr_platforms}
                  isDisabled={formState.isSubmitting}
                >
                  <FormLabel htmlFor="supported_xr_platforms">
                    Supported platforms
                  </FormLabel>
                  <FormHelperText marginBottom={2}>
                    (XR) Platforms supported by this application build.
                  </FormHelperText>
                  <Controller
                    control={control}
                    render={({ field: { ref, onChange, ...fieldProps } }) => (
                      <LaunchConfigurationsPicker
                        {...fieldProps}
                        values={getPossibleXRPlatforms(applicationBuild)}
                        size="lg"
                        isDisabled={formState.isSubmitting}
                        onChange={onChange}
                        // onChange={(val: XRPlatformType[]) =>
                        //   onChangeLaunchConfigurations(val, onChange)
                        // }
                      />
                    )}
                    name="supported_xr_platforms"
                  />
                  <FormErrorMessage>
                    {formState.errors.supported_xr_platforms?.message}
                  </FormErrorMessage>
                </FormControl>
                {isWindows && (
                  <FormControl
                    isInvalid={
                      !!formState.errors.executable_path ||
                      applicationBuild.status === ApplicationStatus.InvalidPath
                    }
                  >
                    <FormLabel htmlFor="executable_path">
                      Executable Path
                    </FormLabel>
                    <Controller
                      control={control}
                      name="executable_path"
                      render={({ field }) => (
                        <Select
                          {...field}
                          isMulti={false}
                          value={{ value: field.value, label: field.value }}
                          options={applicationExecutables.map((executable) => ({
                            value: executable,
                            label: executable,
                          }))}
                          onChange={(val) =>
                            field.onChange(val ? val.value : undefined)
                          }
                          isDisabled={
                            applicationExecutables.length <= 1 &&
                            applicationBuild.status !==
                              ApplicationStatus.InvalidPath
                          }
                        />
                      )}
                    />
                    {!formState.errors.executable_path && (
                      <FormHelperText>
                        Path to the executable used to run the application. This
                        path is relative to the root directory of the
                        application archive.
                      </FormHelperText>
                    )}
                    <FormErrorMessage>
                      {(formState.errors.executable_path?.message ??
                      (applicationBuild.status ===
                        ApplicationStatus.InvalidPath &&
                        applicationBuild.executable_path ===
                          watch("executable_path")))
                        ? "This executable is invalid / does not exist."
                        : undefined}
                    </FormErrorMessage>
                  </FormControl>
                )}
                {isAndroid && (
                  <FormControl isInvalid={!!formState.errors.package_name}>
                    <FormLabel htmlFor="package_name">Package Name</FormLabel>
                    <Input
                      isDisabled
                      id="package_name"
                      {...register("package_name")}
                    />
                    {!formState.errors.package_name && (
                      <FormHelperText>
                        Android package name of this application. Needs to be
                        unique per application. Automatically identified after
                        upload.
                      </FormHelperText>
                    )}
                    <FormErrorMessage>
                      {formState.errors.package_name?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
                <FormControl
                  isInvalid={!!formState.errors.launch_args}
                  isDisabled={formState.isSubmitting}
                >
                  <FormLabel htmlFor="launch_args">Launch arguments</FormLabel>
                  <Input
                    isDisabled={formState.isSubmitting}
                    id="launch_args"
                    {...register("launch_args")}
                  />
                  {!formState.errors.launch_args && (
                    <FormHelperText>
                      If the application requires special arguments to be run,
                      you can specify them above.
                    </FormHelperText>
                  )}
                  <FormErrorMessage>
                    {formState.errors.launch_args?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!formState.errors.supports_arbitrary_cli_args}
                >
                  <Controller
                    control={control}
                    name="supports_arbitrary_cli_args"
                    render={({ field }) => (
                      <Checkbox
                        isChecked={field.value}
                        id="supports_arbitrary_cli_args"
                        {...field}
                        value={field.name}
                        isDisabled={formState.isSubmitting}
                      >
                        Default launch arguments
                      </Checkbox>
                    )}
                  />
                  {!formState.errors.supports_arbitrary_cli_args && (
                    <FormHelperText>
                      When enabled, default arguments will be passed to your
                      application that give context about the user and other
                      important aspects.{" "}
                      {isWindows && (
                        <Popover>
                          <PopoverTrigger>
                            <Link>Click here for details.</Link>
                          </PopoverTrigger>
                          <PopoverContent color="chakra-body-text" width="xl">
                            <PopoverArrow />
                            <PopoverBody>
                              <Stack spacing={2}>
                                <Text>
                                  When enabling default launch argument for your
                                  application, the following arguments will be
                                  passed to the executable (after any arguments
                                  you explicitly specified) when the application
                                  is started:
                                </Text>

                                <LaunchCommand
                                  exeuctablePath={watch("executable_path")}
                                  launchArgs={watch("launch_args")}
                                  supportsCliArgs={true}
                                  user={{
                                    full_name: "<full-user-name>",
                                    email: "<user-email>",
                                    id: "<user-id>",
                                  }}
                                  interfaceMode="<vr|screen>"
                                  resolvedLanguage="<selected-language>"
                                  renderingMode="<local|remote>"
                                  targetDeviceUuid="<device-uuid>"
                                  sessionId="<portal-session-id>"
                                  appId={applicationBuild.id}
                                  appIdentity={
                                    applicationQuery.data?.legacy_identity ??
                                    "unknown"
                                  }
                                  organizationName="<organization-name>"
                                  organizationSubdomain="<organization-subdomain>"
                                  organizationDomain="<organization-subdomain>.<instance-domain>"
                                />

                                <Text>
                                  Please ensure that the application supports
                                  receiving the above command line arguments.
                                </Text>
                              </Stack>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      )}
                    </FormHelperText>
                  )}
                  <FormErrorMessage>
                    {formState.errors.supports_arbitrary_cli_args?.message}
                  </FormErrorMessage>
                </FormControl>
                <StackDivider />
                {isWindows && (
                  <Popover>
                    <PopoverTrigger>
                      <Button
                        size="sm"
                        leftIcon={<InfoIcon />}
                        variant="outline"
                        alignSelf={"start"}
                        isDisabled={isAndroid}
                      >
                        Show full launch command
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent width="xl">
                      <PopoverArrow />
                      <PopoverBody>
                        <LaunchConfigurationPreview
                          application={{
                            ...watch(),
                            identity:
                              applicationQuery.data?.legacy_identity ??
                              "unknown",
                            id: applicationBuild.id,
                          }}
                        />
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                )}
              </Stack>
            </CardBody>
          </Card>

          <Card variant={"outline"}>
            <CardHeader
              onClick={versioningInformationState.onToggle}
              cursor={"pointer"}
            >
              <HStack>
                <Heading as="h3" size="sm">
                  Versioning{" "}
                  <IconButton
                    variant="ghost"
                    size="sm"
                    icon={
                      versioningInformationState.isOpen ? (
                        <ChevronUpIcon />
                      ) : (
                        <ChevronDownIcon />
                      )
                    }
                    aria-label={
                      versioningInformationState.isOpen
                        ? "Hide versioning details"
                        : "Show & edit versioning information"
                    }
                  />
                </Heading>
              </HStack>
            </CardHeader>
            <Collapse in={versioningInformationState.isOpen}>
              <CardBody>
                <Stack spacing={6}>
                  <HStack spacing={8} alignItems="start">
                    <FormControl
                      flexGrow={1}
                      isInvalid={!!formState.errors.version}
                    >
                      <FormLabel htmlFor="version">version</FormLabel>
                      <Input
                        isDisabled={formState.isSubmitting}
                        id="version"
                        {...register("version")}
                      />
                      {!formState.errors.version && (
                        <FormHelperText>
                          <Link target="_blank" href="https://semver.org/">
                            Semantic versioning
                          </Link>{" "}
                          is highly recommended.
                        </FormHelperText>
                      )}
                      <FormErrorMessage>
                        {formState.errors.version?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl width="auto">
                      <FormLabel
                        htmlFor="id"
                        whiteSpace={"nowrap"}
                        marginRight={0}
                      >
                        Build Id
                      </FormLabel>
                      <InputWithCopyToClipboard
                        id="id"
                        isReadOnly
                        value={applicationBuild.id.toString() ?? ""}
                      />
                    </FormControl>
                  </HStack>
                  <FormControl isInvalid={!!formState.errors.changelog}>
                    <FormLabel htmlFor="changelog">Changelog</FormLabel>
                    <Textarea
                      id="changelog"
                      resize="vertical"
                      minHeight={32}
                      isDisabled={formState.isSubmitting}
                      {...register("changelog")}
                    />
                    <FormHelperText>
                      <Link
                        target="_blank"
                        href="https://www.markdownguide.org/"
                      >
                        Markdown
                      </Link>{" "}
                      is supported.
                    </FormHelperText>
                  </FormControl>
                </Stack>
              </CardBody>
            </Collapse>
          </Card>
        </Stack>
      </form>
      <DevTool control={control} placement="bottom-left" />
    </>
  );
}

function ApplicationBuildMetadata({
  metadata: {
    uses_access_control: usesAccessControl,
    uses_hub_sdk: usesHubSdk,
  },
}: {
  metadata: ApplicationBuild["metadata"];
}) {
  return (
    <HStack spacing={2}>
      {usesHubSdk && (
        <Tooltip label="This application is built using the (legacy) Innoactive Hub SDK.">
          <Tag size="md" colorScheme="hub">
            <TagLeftIcon boxSize="12px" as={InnoactiveHubIcon} />
            <TagLabel>Innoactive Hub SDK</TagLabel>
          </Tag>
        </Tooltip>
      )}
      {usesAccessControl && (
        <Tooltip label="This application is protected via Innoactive's Access Control Plugin.">
          <Tag size="md" colorScheme="portal">
            <TagLeftIcon boxSize="12px" as={AccessControlIcon} />
            <TagLabel>Access Control</TagLabel>
          </Tag>
        </Tooltip>
      )}
    </HStack>
  );
}

function PrettyBytes({ bytes }: { bytes: number }) {
  return prettyBytes(bytes);
}
