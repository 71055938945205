import { Flex } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { OrganizationProfile } from ".";
import { Organization } from "../backend";

const columnHelper = createColumnHelper<Organization>();

export const baseColumns = [
  columnHelper.accessor("name", {
    id: "name",
    header: "Organization",
    cell: (props) => <OrganizationProfile organization={props.row.original} />,
  }),
  columnHelper.display({
    id: "options",
    cell: () => null,
    header: () => <Flex justifyContent={"end"}>Options</Flex>,
  }),
];
