import { Heading, Stack, Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { ExternalLinkButton, LinkButton } from "../components";
import { namedRoutes } from "../routes";

export function PasswordChangeDone() {
  const { state } = useLocation();
  const next = state?.next;

  return (
    <Stack spacing="6">
      <Heading size={{ base: "xs", md: "sm" }}>
        Password successfully changed!
      </Heading>
      <Text>
        Your password has been successfully changed. You can now log in with
        your new password.
      </Text>
      {next ? (
        <ExternalLinkButton href={next}>Proceed</ExternalLinkButton>
      ) : (
        <LinkButton colorScheme="brand" to={namedRoutes.landing}>
          Proceed to Control Panel
        </LinkButton>
      )}
    </Stack>
  );
}
