import {
  AbsoluteCenter,
  Box,
  BoxProps,
  Button,
  CircularProgress,
  HStack,
  Icon,
  IconButton,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { DropzoneOptions, DropzoneState, useDropzone } from "react-dropzone";
import {
  FaUpload as AddIcon,
  FaFileDownload as DownloadIcon,
  FaTimes as FailIcon,
  FaCloudUploadAlt as UploadIcon,
} from "react-icons/fa";
import { uploadImage } from "../backend/api";
import { DeleteIconButton } from "./DeleteButton";

interface FileUploadInputProps extends DropzoneOptions {
  children?: React.ReactNode;
}

interface IFileUploadContext
  extends Omit<DropzoneState, "getRootProps" | "getInputProps"> {}

const FileUploadContext = createContext<IFileUploadContext>(
  {} as IFileUploadContext,
);

export function FileUpload({ children, ...props }: FileUploadInputProps) {
  const { getRootProps, getInputProps, ...dropzoneProps } = useDropzone(props);

  return (
    <Box {...getRootProps()}>
      <input {...getInputProps()} />
      <FileUploadContext.Provider value={dropzoneProps}>
        {children ?? (
          <Button leftIcon={<Icon as={UploadIcon} />}>Select file(s)...</Button>
        )}
      </FileUploadContext.Provider>
    </Box>
  );
}

export type FileUploadImagePreviewProps = {
  isLoading?: boolean;
  imageUrl?: string;
  downloadImageUrl?: string;
  onDelete?: () => void;
} & BoxProps;

export function FileUploadImagePreview({
  width = 20,
  height = 20,
  borderRadius = "md",
  isLoading = false,
  imageUrl,
  downloadImageUrl,
  onDelete,
  ...props
}: FileUploadImagePreviewProps) {
  const { isDragActive, isDragReject, isDragAccept } =
    useContext(FileUploadContext);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    // when downloading starts clear it after 2.5 seconds
    if (isDownloading) {
      const timeout = setTimeout(() => {
        setIsDownloading(false);
      }, 2500);

      return () => clearTimeout(timeout);
    }
  });

  return (
    <Box
      position={"relative"}
      width={width}
      height={height}
      borderWidth={1}
      borderRadius={borderRadius}
      flexShrink={0}
      cursor="pointer"
      overflow="hidden"
      padding={2}
      borderColor={
        isDragAccept ? "green.400" : isDragReject ? "red.400" : undefined
      }
      {...props}
    >
      <Box
        bgImage={imageUrl}
        backgroundSize="cover"
        backgroundPosition={"center"}
        backgroundRepeat="no-repeat"
        height="100%"
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems={"center"}
        transition="all 300ms"
      />
      <AbsoluteCenter
        w="full"
        h="full"
        backgroundColor={(isDragActive || imageUrl) && "blackAlpha.600"}
        transition="all 300ms"
        display="flex"
        justifyContent="center"
        alignItems={"center"}
        opacity={imageUrl && !isDragActive ? 0 : 1}
        _hover={{ opacity: 1 }}
      >
        <HStack spacing={2}>
          {isLoading && <CircularProgress isIndeterminate boxSize={16} />}
          {!imageUrl && !isLoading && (
            <IconButton
              icon={<Icon as={AddIcon} />}
              aria-label="Upload image"
              size="sm"
              borderRadius={borderRadius}
              variant="ghost"
            />
          )}
          {isDragActive && isDragAccept && <Icon as={UploadIcon} />}
          {isDragActive && isDragReject && <Icon as={FailIcon} />}
        </HStack>
        <HStack
          padding={2}
          position="absolute"
          bottom={0}
          right={0}
          _groupHover={{ opacity: 1 }}
        >
          {imageUrl && !isDragActive && (
            <>
              {
                <IconButton
                  icon={<Icon as={DownloadIcon} />}
                  isLoading={isDownloading}
                  aria-label="Download image"
                  size="sm"
                  variant="outline"
                  as={"a"}
                  href={downloadImageUrl ?? imageUrl}
                  target="_blank"
                  download
                  onClick={(evt) => {
                    evt.stopPropagation();
                    setIsDownloading(true);
                  }}
                />
              }
              <DeleteIconButton
                aria-label="Delete image"
                onClick={(evt) => {
                  evt.stopPropagation();
                  onDelete && onDelete();
                }}
                isLoading={isLoading}
                borderRadius={borderRadius}
              />
            </>
          )}
        </HStack>
      </AbsoluteCenter>
    </Box>
  );
}

export function BrandingImageUploadWithPreview({
  accept = { "image/*": [".jpg", ".jpeg", ".png", ".gif"] },
  imageUrl,
  onChange,
  ...previewProps
}: {
  accept?: DropzoneOptions["accept"];
  imageUrl?: string;
  onChange: (imageUrl: string | null) => void;
} & FileUploadImagePreviewProps) {
  const uploadMutation = useMutation<string, AxiosError, File>({
    mutationFn: (file) => uploadImage(file),
  });

  return (
    <FileUpload
      accept={accept}
      multiple={false}
      validator={(file) => {
        const maxSizeBytes = 10 * 1024 * 1024;
        if (file.size <= maxSizeBytes) {
          // maximum upload size of 10Mb
          return null;
        }

        return {
          code: "too-large",
          message: `File is larger than ${maxSizeBytes / 1024 / 1024} Mb`,
        };
      }}
      onDropAccepted={async (files) => {
        if (!files.length) return;

        const imageUrl = await uploadMutation.mutateAsync(files[0]);
        onChange(imageUrl);
      }}
    >
      <FileUploadImagePreview
        imageUrl={imageUrl}
        isLoading={uploadMutation.isPending}
        onDelete={() => {
          onChange(null);
        }}
        {...previewProps}
      />
    </FileUpload>
  );
}

export default FileUpload;
