import { createContext, useContext } from "react";
import { Application } from "../backend";

type ApplicationDetailsContext = {
  application: Application;
};
const applicationDetailsContext = createContext<ApplicationDetailsContext>(
  {} as ApplicationDetailsContext,
);
export const { Provider } = applicationDetailsContext;

export function useApplicationDetailsContext() {
  return useContext(applicationDetailsContext);
}
