import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Input,
  Link,
  ListItem,
  Stack,
  Text,
  Textarea,
  TextProps,
  UnorderedList,
  useClipboard,
} from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FaCheck as CheckmarkIcon, FaCopy as CopyIcon } from "react-icons/fa";
import slugify from "slugify";
import { InputWithCopyToClipboard } from "../../components";
import { useActiveOrganizationQuery } from "../../hooks";
import { AuthProviderSchema } from "../OrganizationAuthProvidersForm";
import { AuthProviderDisplayNameInput } from "./AuthProviderDisplayNameInput";
import { useConfiguredAuthProviders } from "./useConfiguredAuthProviders";
import { ProviderSlug } from "./utils";
export function TextWithCopyToClipboard({
  text,
  ...props
}: { text: string } & TextProps) {
  const { hasCopied, onCopy } = useClipboard(text);

  return (
    <Text as="span" display="inline-flex" alignContent={"center"} {...props}>
      <span>{text}</span>
      <IconButton
        size="xs"
        variant="ghost"
        onClick={onCopy}
        icon={<Icon as={hasCopied ? CheckmarkIcon : CopyIcon} />}
        aria-label="Copy to Clipboard"
      ></IconButton>
    </Text>
  );
}

export function SAMLAuthProviderForm() {
  const {
    control,
    formState: { errors, isSubmitting },
    register,
    setValue,
  } = useFormContext<AuthProviderSchema>();
  const slug: ProviderSlug = "saml";
  const { data: providers } = useConfiguredAuthProviders({
    slug,
  });
  const { data: organization } = useActiveOrganizationQuery();
  const samlMetaDataEndpoint = useMemo(() => {
    if (!providers || providers.length < 1 || !organization) {
      return null;
    }
    return `${window.location.protocol}//${organization.domain}/auth/sso/saml/metadata`;
  }, [providers, organization]);
  const orgDisplayName = useWatch({
    control,
    name: "saml.SOCIAL_AUTH_SAML_ORG_INFO.en-US.displayname",
  });
  const orgName = useWatch({
    control,
    name: "saml.SOCIAL_AUTH_SAML_ORG_INFO.en-US.name",
  });

  // automatically determine organization name (if none yet)
  useEffect(() => {
    if (orgDisplayName && !orgName) {
      setValue(
        "saml.SOCIAL_AUTH_SAML_ORG_INFO.en-US.name",
        slugify(orgDisplayName, { lower: true }),
      );
    }
  }, [orgDisplayName, orgName, setValue]);

  return (
    <Stack spacing={4}>
      <Alert variant="subtle" status="info">
        <AlertIcon />
        To setup authentication using SAML, please first fill in the required
        fields below and store the configuration. This will in turn create a set
        of metadata that can be used to register Portal as a service provider
        (SP) with the SAML-enabled identity provider (IdP).
      </Alert>
      <Alert variant="subtle" status="warning">
        <AlertIcon />
        <AlertDescription>
          For authentication via SAML to work
          <UnorderedList>
            <ListItem>
              the <i>user&apos;s ID</i> needs to be mapped to the{" "}
              <TextWithCopyToClipboard
                as="code"
                text="urn:oid:0.9.2342.19200300.100.1.1"
              />
              attribute.{" "}
            </ListItem>
            <ListItem>
              The user&apos;s <i>e-mail address</i> must be mapped to the{" "}
              <TextWithCopyToClipboard
                as="code"
                text="urn:oid:0.9.2342.19200300.100.1.3"
              />{" "}
              attribute.
            </ListItem>
            <ListItem>
              If you cannot ensure this mapping, please reach out to our{" "}
              <Link href="mailto:support@innoactive.de">support</Link> for
              assistance.
            </ListItem>
          </UnorderedList>
        </AlertDescription>{" "}
      </Alert>
      {samlMetaDataEndpoint && (
        <Box>
          <Text>
            Please use the following metadata to register Portal as a service
            provider with your IDP:
          </Text>
          <InputWithCopyToClipboard value={samlMetaDataEndpoint} />
        </Box>
      )}
      <Heading size="sm" paddingTop={3}>
        Organization
      </Heading>
      <FormControl
        isInvalid={
          !!errors.saml?.SOCIAL_AUTH_SAML_ORG_INFO?.["en-US"]?.displayname
        }
        isDisabled={isSubmitting}
      >
        <FormLabel htmlFor="saml.SOCIAL_AUTH_SAML_ORG_INFO.en-US.displayname">
          Name
        </FormLabel>
        <Input
          placeholder="ACME Corporation"
          {...register("saml.SOCIAL_AUTH_SAML_ORG_INFO.en-US.displayname")}
        />
        <FormErrorMessage>
          {
            errors.saml?.SOCIAL_AUTH_SAML_ORG_INFO?.["en-US"]?.displayname
              ?.message
          }
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={!!errors.saml?.SOCIAL_AUTH_SAML_ORG_INFO?.["en-US"]?.url}
        isDisabled={isSubmitting}
      >
        <FormLabel htmlFor="saml.SOCIAL_AUTH_SAML_ORG_INFO.en-US.url">
          Website
        </FormLabel>
        <Input
          placeholder="https://acme.org"
          {...register("saml.SOCIAL_AUTH_SAML_ORG_INFO.en-US.url")}
        />
        <FormErrorMessage>
          {errors.saml?.SOCIAL_AUTH_SAML_ORG_INFO?.["en-US"]?.url?.message}
        </FormErrorMessage>
      </FormControl>
      <Input
        type="hidden"
        {...register("saml.SOCIAL_AUTH_SAML_ORG_INFO.en-US.name")}
      />
      <Heading size="sm" paddingTop={3}>
        Identity Provider
      </Heading>
      <FormControl
        isInvalid={!!errors.saml?.SOCIAL_AUTH_SAML_ENABLED_IDPS?.idp?.url}
        isDisabled={isSubmitting}
      >
        <FormLabel htmlFor="saml.SOCIAL_AUTH_SAML_ENABLED_IDPS.idp.url">
          SAML Single-Sign-On Endpoint
        </FormLabel>
        <Input
          placeholder="https://acme.org/protocol/saml"
          {...register("saml.SOCIAL_AUTH_SAML_ENABLED_IDPS.idp.url")}
        />
        <FormHelperText>
          The SAML endpoint used for single-sign-on. You should be able to
          retrieve this value from your IDP&apos;s SAML descriptor.
        </FormHelperText>
        <FormErrorMessage>
          {errors.saml?.SOCIAL_AUTH_SAML_ENABLED_IDPS?.idp?.url?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={!!errors.saml?.SOCIAL_AUTH_SAML_ENABLED_IDPS?.idp?.entity_id}
        isDisabled={isSubmitting}
      >
        <FormLabel htmlFor="saml.SOCIAL_AUTH_SAML_ENABLED_IDPS.idp.entity_id">
          SAML Entity ID
        </FormLabel>
        <Input
          placeholder="https://acme.org/protocol/saml"
          {...register("saml.SOCIAL_AUTH_SAML_ENABLED_IDPS.idp.entity_id")}
        />
        <FormHelperText>
          You should be able to retrieve this value from your IDP&apos;s SAML
          descriptor.
        </FormHelperText>
        <FormErrorMessage>
          {errors.saml?.SOCIAL_AUTH_SAML_ENABLED_IDPS?.idp?.entity_id?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={!!errors.saml?.SOCIAL_AUTH_SAML_ENABLED_IDPS?.idp?.x509cert}
        isDisabled={isSubmitting}
      >
        <FormLabel htmlFor="saml.SOCIAL_AUTH_SAML_ENABLED_IDPS.idp.x509cert">
          Signing Certificate (X509)
        </FormLabel>
        <Textarea
          placeholder="MIIEDjCCAvagAwIBAgIBADA ... 8Bbnl+ev0peYzxFyF5sQA=="
          {...register("saml.SOCIAL_AUTH_SAML_ENABLED_IDPS.idp.x509cert")}
        />
        <FormHelperText>
          You should be able to retrieve this value from your IDP&apos;s SAML
          descriptor.
        </FormHelperText>
        <FormErrorMessage>
          {errors.saml?.SOCIAL_AUTH_SAML_ENABLED_IDPS?.idp?.x509cert?.message}
        </FormErrorMessage>
      </FormControl>
      <Heading size="sm" paddingTop={3}>
        SAML
      </Heading>
      <FormControl
        isInvalid={!!errors.saml?.SOCIAL_AUTH_SAML_SP_ENTITY_ID}
        isDisabled={isSubmitting}
      >
        <FormLabel htmlFor="saml.SOCIAL_AUTH_SAML_SP_ENTITY_ID">
          Entity ID of Service Provider
        </FormLabel>
        <Input
          placeholder="https://acme.org/protocol/saml"
          {...register("saml.SOCIAL_AUTH_SAML_SP_ENTITY_ID")}
        />
        <FormHelperText>
          If you already have registered a client application with your IdP,
          this should be the respective entity / client id. Otherwise, the
          entity id defined here will identify the client application on your
          SAML-IdP.
        </FormHelperText>
        <FormErrorMessage>
          {errors.saml?.SOCIAL_AUTH_SAML_SP_ENTITY_ID?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={!!errors.saml?.SOCIAL_AUTH_SAML_SP_PRIVATE_KEY}
        isDisabled={isSubmitting}
      >
        <FormLabel htmlFor="saml.SOCIAL_AUTH_SAML_SP_PRIVATE_KEY">
          Private Key
        </FormLabel>
        <Textarea
          placeholder="MIIEv ... ZTA=="
          {...register("saml.SOCIAL_AUTH_SAML_SP_PRIVATE_KEY")}
        />
        <FormHelperText>
          Private Key of a certificate used to sign authorization requests to
          the SAML-IdP. Please omit the first and last line that marks the
          beginning and end of the private key.
        </FormHelperText>
        <FormErrorMessage>
          {errors.saml?.SOCIAL_AUTH_SAML_SP_PRIVATE_KEY?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={!!errors.saml?.SOCIAL_AUTH_SAML_SP_PUBLIC_CERT}
        isDisabled={isSubmitting}
      >
        <FormLabel htmlFor="saml.SOCIAL_AUTH_SAML_SP_PUBLIC_CERT">
          Certificate
        </FormLabel>
        <Textarea
          placeholder="MIID0TCCAr ... j92/adN"
          {...register("saml.SOCIAL_AUTH_SAML_SP_PUBLIC_CERT")}
        />
        <FormHelperText>
          Public Certificate used to validate authorization requests signed with
          the private key on the SAML-IdP. Please omit the first and last line
          that marks the beginning and end of the certificate.
        </FormHelperText>
        <FormErrorMessage>
          {errors.saml?.SOCIAL_AUTH_SAML_SP_PUBLIC_CERT?.message}
        </FormErrorMessage>
      </FormControl>
      <Heading size="sm" paddingTop={3}>
        Technical contact
      </Heading>
      <FormControl
        isInvalid={!!errors.saml?.SOCIAL_AUTH_SAML_TECHNICAL_CONTACT?.givenName}
        isDisabled={isSubmitting}
      >
        <FormLabel htmlFor="saml.SOCIAL_AUTH_SAML_TECHNICAL_CONTACT.givenName">
          Given Name
        </FormLabel>
        <Input
          placeholder="John Doe"
          {...register("saml.SOCIAL_AUTH_SAML_TECHNICAL_CONTACT.givenName")}
        />
        <FormErrorMessage>
          {errors.saml?.SOCIAL_AUTH_SAML_TECHNICAL_CONTACT?.givenName?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={
          !!errors.saml?.SOCIAL_AUTH_SAML_TECHNICAL_CONTACT?.emailAddress
        }
        isDisabled={isSubmitting}
      >
        <FormLabel htmlFor="saml.SOCIAL_AUTH_SAML_TECHNICAL_CONTACT.emailAddress">
          E-Mail address
        </FormLabel>
        <Input
          placeholder="jane.doe@acme.org"
          type="email"
          {...register("saml.SOCIAL_AUTH_SAML_TECHNICAL_CONTACT.emailAddress")}
        />
        <FormErrorMessage>
          {
            errors.saml?.SOCIAL_AUTH_SAML_TECHNICAL_CONTACT?.emailAddress
              ?.message
          }
        </FormErrorMessage>
      </FormControl>
      <Heading size="sm" paddingTop={3}>
        Support contact
      </Heading>
      <FormControl
        isInvalid={!!errors.saml?.SOCIAL_AUTH_SAML_SUPPORT_CONTACT?.givenName}
        isDisabled={isSubmitting}
      >
        <FormLabel htmlFor="saml.SOCIAL_AUTH_SAML_SUPPORT_CONTACT.givenName">
          Given Name
        </FormLabel>
        <Input
          placeholder="Jane Doe"
          {...register("saml.SOCIAL_AUTH_SAML_SUPPORT_CONTACT.givenName")}
        />
        <FormErrorMessage>
          {errors.saml?.SOCIAL_AUTH_SAML_SUPPORT_CONTACT?.givenName?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={
          !!errors.saml?.SOCIAL_AUTH_SAML_SUPPORT_CONTACT?.emailAddress
        }
        isDisabled={isSubmitting}
      >
        <FormLabel htmlFor="saml.SOCIAL_AUTH_SAML_SUPPORT_CONTACT.emailAddress">
          E-Mail address
        </FormLabel>
        <Input
          placeholder="jane.doe@acme.org"
          type="email"
          {...register("saml.SOCIAL_AUTH_SAML_SUPPORT_CONTACT.emailAddress")}
        />
        <FormErrorMessage>
          {errors.saml?.SOCIAL_AUTH_SAML_SUPPORT_CONTACT?.emailAddress?.message}
        </FormErrorMessage>
      </FormControl>
      <Heading size="sm" paddingTop={3}>
        Miscellaneous
      </Heading>
      <AuthProviderDisplayNameInput providerSlug={slug} />
    </Stack>
  );
}
