import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import {
  UnmanagedNode,
  getUnmanagedNodesForRegion,
} from "../../session-management";

export function useUnmanagedRegionNodesQuery<T = UnmanagedNode[]>(
  region: string,
  queryOptions: Partial<UseQueryOptions<UnmanagedNode[], Error, T>> = {},
) {
  return useQuery({
    queryKey: ["unmanaged-region", region, "nodes"],
    queryFn: () => getUnmanagedNodesForRegion(region),
    ...queryOptions,
  });
}
