import { useNavigate } from "react-router-dom";
import { HttpErrorFallback } from "../components/ErrorFallback";
import { namedRoutes } from "../routes";

export default function ServerError() {
  const navigate = useNavigate();

  return (
    <HttpErrorFallback
      statusCode={500}
      message="Request failed with error code 500"
      resetButtonLabel={`Back to Control Panel`}
      resetErrorBoundary={() => {
        navigate(namedRoutes.landing);
      }}
    />
  );
}
