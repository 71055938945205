import { Box } from "@chakra-ui/react";
import { createCalendar, DateValue } from "@internationalized/date";
import { useLocale } from "@react-aria/i18n";
import { AriaDatePickerProps } from "@react-types/datepicker";
import { useRef } from "react";
import { useDateField } from "react-aria";
import { useDateFieldState } from "react-stately";
import { DateSegment } from "./DateSegment";

export function DateField(props: AriaDatePickerProps<DateValue>) {
  const { locale } = useLocale();
  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  });
  const ref = useRef(null);
  const { fieldProps } = useDateField(props, state, ref);

  return (
    <Box display="flex" {...fieldProps} ref={ref}>
      {state.segments.map((segment, i) => (
        <DateSegment key={i} segment={segment} state={state} />
      ))}
    </Box>
  );
}
