import { Stack } from "@chakra-ui/react";
import { Stepper } from "../components/Stepper";
import { Provider, useAddApplicationState } from "./AddApplicationContext";
import {
  AddApplicationMetadataStep,
  FinalizeApplicationStep,
  UploadApplicationBuildStep,
  UploadMediaStep,
} from "./steps";
import { AddApplicationStep } from "./steps/AddApplicationStep";
import { ValidateApplicationBuildStep } from "./steps/ValidateApplicationBuildStep";

export function AddApplicationWizard({
  onCompleted,
}: {
  onCompleted?: () => void;
}) {
  const [state, dispatch] = useAddApplicationState();

  return (
    <Provider value={{ state, dispatch }}>
      <Stack spacing={6}>
        <Stepper
          steps={[
            AddApplicationStep,
            UploadApplicationBuildStep,
            AddApplicationMetadataStep,
            ValidateApplicationBuildStep,
            UploadMediaStep,
            FinalizeApplicationStep,
          ]}
          onCompleted={onCompleted}
        />
      </Stack>
    </Provider>
  );
}
