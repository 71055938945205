import { PropsWithChildren } from "react";
import { useLocation } from "react-router";
import { Navigate } from "react-router-dom";
import { AuthorizingAlert, FullPageCenteredContainer } from "../components";
import { namedRoutes } from "../routes";
import { useAuthentication } from "./auth";

export function RequireAuth({ children }: PropsWithChildren): JSX.Element {
  const auth = useAuthentication();
  const location = useLocation();

  // if we are currently authenticating / authorizing, wait for it to finish before doing anything
  if (auth.isPendingAuthentication) {
    return (
      <FullPageCenteredContainer>
        <AuthorizingAlert />
      </FullPageCenteredContainer>
    );
  }

  if (!auth.user) {
    return <Navigate to={namedRoutes.login} state={{ from: location }} />;
  }

  // all good, render children
  return <>{children}</>;
}
