import {
  Alert,
  AlertIcon,
  Button,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Step, StepProps } from "../../components/Stepper";
import { useStepperContext } from "../../components/StepperContext";
import { useAddApplicationContext } from "../AddApplicationContext";

export function FinalizeApplicationBuildStep({
  goToPrev,
  ...props
}: StepProps) {
  const {
    state: {
      application,
      applicationBuild: { id: applicationBuildId },
    },
  } = useAddApplicationContext();
  const { onCompleted } = useStepperContext();

  return (
    <Step
      actionButtons={
        <HStack>
          <Button
            size="sm"
            variant="ghost"
            isDisabled={!goToPrev}
            onClick={goToPrev ? goToPrev : undefined}
          >
            Back
          </Button>
          {applicationBuildId && (
            <Button
              size="sm"
              colorScheme="brand"
              onClick={() => onCompleted && onCompleted()}
            >
              Finish
            </Button>
          )}
        </HStack>
      }
      {...props}
      title="Next steps"
    >
      <Stack spacing={4}>
        <Alert status="success">
          <AlertIcon />
          <Text>
            Your application build for{" "}
            {application?.name ?? "Unknown application"} has been successfully
            uploaded!
          </Text>
        </Alert>
        <Text>
          Click &quot;Finish&quot; to review your build&quot;s settings.
        </Text>
      </Stack>
    </Step>
  );
}
