import { Divider, Stack } from "@chakra-ui/react";
import { useApplicationDetailsContext } from "./ApplicationDetailsContext";
import { ApplicationPerformanceRequirements } from "./ApplicationPerformanceRequirements";
import { ApplicationUrlRewriteRules } from "./ApplicationUrlRewriteRules";
import { ApplicationNetworkSecurityRulesOverview } from "./network-security-rules/ApplicationNetworkSecurityRulesOverview";

export function ApplicationCloudRenderingSettings() {
  const { application } = useApplicationDetailsContext();

  return (
    <Stack spacing={8}>
      <ApplicationPerformanceRequirements application={application} />
      <Divider />
      <ApplicationNetworkSecurityRulesOverview application={application} />
      <Divider />
      <ApplicationUrlRewriteRules application={application} />
    </Stack>
  );
}
