import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { PortalError, UserApplicationPermission } from "../backend";
import { getUserPermissionsForApplication } from "../backend/api";
import { ApplicationId } from "../backend/types";

export function getQueryKeyForUserPermissionsForApplication(
  applicationId: ApplicationId,
) {
  return ["user_permissions", applicationId];
}

function getQueryForUserPermissionsForApplication(
  applicationId: ApplicationId,
) {
  return {
    queryKey: getQueryKeyForUserPermissionsForApplication(applicationId),
    queryFn: () => getUserPermissionsForApplication(applicationId),
    retry: false,
    placeholderData: [],
  };
}

export function useUserPermissionsForApplicationQuery(
  applicationId: ApplicationId,
) {
  return useQuery<UserApplicationPermission[], AxiosError<PortalError>>(
    getQueryForUserPermissionsForApplication(applicationId),
  );
}
