import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ChunkedUploader } from "./ChunkedUploader";

export type ChunkedUploadState = {
  file?: File;
  setFile: Dispatch<SetStateAction<File | undefined>>;
  isUploading: boolean;
  error: Error | null;
  result: string | null;
  progress: number;
  start: () => void;
  abort: () => void;
};

function useChunkedUpload(): ChunkedUploadState {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [progress, setProgress] = useState(0);
  const [result, setResult] = useState<string | null>(null);
  const [file, setFile] = useState<File | undefined>();
  const uploader = useRef<ChunkedUploader>();

  const reset = useCallback(() => {
    setResult(null);
    setIsUploading(false);
    setProgress(0);
    setError(null);
  }, []);

  useEffect(() => {
    // if the file changed, reset everything
    reset();
  }, [file, reset]);

  const start = () => {
    if (!file) return;

    const chunkedUploader = new ChunkedUploader(file);

    chunkedUploader.events.progress.registerCallback((progress) =>
      setProgress(progress),
    );
    chunkedUploader.events.error.registerCallback((error) => {
      setError(error);
      setIsUploading(false);
    });
    chunkedUploader.events.success.registerCallback((result) => {
      setResult(result);
      setIsUploading(false);
    });
    chunkedUploader.events.abort.registerCallback(() => {
      reset();
    });

    chunkedUploader.start();
    uploader.current = chunkedUploader;
    setIsUploading(true);
  };

  const abort = () => {
    uploader.current?.abort();
  };

  return {
    file,
    setFile,
    isUploading,
    error,
    result,
    progress,
    start,
    abort,
  };
}

export { ChunkedUploader, useChunkedUpload };
