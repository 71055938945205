const hubSdkAuthorizationRuleName = "Hub SDK Authorization";
const omniverseAuthRuleName = "Omniverse Nucleus Auth";

export const rewritePresets = [
  {
    name: hubSdkAuthorizationRuleName,
    description:
      "Rewrite rules for Hub SDK enabled apps. This will rewrite Authorization-URLs to work also when opened locally.",
    match: "redirect_uri=([^&\\?=]+)",
    replace:
      "redirect_uri=https://$organizationDomain/session/remote-rendering-node-backchannel/$1",
  },
  {
    name: "Legacy Hub URLs",
    description:
      "Rewrite rules for legacy Hub URLs to point to the current Portal endpoints.",
    match: "([\\w\\-_]+).hub.innoactive.de",
    replace: "api.$1.innoactive.io",
  },
  {
    name: omniverseAuthRuleName,
    description:
      "Rewrite rules for Nvidia Omniverse Nucleus Authentiation. Please also ensure to create a respective firewall rule to open TCP port 3180 inbound.",
    match: "server=([^&\\?=]+)",
    replace: "server=$serverIp",
  },
];
