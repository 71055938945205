import { WarningTwoIcon } from "@chakra-ui/icons";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import TimeAgo from "react-timeago";
import {
  deleteInvitation,
  fetchInvitations,
  inviteUserToOrganization,
} from "../../backend/api";
import { InvitationInfo } from "../../backend/types";
import {
  DeleteButton,
  LazyUserProfile,
  OptionsButton,
  PaginatedTable,
} from "../../components";
import { NoData } from "../../components/NoData";
import { ResendIcon } from "../../components/icons";
import { usePaginationState } from "../../hooks/usePaginationQueryParams";
import { useSortingState } from "../../hooks/useSortingQueryParams";
import { OrderingParam } from "../../utils/ordering-param";

const columnHelper = createColumnHelper<InvitationInfo>();

const defaultSorting: SortingState = [{ desc: true, id: "created_at" }];

const getPendingInvitationsQueryKey = (params: Record<string, unknown>) => [
  "pendingInvitations",
  params,
];

export function PendingInvitationsDrawer({
  isOpen,
  onClose,
}: ReturnType<typeof useDisclosure>) {
  const [pagination, setPagination] = usePaginationState();
  const [sorting, setSorting] = useSortingState(defaultSorting);
  const ordering = useMemo(() => OrderingParam.encode(sorting), [sorting]);
  const params = {
    pending: true,
    ordering: ordering,
    page: pagination.pageIndex + 1,
    page_size: pagination.pageSize,
  };
  const { data: pendingInvitationsResponse, isLoading } = useQuery({
    queryKey: getPendingInvitationsQueryKey(params),
    queryFn: () => fetchInvitations(params),
  });
  const queryClient = useQueryClient();
  const {
    mutateAsync: revokeInvitationAsync,
    isPending: isPendingInvitationRevocation,
  } = useMutation({
    mutationFn: (invitationId: string) => deleteInvitation(invitationId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getPendingInvitationsQueryKey(params).slice(0, 1),
      });
    },
  });
  const { mutateAsync: createInvitationAsync } = useMutation({
    mutationFn: (invitation: InvitationInfo) =>
      inviteUserToOrganization(
        invitation.user,
        invitation.organization,
        invitation.groups.map((group) => group.id),
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getPendingInvitationsQueryKey(params).slice(0, 1),
      });
    },
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor("user", {
        id: "email",
        header: "E-Mail",
        cell: (props) => <b>{props.getValue()}</b>,
      }),
      columnHelper.accessor("created_at", {
        header: "Invited",
        cell: (props) => (
          <HStack>
            <TimeAgo date={props.getValue()} />
            {isInvitationExpired(props.row.original) && (
              <Tooltip label="This invitation has expired.">
                <WarningTwoIcon color="yellow.500" />
              </Tooltip>
            )}
          </HStack>
        ),
      }),
      columnHelper.accessor("created_by", {
        header: "By",
        cell: (props) => <LazyUserProfile userId={props.getValue()} />,
      }),
      columnHelper.display({
        id: "options",
        cell: (props) => (
          <Flex justifyContent={"end"}>
            <OptionsButton label="Click on this button to display invitation actions">
              <Button
                variant="ghost"
                fontWeight="normal"
                fontSize="sm"
                w="full"
                leftIcon={<ResendIcon />}
                onClick={async () => {
                  await revokeInvitationAsync(props.row.original.id);
                  await createInvitationAsync(props.row.original);
                }}
                {...props}
              >
                Resend invitation
              </Button>
              <DeleteButton
                rightIcon={undefined}
                onClick={() => {
                  revokeInvitationAsync(props.row.original.id);
                }}
                isLoading={isPendingInvitationRevocation}
              >
                Revoke invitation
              </DeleteButton>
            </OptionsButton>
          </Flex>
        ),
        header: () => <Flex justifyContent="end">Options</Flex>,
      }),
    ],
    [
      createInvitationAsync,
      revokeInvitationAsync,
      isPendingInvitationRevocation,
    ],
  );

  const table = useReactTable({
    data: useMemo<InvitationInfo[]>(
      () =>
        isLoading
          ? Array(pagination.pageSize).fill({} as InvitationInfo)
          : (pendingInvitationsResponse?.results ?? []),
      [isLoading, pagination.pageSize, pendingInvitationsResponse?.results],
    ),
    columns: useMemo(
      () =>
        isLoading
          ? columns.map((column) => ({
              ...column,
              cell: () =>
                column.id === "email" ? (
                  <HStack>
                    <SkeletonCircle size="8" />
                    <SkeletonText noOfLines={2} w="100%" />
                  </HStack>
                ) : (
                  <Skeleton>
                    <Text>Loading</Text>
                  </Skeleton>
                ),
            }))
          : columns,
      [columns, isLoading],
    ),
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualSorting: true,
    manualPagination: true,
    pageCount: pendingInvitationsResponse?.count
      ? Math.ceil(pendingInvitationsResponse?.count / pagination.pageSize)
      : undefined,
  });

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Pending Invitations</DrawerHeader>

        <DrawerBody>
          {pendingInvitationsResponse && !pendingInvitationsResponse.count ? (
            <NoData text="Looks like there are no pending invitations (yet)." />
          ) : (
            <Stack>
              <Text>The following users have been invited:</Text>
              <PaginatedTable table={table} />
            </Stack>
          )}
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
function isInvitationExpired(invitation: InvitationInfo) {
  return (
    (new Date().getTime() - new Date(invitation.created_at).getTime()) /
      1000 /
      3600 /
      24 >
    7
  );
}
