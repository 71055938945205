import { Box, Flex, Heading, HStack, Skeleton } from "@chakra-ui/react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isoWeek from "dayjs/plugin/isoWeek";
import localeData from "dayjs/plugin/localeData";
import relativeTime from "dayjs/plugin/relativeTime";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useActiveOrganizationQuery } from "../../hooks";
import { useDashboardParameters } from "../context";
import { useSessionsCountInTimespanQuery } from "../hooks/useSessionsCountInTimespanQuery";
import { useTimeSettings } from "../hooks/useTimeSettings";
import { useTimeSeriesBarChartOptions } from "./chart-options";
import { FailedLoadingData } from "./FailedLoadingData";

dayjs.extend(localeData);
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

export function RenderingModeComparison() {
  const { from, to, ...filters } = useDashboardParameters();
  const { data: organization } = useActiveOrganizationQuery();

  const timeSettings = useTimeSettings(from, to);
  const chartOptions = useTimeSeriesBarChartOptions({
    unitOfTime: timeSettings.unitOfTime,
    yAxisTitle: "Sessions",
  });
  const sessionCountQueryConfig = useMemo(
    () => ({
      ...timeSettings,
      ...filters,
      organization,
    }),
    [organization, timeSettings, filters],
  );
  const cloudRenderedResults = useSessionsCountInTimespanQuery({
    ...sessionCountQueryConfig,
    renderType: "CloudRendered",
  });
  const locallyRenderedResults = useSessionsCountInTimespanQuery({
    ...sessionCountQueryConfig,
    renderType: "LocalRendered",
  });
  const isLoading = useMemo(
    () =>
      [cloudRenderedResults, locallyRenderedResults].some((r) => r.isLoading),
    [cloudRenderedResults, locallyRenderedResults],
  );
  const isError = useMemo(
    () => [cloudRenderedResults, locallyRenderedResults].some((r) => r.isError),
    [cloudRenderedResults, locallyRenderedResults],
  );
  const series = useMemo(
    () => [
      {
        name: "Cloud Rendering",
        data: (cloudRenderedResults.data ?? []).map<{
          x: string | number;
          y: number;
        }>((res) => ({
          x: res.key,
          y: res.count,
        })),
      },
      {
        name: "On-Device Rendering",
        data: (locallyRenderedResults.data ?? []).map<{
          x: string | number;
          y: number;
        }>((res) => ({
          x: res.key,
          y: res.count,
        })),
      },
    ],
    [cloudRenderedResults, locallyRenderedResults],
  );

  return (
    <Flex direction="column">
      <HStack>
        <Heading size="sm">Rendering modes</Heading>
      </HStack>
      <Flex w="100%">
        <Box minH="350px" w="100%" flexGrow={1}>
          {isError && <FailedLoadingData h="100%" justifyContent={"center"} />}
          {!isError && (
            <Skeleton h="100%" w="100%" isLoaded={!isLoading}>
              <ReactApexChart
                type="bar"
                series={series}
                options={{
                  ...chartOptions,
                  chart: { ...chartOptions.chart, stacked: true },
                }}
              />
            </Skeleton>
          )}
        </Box>
      </Flex>
    </Flex>
  );
}
