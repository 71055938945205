import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import { InferType, object, string } from "yup";
import { resetPassword } from "../backend/api";
import { LinkButton } from "../components";
import { DevTool } from "../hookform-devtools";
import { useBrandingQuery } from "../hooks";
import { namedRoutes } from "../routes";
import { extractFormSubmissionErrors } from "../utils/extractFormSubmissionErrors";

const passwordResetSchema = object({
  password: string().required("Password is required"),
  password_confirmation: string()
    .test("passwords-match", "Passwords must match", function (value, context) {
      return context.parent.password === value;
    })
    .required("Password confirmation is required"),
  user_id: string().required(),
  timestamp: string().required(),
  signature: string().required(),
}).noUnknown();

type PasswordResetSchema = InferType<typeof passwordResetSchema>;

function PasswordResetLinkExpiredError() {
  return (
    <Stack spacing={6}>
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>
          <Text>
            The password reset link was invalid, possibly because it has already
            been used. You can restart the process by using the button below.
          </Text>
        </AlertDescription>
      </Alert>
      <LinkButton to={namedRoutes.resetPassword.request}>
        Reset Password
      </LinkButton>
    </Stack>
  );
}

function isAbsoluteUrl(next: string) {
  return next.startsWith("http://") || next.startsWith("https://");
}

export function PasswordResetForm() {
  const { data: branding } = useBrandingQuery();
  const [query] = useQueryParams({
    user_id: withDefault(StringParam, undefined),
    timestamp: withDefault(StringParam, undefined),
    signature: withDefault(StringParam, undefined),
    next: StringParam,
  });
  const navigate = useNavigate();
  const resetPasswordMutation = useMutation({
    mutationFn: async (data: Parameters<typeof resetPassword>[0]) => {
      await resetPassword(data);
    },
  });
  const { register, formState, handleSubmit, control, setError } =
    useForm<PasswordResetSchema>({
      mode: "onSubmit",
      resolver: yupResolver(passwordResetSchema),
      defaultValues: {
        ...query,
      },
    });

  if (formState.errors.root) {
    return <PasswordResetLinkExpiredError />;
  }

  return (
    <form
      onSubmit={handleSubmit(async (values) => {
        try {
          await resetPasswordMutation.mutateAsync(values);

          if (query.next && isAbsoluteUrl(query.next)) {
            // automatically go to next page if it's an absolute url
            window.location.href = query.next;
          } else {
            // redirect to success view
            navigate(namedRoutes.resetPassword.complete, {
              state: { next: query.next },
            });
          }
        } catch (err) {
          extractFormSubmissionErrors(err, passwordResetSchema).forEach(
            ([field, message]) => setError(field, { type: "server", message }),
          );
        }
      })}
    >
      <Stack spacing="6">
        <Heading size={{ base: "xs", md: "sm" }}>
          Please enter and repeat your new password to access{" "}
          {branding?.company_name} {branding?.product_name}.
        </Heading>
        <Stack spacing="5">
          {(!!formState.errors.signature ||
            !!formState.errors.user_id ||
            !!formState.errors.timestamp) && <PasswordResetLinkExpiredError />}
          <FormControl isInvalid={!!formState.errors.password}>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Input
              id="password"
              type="password"
              autoComplete="new-password"
              {...register("password")}
            />
            <FormErrorMessage>
              {formState.errors.password?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!formState.errors.password_confirmation}>
            <FormLabel htmlFor="password_confirmation">
              Password Confirmation
            </FormLabel>
            <Input
              id="password_confirmation"
              type="password"
              autoComplete="new-password"
              {...register("password_confirmation")}
            />
            <FormErrorMessage>
              {formState.errors.password_confirmation?.message}
            </FormErrorMessage>
          </FormControl>
          <Input type="hidden" {...register("user_id")} />
          <Input type="hidden" {...register("signature")} />
          <Input type="hidden" {...register("timestamp")} />
          <Button
            colorScheme="brand"
            type="submit"
            isLoading={formState.isSubmitting}
          >
            Set password
          </Button>
        </Stack>
      </Stack>
      <DevTool control={control} />
    </form>
  );
}
