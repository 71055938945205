import { SessionsStatDto } from "../../session-management";
import { useDashboardParameters } from "../context";
import { usePreviousTimeSpan, useSessionsStatsQuery } from "../hooks";
import { PeriodStatCompare } from "./PeriodStatCompare";

const selectUserCountWithSessions = (
  sessionsStats: SessionsStatDto[],
): Pick<SessionsStatDto, "key" | "count">[] => {
  return [
    {
      count: sessionsStats.length,
      key: "total",
    },
  ];
};

export function TotalUsersWithSessions() {
  const filters = useDashboardParameters();
  const usersWithSessionsCountQuery = useSessionsStatsQuery({
    ...filters,
    groupBy: "user",
    queryOptions: { select: selectUserCountWithSessions },
  });
  const usersWithSessionsCountQueryPrevious = useSessionsStatsQuery({
    ...filters,
    ...usePreviousTimeSpan(filters.from, filters.to),
    groupBy: "user",
    queryOptions: { select: selectUserCountWithSessions },
  });

  return (
    <PeriodStatCompare
      currentPeriodStatQuery={usersWithSessionsCountQuery}
      previousPeriodStatQuery={usersWithSessionsCountQueryPrevious}
      title="Active users"
      label={`w/ at least one session`}
    />
  );
}
