import { forwardRef } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useMemo } from "react";
import type { GroupBase, Props } from "react-select";
import { UserGroup } from "../../backend";

type UserGroupOption = {
  value: UserGroup;
  label: string;
};

export const UserGroupMultiSelect = forwardRef(
  (
    {
      userGroups,
      ...props
    }: {
      userGroups: UserGroup[];
    } & Props<UserGroupOption, true, GroupBase<UserGroupOption>>,
    ref,
  ) => {
    return (
      <Select<UserGroupOption, true>
        ref={ref}
        placeholder="Select user group(s)..."
        isMulti
        name="user-groups"
        options={useMemo(
          () =>
            userGroups.map((userGroup) => ({
              value: userGroup,
              label: userGroup.name,
            })),
          [userGroups],
        )}
        {...props}
      />
    );
  },
);
