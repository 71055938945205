/**
 * Reads chunkSize bytes from the given File
 * @param {File} file File to read a chunk from
 * @param {int} startByte index of byte to start reading from
 * @param {int} chunkSize number of bytes to read
 * @returns {Promise<ArrayBuffer>}
 */
export function readFileChunk(
  file: File,
  startByte: number,
  chunkSize: number,
): Promise<ArrayBuffer> {
  const promise = new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", (e) => {
      if (!e.target) reject("Missing target");
      else if (!e.target?.result) reject("Missing reult");
      else resolve(e.target.result as ArrayBuffer);
    });
    reader.addEventListener("abort", reject);
    reader.addEventListener("error", reject);
    const start = startByte,
      end = Math.min(start + chunkSize, file.size);
    reader.readAsArrayBuffer(file.slice(start, end));
  });
  return promise;
}
