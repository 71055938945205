import { createRoot } from "react-dom/client";
// required for modulepreload polyfill, see https://vitejs.dev/guide/backend-integration.html
import "vite/modulepreload-polyfill";
import App from "./App";
import { ControllableStrictMode } from "./components";
import "./utils/i18n";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ControllableStrictMode>
    <App />
  </ControllableStrictMode>,
);
