import { Box, Button, StyleProps, Td } from "@chakra-ui/react";
import {
  CalendarDate,
  getLocalTimeZone,
  isToday,
} from "@internationalized/date";
import { useRef } from "react";
import { useCalendarCell } from "react-aria";
import { CalendarState } from "react-stately";

export function CalendarCell({
  state,
  date,
  ...styleProps
}: {
  state: CalendarState;
  date: CalendarDate;
} & StyleProps) {
  const ref = useRef(null);
  const {
    cellProps,
    buttonProps,
    isSelected,
    isInvalid,
    formattedDate,
    isDisabled,
  } = useCalendarCell({ date }, state, ref);

  return (
    <Td
      {...cellProps}
      textAlign="center"
      height={10}
      width={10}
      boxSizing="content-box"
      paddingX={0}
      paddingTop={0}
      paddingBottom={1}
    >
      <Box h="full" w="full" {...styleProps}>
        <Button
          {...buttonProps}
          isDisabled={isDisabled}
          ref={ref}
          size="sm"
          colorScheme={isInvalid ? "red" : isSelected ? "brand" : "gray"}
          variant={
            isSelected
              ? "solid"
              : isToday(date, getLocalTimeZone())
              ? "outline"
              : "ghost"
          }
          width="full"
          height="full"
          borderRadius="lg"
        >
          {formattedDate}
        </Button>
      </Box>
    </Td>
  );
}
