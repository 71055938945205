import { Heading, HStack, Skeleton, Spacer, Stack } from "@chakra-ui/react";
import { generatePath, Outlet, useParams } from "react-router-dom";
import {
  DeleteButton,
  NavigationTab,
  NavigationTabs,
  OptionsButton,
  PageTitle,
} from "../components";
import { useUserQuery } from "../hooks";
import { namedRoutes } from "../routes";
import { useUserRemoveFromOrgConfirm } from "./hooks/useUserRemoveFromOrgConfirm";

export function UserDetails() {
  const { userId = "" } = useParams();
  const userQuery = useUserQuery({
    userId,
    options: { throwOnError: true },
  });
  const confirmUserRemovalFromOrg = useUserRemoveFromOrgConfirm();

  return (
    <>
      <PageTitle
        title={
          "Manage " +
          (userQuery.data?.full_name ?? userQuery.data?.email ?? "Unknown user")
        }
      />
      <Stack spacing={6}>
        <HStack>
          <Skeleton isLoaded={userQuery.isSuccess}>
            <Heading>{userQuery.data?.full_name}&apos;s details</Heading>
          </Skeleton>
          <Spacer />
          <OptionsButton label="Click on this button to display possible actions">
            <DeleteButton onClick={confirmUserRemovalFromOrg(userQuery.data)}>
              Remove from organization
            </DeleteButton>
          </OptionsButton>
        </HStack>
        <NavigationTabs>
          <NavigationTab
            to={generatePath(namedRoutes.user.overview, { userId })}
            label="Details"
          />
          <NavigationTab
            to={generatePath(namedRoutes.user.activity, { userId })}
            label="Activity"
          />
          <NavigationTab
            to={generatePath(namedRoutes.user.access, { userId })}
            label="Access / Permissions"
          />
        </NavigationTabs>
        <Outlet />
      </Stack>
    </>
  );
}
