import { Button, ButtonProps, Icon } from "@chakra-ui/react";
import { FaPlus as AddIcon } from "react-icons/fa";

export function AddButton(props: ButtonProps) {
  return (
    <Button
      leftIcon={<Icon boxSize={3} as={AddIcon} />}
      colorScheme="brand"
      {...props}
    >
      {props.children}
    </Button>
  );
}
