import { HStack, Icon, Text } from "@chakra-ui/react";
import { CloudProvider } from "../session-management";
import { AWSIcon, AzureIcon, GoogleCloudIcon, ServerIcon } from "./icons";

export function CloudProviderDisplay({
  cloudProvider,
}: {
  cloudProvider?: CloudProvider;
}) {
  return (
    <HStack spacing={2}>
      <Icon
        as={
          cloudProvider === "AWS"
            ? AWSIcon
            : cloudProvider === "Azure"
            ? AzureIcon
            : cloudProvider === "GoogleCloud"
            ? GoogleCloudIcon
            : cloudProvider === "Unmanaged"
            ? ServerIcon
            : undefined
        }
      />
      <Text>
        {cloudProvider === "Unmanaged"
          ? "On-Premises"
          : cloudProvider ?? "Unknown"}
      </Text>
    </HStack>
  );
}
