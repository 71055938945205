import { Box, BoxProps, Text } from "@chakra-ui/react";
import { InputWithCopyToClipboard } from "../../components";
import { useActiveOrganizationQuery } from "../../hooks";

export function RedirectUriInput({
  providerSlug,
  ...props
}: { providerSlug: string } & BoxProps) {
  const { data: organization } = useActiveOrganizationQuery();
  const redirectUri = `${window.location.protocol}//${organization?.domain}/auth/sso/complete/${providerSlug}/`;

  return (
    <Box {...props}>
      <Text>
        When prompted for a{" "}
        <Text fontWeight="bold" display="inline" as="span">
          redirect uri
        </Text>
        , please use the following value.
      </Text>
      <InputWithCopyToClipboard value={redirectUri} />
    </Box>
  );
}
