import {
  RenderElementProps,
  RenderLeafProps,
  RenderPlaceholderProps,
} from "slate-react";
import { Element } from "./Element";
import { Leaf } from "./Leaf";
import { Placeholder } from "./Placeholder";

export const renderElement = (props: RenderElementProps) => (
  <Element {...props} />
);
export const renderLeaf = (props: RenderLeafProps) => <Leaf {...props} />;

export const renderPlaceholder = (props: RenderPlaceholderProps) => (
  <Placeholder {...props} />
);
