import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { omit } from "remeda";
import { RegionsDropDown } from "../../components/RegionsDropDown";
import { VmSizesDropDown } from "../../components/VmSizesDropDown";
import { useActiveOrganizationQuery } from "../../hooks";
import { usePermissions } from "../../hooks/usePermissions";
import { useCloudRenderingRegionsQuery } from "../hooks";
import { UpdateReservedInstanceSchema } from "./reservedInstanceSchema";

export const ManageReservedInstanceGeneralTabForm = ({
  formState,
  control,
  register,
  setValue,
  watch,
}: Pick<
  UseFormReturn<UpdateReservedInstanceSchema>,
  "formState" | "control" | "register" | "setValue" | "watch"
>) => {
  const activeOrganizationQuery = useActiveOrganizationQuery();
  const permissions = usePermissions();
  const { data: regions } = useCloudRenderingRegionsQuery();

  const selectedRegion = watch("region");
  const allowedVmSizes = useMemo(
    () =>
      regions?.find((r) => r.name === selectedRegion)?.supportedVmSizes ?? [],
    [regions, selectedRegion],
  );

  return (
    <Stack spacing={3}>
      <FormControl isInvalid={!!formState.errors.organizationId}>
        <FormLabel>Organization</FormLabel>
        <Controller
          control={control}
          name="organizationId"
          render={({ field: { value, onChange, ...fieldProps } }) => (
            <RadioGroup
              {...fieldProps}
              value={value ? "current" : "global"}
              onChange={(c) => {
                setValue(
                  "organizationId",
                  activeOrganizationQuery.data?.id?.toString() ?? null,
                );
                return onChange(
                  c === "current" ? activeOrganizationQuery.data?.id : null,
                );
              }}
            >
              <HStack>
                <Radio value="current">
                  {activeOrganizationQuery.data?.name}
                </Radio>
                <Radio value="global" isDisabled={!permissions.is_superuser}>
                  Global
                </Radio>
              </HStack>
              <FormHelperText>
                Global instances ensure instance wide availability. Current
                organization ensures availability for the current organization.
              </FormHelperText>
            </RadioGroup>
          )}
        />
        <FormErrorMessage>
          {formState.errors.organizationId?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!formState.errors.region}>
        <FormLabel>Region</FormLabel>
        <Controller
          control={control}
          name="region"
          render={({ field: { onChange, ...otherFields } }) => {
            return (
              <RegionsDropDown
                onChange={(change) => {
                  // set a new time zone as the region was changed
                  if (change?.timeZoneId) {
                    setValue("timeZoneId", change.timeZoneId);
                  }
                  onChange(change?.name);
                }}
                {...omit(otherFields, ["ref"])}
              />
            );
          }}
        />
        <FormErrorMessage>{formState.errors.region?.message}</FormErrorMessage>

        <FormHelperText>
          Cloud rendering region where the resources should be placed. Different
          cloud rendering regions come with different cloud computing cost.
        </FormHelperText>
      </FormControl>

      <HStack>
        <FormControl isInvalid={!!formState.errors.vmSize}>
          <FormLabel>Vm Size</FormLabel>
          <Controller
            control={control}
            name="vmSize"
            render={({ field: fieldProps }) => {
              return (
                <VmSizesDropDown
                  allowedVmSizes={allowedVmSizes}
                  {...omit(fieldProps, ["ref"])}
                />
              );
            }}
          />
          <FormErrorMessage>
            {formState.errors.vmSize?.message}
          </FormErrorMessage>
          <FormHelperText>
            Different VM sizes come at a different cost in different regions.
          </FormHelperText>
        </FormControl>

        <FormControl isInvalid={!!formState.errors.instances}>
          <FormLabel>Instances</FormLabel>
          <Controller
            control={control}
            name="instances"
            render={({ field }) => (
              <Box>
                <NumberInput max={20} min={0} {...field}>
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Box>
            )}
          />
          <FormErrorMessage>
            {formState.errors.instances?.message}
          </FormErrorMessage>
          <FormHelperText>
            Number of instances to be reserved. More instances come at a higher
            cost.
          </FormHelperText>
        </FormControl>
      </HStack>

      <FormControl isInvalid={!!formState.errors.type}>
        <FormLabel>Type</FormLabel>
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <RadioGroup {...field}>
              <HStack>
                <Radio value="Warm">Warm</Radio>
                <Radio value="Hot">Hot</Radio>
              </HStack>
            </RadioGroup>
          )}
        />
        <FormErrorMessage>{formState.errors.type?.message}</FormErrorMessage>
        <FormHelperText>
          Warm instances are in a stopped state which comes with lower cloud
          cost and improves session startup performance. Hot instances are in a
          started state which comes with high cloud cost but yield the best
          session startup performance.
        </FormHelperText>
      </FormControl>

      <FormControl isInvalid={!!formState.errors.description}>
        <FormLabel>Description</FormLabel>
        <Textarea {...register("description")} />
        <FormErrorMessage>
          {formState.errors.description?.message}
        </FormErrorMessage>
      </FormControl>
    </Stack>
  );
};
