import {
  GridItem,
  HStack,
  Heading,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { MdLocationPin as LocationIcon } from "react-icons/md";
import { useCloudRenderingRegionQuery } from "../cloud-rendering/hooks/useCloudRenderingRegionsQuery";
import { Session } from "../session-management";
import { getRenderingType } from "../session-management/utils";
import { CloudRenderingRegionTypeDisplay, NetworkOperatorDisplay } from "./";
import { CloudProviderDisplay } from "./CloudProviderDisplay";
import { RegionDisplay } from "./RegionDisplay";
import { AndroidIcon, CloudRenderingIcon, DesktopClientIcon } from "./icons";

export function SessionRenderingVisualization({
  session,
}: {
  session: Pick<
    Session,
    "appTargetPlatform" | "deviceType" | "renderingRegion"
  >;
}) {
  const region = useCloudRenderingRegionQuery(
    session.renderingRegion ?? undefined,
  );
  const renderingType = useMemo(() => getRenderingType(session), [session]);

  if (renderingType === "cloud") {
    return (
      <Popover trigger="hover" placement="left">
        <HStack>
          <Icon as={CloudRenderingIcon} />
          <Text>
            <PopoverTrigger>
              <Text
                as="span"
                borderBottomWidth={2}
                borderStyle="dotted"
                cursor={"pointer"}
                _hover={{ borderColor: "brand.100" }}
              >
                <RegionDisplay region={session.renderingRegion ?? undefined} />
              </Text>
            </PopoverTrigger>
            <PopoverContent width="sm">
              <PopoverArrow />
              <PopoverBody>
                <SimpleGrid columns={2} gap={2}>
                  <GridItem>
                    <Stack>
                      <Heading size="2xs" as="h4">
                        Location
                      </Heading>
                      <HStack>
                        <Icon as={LocationIcon} />
                        <Text whiteSpace={"normal"}>
                          <RegionDisplay
                            region={session.renderingRegion ?? undefined}
                          />
                        </Text>
                      </HStack>
                    </Stack>
                  </GridItem>
                  <GridItem>
                    <Stack>
                      <Heading size="2xs" as="h4">
                        Provider
                      </Heading>
                      <CloudProviderDisplay
                        cloudProvider={region?.cloudProvider}
                      />
                    </Stack>
                  </GridItem>
                  <GridItem>
                    <Stack>
                      <Heading size="2xs" as="h4">
                        Region Type
                      </Heading>
                      <CloudRenderingRegionTypeDisplay
                        type={region?.type ?? "Region"}
                      />
                    </Stack>
                  </GridItem>
                  <GridItem>
                    <Stack>
                      <Heading size="2xs" as="h4">
                        Network
                      </Heading>
                      <NetworkOperatorDisplay
                        networkOperator={region?.networkOperator}
                      />
                    </Stack>
                  </GridItem>
                </SimpleGrid>
              </PopoverBody>
            </PopoverContent>
          </Text>
        </HStack>
      </Popover>
    );
  }

  return (
    <HStack>
      <Icon
        as={
          session.appTargetPlatform === "Windows"
            ? DesktopClientIcon
            : AndroidIcon
        }
      />
      <Text>Local</Text>
    </HStack>
  );
}
