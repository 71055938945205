import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  FlexProps,
  Heading,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
} from "@chakra-ui/react";
import {
  FaKey as ChangePasswordIcon,
  FaSignOutAlt as LogoutIcon,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { useAuthentication } from "../auth";
import { useBrandingQuery } from "../hooks";
import { namedRoutes } from "../routes";
import { UserProfile } from "./UserProfile";

export function Header(props: FlexProps) {
  const { data: branding } = useBrandingQuery();
  const { user } = useAuthentication();

  return (
    <Flex
      padding={4}
      alignItems="center"
      spacing={2}
      flexDirection="row"
      {...props}
    >
      <Box>
        <Heading as="h2" size="sm" color="GrayText">
          Control Panel
        </Heading>
      </Box>

      <Spacer />

      <Menu matchWidth>
        <MenuButton
          _expanded={{
            borderColor: "gray.200",
          }}
          borderColor="transparent"
          borderWidth={1}
          borderStyle="solid"
          _focus={{ boxShadow: "outline" }}
          marginLeft={2}
          cursor="pointer"
          padding={2}
          borderRadius="md"
          transition="all 0.2s"
          _hover={{ background: "gray.100" }}
          as={UserProfile}
          user={user}
          align="right"
        />
        <MenuList>
          <MenuItem
            as={Link}
            to={namedRoutes.changePassword.request}
            icon={<ChangePasswordIcon />}
          >
            Change Password
          </MenuItem>
          <MenuDivider />
          <MenuItem
            as={"a"}
            href={"/control-panel/portal-frontend/"}
            icon={<ExternalLinkIcon />}
          >
            {branding?.product_name}
          </MenuItem>
          <MenuDivider />
          <MenuItem
            as={Link}
            state={{ from: useLocation() }}
            to={namedRoutes.logout}
            icon={<LogoutIcon />}
          >
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
}
