import { HStack, Text } from "@chakra-ui/react";
import { Fragment, ReactNode, useMemo } from "react";
import { partition } from "remeda";
import { XRPlatformType } from "../../backend/types";
import { TargetPlatformIcon } from "../../components/TargetPlatformDisplay";
import { getXRPlatformDisplayText } from "./getXRPlatformDisplayText";

export function XRPlatformDisplay({
  xrPlatforms,
}: {
  xrPlatforms: XRPlatformType;
}) {
  const displayText = getXRPlatformDisplayText(xrPlatforms);

  return (
    <HStack as="span" display="inline-flex" alignItems="baseline" gap={2}>
      <TargetPlatformIcon
        platform={xrPlatforms.startsWith("win-") ? "windows" : "android"}
        xrPlatform={xrPlatforms}
        alignSelf="center"
      />
      <Text as="span">{displayText}</Text>
    </HStack>
  );
}

export function XRPlatformsDisplay({
  xrPlatforms,
}: {
  xrPlatforms: XRPlatformType[];
}) {
  const [windowsXRPlatforms, otherXRPlatforms] = useMemo(
    () => partition(xrPlatforms, (type) => type.startsWith("win-")),
    [xrPlatforms],
  );

  if (xrPlatforms.length === 0) {
    return (
      <Text as="span" color="chakra-subtle-text">
        No build uploaded
      </Text>
    );
  }

  if (xrPlatforms.length === 1) {
    return <XRPlatformDisplay xrPlatforms={xrPlatforms[0]} />;
  }

  let launchConfigurationsRendered: ReactNode[] = [];
  if (
    windowsXRPlatforms.length &&
    windowsXRPlatforms.every((type) => ["win-vr", "win-non-vr"].includes(type))
  ) {
    launchConfigurationsRendered.push(
      <HStack as="span" display="inline-flex" alignItems="baseline">
        <TargetPlatformIcon platform="windows" alignSelf="center" />
        <Text as="span">VR + Non-VR</Text>
      </HStack>,
    );
  } else {
    launchConfigurationsRendered = windowsXRPlatforms.map((type) => (
      <XRPlatformDisplay key={type} xrPlatforms={type} />
    ));
  }

  otherXRPlatforms.forEach((type) => {
    launchConfigurationsRendered.push(
      <XRPlatformDisplay key={type} xrPlatforms={type} />,
    );
  });

  return (
    <HStack as="span" display="inline-flex" spacing={2}>
      {launchConfigurationsRendered.map((rendered, index, all) => (
        <Fragment key={index}>
          {rendered}
          {index < all.length - 1 && (
            <Text as="span" color="chakra-subtle-text" fontSize={"md"}>
              +
            </Text>
          )}
        </Fragment>
      ))}
    </HStack>
  );
}
