import { chakra, Image, StyleProps } from "@chakra-ui/react";
import { useBrandingQuery } from "../hooks";
import { BrandingData } from "../hooks/useBranding";
import _PortalLogo from "../img/portal-logo.svg?react";

const PortalLogo = chakra(_PortalLogo);

export function OrganizationLogo(props: StyleProps) {
  const { data: branding } = useBrandingQuery();

  return <BrandedOrganizationLogo branding={branding} {...props} />;
}

export function BrandedOrganizationLogo({
  branding,
  ...props
}: StyleProps & { branding?: BrandingData }) {
  if (branding?.logo)
    return <Image src={branding?.logo ?? undefined} {...props} />;
  else return <PortalLogo fill={branding?.primary} {...props} />;
}
