import { useCallback, useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

// shamelessly ripped from https://stackoverflow.com/a/71587163/1142028
// required because react-router-dom-v6 no longer provides useBlocker and usePrompt hooks to prevent user navigation e.g. on unsaved form data.
// also see https://github.com/remix-run/react-router/issues/8139
export function useBlocker(blocker: () => boolean, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }

    const push = navigator.push;

    navigator.push = (...args: Parameters<typeof push>) => {
      const result = blocker();
      if (result !== false) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, blocker, when]);
}

export default function usePrompt({
  message = "Do you want to leave?",
  when = true,
}: { message?: string; when?: boolean } = {}) {
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return message;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when]);

  const confirmExit = useCallback(() => {
    const confirm = window.confirm(message);
    return confirm;
  }, [message]);
  useBlocker(confirmExit, when);
}
