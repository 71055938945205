import { SortingState } from "@tanstack/react-table";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { OrderingParam } from "../utils/ordering-param";

export function useSortingQueryParams(
  defaultSorting: SortingState = [],
): [SortingState, Dispatch<SetStateAction<SortingState>>] {
  const OrderingParamWithDefault = useMemo(
    () => withDefault(OrderingParam, defaultSorting),
    [defaultSorting],
  );
  const [orderingQueryParam, setOrderingQueryParam] = useQueryParam(
    "ordering",
    OrderingParamWithDefault,
    {
      updateType: "replaceIn",
    },
  );
  const [ordering, setOrdering] = useSortingState(orderingQueryParam);

  useEffect(() => {
    // update ordering query parameters
    setOrderingQueryParam(ordering);
  }, [ordering, setOrderingQueryParam]);

  return [ordering, setOrdering];
}

export function useSortingState(defaultSorting: SortingState = []) {
  return useState<SortingState>(defaultSorting);
}
