import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAuthentication } from "../../auth";
import {
  ReservedInstance,
  updateReservedInstance,
  UpdateReservedInstance,
} from "../../session-management";
import { getReservedInstancesQueryKey } from "./useReservedInstancesQuery";

export function useUpdateReservedInstanceMutation({
  onSuccess,
  ...options
}: Omit<
  UseMutationOptions<
    ReservedInstance,
    AxiosError,
    { id: string; change: UpdateReservedInstance }
  >,
  "mutationFn"
>) {
  const { user } = useAuthentication();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      id,
      change,
    }: {
      id: string;
      change: UpdateReservedInstance;
    }) => {
      if (!user) throw new Error("User is not available");

      return updateReservedInstance(
        id,
        change,
        user.id.toString(),
      );
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: getReservedInstancesQueryKey(),
      });

      if (onSuccess) onSuccess(data, variables, context);
    },
    ...options,
  });

  return mutation;
}
