import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { updateCloudRenderingRegion } from "../../session-management";
import { getCloudRegionsQueryKey } from "./useCloudRenderingRegionsQuery";
import { useActiveOrganizationQuery } from "../../hooks";

export interface CloudRenderingRegionUpdate {
  name: string;
  displayName: string;
  enabled: boolean;
  organizationId?: string | null;
}

export function useCloudRenderingRegionMutation({
  ...options
}: Omit<
  UseMutationOptions<void, AxiosError, CloudRenderingRegionUpdate>,
  "mutationFn"
>) {
  const queryClient = useQueryClient();

  const { data: organization } = useActiveOrganizationQuery();

  const mutation = useMutation<void, AxiosError, CloudRenderingRegionUpdate>({
    mutationFn: async (change: CloudRenderingRegionUpdate) => {
      // if not set explicitly
      if (change.organizationId === undefined) {
        change.organizationId = organization?.id?.toString();
      }
      await updateCloudRenderingRegion(
        change.name,
        change.enabled,
        change.organizationId,
      );
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: getCloudRegionsQueryKey(),
      });
    },
    ...options,
  });
  return mutation;
}
