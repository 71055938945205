import { useDebounce } from "use-debounce";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

const defaultSearchParam = withDefault(StringParam, "");

export function useSearchQueryParams({
  queryParamName = "search",
  searchDebounceDelayMs = 300,
}: { queryParamName?: string; searchDebounceDelayMs?: number } = {}) {
  const [search, setSearch] = useQueryParam(
    queryParamName,
    defaultSearchParam,
    { updateType: "replaceIn" },
  );
  // during typing the search text frequently changes but we only want to trigger new API calls 300ms later
  const [debouncedSearchText] = useDebounce(search, searchDebounceDelayMs);
  return { debouncedSearchText, search, setSearch };
}
