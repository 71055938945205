import {
  HStack,
  Heading,
  Spacer,
  Stack,
  StackProps,
  Text,
  VStack,
} from "@chakra-ui/react";
import { NoDataImage } from "./ErrorFallback";

export function NoData({
  title = "No Data",
  text = "There is no data to display.",
  callToAction,
  ...props
}: {
  title?: string | JSX.Element;
  text?: string | JSX.Element;
  callToAction?: JSX.Element;
} & StackProps) {
  return (
    <VStack role="alert" paddingY={10} spacing={4} {...props}>
      <NoDataImage width={64} fill="brand-bg" />
      <Stack spacing={4} alignItems={"inherit"}>
        <Heading size="md">{title}</Heading>
        <Text>{text}</Text>
        <Spacer />
        {callToAction}
      </Stack>
    </VStack>
  );
}

export function NoDataHorizontal({
  title = "No Data",
  text = "There is no data to display.",
  callToAction,
  ...props
}: {
  title?: string | JSX.Element;
  text?: string | JSX.Element;
  callToAction?: JSX.Element;
} & StackProps) {
  return (
    <HStack role="alert" paddingY={2} spacing={4} {...props}>
      <NoDataImage width={24} fill="brand-bg" maxHeight={"full"} />
      <Stack spacing={2}>
        <Heading size="md">{title}</Heading>
        <Text>{text}</Text>
        {callToAction}
      </Stack>
    </HStack>
  );
}
