import { useMemo } from "react";
import {
  applicationUrlRewriteRuleMatchSchema,
  applicationUrlRewriteRuleNameSchema,
  applicationUrlRewriteRuleReplacerSchema,
  applicationUrlRewriteRuleSchema,
} from "../../backend/schema";
import { ApplicationUrlRewriteRule } from "../../backend/types";

export function useRewriteRuleSchema(
  existingRules: Array<ApplicationUrlRewriteRule>,
) {
  return useMemo(
    () =>
      applicationUrlRewriteRuleSchema.shape({
        name: applicationUrlRewriteRuleNameSchema.notOneOf(
          existingRules.map((rule) => rule.name),
          "Name must be unique",
        ),
        match: applicationUrlRewriteRuleMatchSchema.notOneOf(
          existingRules.map((rule) => rule.match),
          "Matcher must be unique",
        ),
        replace: applicationUrlRewriteRuleReplacerSchema.notOneOf(
          existingRules.map((rule) => rule.replace),
          "Replacer must be unique",
        ),
      }),
    [existingRules],
  );
}
