import { PermissionSubject, User, UserGroup } from "../backend";
import { GroupProfile } from "./GroupProfile";
import { UserProfile } from "./UserProfile";

export function UserOrGroupProfile({
  subject,
}: {
  subject: PermissionSubject;
}) {
  return (
    <>
      {subject.type === "user" && <UserProfile user={subject as User} />}
      {subject.type === "group" && (
        <GroupProfile group={subject as UserGroup} />
      )}
    </>
  );
}
