import { Alert, AlertIcon, Heading, VStack } from "@chakra-ui/react";
import { Navigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { AuthorizingAlert, FullPageCenteredContainer } from "../components";
import { useAuthentication } from "./auth";

export default function OAuth2AuthCodeCallback() {
  const { isPendingAuthentication, error } = useAuthentication();
  const [state] = useQueryParam("state", StringParam);

  if (isPendingAuthentication) {
    return (
      <FullPageCenteredContainer>
        <AuthorizingAlert />
      </FullPageCenteredContainer>
    );
  }

  if (error) {
    return (
      <FullPageCenteredContainer>
        <Alert status="error" maxW={"container.sm"}>
          <AlertIcon />
          {error}
        </Alert>
      </FullPageCenteredContainer>
    );
  }

  const [pathname, search] = (state ?? "/").split("?");

  return (
    <>
      <FullPageCenteredContainer>
        <Alert status="info" maxW={"container.sm"}>
          <AlertIcon />
          Redirecting to {state}...
        </Alert>
      </FullPageCenteredContainer>
      <VStack>
        <Heading>Will redirect to {state}...</Heading>
      </VStack>
      <Navigate to={{ pathname, search }} replace />
    </>
  );
}
