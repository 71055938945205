import { createContext, useContext } from "react";
import { User } from "../backend";

interface IAuthenticationContext {
  user?: User;
  token?: string;
  error?: string;
  isPendingAuthentication: boolean;
}

const authentication: IAuthenticationContext = {
  user: undefined,
  token: undefined,
  error: undefined,
  isPendingAuthentication: true,
};

export const AuthenticationContext = createContext(authentication);

export const useAuthentication = () => useContext(AuthenticationContext);
