import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { useAuthentication } from "./auth";
import config from "./config";

Sentry.init({
  dsn: config.sentryDSN,
  environment: import.meta.env.MODE,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  release: `control-panel@${config.version}`,
  tracesSampleRate: 0.5,
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export function SentryEventsEnricher() {
  const { user } = useAuthentication();
  // set user context
  useEffect(() => {
    if (!user) {
      Sentry.setUser(null);
    } else {
      Sentry.setUser({
        email: user.email,
        ip_address: "{{auto}}",
        id: user.id.toString(),
      });
    }
  }, [user]);

  return null;
}
