import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  getApplicationBuild,
  getApplicationBuildsPaginated,
  getLaunchConfigurationsForBuild,
} from "../backend/api";
import {
  ApplicationBuild,
  ApplicationBuildId,
  ApplicationBuildListFilters,
  PaginatedApplicationBuildsList,
} from "../backend/types";

export const baseQueryKeyForApplicationBuilds = ["application-builds"] as const;

export function getQueryKeyForApplicationBuild(applicationBuildId: number) {
  return [...baseQueryKeyForApplicationBuilds, applicationBuildId];
}

export function useApplicationBuildsPaginatedQuery(
  filters: ApplicationBuildListFilters = { page_size: 10 },
  queryOptions: Partial<UseQueryOptions<PaginatedApplicationBuildsList>> = {},
) {
  return useQuery<PaginatedApplicationBuildsList>({
    queryKey: getQueryKeyForApplicationBuildsPaginated(filters),
    queryFn: () => getApplicationBuildsPaginated(filters),
    ...queryOptions,
  });
}

export function getQueryKeyForApplicationBuildsPaginated({
  application,
  supported_xr_platform: xrPlatform,
  ...filters
}: ApplicationBuildListFilters = {}) {
  return [
    ...baseQueryKeyForApplicationBuilds,
    application,
    xrPlatform,
    filters,
  ];
}

export function useApplicationBuildQuery(
  applicationBuildId?: ApplicationBuildId,
  queryOptions: Partial<UseQueryOptions<ApplicationBuild, AxiosError>> = {},
) {
  return useQuery({
    queryKey: getQueryKeyForApplicationBuild(applicationBuildId ?? 0),
    queryFn: () => getApplicationBuild(applicationBuildId ?? 0),
    ...queryOptions,
  });
}

function getQueryKeyForLaunchConfigurationsForApplicationBuild(
  applicationBuildId: ApplicationBuildId,
) {
  return [
    ...getQueryKeyForApplicationBuild(applicationBuildId),
    "launch-configurations",
  ];
}

type PaginatedLaunchConfigurationsForBuild = Awaited<
  ReturnType<typeof getLaunchConfigurationsForBuild>
>;

export function useLaunchConfigurationsForApplicationBuildQuery(
  applicationBuildId?: ApplicationBuildId,
  queryOptions: Partial<
    UseQueryOptions<PaginatedLaunchConfigurationsForBuild>
  > = {},
) {
  return useQuery<PaginatedLaunchConfigurationsForBuild>({
    queryKey: getQueryKeyForLaunchConfigurationsForApplicationBuild(
      applicationBuildId ?? 0,
    ),
    queryFn: () => getLaunchConfigurationsForBuild(applicationBuildId ?? 0),
    ...queryOptions,
  });
}
