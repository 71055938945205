import {
  Button,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef } from "react";
import {
  FaPlus as AddIcon,
  FaTimes as ClearIcon,
  FaSearch as SearchIcon,
} from "react-icons/fa";
import { useSearchQueryParams } from "../hooks";
import { UserGroupOverview } from "./components";
import { UserGroupCreateForm } from "./components/UserGroupCreateForm";

export function UserGroupList() {
  const { debouncedSearchText, search, setSearch } = useSearchQueryParams();
  const firstFieldRef = useRef<HTMLInputElement>(null);
  const userGroupCreationDialogState = useDisclosure();

  return (
    <Stack spacing={6}>
      <Flex justifyContent="space-between">
        <HStack>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={SearchIcon} color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Filter by name..."
              minWidth={"md"}
              value={search}
              onChange={(evt) => setSearch(evt.target.value, "replaceIn")}
            />
            {search && (
              <InputRightElement
                cursor="pointer"
                color="gray.300"
                _hover={{ color: "red" }}
                onClick={() => setSearch("", "replaceIn")}
              >
                <Icon as={ClearIcon} />
              </InputRightElement>
            )}
          </InputGroup>
        </HStack>
        <Popover
          isOpen={userGroupCreationDialogState.isOpen}
          initialFocusRef={firstFieldRef}
          onOpen={userGroupCreationDialogState.onOpen}
          onClose={userGroupCreationDialogState.onClose}
          placement="right"
          closeOnBlur={false}
        >
          <PopoverTrigger>
            <Button
              leftIcon={<Icon boxSize={3} as={AddIcon} />}
              onClick={() => userGroupCreationDialogState.onOpen()}
              colorScheme="brand"
            >
              Create user group
            </Button>
          </PopoverTrigger>
          <PopoverContent p={5}>
            <PopoverArrow />
            <PopoverCloseButton />
            {userGroupCreationDialogState.isOpen && (
              <UserGroupCreateForm firstFieldRef={firstFieldRef} />
            )}
          </PopoverContent>
        </Popover>
      </Flex>

      <UserGroupOverview search={debouncedSearchText} />
    </Stack>
  );
}
