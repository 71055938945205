import { Skeleton, Stack, Text } from "@chakra-ui/react";
import { ApplicationCategory } from "../../backend/types";
import { ApplicationDisplay } from "../../components";
import { useApplicationsQuery } from "../../hooks/useApplicationQuery";

export function ApplicationCategoryDeleteConfirmBody({
  category,
}: {
  category: ApplicationCategory;
}) {
  const applicationsQuery = useApplicationsQuery(
    {
      id_in: category.applications,
      page_size: category.applications.length,
    },
    { enabled: category.applications.length > 0 },
  );

  return (
    <Stack>
      <Text>
        You are about to delete the category &ldquo;{category.name}
        &rdquo;.
      </Text>
      {category.applications.length > 0 && (
        <>
          <Text>
            The following applications currently belong to this category:
          </Text>
          <Stack spacing={2}>
            {applicationsQuery.data?.results.map((application, idx) =>
              application ? (
                <ApplicationDisplay
                  key={idx}
                  size="small"
                  application={application}
                />
              ) : (
                <Skeleton key={idx} />
              ),
            )}
          </Stack>
          <Text>
            Deleting this category will <b>NOT</b> delete the applications
            within it.
          </Text>
        </>
      )}
      <Text>Please be aware that this action cannot be undone.</Text>
    </Stack>
  );
}
