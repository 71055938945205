import { Heading, Stack } from "@chakra-ui/react";
import { useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";
import { NavigationTab, NavigationTabs } from "../components";
import { namedRoutes } from "../routes";
import { UserAccess } from "./UserAccess";
import { UserActivity } from "./UserActivity";
import { UserDetails } from "./UserDetails";
import { UserInfo } from "./UserInfo";
import { UserList } from "./UserList";

export function UserManagementPage() {
  const { groupId, userId } = useParams();
  const isDetailPage = useMemo(() => !!(groupId ?? userId), [groupId, userId]);

  return (
    <Stack spacing={6}>
      {!isDetailPage && (
        <>
          <Heading>User & Group Management</Heading>
          <NavigationTabs>
            <NavigationTab to={namedRoutes.user.list} label="Users" />
            <NavigationTab
              to={namedRoutes.userGroup.list}
              label="User Groups"
            />
          </NavigationTabs>
        </>
      )}

      <Outlet />
    </Stack>
  );
}

export { UserAccess, UserActivity, UserDetails, UserInfo, UserList };
