import { Box, Button, StyleProps, Td } from "@chakra-ui/react";
import {
  CalendarDate,
  getLocalTimeZone,
  isSameDay,
  isToday,
} from "@internationalized/date";
import { useMemo, useRef } from "react";
import { useCalendarCell } from "react-aria";
import { RangeCalendarState } from "react-stately";

export function RangeCalendarCell({
  state,
  date,
  ...styleProps
}: {
  state: RangeCalendarState;
  date: CalendarDate;
} & StyleProps) {
  const ref = useRef(null);
  const { cellProps, buttonProps, isSelected, isInvalid, formattedDate } =
    useCalendarCell({ date }, state, ref);

  const isStartOfSelection = useMemo(
    () =>
      !!state.highlightedRange?.start &&
      isSameDay(state.highlightedRange?.start, date),
    [date, state.highlightedRange?.start],
  );
  const isEndOfSelection = useMemo(
    () =>
      !!state.highlightedRange?.end &&
      isSameDay(state.highlightedRange?.end, date),
    [date, state.highlightedRange?.end],
  );
  const isInSelection = useMemo(
    () =>
      state.highlightedRange &&
      date >= state.highlightedRange.start &&
      date <= state.highlightedRange.end,
    [date, state.highlightedRange],
  );

  return (
    <Td
      {...cellProps}
      textAlign="center"
      height={10}
      width={10}
      boxSizing="content-box"
      paddingX={0}
      paddingTop={0}
      paddingBottom={1}
    >
      <Box
        h="full"
        w="full"
        bgColor={isInSelection ? "brand-bg" : undefined}
        {...styleProps}
        borderLeftRadius={
          styleProps.borderLeftRadius ?? (isStartOfSelection ? "lg" : undefined)
        }
        borderRightRadius={
          styleProps.borderRightRadius ?? (isEndOfSelection ? "lg" : undefined)
        }
      >
        <Button
          {...buttonProps}
          isDisabled={false}
          ref={ref}
          size="sm"
          colorScheme={isInvalid ? "red" : isSelected ? "brand" : "gray"}
          variant={
            isStartOfSelection || isEndOfSelection
              ? "solid"
              : isToday(date, getLocalTimeZone())
                ? "outline"
                : "ghost"
          }
          width="full"
          height="full"
          borderRadius="lg"
        >
          {formattedDate}
        </Button>
      </Box>
    </Td>
  );
}
