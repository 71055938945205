import { array, object, string } from "yup";

export const samlSchema = object({
  SOCIAL_AUTH_SAML_ORG_INFO: object({
    "en-US": object({
      url: string().url().required(),
      name: string().trim().required(),
      displayname: string().trim().required(),
    }).required(),
  }).required(),
  SOCIAL_AUTH_SAML_ENABLED_IDPS: object({
    idp: object({
      url: string().url().required(),
      x509cert: string().trim().required(),
      entity_id: string().trim().required(),
    }),
  }).required(),
  SOCIAL_AUTH_SAML_SP_ENTITY_ID: string().trim().required(),
  SOCIAL_AUTH_SAML_SP_PRIVATE_KEY: string().required(),
  SOCIAL_AUTH_SAML_SP_PUBLIC_CERT: string().required(),
  SOCIAL_AUTH_SAML_SUPPORT_CONTACT: object({
    givenName: string().trim().required(),
    emailAddress: string().trim().email().required(),
  }).required(),
  SOCIAL_AUTH_SAML_TECHNICAL_CONTACT: object({
    givenName: string().trim().required(),
    emailAddress: string().trim().email().required(),
  }).required(),
});

export const oidcSchema = object({
  SOCIAL_AUTH_OIDC_KEY: string().trim().required(),
  SOCIAL_AUTH_OIDC_SECRET: string().trim().required(),
  SOCIAL_AUTH_OIDC_OIDC_ENDPOINT: string().url().trim().required(),
});

export const googleOAuth2Schema = object({
  SOCIAL_AUTH_GOOGLE_OAUTH2_KEY: string().trim().required(),
  SOCIAL_AUTH_GOOGLE_OAUTH2_SECRET: string().trim().required(),
  SOCIAL_AUTH_GOOGLE_OAUTH2_SCOPE: array(string().defined()).optional(),
});

export const azureadSchema = object({
  SOCIAL_AUTH_AZUREAD_OAUTH2_KEY: string()
    .trim()
    .required("Application (Client) ID is required"),
  SOCIAL_AUTH_AZUREAD_OAUTH2_SECRET: string()
    .trim()
    .required("Client Secret is required"),
});

// Options for SSO / Auth:
// - Portal Authentication
// - SAML
// - OpenID Connect
// - Google Workspace
// - Azure AD
// - missing an option?
export const providerSlugs = [
  "google-oauth2",
  "azuread-oauth2",
  "oidc",
  "saml",
] as const;

export type ProviderSlug = (typeof providerSlugs)[number];
export type EnabledProviderSlug = `${ProviderSlug}-enabled`;
export type ProviderDisplayName = `${ProviderSlug}.display_name`;

type JSONValue =
  | null
  | string
  | number
  | boolean
  | { [x: string]: JSONValue }
  | Array<JSONValue>;

export interface AuthProviderData {
  id: number;
  slug: ProviderSlug;
  settings: JSONValue;
  display_name: string;
  enabled: boolean;
}
