import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  enableVirtualMachineDebugMode
} from "../../session-management";
import { VirtualMachine } from "../../session-management/types";
import { getVirtualMachinesQueryKey } from "./useVirtualMachinesQuery";

export function useEnableDebugModeVirtualMachineMutation({
  onSuccess,
  ...options
}: Omit<UseMutationOptions<VirtualMachine, AxiosError, string>, "mutationFn">) {
  const queryClient = useQueryClient();
  const mutation = useMutation<VirtualMachine, AxiosError, string>({
    mutationFn: (id) => enableVirtualMachineDebugMode(id),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: getVirtualMachinesQueryKey(),
      });
      if (onSuccess) onSuccess(data, variables, context);
    },
    ...options,
  });
  return mutation;
}
