import { Navigate } from "react-router-dom";
import { usePermissions } from "../hooks/usePermissions";
import { namedRoutes } from "../routes";

export function LandingPage() {
  const {
    isLoading,
    add_application,
    view_user,
    view_usergroup,
    change_organization,
  } = usePermissions();

  if (isLoading) return null;
  if (view_user && view_usergroup)
    return <Navigate to={namedRoutes.dashboard} />;
  if (add_application) return <Navigate to={namedRoutes.application.list} />;
  if (view_user) return <Navigate to={namedRoutes.user.list} />;
  if (view_usergroup) return <Navigate to={namedRoutes.userGroup.list} />;
  if (change_organization)
    return <Navigate to={namedRoutes.organization.settings} />;

  return <Navigate to={namedRoutes.accessDenied} />;
}
