import dayjs from "dayjs";
import dayjsLocalizedFormat from "dayjs/plugin/localizedFormat";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// dayjs locales
import "dayjs/locale/de";
import "dayjs/locale/en";

dayjs.extend(dayjsLocalizedFormat);

i18next
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: import.meta.env.DEV,
  })
  .then(() => {
    dayjs.locale(i18next.language);
  });
