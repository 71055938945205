import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { ObfuscatedSecretInput } from "../../components";
import { AuthProviderSchema } from "../OrganizationAuthProvidersForm";
import { AuthProviderDisplayNameInput } from "./AuthProviderDisplayNameInput";
import { RedirectUriInput } from "./RedirectUriInput";
import { ProviderSlug } from "./utils";

export function GoogleOAuth2AuthProviderForm() {
  const {
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<AuthProviderSchema>();
  const slug: ProviderSlug = "google-oauth2";

  return (
    <Stack spacing={4}>
      <Text>
        Please see the{" "}
        <Link
          textDecor={"underline"}
          href="https://developers.google.com/identity/protocols/oauth2/web-server#creatingcred"
        >
          official documentation from Google
        </Link>{" "}
        on how to obtain the values below.
      </Text>
      <RedirectUriInput providerSlug={slug} />
      <FormControl
        isInvalid={!!errors["google-oauth2"]?.SOCIAL_AUTH_GOOGLE_OAUTH2_KEY}
        isDisabled={isSubmitting}
      >
        <FormLabel htmlFor="google-oauth2.SOCIAL_AUTH_GOOGLE_OAUTH2_KEY">
          Client ID
        </FormLabel>
        <Input
          placeholder="11111111-1111-1111-1111-111111111"
          {...register("google-oauth2.SOCIAL_AUTH_GOOGLE_OAUTH2_KEY")}
        />
        <FormHelperText>
          The <Text as="i">Client ID</Text> as provided by Google.
        </FormHelperText>
        <FormErrorMessage>
          {errors["google-oauth2"]?.SOCIAL_AUTH_GOOGLE_OAUTH2_KEY?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={!!errors["google-oauth2"]?.SOCIAL_AUTH_GOOGLE_OAUTH2_SECRET}
        isDisabled={isSubmitting}
      >
        <FormLabel htmlFor="google-oauth2.SOCIAL_AUTH_GOOGLE_OAUTH2_SECRET">
          Client Secret
        </FormLabel>
        <ObfuscatedSecretInput
          placeholder="Enter client secret ..."
          {...register("google-oauth2.SOCIAL_AUTH_GOOGLE_OAUTH2_SECRET")}
        />
        <FormHelperText>
          The <Text as="i">Client Secret</Text> configured within Google&apos;s
          API console for the client application. Please refer to the{" "}
          <Link href="https://developers.google.com/identity/protocols/oauth2/web-server#creatingcred">
            official documentation on how to obtain
          </Link>
          .
        </FormHelperText>
        <FormErrorMessage>
          {errors["google-oauth2"]?.SOCIAL_AUTH_GOOGLE_OAUTH2_SECRET?.message}
        </FormErrorMessage>
        <AuthProviderDisplayNameInput providerSlug={slug} />
      </FormControl>
    </Stack>
  );
}
