import dayjs from "dayjs";
import {
  NetworkSecurityRule,
  NetworkSecurityRuleDirection,
} from "../backend/types";
import { PortRule } from "./";
import { Session } from "./types";

export const mapNetworkSecurityRuleToSmType = (
  data: NetworkSecurityRule,
): PortRule => {
  return {
    description: data.description,
    fromPort: data.from_port,
    toPort: data.to_port,
    direction:
      data.direction === NetworkSecurityRuleDirection.Inbound
        ? "Inbound"
        : "Outbound",
    ipProtocol:
      data.protocol === "any" ? "Any" : data.protocol === "udp" ? "Udp" : "Tcp",
    cidrBlocks: data.cidr_blocks,
  };
};

export function getRenderingType(
  session: Pick<Session, "deviceType" | "appTargetPlatform">,
): "local" | "cloud" {
  if (session.appTargetPlatform === "Windows") {
    if (
      session.deviceType === "Browser" ||
      session.deviceType === "VrStandalone"
    ) {
      return "cloud";
    }
  }
  return "local";
}

export function getSessionDuration(
  session: Pick<
    Session,
    "sessionTerminatedDateTime" | "experienceStartedDateTime"
  >,
): number {
  return dayjs(session.sessionTerminatedDateTime ?? new Date()).diff(
    session.experienceStartedDateTime,
  );
}
