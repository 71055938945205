import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAuthentication } from "../../auth";
import {
  createReservedInstance,
  ReservedInstance,
  UpdateReservedInstance,
} from "../../session-management";
import { getReservedInstancesQueryKey } from "./useReservedInstancesQuery";

export function useCreateReservedInstanceMutation({
  onSuccess,
  ...options
}: Omit<
  UseMutationOptions<ReservedInstance, AxiosError, UpdateReservedInstance>,
  "mutationFn"
>) {
  const { user } = useAuthentication();
  const queryClient = useQueryClient();
  const mutation = useMutation<
    ReservedInstance,
    AxiosError,
    UpdateReservedInstance
  >({
    mutationFn: (reservedInstanceData) => {
      if (!user) throw new Error("User is not available");

      return createReservedInstance(reservedInstanceData, user.id.toString());
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: getReservedInstancesQueryKey(),
      });
      if (onSuccess) onSuccess(data, variables, context);
    },
    ...options,
  });
  return mutation;
}
