import { ViewIcon, ViewOffIcon, WarningIcon } from "@chakra-ui/icons";
import { BsSlashCircleFill } from "react-icons/bs";
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Box,
  Button,
  Flex,
  HStack,
  Skeleton,
  Spacer,
  Spinner,
  Stack,
  Switch,
  Tag,
  Text,
  Tooltip,
  Wrap,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { UseQueryResult } from "@tanstack/react-query";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CloudProviderDisplay,
  CloudRenderingRegionTypeDisplay,
  EditButton,
  NetworkOperatorDisplay,
  OptionsButton,
  PaginatedTable,
} from "../../components";
import { Region, ReservedInstance } from "../../session-management";
import {
  useCloudRenderingRegionMutation,
  useCloudRenderingRegionsQuery,
  useFixedIps,
  useVmSizesQuery,
} from "../hooks";
import { EditRegionModal } from "./EditRegionModal";
import { FixedIpDownload } from "./FixedIpUtils";

const columnHelper = createColumnHelper<Region>();

function useReservedInstancesTable({
  cloudRegionsQuery,
  editRegion,
}: {
  cloudRegionsQuery: UseQueryResult<Region[], unknown>;
  editRegion: (region: string) => void;
}) {
  const toast = useToast();
  const fixedIps = useFixedIps();
  const vmSizes = useVmSizesQuery();
  const globalCloudRegionsQuery = useCloudRenderingRegionsQuery({
    includeDisabledRegions: true,
    organizationId: null,
  });

  const regionsMutation = useCloudRenderingRegionMutation({
    onSuccess: (data, variables) => {
      const disabledAndFixedIps =
        !variables.enabled &&
        fixedIps.data?.some((ip) => ip.region === variables.name);
      toast({
        title: variables.enabled ? "Enabled region" : "Disabled region",
        description: variables.enabled
          ? `Enabled ${variables.displayName} region.`
          : disabledAndFixedIps
            ? `Disabled ${variables.displayName} region. Fixed IP's must be deleted manually.`
            : `Disabled ${variables.displayName} region.`,
        status: disabledAndFixedIps ? "warning" : "success",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    },
    onError: (data, variables) => {
      toast({
        title: "Error",
        description: `Could not save ${variables.displayName} update.`,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    },
  });

  const globalRegionEnabled = useMemo(() => {
    var regions: { [id: string]: boolean } = {};

    if (globalCloudRegionsQuery.data) {
      regions = Object.fromEntries(
        globalCloudRegionsQuery.data.map((c) => [c.name, c.isEnabled]),
      );
    }

    return regions;
  }, [globalCloudRegionsQuery.data]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("isEnabled", {
        header: "Usable",
        cell: (props) => {
          return (
            <Stack direction="row" align="center">
              <Tooltip
                label="Globally disabled"
                hidden={globalRegionEnabled[props.row.original.name]}
              >
                <Box>
                  <Switch
                    isChecked={props.row.original.isEnabled}
                    isDisabled={
                      regionsMutation.isPending ||
                      !globalRegionEnabled[props.row.original.name]
                    }
                    onChange={async (change) => {
                      regionsMutation.mutateAsync({
                        name: props.row.original.name,
                        displayName: props.row.original.displayName,
                        enabled: change.target.checked,
                      });
                    }}
                  />
                </Box>
              </Tooltip>
              <Spinner
                opacity={
                  regionsMutation.isPending &&
                  regionsMutation.variables?.name === props.row.original.name
                    ? 1
                    : 0
                }
              />
            </Stack>
          );
        },
      }),
      columnHelper.accessor("displayName", {
        header: "Name",
      }),
      columnHelper.accessor("cloudProvider", {
        header: "Cloud Provider",
        cell: (props) => (
          <Tag>
            <CloudProviderDisplay cloudProvider={props.getValue()} />
          </Tag>
        ),
      }),
      columnHelper.accessor("type", {
        header: "Type",
        cell: (props) => (
          <Tag>
            <CloudRenderingRegionTypeDisplay type={props.getValue()} />
          </Tag>
        ),
      }),
      columnHelper.accessor("networkOperator", {
        header: "Access Network",
        cell: (props) => (
          <Tag>
            <NetworkOperatorDisplay networkOperator={props.getValue()} />
          </Tag>
        ),
      }),
      columnHelper.display({
        id: "fixedIps",
        header: "Fixed IPs",
        cell: (props) => {
          const fixedIpsCount = fixedIps.data.filter(
            (r) => r.region === props.row.original.name,
          ).length;

          if (props.row.original.type === "Unmanaged") {
            return (
              <Tooltip label="Unmanaged regions do not support management of fixed-ips">
                <Text>n/a</Text>
              </Tooltip>
            );
          }

          return (
            <HStack>
              <Text>{fixedIpsCount}</Text>
              {fixedIpsCount > 0 && !props.row.original.isEnabled && (
                <Tooltip label="Fixed IP's still allocated and paid for.">
                  <WarningIcon color="orange" boxSize={5} />
                </Tooltip>
              )}
            </HStack>
          );
        },
      }),
      columnHelper.display({
        id: "vmSizes",
        header: "VM Sizes",
        cell: (props) => (
          <Wrap spacing={2}>
            {props.row.original.supportedVmSizes.map((vmSize, idx) => (
              <Tag key={idx}>
                {vmSizes.data?.find((c) => c.name === vmSize)?.displayName}
              </Tag>
            ))}
          </Wrap>
        ),
      }),
      columnHelper.display({
        id: "options",
        cell: (props) => {
          return (
            <Flex justifyContent={"end"}>
              <OptionsButton
                label="Click on this button to display user actions"
                isDisabled={regionsMutation.isPending}
              >
                <EditButton
                  variant="ghost"
                  width="full"
                  onClick={() => {
                    editRegion(props.row.original.name);
                  }}
                  fontWeight="normal"
                  fontSize="sm"
                >
                  Edit
                </EditButton>
                <Button
                  variant="ghost"
                  fontWeight="normal"
                  fontSize="sm"
                  w="full"
                  hidden={!globalRegionEnabled[props.row.original.name]}
                  colorScheme={props.row.original.isEnabled ? "red" : "green"}
                  onClick={async () => {
                    regionsMutation.mutateAsync({
                      name: props.row.original.name,
                      displayName: props.row.original.displayName,
                      enabled: !props.row.original.isEnabled,
                    });
                  }}
                  leftIcon={
                    props.row.original.isEnabled ? (
                      <ViewOffIcon />
                    ) : (
                      <ViewIcon />
                    )
                  }
                >
                  {props.row.original.isEnabled ? "Disable" : "Enable"}
                </Button>
                <Button
                  variant="ghost"
                  fontWeight="normal"
                  fontSize="sm"
                  w="full"
                  colorScheme={globalRegionEnabled[props.row.original.name] ? "red" : "green"}
                  onClick={async () => {
                    regionsMutation.mutateAsync({
                      name: props.row.original.name,
                      displayName: props.row.original.displayName,
                      enabled: !globalRegionEnabled[props.row.original.name],
                      organizationId: null,
                    });
                  }}
                  leftIcon={
                    globalRegionEnabled[props.row.original.name] ? (
                      <BsSlashCircleFill />
                    ) : (
                      <ViewIcon />
                    )
                  }
                >
                  {globalRegionEnabled[props.row.original.name]
                    ? "Globally disable"
                    : "Globally enable"}
                </Button>
              </OptionsButton>
            </Flex>
          );
        },
        header: () => <Flex justifyContent={"end"}>Options</Flex>,
      }),
    ],
    [
      editRegion,
      fixedIps.data,
      globalRegionEnabled,
      regionsMutation,
      vmSizes.data,
    ],
  );

  const table = useReactTable({
    columns: useMemo(
      () =>
        cloudRegionsQuery.isLoading
          ? columns.map((col, idx) => ({
              ...col,
              cell: () => <Skeleton key={idx}>Loading</Skeleton>,
            }))
          : columns,
      [columns, cloudRegionsQuery.isLoading],
    ),
    data: useMemo(
      () =>
        cloudRegionsQuery.isLoading
          ? Array(5).fill({} as ReservedInstance)
          : [...(cloudRegionsQuery.data ?? [])],
      [cloudRegionsQuery.data, cloudRegionsQuery.isLoading],
    ),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      pagination: {
        pageSize: 30,
      },
      sorting: [
        {
          id: "displayName",
          desc: false,
        },
      ],
    },
  });

  return table;
}

export function RegionsTable() {
  const toast = useToast();
  const editDialog = useDisclosure();
  const changeAll = useDisclosure();
  const [editedRegion, setEditedRegion] = useState<string | undefined>();
  const cloudRegionsQuery = useCloudRenderingRegionsQuery({
    includeDisabledRegions: true,
  });
  const fixedIps = useFixedIps();
  const regionsMutation = useCloudRenderingRegionMutation({});
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [isChangeAllLoading, setIsLoading] = useState(false);
  const allRegionsDisabled = useMemo(
    () => cloudRegionsQuery.data?.every((r) => !r.isEnabled),
    [cloudRegionsQuery.data],
  );

  const onChangeAllRegions = useCallback(async () => {
    setIsLoading(true);

    for (const region of cloudRegionsQuery.data ?? []) {
      if (region.isEnabled !== allRegionsDisabled) {
        await regionsMutation.mutateAsync({
          name: region.name,
          displayName: region.displayName,
          enabled: allRegionsDisabled ?? false,
        });
      }
    }
    setIsLoading(false);
    changeAll.onClose();
  }, [allRegionsDisabled, changeAll, cloudRegionsQuery.data, regionsMutation]);

  useEffect(() => {
    if (cloudRegionsQuery.isError) {
      toast({
        title: "Could not load regions.",
        description: `Could not load regions. Please try again later.`,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [cloudRegionsQuery, toast]);

  const table = useReservedInstancesTable({
    cloudRegionsQuery: cloudRegionsQuery,
    editRegion: (region) => {
      setEditedRegion(region);
      editDialog.onOpen();
    },
  });

  const fixedIpInDisabledRegions = useMemo(() => {
    for (const region of cloudRegionsQuery.data ?? []) {
      if (!region.isEnabled) {
        if (fixedIps.data.some((ip) => ip.region === region.name)) {
          return true;
        }
      }
    }
    return false;
  }, [cloudRegionsQuery.data, fixedIps.data]);

  return (
    <>
      <Stack spacing={4}>
        <HStack>
          <Text>
            Manage your organization&apos;s regions availability to match your
            IT rules. You can enable/disable regions here. If a region is
            disabled, you cannot use it for cloud rendering.
          </Text>
          <Spacer />
          <Tooltip
            label="Waiting for all IPs to be available"
            hidden={fixedIps.allIpsAvailable}
          >
            <Button
              colorScheme="brand"
              hidden={fixedIps.isSuccess && fixedIps.data.length === 0}
              isLoading={!fixedIps.isSuccess}
              isDisabled={!fixedIps.allIpsAvailable}
              onClick={() => FixedIpDownload(fixedIps.data)}
              flexShrink={0}
            >
              Download Fixed IPs
            </Button>
          </Tooltip>
          <Button
            colorScheme="brand"
            isLoading={isChangeAllLoading}
            onClick={() => changeAll.onOpen()}
            flexShrink={0}
          >
            {allRegionsDisabled ? "Enable" : "Disable"} all regions
          </Button>

          <AlertDialog
            isOpen={changeAll.isOpen}
            leastDestructiveRef={cancelRef}
            onClose={changeAll.onClose}
            closeOnEsc={!isChangeAllLoading}
            closeOnOverlayClick={!isChangeAllLoading}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  {allRegionsDisabled ? "Enable" : "Disable"} all regions
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure that you want to{" "}
                  {allRegionsDisabled ? "enable" : "disable"} all regions? You
                  can revert this at any time.
                  {!allRegionsDisabled && (
                    <Alert status="warning" mt={4}>
                      <AlertIcon />
                      All regions will be disabled. All Reserved Instances will
                      be disabled. Fixed IP&apos;s will not be released and will
                      still create charges.
                    </Alert>
                  )}
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button
                    ref={cancelRef}
                    onClick={changeAll.onClose}
                    isLoading={isChangeAllLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={onChangeAllRegions}
                    ml={3}
                    isLoading={isChangeAllLoading}
                  >
                    {allRegionsDisabled ? "Enable" : "Disable"} all
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </HStack>

        {fixedIpInDisabledRegions && (
          <Alert status="warning" ml={4}>
            <AlertIcon />
            Some regions are disabled but still have Fixed IP&apos;s which will
            incur charges. Delete the Fixed IP&apos;s manually if you do not
            need them anymore.
          </Alert>
        )}
        <PaginatedTable table={table} />
      </Stack>
      <EditRegionModal
        isOpen={editDialog.isOpen}
        onClose={() => {
          editDialog.onClose();
          setEditedRegion(undefined);
        }}
        region={editedRegion ?? ""}
      />
    </>
  );
}
