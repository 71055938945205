import { Box, Flex, Heading, HStack, Skeleton } from "@chakra-ui/react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useDashboardParameters } from "../context";
import { useSessionsCountInTimespanQuery } from "../hooks/useSessionsCountInTimespanQuery";
import { useTimeSettings } from "../hooks/useTimeSettings";
import { useTimeSeriesBarChartOptions } from "./chart-options";
import { FailedLoadingData } from "./FailedLoadingData";

dayjs.extend(duration);

export function SessionsTrend() {
  const { from, to, ...filters } = useDashboardParameters();
  const timeSettings = useTimeSettings(from, to);
  const {
    isError,
    isLoading,
    data: results,
  } = useSessionsCountInTimespanQuery({
    ...timeSettings,
    ...filters,
  });
  const chartOptions = useTimeSeriesBarChartOptions({
    unitOfTime: timeSettings.unitOfTime,
    yAxisTitle: "Sessions",
  });

  const data = useMemo(() => {
    return (results ?? []).map<{ x: string | number; y: number }>((res) => ({
      x: res.key,
      y: res.count ?? 0,
    }));
  }, [results]);

  return (
    <Flex direction="column" h="100%">
      <HStack>
        <Heading size="sm">Number of sessions</Heading>
        <Heading size="sm" as="h4" color="menu-text" fontWeight={"normal"}>
          over time
        </Heading>
      </HStack>
      <Box width="100%" flexGrow={1}>
        {isError && <FailedLoadingData h="100%" justifyContent={"center"} />}
        {!isError && (
          <Skeleton w="100%" h="100%" isLoaded={!isLoading}>
            <ReactApexChart
              type="bar"
              series={[
                {
                  name: "Sessions",
                  data,
                },
              ]}
              options={{
                ...chartOptions,
              }}
            />
          </Skeleton>
        )}
      </Box>
    </Flex>
  );
}
