import { Alert, Heading, Skeleton } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import * as R from "remeda";
import {
  Application,
  ApplicationBuildId,
  ApplicationLaunchConfiguration,
} from "../backend/types";
import { ErrorBoundary } from "../components";
import { FailedLoadingData } from "../dashboard/charts/FailedLoadingData";
import { useTimeSeriesBarChartOptions } from "../dashboard/charts/chart-options";
import { useSessionsCountInTimespanQuery } from "../dashboard/hooks/useSessionsCountInTimespanQuery";
import { useTimeSettings } from "../dashboard/hooks/useTimeSettings";
import { useDateRange } from "../hooks";
import { SessionListContextProvider } from "../sessions/SessionListContextProvider";
import { useApplicationDetailsContext } from "./ApplicationDetailsContext";
import { ApplicationSessionOverview } from "./components/ApplicationSessionOverview";

function ApplicationBuildPopularityOverTime({
  applicationBuildId: applicationId,
  startDate,
  endDate,
}: {
  applicationBuildId: ApplicationBuildId;
  startDate: Date;
  endDate: Date;
}) {
  const { unitOfTime } = useTimeSettings(startDate, endDate);
  const {
    isError,
    isLoading,
    data: results,
  } = useSessionsCountInTimespanQuery({
    endDate,
    startDate,
    unitOfTime,
    appId: applicationId,
  });
  const chartOptions = useTimeSeriesBarChartOptions({
    yAxisTitle: "Sessions",
    unitOfTime,
  });

  const data = useMemo(() => {
    return (results ?? []).map<{ x: string | number; y: number }>((res) => ({
      x: res.key,
      y: res.count,
    }));
  }, [results]);

  if (isError) {
    return <FailedLoadingData justifyContent={"center"} />;
  }

  return (
    <Skeleton width="100%" isLoaded={!isLoading}>
      <ReactApexChart
        type="bar"
        series={[
          {
            name: "Sessions",
            data,
          },
        ]}
        options={chartOptions}
        height="350px"
      />
    </Skeleton>
  );
}

/**
 * Given an application, returns the "current" build id for that application.
 * FIXME: Since technically there are multiple current builds (one for each launch configuration), this can be very misleading and should be used with caution.
 * @param application The application for which to get the "current" build
 */
function useApplicationBuildId(application: Application) {
  type LaunchableApplicationLaunchConfiguration = Omit<
    ApplicationLaunchConfiguration,
    "application_build"
  > & {
    application_build: ApplicationBuildId;
  };

  const buildIdsGrouped = R.pipe(
    // get all build ids of any known launch configuration
    application.launch_configurations,
    // filter out invalid launch configurations without a build id
    R.filter(
      (
        launchConfig,
      ): launchConfig is LaunchableApplicationLaunchConfiguration =>
        launchConfig.application_build !== null,
    ),
    // prioritize windows builds
    R.sort((a, _) => (a.xr_platform.startsWith("win-") ? -1 : 1)),
    // we only need the build ids
    R.map((launchConfig) => launchConfig.application_build),
    // group by build id to find the most prominent one
    R.groupBy((buildId) => buildId),
  );
  const mostProminentBuild = R.pipe(
    R.entries(buildIdsGrouped),
    // return the one that appears the most often
    R.firstBy([([_, group]) => group.length, "desc"]),
  );

  if (!mostProminentBuild) {
    return undefined;
  }

  return Number(mostProminentBuild[0]);
}

export function ApplicationActivity() {
  const { application } = useApplicationDetailsContext();
  const applicationBuildId = useApplicationBuildId(application);

  const {
    startDate,
    endDate,
    updateDateRange,
    defaultDateRange,
    minDate,
    maxDate,
  } = useDateRange({
    fromDate: useMemo(
      () => dayjs().subtract(30, "days").startOf("day").toDate(),
      [],
    ),
    toDate: useMemo(() => dayjs().endOf("day").toDate(), []),
  });

  if (!applicationBuildId) {
    return <Alert status="warning">No activity without a build.</Alert>;
  }

  return (
    <ErrorBoundary>
      <Heading size="sm">Popularity over time</Heading>
      <ApplicationBuildPopularityOverTime
        applicationBuildId={applicationBuildId}
        startDate={startDate}
        endDate={endDate}
      />
      <Heading size="sm">Latest Sessions</Heading>
      <SessionListContextProvider
        applicationBuildId={applicationBuildId}
        from={startDate}
        to={endDate}
      >
        <ApplicationSessionOverview
          updateDateRange={updateDateRange}
          defaultDateRange={defaultDateRange}
          minDate={minDate}
          maxDate={maxDate}
        />
      </SessionListContextProvider>
    </ErrorBoundary>
  );
}
