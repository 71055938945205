import { Skeleton, Text } from "@chakra-ui/react";
import { UseQueryResult } from "@tanstack/react-query";
import { ReactNode } from "react";
import { ApplicationCategory, User, UserGroup } from "../backend/types";
import { useUserGroupQuery } from "../dashboard/hooks/useUserGroups";
import { useUserQuery } from "../hooks";
import { useApplicationCategoryQuery } from "../hooks/useApplicationCategoriesQuery";

function isValidReactNode(
  value: object | string | number | boolean | null | undefined,
): value is ReactNode {
  return value?.constructor !== Object;
}

function LazyDisplayName<
  T extends Record<
    string,
    object | string | number | boolean | null | undefined
  >,
>({ query, fieldName }: { fieldName: keyof T; query: UseQueryResult<T> }) {
  if (query.isError) {
    return <Text>Error</Text>;
  }

  const data = query.data ? query.data[fieldName] : undefined;

  return (
    <Skeleton isLoaded={query.isSuccess}>
      <Text>
        {data
          ? isValidReactNode(data)
            ? data
            : "Unsupported value"
          : "Loading"}
      </Text>
    </Skeleton>
  );
}

export function UserName({ id: userId }: Pick<User, "id">) {
  const userQuery = useUserQuery({
    userId,
    options: { retry: false, retryOnMount: false, refetchOnWindowFocus: false },
  });
  return <LazyDisplayName query={userQuery} fieldName="full_name" />;
}

export function UserGroupName({ id }: Pick<UserGroup, "id">) {
  const userGroupQuery = useUserGroupQuery(id);
  return <LazyDisplayName query={userGroupQuery} fieldName="name" />;
}

export function ApplicationCategoryName({
  id,
}: Pick<ApplicationCategory, "id">) {
  const applicationCategoryQuery = useApplicationCategoryQuery(id, {
    retry: false,
    refetchOnWindowFocus: false,
  });
  return <LazyDisplayName query={applicationCategoryQuery} fieldName="name" />;
}
