import {
  AbsoluteCenter,
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { OrganizationLogo } from "../components";
import { loadDataFromElement } from "../utils/hydrate-data";
import { PasswordResetForm } from "./PasswordResetForm";
import { SSOButtonGroup } from "./SSOButtonGroup";
import { authProviders } from "./authProviders";

const errorMessage = loadDataFromElement<string | undefined>(
  "error",
  undefined,
);

function SetupAccountLayout({ children }: { children: React.ReactNode }) {
  return (
    <Stack spacing="6" maxWidth={"lg"} alignSelf={"center"}>
      <OrganizationLogo maxWidth={"3xs"} />
      {children}
    </Stack>
  );
}

export function AccountSetup() {
  const [useEmailAndPassword, setUseEmailAndPassword] = useState(false);

  if (errorMessage) {
    return (
      <SetupAccountLayout>
        <Alert status="warning">
          <AlertIcon />
          {errorMessage}
        </Alert>
      </SetupAccountLayout>
    );
  }

  return (
    <SetupAccountLayout>
      <Text>
        You need to set up your account before you can access the platform.
      </Text>
      {authProviders.length && !useEmailAndPassword ? (
        <>
          <Stack spacing="6" flexBasis={"full"}>
            <Stack spacing="2">
              <Heading size={{ base: "xs", md: "sm" }}>Setup account</Heading>
              <Heading
                size={{ base: "2xs", md: "xs" }}
                fontWeight={"normal"}
                color="chakra-subtle-text"
              >
                using
              </Heading>
            </Stack>
            <Stack spacing="4" position={"relative"}>
              <SSOButtonGroup />
              <Box position="relative" paddingY={8}>
                <Divider orientation="horizontal" />
                <AbsoluteCenter
                  bg="chakra-body-bg"
                  fontSize={"sm"}
                  color="chakra-subtle-text"
                  axis={"both"}
                >
                  or
                </AbsoluteCenter>
              </Box>
              <Button
                width="full"
                variant="outline"
                display="flex"
                alignItems={"center"}
                onClick={() => setUseEmailAndPassword(true)}
              >
                Use e-mail and password
              </Button>
            </Stack>
          </Stack>
        </>
      ) : (
        <Stack>
          <PasswordResetForm />
          {authProviders.length && (
            <>
              <Box position="relative" paddingY={8}>
                <Divider orientation="horizontal" />
                <AbsoluteCenter
                  bg="chakra-body-bg"
                  fontSize={"sm"}
                  color="chakra-subtle-text"
                  axis={"both"}
                >
                  or
                </AbsoluteCenter>
              </Box>
              <Button
                width="full"
                variant="outline"
                display="flex"
                alignItems={"center"}
                onClick={() => setUseEmailAndPassword(false)}
              >
                Use SSO instead
              </Button>
            </>
          )}
        </Stack>
      )}
    </SetupAccountLayout>
  );
}
