import { useQuery } from "@tanstack/react-query";
import { useActiveOrganizationQuery } from "../../hooks";
import { getTimeZones } from "../../session-management";

export function useTimeZonesQuery() {
  const { data: organization } = useActiveOrganizationQuery();

  return useQuery({
    queryKey: ["time-zones"],
    queryFn: async () => getTimeZones(),
    enabled: !!organization,
    retry: false,
    throwOnError: true,
  });
}
