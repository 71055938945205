import { Heading, Stack, StackDivider, Text } from "@chakra-ui/react";
import { LinkButton } from "../components";
import { useBrandingQuery } from "../hooks";
import { namedRoutes } from "../routes";
import { loadDataFromElement } from "../utils/hydrate-data";

const next = loadDataFromElement<string | undefined>("next", undefined);

export function LogoutSuccessful() {
  const { data: branding } = useBrandingQuery();

  return (
    <Stack spacing="6">
      <Heading size={{ base: "xs", md: "sm" }}>Logout successful</Heading>
      <Text>
        You have been successfully logged out of {branding?.company_name}{" "}
        {branding?.product_name}.
      </Text>
      <StackDivider />
      <Text>Didn&apos;t mean to log out?</Text>
      <LinkButton to={next ?? namedRoutes.landing} colorScheme="brand">
        Log in again
      </LinkButton>
    </Stack>
  );
}
