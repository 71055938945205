import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  forwardRef,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { SketchPicker } from "react-color";

interface ColorInputProps extends InputProps {
  value: string | undefined;
  onColorChange: (color: string) => void;
}

export const ColorInput = forwardRef<ColorInputProps, "input">(
  ({ value, onColorChange, ...props }, ref) => {
    const {
      isOpen: isColorPickerOpen,
      onToggle: onToggleColorPicker,
      onClose: onCloseColorPicker,
      onOpen: onOpenColorPicker,
    } = useDisclosure();
    const [color, setColor] = useState(value);

    return (
      <Popover isOpen={isColorPickerOpen} onClose={onCloseColorPicker}>
        <InputGroup display="inline-flex" width="auto">
          <InputLeftElement
            padding={1.5}
            cursor={"pointer"}
            onClick={onToggleColorPicker}
          >
            <Box
              bgColor={color ?? value}
              borderRadius={"sm"}
              height="100%"
              width="100%"
            />
          </InputLeftElement>
          <Input
            placeholder="#00000"
            width="xs"
            ref={ref}
            onFocus={onOpenColorPicker}
            onBlur={onCloseColorPicker}
            {...props}
          />

          <PopoverTrigger>
            <InputRightElement cursor={"pointer"} onClick={onToggleColorPicker}>
              <PopoverAnchor>
                {(isColorPickerOpen && <ChevronUpIcon boxSize={5} />) || (
                  <ChevronDownIcon boxSize={5} />
                )}
              </PopoverAnchor>
            </InputRightElement>
          </PopoverTrigger>
        </InputGroup>
        <PopoverContent width="auto" padding={0} background="#fff">
          <PopoverArrow sx={{ background: "#fff !important" }} />
          {/* <PopoverCloseButton /> */}
          <PopoverBody>
            <SketchPicker
              color={color ?? value}
              onChangeComplete={(color) => onColorChange(color.hex)}
              onChange={(color) => {
                setColor(color.hex);
              }}
              styles={{
                picker: {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  backgroundColor: "#fff",
                  width: "200px",
                },
              }}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  },
);
