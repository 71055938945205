import { Stack, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Select } from "chakra-react-select";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { keys } from "remeda";
import { updateApplicationCloudRenderingConfiguration } from "../../backend/api";
import { applicationUrlRewriteRuleSchema } from "../../backend/schema";
import {
  ApplicationCloudRenderingConfiguration,
  ApplicationId,
  ApplicationUrlRewriteRule,
} from "../../backend/types";
import { useConfirm } from "../../confirm-dialog";
import { getQueryKeyForApplicationCloudRenderingConfiguration } from "../../hooks/useApplicationCloudRenderingConfigurationQuery";
import { extractFormSubmissionErrors } from "../../utils/extractFormSubmissionErrors";
import { RewriteRuleForm } from "./RewriteRuleForm";
import { rewritePresets } from "./rewritePresets";
import { useRewriteRuleSchema } from "./useRewriteRuleSchema";

interface RewriteRulePresetOption {
  readonly value: string;
  readonly label: string;
  readonly data: ApplicationUrlRewriteRule;
}

const customRuleOption: RewriteRulePresetOption = {
  value: "custom",
  label: "Custom",
  data: {
    enabled: true,
    isPreset: false,
    description: "",
    name: "",
    match: "",
    replace: "",
  },
};

const rewriteRulePresetOptions: readonly RewriteRulePresetOption[] =
  rewritePresets
    .map<RewriteRulePresetOption>((preset) => ({
      value: preset.name,
      label: preset.name,
      data: {
        ...preset,
        enabled: true,
        isPreset: true,
      },
    }))
    .concat(customRuleOption);

export function RewriteRuleCreateForm({
  applicationId,
  existingRules,
  onSuccess,
}: {
  existingRules: Array<ApplicationUrlRewriteRule>;
  applicationId: ApplicationId;
  onSuccess?: () => void;
}) {
  const { confirm } = useConfirm();
  const queryClient = useQueryClient();
  const { mutateAsync: updateCloudRenderingConfigurationAsync } = useMutation({
    mutationFn: (data: ApplicationUrlRewriteRule) =>
      updateApplicationCloudRenderingConfiguration(applicationId, {
        url_rewrites: { rules: [...existingRules, data] },
      }),
    onMutate: (data) => {
      // backup the current state
      const previousData =
        queryClient.getQueryData<ApplicationCloudRenderingConfiguration>(
          getQueryKeyForApplicationCloudRenderingConfiguration(applicationId),
        );

      // Optimistically update the UI
      queryClient.setQueryData<ApplicationCloudRenderingConfiguration>(
        getQueryKeyForApplicationCloudRenderingConfiguration(applicationId),
        (existingConfiguration) => ({
          ...existingConfiguration,
          vm_size: existingConfiguration?.vm_size ?? null,
          url_rewrites: {
            ...existingConfiguration?.url_rewrites,
            rules: [...existingRules, data],
          },
        }),
      );

      return { previousData };
    },
    onError: (_error, _variables, context) => {
      form.reset(undefined, { keepValues: true, keepDirty: true });
      // rollback the optimistic update
      queryClient.setQueryData<ApplicationCloudRenderingConfiguration>(
        getQueryKeyForApplicationCloudRenderingConfiguration(applicationId),
        context?.previousData,
      );
    },
    onSuccess: () => {
      form.reset(undefined, { keepValues: true, keepIsSubmitted: true });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey:
          getQueryKeyForApplicationCloudRenderingConfiguration(applicationId),
      });
    },
  });
  const { handleSubmit, setError, formState, register, ...form } =
    useForm<ApplicationUrlRewriteRule>({
      mode: "onChange",
      resolver: yupResolver(useRewriteRuleSchema(existingRules)),
      defaultValues: {
        isPreset: false,
      },
    });
  const onSubmit = useCallback(
    async (values: ApplicationUrlRewriteRule) => {
      try {
        await updateCloudRenderingConfigurationAsync(values);
        onSuccess?.();
      } catch (err) {
        extractFormSubmissionErrors(
          err,
          applicationUrlRewriteRuleSchema,
        ).forEach(([field, message]) =>
          setError(field, { type: "server", message }),
        );
      }
    },
    [updateCloudRenderingConfigurationAsync, onSuccess, setError],
  );

  return (
    <Stack spacing={4}>
      <Text>
        Select a preset below or create a new rewrite rule from scratch.
      </Text>
      <Select
        options={rewriteRulePresetOptions.filter(
          // filter out rules that are already in the list
          (option) =>
            existingRules.find((rule) => rule.name === option.value) ===
            undefined,
        )}
        defaultValue={customRuleOption}
        onChange={(value) => {
          if (!value) return;

          function setPresetValues(preset: RewriteRulePresetOption) {
            keys(preset.data).forEach((key) => {
              form.setValue(key, preset.data[key], {
                shouldDirty: true,
              });
            });

            form.reset(undefined, {
              keepValues: true,
              keepDirty: true,
            });
          }

          if (Object.values(formState.dirtyFields).some(Boolean)) {
            confirm({
              title: "Replace form values?",
              body: "Do you want to replace the form values with the preset?",
              onConfirm: async () => setPresetValues(value),
            });
            return;
          }

          setPresetValues(value);
        }}
        placeholder="Select a preset or create a new rule"
      />
      <RewriteRuleForm
        form={{ formState, handleSubmit, register, setError, ...form }}
        onSubmit={onSubmit}
      />
    </Stack>
  );
}
