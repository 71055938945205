import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { ObfuscatedSecretInput } from "../../components";
import { AuthProviderSchema } from "../OrganizationAuthProvidersForm";
import { AuthProviderDisplayNameInput } from "./AuthProviderDisplayNameInput";
import { RedirectUriInput } from "./RedirectUriInput";
import { ProviderSlug } from "./utils";

export function OIDCAuthProviderForm() {
  const {
    formState: { errors, isSubmitting },
    register,
    watch,
  } = useFormContext<AuthProviderSchema>();
  const slug: ProviderSlug = "oidc";
  const oidcEndpointWellKnownConfiguration = `${watch(
    "oidc.SOCIAL_AUTH_OIDC_OIDC_ENDPOINT",
  )}/.well-known/openid-configuration`;

  return (
    <Stack spacing={4}>
      <Text>
        Please refer to your existing Identity Provider&apos;s (IDP)
        documentation on how to obtain the required configuration parameters
        below to setup an OpenID Connect (OIDC) provider.
      </Text>
      <RedirectUriInput providerSlug={slug} />
      <FormControl
        isInvalid={!!errors.oidc?.SOCIAL_AUTH_OIDC_OIDC_ENDPOINT}
        isDisabled={isSubmitting}
      >
        <FormLabel htmlFor="oidc.SOCIAL_AUTH_OIDC_OIDC_ENDPOINT">
          OpenID Connect Endpoint
        </FormLabel>
        <Input
          placeholder="https://idp.example.org/protocol/oidc/"
          {...register("oidc.SOCIAL_AUTH_OIDC_OIDC_ENDPOINT")}
        />
        <FormHelperText>
          The endpoint used to perform authentication &amp; authorization using
          OIDC. This endpoint must provide a configuration of the OIDC provider
          when opening{" "}
          <Link href={oidcEndpointWellKnownConfiguration} target="_blank">
            .well-known/openid-configuration
          </Link>
          .
        </FormHelperText>
        <FormErrorMessage>
          {errors.oidc?.SOCIAL_AUTH_OIDC_OIDC_ENDPOINT?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={!!errors.oidc?.SOCIAL_AUTH_OIDC_KEY}
        isDisabled={isSubmitting}
      >
        <FormLabel htmlFor="oidc.SOCIAL_AUTH_OIDC_KEY">Client ID</FormLabel>
        <Input
          placeholder="11111111-1111-1111-1111-111111111"
          {...register("oidc.SOCIAL_AUTH_OIDC_KEY")}
        />
        <FormHelperText>
          The <Text as="i">Client ID</Text> as provided by Google.
        </FormHelperText>
        <FormErrorMessage>
          {errors.oidc?.SOCIAL_AUTH_OIDC_KEY?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={!!errors.oidc?.SOCIAL_AUTH_OIDC_SECRET}
        isDisabled={isSubmitting}
      >
        <FormLabel htmlFor="oidc.SOCIAL_AUTH_OIDC_SECRET">
          Client Secret
        </FormLabel>
        <ObfuscatedSecretInput
          placeholder="Enter client secret ..."
          {...register("oidc.SOCIAL_AUTH_OIDC_SECRET")}
        />
        <FormHelperText>
          The <Text as="i">Client Secret</Text> configured within Google&apos;s
          API console for the client application. Please refer to the{" "}
          <Link href="https://developers.google.com/identity/protocols/oauth2/web-server#creatingcred">
            official documentation on how to obtain
          </Link>
          .
        </FormHelperText>
        <FormErrorMessage>
          {errors.oidc?.SOCIAL_AUTH_OIDC_SECRET?.message}
        </FormErrorMessage>
      </FormControl>
      <AuthProviderDisplayNameInput providerSlug={slug} />
    </Stack>
  );
}
