import { UseQueryResult } from "@tanstack/react-query";
import { useMemo } from "react";

export function useMergedQueriesState(queries: UseQueryResult[]) {
  return useMemo(
    () => ({
      isLoading: queries.some((q) => q.isLoading),
    }),
    [queries],
  );
}
