import { Alert, AlertIcon, Grid, GridItem, Skeleton } from "@chakra-ui/react";
import { useQueries } from "@tanstack/react-query";
import { Fragment } from "react";
import { Link, generatePath } from "react-router-dom";
import { PermissionExpiry } from "../../applications/components";
import { ApplicationId } from "../../backend/types";
import {
  GroupProfile,
  PermissionListLoader,
  PermissionSelect,
  UserProfile,
} from "../../components";
import { MessageFromAxiosError } from "../../components/MessageFromAxiosError";
import {
  getQueryForGroup,
  getQueryForUser,
  useApplicationPermissionMutation,
  useGroupPermissionsForApplicationQuery,
  useUserPermissionsForApplicationQuery,
} from "../../hooks";
import { namedRoutes } from "../../routes";

export function ApplicationPermissionManagement({
  applicationId,
}: {
  applicationId: ApplicationId;
}) {
  const permissionMutation = useApplicationPermissionMutation(applicationId);
  const {
    data: userPermissionsForApp,
    isLoading: isUserPermissionsLoading,
    error: userPermissionsError,
    isError: isUserPermissionsError,
  } = useUserPermissionsForApplicationQuery(applicationId);
  // FIXME: would be better to have one api call to get all the users
  const userPermissionsForAppEnriched = useQueries({
    queries: (userPermissionsForApp ?? []).map((item) => {
      return getQueryForUser(item.user);
    }),
    combine: (results) =>
      (userPermissionsForApp ?? []).map((userPermissionForApp) => ({
        ...userPermissionForApp,
        userDetails: results.find(
          (query) => query.data && query.data.id === userPermissionForApp.user,
        )?.data,
      })),
  });

  const {
    data: groupPermissionsForApp,
    isLoading: isGroupPermissionsLoading,
    isError: isGroupPermissionsError,
    error: groupPermissionsError,
  } = useGroupPermissionsForApplicationQuery(applicationId);
  // FIXME: would be better to have one api call to get all the groups
  const groupPermissionsForAppEnriched = useQueries({
    queries: (groupPermissionsForApp ?? []).map((item) =>
      getQueryForGroup(item.group),
    ),
    combine: (results) =>
      (groupPermissionsForApp ?? []).map((groupPermissionForApp) => ({
        ...groupPermissionForApp,
        groupDetails: results.find(
          (query) =>
            query.data && query.data.id === groupPermissionForApp.group,
        )?.data,
      })),
  });

  const existingPermissions = [
    ...groupPermissionsForAppEnriched,
    ...userPermissionsForAppEnriched,
  ];

  if (isGroupPermissionsLoading || isUserPermissionsLoading) {
    return <PermissionListLoader type="user" />;
  }

  return (
    <>
      {isUserPermissionsError && (
        <Alert status="error">
          <AlertIcon />
          <MessageFromAxiosError
            error={userPermissionsError}
            message="Error while fetching user permissions"
          />
        </Alert>
      )}
      {isGroupPermissionsError && (
        <Alert status="error">
          <AlertIcon />
          <MessageFromAxiosError
            error={groupPermissionsError}
            message="Error while fetching group permissions"
          />
        </Alert>
      )}
      <Grid
        gridTemplateColumns="minmax(var(--chakra-sizes-52), max-content) minmax(var(--chakra-sizes-md), max-content) 1fr"
        gridTemplateRows="auto"
        columnGap={4}
        rowGap={2}
        alignItems={"center"}
      >
        {existingPermissions?.map((permission) => {
          const expiryDate = permission.valid_until
            ? new Date(permission.valid_until)
            : undefined;
          const subjectType = "user" in permission ? "user" : "group";
          const subjectId =
            "user" in permission ? permission.user : permission.group;

          return (
            <Fragment key={subjectId}>
              <GridItem>
                {"user" in permission ? (
                  <Link
                    to={generatePath(namedRoutes.user.overview, {
                      userId: subjectId.toString(),
                    })}
                  >
                    <UserProfile
                      user={permission.userDetails}
                      textProps={{ _hover: { textDecoration: "underline" } }}
                      cursor="pointer"
                    />
                  </Link>
                ) : (
                  <Link
                    to={generatePath(namedRoutes.userGroup.overview, {
                      groupId: subjectId.toString(),
                    })}
                  >
                    <GroupProfile
                      group={permission.groupDetails}
                      _hover={{ textDecoration: "underline" }}
                      cursor="pointer"
                    />
                  </Link>
                )}
              </GridItem>
              <GridItem>
                <PermissionSelect
                  applicationId={applicationId}
                  organizationId={permission.organization}
                  permissions={permission.permissions}
                  subject={{ type: subjectType, id: subjectId }}
                  validUntil={expiryDate}
                />
              </GridItem>
              <GridItem>
                <Skeleton isLoaded={!permissionMutation.isPending}>
                  <PermissionExpiry
                    applicationId={applicationId}
                    organizationId={permission.organization}
                    permissions={permission.permissions}
                    subject={{ type: subjectType, id: subjectId }}
                    validUntil={expiryDate}
                  />
                </Skeleton>
              </GridItem>
            </Fragment>
          );
        })}
      </Grid>
    </>
  );
}
