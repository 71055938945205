import { Box, Button, Flex, HStack, Stack } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { AxiosError } from "axios";
import { useCallback, useMemo, useState } from "react";
import { Link, generatePath } from "react-router-dom";
import { User } from "../../backend";
import { addUsersToGroup } from "../../backend/api";
import {
  LinkButton,
  OptionsButton,
  PaginatedTable,
  RemoveUserFromGroupButton,
  UserProfile,
} from "../../components";
import { UserOption, UserSelect } from "../../components/UserSelect";
import { usePaginationQueryParams } from "../../hooks";
import {
  getQueryKeyForGroup,
  useGroupUsersQuery,
} from "../../hooks/useGroupQuery";
import { getQueryKeyForUser } from "../../hooks/useUserQuery";
import { namedRoutes } from "../../routes";
import { useUserColumns } from "../../users/hooks/useUserColumns";

const columnHelper = createColumnHelper<User>();

export function GroupUserMemberships({
  groupId,
}: {
  groupId: number | string;
}) {
  const queryClient = useQueryClient();
  const [pagination, setPagination] = usePaginationQueryParams();
  const usersQuery = useGroupUsersQuery(groupId ?? 0, {
    page: pagination.pageIndex + 1,
    page_size: pagination.pageSize,
  });
  const columns = useMemo(
    () => [
      columnHelper.accessor("full_name", {
        id: "email",
        header: "User",
        cell: (props) => (
          <Link
            to={generatePath(namedRoutes.user.overview, {
              userId: props.row.original.id.toString(),
            })}
          >
            <UserProfile
              user={props.row.original}
              textProps={{ _hover: { textDecoration: "underline" } }}
              cursor="pointer"
            />
          </Link>
        ),
      }),
      columnHelper.display({
        id: "options",
        cell: (props) => (
          <Flex justifyContent={"end"}>
            <OptionsButton label="Click on this button to display user actions">
              <LinkButton
                to={generatePath(namedRoutes.user.overview, {
                  userId: props.row.original.id.toString(),
                })}
                fontWeight="normal"
                fontSize="sm"
                variant="ghost"
                w="full"
              >
                Show users&apos;s details
              </LinkButton>
              <RemoveUserFromGroupButton
                groupId={groupId}
                userId={props.row.original.id}
              />
            </OptionsButton>
          </Flex>
        ),
        header: () => <Flex justifyContent={"end"}>Options</Flex>,
      }),
    ],
    [groupId],
  );
  const table = useReactTable({
    columns: useUserColumns(columns, usersQuery.isLoading),
    data: useMemo<User[]>(
      () =>
        usersQuery.isLoading
          ? Array(pagination.pageSize).fill({} as User)
          : usersQuery.data ?? [],
      [usersQuery.isLoading, usersQuery.data, pagination.pageSize],
    ),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
    pageCount: usersQuery.count
      ? Math.ceil(usersQuery.count / pagination.pageSize)
      : undefined,
  });
  const [usersToBeAdded, setUsersToBeAdded] = useState<UserOption[]>([]);
  const addUserToGroupMutation = useMutation<void, AxiosError, UserOption[]>({
    mutationFn: async (users) => {
      await addUsersToGroup(
        groupId,
        users.map((userOption) => userOption.value),
      );
    },
    onSuccess: (_, userOptions) => {
      // refetch user details after adding them to groups
      userOptions.forEach((userOption) =>
        queryClient.invalidateQueries({
          queryKey: getQueryKeyForUser(userOption.value).slice(0, 2),
        }),
      );
      // refetch group users after adding users to the group
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForGroup(groupId).slice(0, 2),
      });
    },
  });

  return (
    <Stack maxW={"lg"} spacing={3}>
      <HStack>
        <Box flexGrow={1}>
          <UserSelect
            placeholder="Find user(s) to add to group ..."
            onChange={(values) => setUsersToBeAdded(values.slice())}
            value={usersToBeAdded}
            filter={useCallback(
              (user: User) =>
                !usersQuery.data.find((_user) => _user.id === user.id),
              [usersQuery],
            )}
          />
        </Box>
        <Button
          size="sm"
          isDisabled={!usersToBeAdded.length}
          isLoading={addUserToGroupMutation.isPending}
          onClick={async () => {
            await addUserToGroupMutation.mutateAsync(usersToBeAdded);
            setUsersToBeAdded([]);
          }}
        >
          Add to group
        </Button>
      </HStack>
      <PaginatedTable table={table} />
    </Stack>
  );
}
