import { ExternalLinkIcon, SearchIcon, SmallCloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonProps,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  FaChevronDown as ChevronDownIcon,
  FaBuilding as OrganizationIcon,
  FaCheck as SelectedIcon,
} from "react-icons/fa";
import { Organization } from "../backend";
import { useFilteredUserOrganizations } from "../hooks";
import { useActiveOrganizationQuery } from "../hooks/useActiveOrganization";
import { useBrandingForOrganizationQuery } from "../hooks/useBranding";

function OrganizationListIcon({
  active,
  organizationData,
}: {
  active: boolean;
  organizationData: Organization;
}) {
  const brandingQuery = useBrandingForOrganizationQuery(organizationData.id, {
    refetchOnWindowFocus: false,
  });

  if (active) {
    return <SelectedIcon />;
  }

  return (
    <Box width="1em">
      {brandingQuery.data?.logo && (
        <Image src={brandingQuery.data?.logo} alt={organizationData.name} />
      )}
    </Box>
  );
}

const organizationCountFilterThreshold = 5;

export function OrganizationSwitcher(props: ButtonProps) {
  const { data: activeOrganization } = useActiveOrganizationQuery();
  const [search, setSearch] = useState("");
  const filteredOrganizations = useFilteredUserOrganizations(search);

  return (
    <Popover matchWidth onClose={() => setSearch("")}>
      <PopoverTrigger>
        <Button
          as={Button}
          leftIcon={<OrganizationIcon />}
          justifyContent="normal"
          variant="ghost"
          fontSize="sm"
          textDecorationColor="transparent"
          background="chakra-body-bg"
          {...props}
        >
          <HStack w="full">
            <Box>{activeOrganization?.name}</Box>
            <Spacer />
            <ChevronDownIcon />
          </HStack>
        </Button>
      </PopoverTrigger>
      <PopoverContent width="full">
        <Stack>
          {(search ||
            filteredOrganizations.length >
              organizationCountFilterThreshold) && (
            <Box padding={1.5}>
              <InputGroup size="sm">
                <Input
                  placeholder="Type to filter ..."
                  value={search}
                  onChange={(evt) => setSearch(evt.currentTarget.value)}
                />
                <InputRightElement
                  onClick={search ? () => setSearch("") : undefined}
                  cursor={search ? "pointer" : undefined}
                >
                  {!search ? (
                    <SearchIcon boxSize={4} />
                  ) : (
                    <Tooltip title="Clear search">
                      <SmallCloseIcon boxSize={4} />
                    </Tooltip>
                  )}
                </InputRightElement>
              </InputGroup>
            </Box>
          )}
          <Stack maxHeight={"sm"} overflow="auto">
            {filteredOrganizations.length >= 1 && (
              <>
                {filteredOrganizations.map((organization) => (
                  <Button
                    flexShrink={0}
                    justifyContent="space-between"
                    fontWeight={
                      organization.id === activeOrganization?.id
                        ? "bold"
                        : "normal"
                    }
                    variant="ghost"
                    key={organization.id}
                    leftIcon={
                      <OrganizationListIcon
                        active={organization.id === activeOrganization?.id}
                        organizationData={organization}
                      />
                    }
                    onClick={
                      organization.id !== activeOrganization?.id
                        ? () => {
                            window.location.assign(
                              window.location.href.replace(
                                activeOrganization?.domain ?? "",
                                organization.domain,
                              ),
                            );
                          }
                        : undefined
                    }
                    role="group"
                    cursor={
                      organization.id === activeOrganization?.id
                        ? "not-allowed"
                        : "pointer"
                    }
                  >
                    <HStack width="full">
                      <Box>{organization.name}</Box>
                      <Spacer />
                      <ExternalLinkIcon
                        boxSize={3}
                        display="none"
                        _groupHover={{
                          display:
                            organization.id !== activeOrganization?.id
                              ? "block"
                              : undefined,
                        }}
                      />
                    </HStack>
                  </Button>
                ))}
              </>
            )}
          </Stack>
        </Stack>
      </PopoverContent>
    </Popover>
  );
}
