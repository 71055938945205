import { FormState } from "react-hook-form";
import usePrompt from "./usePrompt";

export function usePromptDirtyForm({
  message = "Do you want to leave?",
  formState: { isDirty, isSubmitting },
}: {
  message?: string;
  formState: Pick<FormState<never>, "isSubmitting" | "isDirty">;
}) {
  return usePrompt({ message, when: isDirty && !isSubmitting });
}
