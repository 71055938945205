import dayjs from "dayjs";
import { SessionStatsGroupByFilter } from "../../session-management";

export function getTimeSpanDisplay(
  date: dayjs.ConfigType,
  timespanType: SessionStatsGroupByFilter,
) {
  const parseDate = dayjs(date);
  switch (timespanType) {
    case "day":
      return parseDate.format("L");

    case "month":
      return parseDate.format("MMM YY");

    case "year":
      return parseDate.format("YYYY");
    default:
      return "n/a";
  }
}
