import { BreadcrumbLink } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";

export function BreadcrumbNavLink({
  to,
  children,
}: {
  children: ReactNode;
  to: string;
}) {
  const isCurrentPage = useLocation().pathname === to;
  return (
    <BreadcrumbLink
      as={Link}
      to={isCurrentPage ? "#" : to}
      isCurrentPage={isCurrentPage}
    >
      {children}
    </BreadcrumbLink>
  );
}
