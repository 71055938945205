import { useDashboardParameters } from "../context";
import { SessionCountInTimeSpan } from "./SessionCountInTimeSpan";

export function TotalSessionsInTimeSpan() {
  const { from, to, ...filters } = useDashboardParameters();

  return (
    <SessionCountInTimeSpan
      from={from}
      to={to}
      title={"Total"}
      filters={filters}
    />
  );
}
