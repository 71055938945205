import { InfoIcon } from "@chakra-ui/icons";
import {
  Button,
  CircularProgress,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { InferType } from "yup";
import { ApplicationBuild } from "../../backend";
import {
  createApplicationBuild,
  updateApplicationBuild,
} from "../../backend/api";
import { FormRootErrorMessage } from "../../components";
import { Step, StepProps } from "../../components/Stepper";
import { useSkipStep } from "../../components/StepperContext";
import { DevTool } from "../../hookform-devtools";
import { usePromptDirtyForm } from "../../hooks";
import { extractFormSubmissionErrors } from "../../utils/extractFormSubmissionErrors";
import { useAddApplicationContext } from "../AddApplicationContext";
import { LaunchConfigurationsPicker } from "../components";
import { getQueryKeyForApplicationLaunchConfigurationsQuery } from "../hooks/useApplicationLaunchConfigurationsQuery";
import { applicationBuildMetaDataSchema } from "./schema";
import { getDefaultXRPlatforms, getPossibleXRPlatforms } from "./utils";

const applicationBuildDataSchema = applicationBuildMetaDataSchema.pick([
  "application_archive",
  "version",
  "supported_xr_platforms",
]);

type ApplicationBuildMetaDataSchema = InferType<
  typeof applicationBuildDataSchema
>;

export function AddApplicationMetadataStep({ ...props }: StepProps) {
  const {
    state: {
      upload: { fileUrl = "", inProgress: isUploading, originalFileName },
      baseData: { application: applicationId },
      applicationBuild: { id: applicationBuildId },
    },
    dispatch,
  } = useAddApplicationContext();
  const {
    handleSubmit,
    setError,
    register,
    setValue,
    formState,
    getFieldState,
    trigger,
    control,
  } = useForm<ApplicationBuildMetaDataSchema>({
    resolver: yupResolver(applicationBuildDataSchema),
    mode: "onChange",
    defaultValues: { version: "1.0.0" },
  });
  const { errors, isSubmitting, isValid, dirtyFields, isDirty } = formState;
  const queryClient = useQueryClient();

  const { goToNext, goToPrev } = props;

  // Skip validation if no build uploaded
  useSkipStep(props, !fileUrl && !isUploading);

  // prevent the user from closing the tab while uploading
  usePromptDirtyForm({ formState: { isSubmitting, isDirty } });

  useEffect(() => {
    // pass the application archive to the form
    if (fileUrl) {
      setValue("application_archive", fileUrl, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [setValue, fileUrl, getFieldState, trigger]);

  useEffect(() => {
    if (dirtyFields.supported_xr_platforms || !fileUrl) return;
    // update the default supported launch configurations (if not set yet)
    setValue(
      "supported_xr_platforms",
      getDefaultXRPlatforms({
        application_archive: fileUrl,
      }),
      { shouldDirty: false, shouldValidate: true },
    );
  }, [setValue, fileUrl, dirtyFields.supported_xr_platforms]);

  const { mutateAsync: upsertApplicationBuildAsync } = useMutation<
    ApplicationBuild,
    AxiosError,
    ApplicationBuildMetaDataSchema
  >({
    mutationFn: async (data) => {
      if (!applicationId) {
        throw new Error("Application ID is required");
      }

      return await (applicationBuildId
        ? updateApplicationBuild(applicationBuildId, data)
        : createApplicationBuild({ ...data, application: applicationId }));
    },
    onSuccess: async (applicationBuild) => {
      dispatch({
        type: "APPLICATION_BUILD_CREATE:SUCCESS",
        data: applicationBuild,
      });
      await queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationLaunchConfigurationsQuery(
          applicationBuild.application,
        ),
      });
      goToNext && goToNext();
    },
  });

  const onSubmit = useCallback(
    async (values: ApplicationBuildMetaDataSchema) => {
      try {
        await upsertApplicationBuildAsync(values);
      } catch (err: unknown) {
        extractFormSubmissionErrors(err, applicationBuildDataSchema).forEach(
          ([field, message]) => setError(field, { type: "server", message }),
        );
      }
    },
    [upsertApplicationBuildAsync, setError],
  );

  return (
    <Step
      icon={
        isSubmitting ? (
          <CircularProgress
            size={8}
            isIndeterminate
            color="brand.500"
            bg="modal-bg"
          />
        ) : undefined
      }
      actionButtons={
        <HStack>
          <Button size="sm" onClick={goToPrev}>
            Back
          </Button>
          <Tooltip
            hasArrow={true}
            label={
              !fileUrl
                ? "Please wait until your application archive is successfully uploaded"
                : undefined
            }
          >
            <Button
              size="sm"
              colorScheme={"brand"}
              isLoading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              isDisabled={!isValid}
            >
              Save
            </Button>
          </Tooltip>
        </HStack>
      }
      {...props}
      title={"Provide information"}
    >
      <Text>
        While your upload is being processed, please share some details about
        your application.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <FormControl isInvalid={!!errors.application_archive}>
            <Input type="hidden" {...register("application_archive")} />
            <FormErrorMessage>
              {errors.application_archive?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.supported_xr_platforms}>
            <FormLabel htmlFor="supported_xr_platforms">
              Platform Support
            </FormLabel>
            <FormHelperText marginBottom={2}>
              Which (XR) platforms are supported by this build?{" "}
              <Tooltip label="Sessions running on any of the platforms selected here will use the newly uploaded build from now on.">
                <InfoIcon />
              </Tooltip>
            </FormHelperText>
            <Controller
              control={control}
              render={({ field: { ref, ...fieldProps } }) => (
                <LaunchConfigurationsPicker
                  {...fieldProps}
                  values={getPossibleXRPlatforms({
                    application_archive: originalFileName,
                  })}
                />
              )}
              name="supported_xr_platforms"
            />
            <FormErrorMessage>
              {errors.supported_xr_platforms?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.version}>
            <FormLabel htmlFor="version">Version</FormLabel>
            <InputGroup width={32}>
              <InputLeftAddon>v</InputLeftAddon>
              <Input
                id="version"
                placeholder="1.0.x"
                {...register("version")}
              />
            </InputGroup>
            <FormErrorMessage>{errors.version?.message}</FormErrorMessage>
          </FormControl>
          <FormRootErrorMessage formState={formState} />

          {/* Hidden submit button to ensure form can be submitted with return key */}
          <Button type="submit" hidden></Button>
        </Stack>
      </form>
      <DevTool control={control} placement="bottom-left" />
    </Step>
  );
}
