import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Divider,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  Skeleton,
  Spacer,
  Stack,
  StackProps,
  Text,
} from "@chakra-ui/react";
import { useMemo, useRef, useState } from "react";
import { Organization } from "../backend";
import { ExternalLinkButton, LinkButton } from "../components";
import { HttpErrorImage } from "../components/ErrorFallback";
import { OrganizationIcon } from "../components/GroupProfile";
import { useCurrentUserQuery, useFilteredUserOrganizations } from "../hooks";
import {
  BrandingData,
  useBrandingForOrganizationQuery,
} from "../hooks/useBranding";
import { CSRFTokenInput } from "./CSRFTokenInput";

const organizationCountSearchThreshold = 5;

const loginPath = "/auth/login/";

function BaseOrganizationItem({
  isLoading = false,
  organization,
  branding,
  onClick,
}: {
  isLoading: boolean;
  organization: Pick<Organization, "name">;
  branding?: BrandingData;
  onClick?: StackProps["onClick"];
}) {
  return (
    <HStack
      onClick={onClick}
      role="group"
      spacing={4}
      cursor="pointer"
      borderBottom="1px"
      borderColor="chakra-border-color"
      paddingY={2}
    >
      <Skeleton
        display="flex"
        justifyContent={"center"}
        alignItems="center"
        height={16}
        width={16}
        isLoaded={!isLoading}
      >
        {branding?.logo ? (
          <Image
            src={branding?.logo}
            alt={organization.name}
            maxHeight={"full"}
          />
        ) : (
          <OrganizationIcon aria-label={organization.name} />
        )}
      </Skeleton>
      <Heading size="sm">{organization.name}</Heading>
      <Spacer />
      <IconButton
        _groupHover={{ opacity: 1 }}
        transition="all 300 ms"
        opacity={0}
        aria-label="Select organization"
        icon={<ArrowForwardIcon />}
        _hover={{
          color: branding?.primaryContrast ?? "chakra-body-text",
          bgColor: branding?.primary ?? "chakra-primary-color",
        }}
      />
    </HStack>
  );
}

function OrganizationItem({
  organization,
  onClick,
}: {
  organization: Organization;
  onClick: StackProps["onClick"];
}) {
  const organizationBrandingQuery = useBrandingForOrganizationQuery(
    organization.id,
  );

  return (
    <BaseOrganizationItem
      organization={organization}
      isLoading={organizationBrandingQuery.isLoading}
      branding={organizationBrandingQuery.data}
      onClick={onClick}
    />
  );
}

export function OrganizationSelectForm() {
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    data: user,
    isLoading,
    error,
  } = useCurrentUserQuery({ retry: false });

  const [search, setSearch] = useState<string>("");
  const filteredOrganizations = useFilteredUserOrganizations(search);
  const additionalOrganizationsCount = useMemo(
    () => filteredOrganizations.length - organizationCountSearchThreshold,
    [filteredOrganizations],
  );

  const isSearchShown = useMemo(
    () => additionalOrganizationsCount > 0 || search !== "",
    [additionalOrganizationsCount, search],
  );

  // if not authenticated login first
  if (error?.response?.status === 401) {
    return (
      <Stack textAlign="center" spacing={6}>
        <HttpErrorImage statusCode={401} maxWidth="full" />
        <Heading
          display="inline-block"
          as="h2"
          size="2xl"
          bgGradient="linear(to-r, blue.400, blue.600)"
          backgroundClip="text"
        >
          Oops!
        </Heading>
        <Text>It looks like you are not logged in. Please log in first.</Text>
        <Divider />
        <ExternalLinkButton
          rightIcon={undefined}
          href={loginPath + "?next=" + window.location.pathname}
        >
          Login
        </ExternalLinkButton>
      </Stack>
    );
  }

  if (user && !user?.organizations.length) {
    return (
      <Stack textAlign="center" spacing={6}>
        <HttpErrorImage statusCode={403} maxWidth="full" />
        <Heading
          display="inline-block"
          as="h2"
          size="2xl"
          bgGradient="linear(to-r, blue.400, blue.600)"
          backgroundClip="text"
        >
          Oops!
        </Heading>
        <Text>It looks like you&apos;re not part of any organization.</Text>
        <Text>
          Please ask someone you know to invite you (<b>{user?.email}</b>).
        </Text>
        <Divider />
        <Text color={"gray.500"}>
          Not your account? Switch to a different one by clicking below:
        </Text>
        <LinkButton to={loginPath}>Switch Account</LinkButton>
      </Stack>
    );
  }

  return (
    <Stack spacing={4}>
      <Heading>Select organization</Heading>
      <Text>
        You are part of multiple organizations. Please select in which
        organization you want to proceed.
      </Text>
      {isSearchShown && (
        <Text>Use the search to find your organization swiftly.</Text>
      )}
      <form method="post" ref={formRef}>
        <CSRFTokenInput />
        {isSearchShown && (
          <Input
            onChange={(evt) => setSearch(evt.target.value)}
            type="text"
            placeholder="Search for organization ..."
          />
        )}
        <Stack spacing={0}>
          {isLoading
            ? [1, 2, 3].map((id) => (
                <BaseOrganizationItem
                  key={id}
                  organization={{ name: "Loading ..." }}
                  isLoading={true}
                />
              ))
            : filteredOrganizations
                .slice(0, organizationCountSearchThreshold)
                .map((organization) => (
                  <OrganizationItem
                    key={organization.id}
                    organization={organization}
                    onClick={() => {
                      if (inputRef.current) {
                        inputRef.current.value = organization.id.toString();
                      }
                      formRef.current?.submit();
                    }}
                  />
                ))}
          {additionalOrganizationsCount > 0 && (
            <Text paddingX={16} paddingY={4}>
              ... and {additionalOrganizationsCount} more. Use search to filter.
            </Text>
          )}
        </Stack>
        <Input ref={inputRef} name="organization" type="hidden" />
      </form>
    </Stack>
  );
}
