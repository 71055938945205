import { CircularProgress } from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useActiveOrganizationQuery } from "../hooks";
import { namedRoutes } from "../routes";

export function RequireOrganization({
  children,
}: {
  children: React.ReactNode;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const activeOrganizationQuery = useActiveOrganizationQuery();

  useEffect(() => {
    if (activeOrganizationQuery.error) {
      activeOrganizationQuery.error.response?.status === 404 &&
        navigate(namedRoutes.organization.select, {
          replace: true,
          state: {
            from: { pathname: location.pathname, search: location.search },
          },
        });
    }
  }, [
    activeOrganizationQuery.error,
    location.pathname,
    location.search,
    navigate,
  ]);

  if (activeOrganizationQuery.isLoading) {
    return <CircularProgress isIndeterminate />;
  }

  if (activeOrganizationQuery.isSuccess) {
    return <>{children}</>;
  }

  return null;
}
