import { Alert, AlertDescription, AlertIcon, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { updateApplicationNetworkSecurityRule } from "../../backend/api";
import {
  ApplicationId,
  ApplicationNetworkSecurityRule,
  PortalError,
} from "../../backend/types";
import {
  getQueryKeyForApplicationNetworkSecurityRules,
  useApplicationNetworkSecurityRules,
} from "../../hooks/useApplicationNetworkSecurityRules";
import { validatePortRequirements } from "../../session-management";
import { mapNetworkSecurityRuleToSmType } from "../../session-management/utils";
import { ApplicationNetworkSecurityRuleForm } from "./ApplicationNetworkSecurityRuleForm";
import {
  ApplicationNetworkSecurityRuleProps,
  ApplicationNetworkSecurityRuleSchema,
  netwokSecurityRuleSchema,
} from "./ApplicationNetworkdSecurityRuleSchema";

export function EditApplicationNetworkSecurityRuleForm({
  applicationId,
  rule,
}: ApplicationNetworkSecurityRuleProps & {
  applicationId: ApplicationId;
}) {
  const queryClient = useQueryClient();
  const securityRulesQuery = useApplicationNetworkSecurityRules(applicationId);
  const form = useForm<ApplicationNetworkSecurityRuleSchema>({
    resolver: yupResolver(netwokSecurityRuleSchema),
    defaultValues: rule,
  });
  const {
    mutateAsync: updateNetworkSecurityRuleAsync,
    ...updateNetworkSecurityRuleMutation
  } = useMutation<
    ApplicationNetworkSecurityRule,
    AxiosError<PortalError>,
    ApplicationNetworkSecurityRuleSchema
  >({
    mutationFn: async (data) => {
      const allRules =
        securityRulesQuery.data?.results
          // remove unchanged rule if exists
          ?.filter((r) => r.id !== rule.id)
          ?.map((rule) => mapNetworkSecurityRuleToSmType(rule)) ?? [];

      // add updated rule to validation
      allRules.push(mapNetworkSecurityRuleToSmType(data));

      const validationResponse = await validatePortRequirements(allRules);

      if (validationResponse.status !== 200) {
        const errors = Object.entries(validationResponse.data).map((c) => c[1]);
        throw new Error("Validation errors occured: " + errors.join(", "));
      }

      return await updateApplicationNetworkSecurityRule(applicationId, {
        ...data,
        id: rule.id,
      });
    },

    onSuccess: () => {
      form.reset(undefined, { keepValues: true });
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationNetworkSecurityRules(applicationId),
      });
    },
    onError: () => {
      form.reset(undefined, { keepValues: true, keepDirty: true });
    },
  });

  return (
    <Stack spacing={2}>
      <ApplicationNetworkSecurityRuleForm
        form={form}
        onSubmit={useCallback(
          (data) => updateNetworkSecurityRuleAsync(data),
          [updateNetworkSecurityRuleAsync],
        )}
      />
      {updateNetworkSecurityRuleMutation.isError && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>
            {updateNetworkSecurityRuleMutation.error.message}
          </AlertDescription>
        </Alert>
      )}
    </Stack>
  );
}
