import {
  OptionProps,
  Props,
  Select,
  chakraComponents,
} from "chakra-react-select";
import { useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import { Application } from "../backend";
import { ApplicationId, ApplicationListFilters } from "../backend/types";
import { usePaginatedApplicationsQuery } from "../hooks/useApplicationQuery";
import { LazyApplicationDisplay } from "./ApplicationDisplay";

const customSelectComponents = {
  Option: (props: OptionProps<ApplicationOption>) => (
    <chakraComponents.Option {...props}>
      <LazyApplicationDisplay applicationId={props.data.value} />
    </chakraComponents.Option>
  ),
};

type ApplicationOption = {
  label: string;
  value: ApplicationId;
  application: Application;
};

export function ApplicationSelect({
  filter = () => true,
  filters,
  ...props
}: Props<ApplicationOption, false> & {
  filter?: (value: Application, index: number, array: Application[]) => boolean;
  onSelect?: (value: Application | null) => void;
  filters?: ApplicationListFilters;
}) {
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const { isFetching, data, fetchNextPage, hasNextPage } =
    usePaginatedApplicationsQuery({
      ...filters,
      fulltext_search: debouncedSearchText,
    });
  const filteredApplications = useMemo(
    () =>
      (data?.pages ?? [])
        .map((page) => page.results)
        .flat()
        .filter(filter),
    [data, filter],
  );

  return (
    <Select<ApplicationOption>
      placeholder="Type to search for application to be added ..."
      isLoading={isFetching}
      inputValue={searchText}
      onInputChange={(value, action) => {
        if (action.action !== "input-blur" && action.action !== "menu-close") {
          setSearchText(value);
        }
      }}
      onChange={(option) => {
        props.onSelect?.(option?.application ?? null);
      }}
      // We don't want the value to ever be shown
      value={null}
      options={(filteredApplications ?? [])?.map((app) => ({
        value: app.id,
        label: app.name,
        application: app,
      }))}
      components={customSelectComponents}
      // Need to disable the built-in filtering as we're loading data via the API, see https://github.com/TanStack/query/discussions/3277#discussioncomment-4861535
      filterOption={null}
      onMenuScrollToBottom={() => hasNextPage && !isFetching && fetchNextPage()}
      {...props}
    />
  );
}
