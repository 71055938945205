import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Heading,
  Stack,
} from "@chakra-ui/react";
import { generatePath, useParams } from "react-router-dom";
import { LinkButton } from "../components";
import { useUserQuery } from "../hooks";
import { namedRoutes } from "../routes";
import {
  UserApplicationPermissionManagement,
  UserGroupApplicationPermissionManagement,
} from "./components";

export function UserAccess() {
  const { userId = "" } = useParams();
  const { data: user } = useUserQuery({ userId: userId });

  return (
    <Stack width="fit-content" spacing={6}>
      <Heading size="md">Permissions</Heading>
      {user?.is_superuser && (
        <Alert status="info">
          <AlertIcon alignSelf={"start"} />
          <Stack>
            <AlertTitle>This user is a superuser!</AlertTitle>
            <AlertDescription>
              Please note that this user is a <i>superuser</i> and as such has
              access to any application, independently of the permissions
              granted to them directly or via a group membership.
            </AlertDescription>
          </Stack>
        </Alert>
      )}
      <UserApplicationPermissionManagement userId={parseInt(userId)} />
      <Heading size="md">
        Inherited permissions through group membership(s)
      </Heading>
      <Accordion allowToggle>
        {user?.groups?.map((group) => (
          <AccordionItem key={group.id}>
            <Heading size="sm">
              <AccordionButton>
                {group.name}
                <AccordionIcon />
              </AccordionButton>
            </Heading>
            <AccordionPanel pb={4}>
              <UserGroupApplicationPermissionManagement
                id={group.id}
                callToActionNoPermissions={
                  <LinkButton
                    to={generatePath(namedRoutes.userGroup.access, {
                      groupId: group.id.toString(),
                    })}
                    size="sm"
                  >
                    Add permissions
                  </LinkButton>
                }
              />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Stack>
  );
}
