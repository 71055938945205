import { DateValue, createCalendar } from "@internationalized/date";
import { useLocale } from "@react-aria/i18n";
import { useRef } from "react";
import { RangeCalendarProps, useRangeCalendar } from "react-aria";
import { useRangeCalendarState } from "react-stately";
import { CalendarContainer } from "./Calendar";
import { RangeCalendarGrid } from "./RangeCalendarGrid";

export function RangeCalendar(props: RangeCalendarProps<DateValue>) {
  const { locale } = useLocale();
  const state = useRangeCalendarState({
    ...props,
    locale,
    createCalendar,
  });

  const ref = useRef(null);
  const calendarAria = useRangeCalendar(props, state, ref);

  return (
    <CalendarContainer ref={ref} {...calendarAria} state={state}>
      <RangeCalendarGrid state={state} />
    </CalendarContainer>
  );
}
